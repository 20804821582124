import {postAjax,getAjax}  from "../common/axios.js"

//获取总店课程列表
export const adminList = params => {
    return postAjax('/api/run/courseName/adminList',params);
}

//获取门店课程列表
export const courseList = params => {
    return postAjax('/api/run/courseName/list',params);
}

//获取训练目的列表
export const trainPurposeList = params => {
    return postAjax('/api/run/trainPurpose/list',params);
}

//新增训练目的
export const trainPurposeInsert = params => {
    return postAjax('/api/run/trainPurpose/insert',params);
}

//编辑训练目的
export const trainPurposeUpdate = params => {
    return postAjax('/api/run/trainPurpose/update',params);
}
//删除训练目的
export const trainPurposeDelete = params => {
    return postAjax('/api/run/trainPurpose/delete',params);
}

//新增课程
export const courseInsert = params => {
    return postAjax('/api/run/courseName/insert',params);
}
//编辑课程
export const courseUpdate = params => {
    return postAjax('/api/run/courseName/update',params);
}

//课程详情
export const courseInfo = params => {
    return postAjax('/api/run/courseName/info',params);
}

//查看教练
export const queryCourseTeacher = params => {
    return postAjax('/api/run/courseName/queryCourseTeacher',params);
}

//课程开启、关闭/设置价格
export const updateStateOrValue = params => {
    return postAjax('/api/run/courseName/updateStateOrValue',params);
}

// 课节详情
export const courseToEdit = params => {
    return getAjax('/api/course/courseToEdit',params);
}
// 编辑课节
export const courseEdit = params => {
    return postAjax('/api/course/courseEdit',params);
}

// 获取课节的教练
export const appointment = params => {
    return postAjax('/api/member/appointment/getAppointmentCourseNamePrivateDetail',params);
}

// 获取课节的教练的日程
export const getTeacherSchedule = params => {
    return postAjax('/api/member/appointment/getTeacherScheduleByStore',params);
}

// 获取会员可用资产数量
export const getAvailableAssets = params => {
    return postAjax('/api/member/appointment/getAppointmentCourseNamePrivateDetail',params);
}

// 获取会员可用资产
export const getAppointmentCards = params => {
    return postAjax('/api/member/appointment/getAppointmentCards',params);
}

// 新增私教预约
export const storeBook = params => {
    return postAjax('/api/member/appointment/storeBook',params);
}

// 编辑私教预约
export const courseTimeEditPlus = params => {
    return postAjax('/api/course/courseTimeEditPlus',params);
}

// 签到
export const courseInfoSign = params => {
    return postAjax('/api/course/courseInfoSign',params);
}

// 候补入课
export const courseAfterBatchTurnClass = params => {
    return postAjax('/api/course/courseAfterBatchTurnClass',params);

}
// 删除课程
export const courseNameDelete = params => {
    return postAjax('/api/course/courseNameDelete',params);

}
// 取消预约
export const storeBookCancel = params => {
    return postAjax('/api/member/appointment/storeBookCancel',params);
}

// 取消候补
export const storeBookAfterCancel = params => {
    return postAjax('/api/member/appointment/storeBookAfterCancel',params);
}
