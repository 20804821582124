<template>
    <div>
        <div id="myChart2" class="h250 mt20"></div>
    </div>
</template>

<script setup>
    import { reactive, watch, onBeforeUnmount, onMounted } from 'vue';
    import { bmiList } from '@/api/market.js';
    import * as echarts from 'echarts';
    let myChart2 = null
    const propData = defineProps({
		info: Object,
        show: Boolean,
        value: Number
	})
    const pageData = reactive({
        option: {
            title: {
                text: 'BMI对照表',
                textStyle:{ fontSize:12}
            },
		  	tooltip: {
		  	  	trigger: 'axis',
                axisPointer: { type: 'cross', show: true },
		  	},
            color: ['#5470c6', '#91cc75', '#fac858', '#fc8452'],
		  	grid: {
		  	  	left: '3%',
		  	  	right: '4%',
		  	  	bottom: '3%',
		  	  	containLabel: true
		  	},
		  	xAxis: [{
		  	  	type: 'category',
		  	  	boundaryGap: false,
		  	  	data: [],
		  	}],
		  	yAxis: [{
		        type : 'value',
                scale : true,
                max : 30,
                min : 0,
                splitNumber : 10,
                boundaryGap : [ 0.2, 0.2 ]
            }],
		  	series: [{
		      	name: '低体重',
		      	type: 'line',
				data: [],
		      	symbol: 'none',
                areaStyle: {},
                zlevel: 3,
                endLabel: {
      			  show: true,
      			  formatter: function (params) {
      			    return '低体重';
      			  },
				  offset: [-30, 10]
      			},
		    }, {
		      	name: '正常',
		      	type: 'line',
		      	data: [],
                areaStyle: {},
		      	symbol: 'none',
                zlevel: 2,
                endLabel: {
      			  show: true,
      			  formatter: function (params) {
      			    return '正常';
      			  },
				  offset: [-30, 10]
      			},
		    }, {
		      	name: '超重',
		      	type: 'line',
		      	data: [],
                areaStyle: {},
		      	symbol: 'none',
                zlevel: 1,
                endLabel: {
      			  show: true,
      			  formatter: function (params) {
      			    return '超重';
      			  },
				  offset: [-30, 10]
      			},
		    }, {
                type:'scatter',
                zlevel: 4,
                data:[]
            }]
		}
    })
    watch(() => propData.show, (newVal, oldVal) => {
        if (newVal) {
            getInfo()
        }
    })
    /**
     * 获取身高曲线
     */
    function getInfo(){
        let params = {
            sex: propData.info.sex,
            ageMin: propData.info.ageMin,
            ageMax: propData.info.ageMax
        }
        bmiList(params).then((res) => {
            if (res.code == 0) {
				myChart2 = echarts.init(document.getElementById('myChart2'))
				pageData.option.xAxis[0].data = [] // x轴
				pageData.option.series[0].data = [] // 潜在会员
				pageData.option.series[1].data = [] // 正式会员
                let arr = [], index = ''
				res.data.forEach((item, i) => {
					pageData.option.xAxis[0].data.push(item.age)
					pageData.option.series[0].data.push(item.data1)
                    pageData.option.series[1].data.push(item.data2)
                    arr.push(item.data3)
                    if (item.age == propData.info.age) {
						index = i
					}
				})
                pageData.option.series[2].data = arr
                pageData.option.yAxis.max = Math.max(...arr)
                pageData.option.series[3].data = [[index, propData.value]]
                myChart2.clear()
				myChart2.setOption(pageData.option, true);
				window.addEventListener("resize",function(){
        		    myChart2.resize();
        		});
			}
        })
    }
    onMounted(() => {
        getInfo()
    })
    onBeforeUnmount(() => {
      if (!myChart2) {
        return
      }
      myChart2.dispose()
      myChart2 = null
    })
</script>
<style type='css/style' scoped>

</style>