<template>
	<workArea :barlist="pageData.barlist">
		<div class="card_manage bg-fff pl16 pt10 pr16">
			<el-tabs v-model="tabNum" @tab-click="handleClick">
				<el-tab-pane label="员工管理" name="0" v-if="utils.getMenuRole(33100)"></el-tab-pane>
				<!-- <el-tab-pane label="考勤记录" name="1"></el-tab-pane> -->
				<el-tab-pane label="岗位管理" name="1" v-if="utils.getMenuRole(33101)"></el-tab-pane>
			</el-tabs>
			<component :is="components[tabNum]" :tab="tabNum"></component>
		</div>
	</workArea>
</template>
<script>
  export default {
    name: 'Employ'
  }
</script>
<script setup>
	import { ref, reactive, onMounted, onBeforeUnmount } from 'vue';
	import { useRouter } from 'vue-router';
	import workArea from '@/components/branch-store/workArea.vue';
	import Staff from '@/views/branch-store/store/employ/staff.vue'; //潜在会员
	import Post from '@/views/branch-store/store/employ/post.vue'; //公海池
	import utils from '@/common/utils.js';
	const router = useRouter();
	const components = [Staff,Post];
	const tabNum = ref('0');
	var menuId = [33100,33101]; //会员有权限tab默认项
	for(var i = 0;i < menuId.length;i++){
		if(utils.getMenuRole(menuId[i])){
			tabNum.value = i.toString();
			break;
		}
	}
	const pageData = reactive({
		barlist:[{ name : '首页' },{ name : '机构中心' },{ name : '员工管理'}]// 面包屑
	})
	function handleClick(tab){
		pageData.barlist[2].name = tabNum.value == 0 ? '员工管理' :'岗位管理';
		localStorage.setItem('employ', tab.props.name)
	}
	onMounted(()=> {
      if (localStorage.getItem('employ') != null) {
          tabNum.value = localStorage.getItem('employ')
		  pageData.barlist[2].name = tabNum.value == 0 ? '员工管理' :'岗位管理';
	    }
	})
    onBeforeUnmount(() => {
		localStorage.removeItem('employ')
	})
</script>

<style scoped lang="stylus">
	
</style>
