<template>
	<div class="bg-fff pt16">
		<!-- 查询 -->
		<div class="rx-sc mb25">
			<div class="mr16">
				<el-input v-model="pageData.searchForm.searchKeys" placeholder="输入学生姓名/手机号搜索" />
			</div>
			<el-button type="primary" @click="searchTable" :icon="Search">搜索</el-button>
		</div>
		<!-- 数据表格 -->
		<div>
			<el-table  :data="pageData.tableData" stripe style="width: 100%">
			    <el-table-column prop="realName" label="学生姓名" >
					<template #default="scope">
						<a href="javascript:;" class="color-409EFF tool_btn" @click="addStaff(scope.row)">{{scope.row.realName}}</a>
					</template>
				</el-table-column>
			    <el-table-column prop="telephoneNum" label="手机号" />
			    <el-table-column prop="sex" label="性别" />
			    <el-table-column prop="age" label="年龄" />
			    <el-table-column prop="createTime" label="信息提交时间" />
			    <el-table-column prop="createTime" label="创建展厅个数" />
			    <el-table-column prop="upStatusName" label="跟进状态" />
			    <el-table-column prop="upStatusName" label="跟进人" />
			</el-table>
			<div class="mt20 rx-ec" v-show="pageData.tableData.length > 0">
			    <el-pagination
			      v-model:currentPage="pageData.searchForm.page"
			      :page-sizes="[15,20,30,50,100]"
			      :page-size="pageData.searchForm.limit"
			      layout="total, sizes, prev, pager, next, jumper"
			      :total="pageData.totalCount"
			      @size-change="pageSizeChange"
			      @current-change="()=>{getList()}"
			    >
			    </el-pagination>
			  </div>
		</div>
	</div>
</template>
<script setup>
	import { ref, reactive, onMounted } from 'vue';
	import { ElMessage} from 'element-plus'
	import {Search } from '@element-plus/icons-vue'
	import { selectStoreMembersHall } from '@/api/showroom.js'
	const pageData = reactive({
		tableData:[],
		totalCount : 0,
		searchForm:{
			page:1,
			limit:15,
			searchKeys:'',
		}
	})
	
	function pageSizeChange(limit){
		pageData.searchForm.limit = limit;
		searchTable();
	}
	//获取员工列表数据
	function searchTable(){
		pageData.searchForm.page = 1;
		getList();
	};
	getList();
	function getList(){
		selectStoreMembersHall(pageData.searchForm).then((res)=>{
			if(res.code == 0){
				pageData.tableData = res.data;
				pageData.totalCount = res.count;
			}
		})
	}
	
</script>

<style scoped >
	
</style>
