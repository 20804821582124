<template>
	<div class="rx-cc pt100 pb100">
		<div class="w330 mr30 pl50 pr50"  id="person">
			<div class="color-666 ft16 mb40 t_a_c">1:个人实名认证</div>
			<div class="bg-FBFBFD pt50 pb60 cx-cc" style="border: 1px solid #F0F1F7;">
				<img class="w50 mb20" src="@/assets/authentication1.png" alt="">
				<div class="mb50 w210 ft14 h60" style="line-height: 20px;">在进行企业实名认证前，经办人需要先完成个人实名认证</div>
				<el-button @click="toPage(pageData.psnInfo.realnameStatus == 1? '':pageData.psnInfo.authUrl)" :disabled="!pageData.psnInfo.authUrl" :type="pageData.psnInfo.realnameStatus == 1 ? 'success':'primary'" :plain="pageData.psnInfo.realnameStatus == 1">{{pageData.psnInfo.realnameStatus == 1 ? '认证完成' : '立即认证'}}</el-button>
				<p class="color-999 ft14 opacity0">请完成前一步骤</p>
			</div>
		</div>
		<div class="w330 mr30 pl50 pr50" id="enterprise">
			<div class="color-666 ft16 mb40 t_a_c">2:企业实名认证</div>
			<div class="bg-FBFBFD pt50 pb60 cx-cc" style="border: 1px solid #F0F1F7;">
				<img class="w50 mb20" src="@/assets/authentication2.png" alt="">
				<div class="mb50 w210 ft14 h60" style="line-height: 20px;">完成认证后，将为机构生成认证书，签署更安全</div>
				<el-button @click="toPage(pageData.orgInfo.realnameStatus == 1? '':pageData.orgInfo.authUrl)" :disabled="!pageData.psnInfo.realnameStatus || !pageData.orgInfo.authUrl" :type="pageData.orgInfo.realnameStatus == 1 ? 'success':'primary'" :plain="pageData.orgInfo.realnameStatus == 1">{{pageData.orgInfo.realnameStatus == 1 ? '认证完成' : '立即认证'}}</el-button>
				<p class="color-999 ft14" :class="{opacity0:pageData.psnInfo.realnameStatus == 1}">请完成前一步骤</p>
			</div>
		</div>
		<div class="w330 mr30 pl50 pr50" id="sign">
			<div class="color-666 ft16 mb40 t_a_c">3:签署授权委托书</div>
			<div class="bg-FBFBFD pt50 pb60 cx-cc" style="border: 1px solid #F0F1F7;">
				<img class="w50 mb20" src="@/assets/authentication3.png" alt="">
				<div class="mb50 w210 ft14 h60" style="line-height: 20px;">完成签署后，在卡蜜助教发起的合同将自动完成机构方签署，无需反复授权，提升合同签署效率</div>
				<el-button @click="toPage(pageData.sealInfo.authStatus == 1? '':pageData.sealInfo.authUrl)" :disabled="!pageData.orgInfo.realnameStatus || !pageData.sealInfo.authUrl" :type="pageData.sealInfo.authStatus == 1 ? 'success':'primary'" :plain="pageData.sealInfo.authStatus == 1">{{pageData.sealInfo.authStatus == 1 ? '认证完成' : '立即认证'}}</el-button>
				<p class="color-999 ft14" :class="{opacity0:pageData.orgInfo.realnameStatus == 1}">请完成前一步骤</p>
			</div>
		</div>
	</div>
</template>

<script setup>
	import { ref, reactive } from 'vue';
	import { getEsignCertPsnInfo,getEsignCertOrgInfo,getEsignCertSealInfo } from '@/api/contract.js';
	const pageData = reactive({
		psnInfo:{},
		orgInfo:{},
		sealInfo:{},
	})
	getEsignCertPsnInfo().then(res=>{
		pageData.psnInfo = res.data;
		if(res.data.realnameStatus == 1){
			getOrgInfo();
		}
	})
	function getOrgInfo(){
		getEsignCertOrgInfo().then(res=>{
			pageData.orgInfo = res.data;
			if(res.data.realnameStatus == 1){
				getSealInfo();
			}
		})
	}
	function getSealInfo(){
		getEsignCertSealInfo().then(res=>{
			pageData.sealInfo = res.data;
		})
	}
	function toPage(url){
		if(url){
			window.open(url);
		}
	}
</script>

<style scoped>
	.btns{
		display: inline-block;
	}
	.success{
		background-color: white !important;
		border: 1px solid #00B809 !important;
		color: #00B809 !important;
	}
</style>