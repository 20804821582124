<template>
    <div class="hh100 posi-r">
        <div class="posi-a container rx-wbs">
            <div class="mt40 w110 cx-cc" v-for="(item, index) in pageData.list" :key="index">
                <div class="ft20 color-666">{{ item.name }}</div>
                <div class="img">
                    <img class="ww100 hh100" :src="`https://image.taokatao.cn/imgs/market/icon${item.val}.png`" alt="">
                </div>
                <div class="d_i_b pl10 pr10 store_bg w80 mr10 mb12">
					<el-checkbox v-model="item.checkbox" @change="changeBox"><span class="color-333">展示</span></el-checkbox>
				</div>
            </div>
        </div>
    </div>
</template>

<script setup>
    import { reactive } from 'vue';
    const pageProp = defineProps({
       info: Object,
       type: Number 
    })
    const pageData = reactive({
        list: [{
            name: '驼背',
            val: 1,
            checkbox: false,
        }, {
            name: '骨盆前倾',
            val: 2,
            checkbox: false,
        }, {
            name: '骨盆后倾',
            val: 3,
            checkbox: false,
        }, {
            name: '颈椎侧曲',
            val: 4,
            checkbox: false,
        }, {
            name: '圆肩探颈',
            val: 5,
            checkbox: false,
        }, {
            name: '弯腰弓背',
            val: 6,
            checkbox: false,
        }, {
            name: '膝超伸',
            val: 7,
            checkbox: false,
        }, {
            name: 'O型腿',
            val: 8,
            checkbox: false,
        }, {
            name: 'X型腿',
            val: 9,
            checkbox: false,
        }]
    })
    pageProp.info.postureOpens.length && pageProp.info.postureOpens.split(',').forEach(item => {
        pageData.list.find((v)=> {
            if (v.val == item) {
                v.checkbox = true
            }
        })
    });
    function changeBox() {
        let arr = []
        pageData.list.forEach(item => {
            if (item.checkbox) {
                arr.push(item.val)
            }
        })
        pageProp.info.postureOpens = arr.join(',')
    }
</script>
<style type='css/style' scoped>
    .container {
        width: 600px;
    	top: 50%;
    	left: 50%;
        transform: translate(-50%, -50%);	
    }
    .container .img {
        width: 43px;
        height: 211px;
        margin-top: 20px;
        margin-bottom: 20px;
    }
    .store_bg {
			background-color: #F7F7F7;
			border: 1px solid #EAF0F8;
			border-radius: 1px;
		}
</style>