<template>
	<div class="pt20">
		<el-table ref="table" :data="tableData.list" stripe style="width: 100%" height="65vh">
			<el-table-column prop="realName" label="微信昵称" min-width="110" />
			<el-table-column prop="telephoneNum" label="手机号" min-width="110" />
			<el-table-column prop="buyTemplateNum" label="已购买模板数" min-width="110" />
			<el-table-column prop="buyTemplateMoney" label="模板付费金额" min-width="110" />
			<el-table-column prop="buyOpenNumMoney" label="展厅次数充值金额" min-width="110" />
			<el-table-column prop="openNumRemain" label="模板剩余未使用总预览次数" min-width="110" />
			<el-table-column prop="hasHallNum" label="已创建展厅数" min-width="110" />
			<el-table-column prop="createTime" label="注册时间" min-width="120" />
		</el-table>
		<div class="mt20 rx-ec">
			<el-pagination v-model:currentPage="searchForm.page" :page-sizes="[15,20,30,50,100]"
				:page-size="searchForm.limit" layout="total, sizes, prev, pager, next, jumper"
				:total="tableData.count" @size-change="pageSizeChange" @current-change="()=>{getList()}">
			</el-pagination>
		</div>
	</div>
</template>

<script setup>
	import {
		ref,
		reactive
	} from 'vue';
	
	import {selectHallPersonSimple} from '@/api/showroom.js'
	const searchForm = reactive({
		page: 1,
		limit: 15
	})
	const tableData = reactive({
		list: [],
		count: 0
	})

	//搜索table
	function searchTable() {
		searchForm.page = 1;
		getList();
	}
	//切换分页数量
	function pageSizeChange(limit) {
		searchForm.limit = limit;
		searchTable();
	}
	getList();

	function getList() {
		selectHallPersonSimple(searchForm).then((res)=>{
			if(res.data){
				tableData.list = res.data;
				tableData.count = res.data;
			}else{
				tableData.list =[];
				tableData.count = 0;
			}
		})
	}

	
</script>
<style scoped>
	
</style>