import dict from './dict.js'
import {getLocalStorage} from './common.js'
import moment from 'moment';

var funcs = {};
Object.assign(funcs,dict);

// 判断有效期是否过期
funcs.expired=(timer) => {
    return new Date(timer).getTime() - new Date().getTime()
}

// 表单通用是否显示收起、展开
funcs.initQuery = (queryItemShowAll,queryBox)=>{
    if(queryBox.value.offsetHeight > 60){
        queryItemShowAll.value = 1;
    }
}
// 获取一周日天的标题
funcs.getWeekArray =(startDate)=>{
    var weekStrObj = {"1":"周一","2":"周二","3":"周三","4":"周四","5":"周五","6":"周六","7":"周日"};
    var weekArray = [];
    for(var i=0;i<7;i++){
        var dateStr = moment(startDate).add(i, 'days').format('DD');
        var weekStr = weekStrObj[moment(startDate).add(i, 'days').format('E')];
        weekArray.push(dateStr + '/' + weekStr);
    }
    return weekArray;
}
// 获取周一和周日
funcs.getWeekDays =(num)=>{
  num = num || 0;
  let date = []
  let weekOfday = parseInt(moment().format('E')) // 计算今天是这周第几天 周日为一周中的最后一天
  let start = moment().add(1 - weekOfday + 7*num, 'days').format('YYYY-MM-DD') // 周一日期
  let end = moment().add(7 - weekOfday + 7*num, 'days').format('YYYY-MM-DD') // 周日日期
  return [start,end];
}
//日期时间格式化  国际时间 -> 2022-12-13 21:11:11
funcs.getFormatDate = (data)=>{
	return data ? moment(data).format('YYYY-MM-DD HH:mm:ss') : '';
}

//日期时间格式化  20221213121314 -> 2022-12-13 12:13:14
funcs.getFormatDate2 = (data)=>{
	if(data){
		data = data.toString();
		var y = data.substring(0,4);
		var mt = data.substring(4,6);
		var d = data.substring(6,8);
		var h = data.substring(8,10);
		var m = data.substring(10,12);
		var s = data.substring(12,14);
		return y +'-'+ mt +'-'+ d +' '+ h +':'+ m +':'+ s
	}else{
		return '';
	}
}
// 时间戳转日期
funcs.getFormatDate3 = (now) => {   
    var date = new Date(now)
    var Y = date.getFullYear() + '-'
    var M = (date.getMonth()+1 < 10 ? '0'+(date.getMonth()+1) : date.getMonth()+1) + '-'
    var D = (date.getDate() < 10 ? '0'+ date.getDate() : date.getDate()) + ' '
    var h = (date.getHours() < 10 ? '0'+ date.getHours() : date.getHours()) + ':'
    var m = (date.getMinutes() < 10 ? '0'+ date.getMinutes() : date.getMinutes()) + ':'
    var s = (date.getSeconds() < 10 ? '0'+ date.getSeconds() : date.getSeconds())
    return Y + M + D + h + m + s;

 } 
 // 时间对象
 
 funcs.getDateObj = (now) => {   
     var date = now ? new Date(now) : new Date();
     var Y = date.getFullYear() ;
     var M = date.getMonth()+1 < 10 ? '0'+(date.getMonth()+1) : date.getMonth()+1;
     var D = date.getDate() < 10 ? '0'+ date.getDate() : date.getDate();
     var W = date.getDay();
     var h = date.getHours() < 10 ? '0'+ date.getHours() : date.getHours();
     var m = date.getMinutes() < 10 ? '0'+ date.getMinutes() : date.getMinutes();
     var s = date.getSeconds() < 10 ? '0'+ date.getSeconds() : date.getSeconds();
	 var time = date.getTime();
	 var text = Y+'-'+M+'-'+D+' '+h+':'+m+':'+s;
     return {
		 Y,M,D,W,h,m,s,time,text
	 };
  } 
//获取星级对应的数字  1->5 2->4 3->3 4->2 5->1
funcs.getStartNum = (data)=>{
	var arr = [5,4,3,2,1];
	return data ? arr[Number(data)-1] : 1;
}

// 数组去除 空、undefined、null 项
funcs.trimArray = (array) => { 
    var a = array.filter((val) => {
       return val
     })
     return a
 }

 // 对象去除 空、undefined、null 项
funcs.trimObject = (obj) => { 
    Object.keys(obj).map((item) => {
        if (obj[item] == '' || obj[item] == undefined || obj[item] == null){
            delete obj[item]
        }
     })
    return obj
}

//判断菜单权限
funcs.getMenuRole = (id)=>{
	var menuList = getLocalStorage('businessUserData').allMenuId || [];
	return id ? menuList.indexOf(id) > -1 : true;
}
// base64转文件
funcs.base64ImgtoFile = (dataurl, filename = 'file') => {
    let arr = dataurl.split(',')
    let mime = arr[0].match(/:(.*?);/)[1]
    let suffix = mime.split('/')[1]
    let bstr = atob(arr[1])
    let n = bstr.length
    let u8arr = new Uint8Array(n)
    while (n--) {
        u8arr[n] = bstr.charCodeAt(n)
    }
    return new File([u8arr], `${filename}.${suffix}`, {
        type: mime
    })
}
// 两个日期之间的天数  sDate1和sDate2是2006-12-18格式
funcs.datedifference = (sDate1, sDate2) => {    
    let dateSpan,iDays
    sDate1 = Date.parse(sDate1)
    sDate2 = Date.parse(sDate2)
    dateSpan = sDate2 - sDate1
    dateSpan = Math.abs(dateSpan)
    iDays = Math.floor(dateSpan / (24 * 3600 * 1000))
    return iDays+1
 }
 // 两个日期之间的月数 date1和date2是2019-3-12格式
 funcs.MonthsBetw = (date1, date2)=>{
    //用-分成数组
    date1 = date1.split("-");
    date2 = date2.split("-");
    //获取年,月数
    var year1 = parseInt(date1[0]),
        month1 = parseInt(date1[1]),
        year2 = parseInt(date2[0]),
        month2 = parseInt(date2[1]),
        //通过年,月差计算月份差
        months = (year2 - year1) * 12 + (month2 - month1) + 1;
    return months;
}

funcs.isRepeat =(v) =>{
    let obj = {}
    for(let i in v){
      if(obj[v[i]]){
        return true
      }
      obj[v[i]] = true
    }
    return false
}

funcs.getPath =(treeData, targetId) => {
	var arrData = [];
	for (let i = 0; i < treeData.length; i++) {
		var arr = treeData[i].list.filter(n=>n.id == targetId);
		if(arr.length > 0){
			return [treeData[i].id,targetId];
		}
	}
	return arrData;
    // let path = [];
    // let toggle = false;
    // for (let i = 0; i < treeData.length; i++) {
    //   let item = treeData[i];
    //   path.push(item.id);
    //   if (item.id === targetId) {
    //     toggle = true;
    //     break;
    //   } else {
    //     if (item.list) {
    //       toggle = funcs.findPath(item.list, targetId, path, toggle);
    //       if (!toggle) {
    //         path.pop();
    //       } else {
    //         break;
    //       }
    //     } else {
    //       path.pop();
    //     }
    //   }
    // }

    // if (toggle) {
    //   return path;
    // } else {
    //   return [];
    // }
  },
  funcs.findPath = (nodes, targetId, path, toggle) => {
    for (let i = 0; i < nodes.length; i++) {
      let item = nodes[i];
      path.push(item.id);
      if (item.id === targetId) {
        return true;
      } else {
        if (item.list) {
          toggle = funcs.findPath(item.list, targetId, path, toggle);
          if (toggle) {
            return toggle;
          } else {
            path.pop();
          }
        } else {
          path.pop();
        }
      }
    }
    return toggle;
  }
export default funcs;