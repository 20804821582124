<template>
    <div class="rx-bc hh100">
        <div class="w375 hh100">
            <div class="rx-bc bg-fff box mb20">
                <div class="rx-c">
                    <span v-if="!pageData.show" class="l_h32">{{ pageProp.info.pliableTitle }}</span>
                    <el-input v-else v-model="pageProp.info.pliableTitle" maxlength="20" placeholder="请输入模板名称" />
                    <el-icon v-if="!pageData.show" :size="14" color="#409EFC" class="c_p ml5" @click="pageData.show = true">
                        <EditPen />
                    </el-icon>
                    <el-button v-else class="ml5" type="primary"  @click="pageData.show = false">完成</el-button>
                </div>
                <el-switch v-model="pageProp.info.openPliable" :active-value="1" :inactive-value="0" />
            </div>
            <div class="container cx-c o_y_s">
                <div class="card mb20" v-for="(item, index) in pageProp.pliableList" :key="index">
                    <div class="card-top rx-bc pl10 pr15">
                        <div class="ft16 fwb">测试项目：{{ item.name }}</div>
                        <el-switch v-model="item.ifOpen" :active-value="1" :inactive-value="0" />
                    </div>
                    <div class="card-box bg-fff">
                        <div class="card-box-top rx-bc">
                            <div>
                                <div class="ft15 color-666">{{ item.tags.join(',') }}</div>
                            </div>
                            <el-icon :size="14" color="#409EFC" class="c_p ml5" @click="modify(item, 1)">
                                <EditPen />
                            </el-icon>
                        </div>
                        <div class="card-box-container rx-bc">
                            <div class="img">
                                <el-image
                                    class="ww100"
                                    :src="`https://image.taokatao.cn/imgs/market/${filtersType(item.name).text}_1.png`"
                                    :preview-src-list="filtersType(item.name).list"
                                    :initial-index="0"
                                    fit="cover"
                                />
                                <div class="color-666 mt10 mb25">{{item.name}}: 10%</div>
                            </div>
                            <div class="img">
                                <el-image
                                    class="ww100"
                                    :src="`https://image.taokatao.cn/imgs/market/${filtersType(item.name).text}_2.png`"
                                    :preview-src-list="filtersType(item.name).list"
                                    :initial-index="1"
                                    fit="cover"
                                />
                               
                                <div class="color-666 mt10 mb25">{{item.name}}: 50%</div>
                            </div>
                            <div class="img">
                                <el-image
                                    class="ww100"
                                    :src="`https://image.taokatao.cn/imgs/market/${filtersType(item.name).text}_3.png`"
                                    :preview-src-list="filtersType(item.name).list"
                                    :initial-index="2"
                                    fit="cover"
                                />
                                <div class="color-666 mt10 mb25">{{item.name}}: 100%</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="w375 hh100">
            <div class="rx-bc bg-fff box mb20">
                <div class="rx-c">
                    <span v-if="!pageData.show1" class="l_h32">{{ pageProp.info.qualityTitle }}</span>
                    <el-input v-else v-model="pageProp.info.qualityTitle" maxlength="20" placeholder="请输入模板名称" />
                    <el-icon v-if="!pageData.show1" :size="14" color="#409EFC" class="c_p ml5" @click="pageData.show1 = true">
                        <EditPen />
                    </el-icon>
                    <el-button v-else class="ml5" type="primary"  @click="pageData.show1 = false">完成</el-button>
                </div>
                <el-switch v-model="pageProp.info.openQuality" :active-value="1" :inactive-value="0" />
            </div>
            <div class="container cx-c o_y_s">
                <div class="card mb20" v-for="(item, index) in pageProp.qualityList" :key="index">
                    <div class="card-top rx-bc pl10 pr15">
                        <div class="ft16 fwb">测试项目：{{ item.name }}</div>
                        <el-switch v-model="item.ifOpen" :active-value="1" :inactive-value="0" />
                    </div>
                    <div class="card-box bg-fff">
                        <div class="card-box-top rx-bc">
                            <div>
                                <div class="ft15 color-666">{{ item.tags.join(',') }}</div>
                            </div>
                            <el-icon :size="14" color="#409EFC" class="c_p ml5" @click="modify(item, 2)">
                                <EditPen />
                            </el-icon>
                        </div>
                        <div class="card-box-container cx-c pb35">
                            <div class="rx-bc mb12">
                                <div class="ww25 t_a_c color-808080">标准</div>
                                <div class="ww25 t_a_c color-808080">中等</div>
                                <div class="ww25 t_a_c color-808080">良好</div>
                                <div class="ww25 t_a_c ft18 fwb color-ED2169">优秀</div>
                            </div>
                            <div class="bar posi-r">
                                <div class="posi-a div1"></div>
                                <div class="posi-a div2"></div>
                                <div class="posi-a div3"></div>
                            </div>
                        </div>
                    </div>
                </div>
                <el-button class="ww100" v-if="pageProp.qualityList.length<10" type="primary" plain :icon="Plus" @click="addList">添加一条</el-button>
            </div>
        </div>
    </div>
    <el-dialog
        v-model="pageData.dialogVisible"
        :title="pageData.title"
        width="530px"
        :before-close="handleClose"
    >
        <div class="p20">
            <el-form ref="ruleFormRef" :model="pageData.ruleForm" :rules="rules" label-width="120px">
                <el-form-item label="项目名称：" prop="name">
                    <el-input class="w200" v-model="pageData.ruleForm.name" :disabled="pageData.title != '添加'" maxlength="6" placeholder="请输入" />
                </el-form-item>
                <el-form-item label="项目内容：">
                    <el-tag class="mr10 mb10" v-for="(item,index) in pageData.ruleForm.labels" :key="index" type='info' size="large" closable :disable-transitions="false" @close="deleLabel(index,1)"> {{ item }}</el-tag>
				    <el-input class="w200 mb10" v-show="pageData.ruleForm.labels.length < 5"  maxlength="5" placeholder="请输入" v-model="pageData.labelText" @keyup.enter="addLabel" @blur="addLabel"/>
                </el-form-item>
            </el-form>
        </div>
        <template #footer>
            <span class="dialog-footer">
                <el-button @click="handleClose">取消</el-button>
                <el-button type="primary" @click="onSubmit">确定</el-button>
            </span>
        </template>
    </el-dialog>
</template>

<script setup>
    import { ref, reactive } from 'vue';
    import { EditPen, Plus } from '@element-plus/icons-vue'
    import { ElMessage} from 'element-plus'
    const ruleFormRef = ref()
    const pageProp = defineProps({
       info: Object,
       pliableList: Array,
       qualityList: Array
    })
    const pageData = reactive({
        show: false,
        show1: false,
        srcList: [],
        dialogVisible: false,
        title: '',
        ruleForm: {
            name: '',
            labels: [],
            oldLabels: []
        },
        labelText: '',
        disable: false,
        rowObj:{},
    })
    const rules = {
        name: [{ required: true, message: '请输入项目名称', trigger: 'blur' }]
    }
    /**
     * 字段过滤过滤展示
     */
    function filtersType(val){
        let text = '', list = []
        let productTypeList = [
	    	{value: 1, label: '腿' },
	    	{value: 2, label: '胯' },
	    	{value: 3, label: '腰' },
	    	{value: 4, label: '肩' },
	    	{value: 5, label: '脚背' },
	    ]
        productTypeList.forEach((item) => {
            if (item.label == val) {
                text = item.value
    		}
        })
        switch (text) {
		    case 1:
    	    	list = [
                    'https://image.taokatao.cn/imgs/market/1_1.png',
                    'https://image.taokatao.cn/imgs/market/1_2.png',
                    'https://image.taokatao.cn/imgs/market/1_3.png'
                ]
		        break;
            case 2:
    	    	list = [
                    'https://image.taokatao.cn/imgs/market/2_1.png',
                    'https://image.taokatao.cn/imgs/market/2_2.png',
                    'https://image.taokatao.cn/imgs/market/2_3.png'
                ]
		        break;
            case 3:
    	    	list = [
                    'https://image.taokatao.cn/imgs/market/3_1.png',
                    'https://image.taokatao.cn/imgs/market/3_2.png',
                    'https://image.taokatao.cn/imgs/market/3_3.png'
                ]
		        break;
            case 4:
    	    	list = [
                    'https://image.taokatao.cn/imgs/market/4_1.png',
                    'https://image.taokatao.cn/imgs/market/4_2.png',
                    'https://image.taokatao.cn/imgs/market/4_3.png'
                ]
		        break;
            case 5:
    	    	list = [
                    'https://image.taokatao.cn/imgs/market/5_1.png',
                    'https://image.taokatao.cn/imgs/market/5_2.png',
                    'https://image.taokatao.cn/imgs/market/5_3.png'
                ]
		        break;
        }
        return { text, list }
    }
    /**
     * 编辑调起弹框
     */
    function modify(row, type) {
        pageData.rowObj = row
        pageData.disable = type == 1 ? true : false
        pageData.ruleForm.name = row.name
        pageData.ruleForm.labels = row.tags
        pageData.ruleForm.oldLabels = JSON.parse(JSON.stringify(row.tags))
        pageData.title = '编辑'
        pageData.dialogVisible = true
    }
    /**
     * 新增
     */
    function addList() {
        pageData.title = '添加'
        pageData.dialogVisible = true
    }
    /**
     * 增加标签
     */
	function addLabel(e){
		if(pageData.labelText){
			pageData.ruleForm.labels.push(pageData.labelText);
			pageData.labelText = '';
		}
	}
    /**
     * 删除标签
     */
	function deleLabel(index){
		pageData.ruleForm.labels.splice(index,1)
	}
    /**
     * 取消弹框
     */
    function handleClose() {
        ruleFormRef.value.resetFields()
        pageData.rowObj.tags = pageData.ruleForm.oldLabels
        pageData.dialogVisible = false
    }
    /**
     * 确定弹框
     */
    function onSubmit() {
        ruleFormRef.value.validate((valid) => {
                if (valid) {
                    if (!pageData.ruleForm.labels.length) {
                        ElMessage.warning('项目内容不能为空')
                        return
                    }
                    if (pageData.title == '添加') {
                        pageProp.qualityList.push({
                            name: pageData.ruleForm.name,
                            tags: pageData.ruleForm.labels,
                            ifOpen: 1
                        })
                    } else {
                        pageData.rowObj.name = pageData.ruleForm.name
                        pageData.rowObj.tags = pageData.ruleForm.labels
                    }
                    pageData.dialogVisible = false
                } else {
                  console.log('error submit!!');
                  return false;
                }
            })
    }
</script>
<style type='css/style' scoped>
.box {
    padding: 5px 19px 5px 28px;
}
.container {
    height: 92%;
    padding: 24px;
    background: #FFF;
}
.card {
    background: #FFF3F9;
    box-shadow: 0px 0px 13px 0px rgba(215,207,211,0.5000);
}
.card-top {
    padding-top: 16px;
    padding-bottom: 14px;
}
.card-top div {
    color: #2E3F51
}
.card-box {
    padding-left: 11px;
    padding-right: 15px;
    border-radius: 8px
}
.card-box-top {
    padding-top: 19px;
    padding-bottom: 19px;
}
.card-box-container .img {
    width: 30%;
}
.bar {
    height: 10px;
    width: 100%;
    border-radius: 5px;
    background: linear-gradient(90deg, #EB5835 0%, #FFD753 32%, #90D80C 65%, #01C4F2 100%);
}
.bar div{
    width: 2px;
    height: 10px;
    background: #FFF;
}
.bar .div1 {
    left: 25%;
}
.bar .div2 {
    left: 50%;
}
.bar .div3 {
    left: 75%;
}
</style>