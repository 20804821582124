<template>
	<div class="bg-fff pt16">
		<!-- 查询 -->
		<div class="rx-sc mb25">
			<div class="mr16">
				<el-input v-model="pageData.searchForm.searchKeys" placeholder="输入员工姓名、手机号" />
			</div>
			<el-button type="primary" @click="searchTable" :icon="Search">搜索</el-button>
		</div>
		<!-- 按钮区域 -->
		<div class="mb16 rx-sc">
			<el-button type="primary" @click="()=>{addStaff()}">新增员工</el-button>
			<el-button type="danger" plain @click="deleStaff" >批量删除</el-button>
			<el-button @click="resetPassword">重置密码</el-button>
			<el-button v-if="getLocalStorage('relevancy')" @click="synchro">同步门店员工</el-button>
			<el-button @click="binding">一键绑定</el-button>
		</div>
		<div>
			<CommonTip text="初始密码8个8,登录账号用作登录移动端和电脑端! 当开通平台其他分店主体产品时，可一键同步主体里的员工信息，后可手动操作两边的账号绑定，将两侧的身份合一"/>
		</div>
		<!-- 数据表格 -->
		<div>
			<el-table ref="satffTable" :data="pageData.tableData" stripe style="width: 100%" @selection-change="selectionChange">
				<el-table-column type="selection" width="55" />
			    <el-table-column prop="realName" label="员工姓名" show-overflow-tooltip >
					<template #default="scope">
						<span v-if="(scope.row.userType == 2 || scope.row.userType == 5)">{{scope.row.realName}}</span>
						<a v-else href="javascript:;" class="color-409EFF tool_btn" @click="addStaff(scope.row)">{{scope.row.realName}}</a>
					</template>
				</el-table-column>
			    <el-table-column prop="stallPhone" label="手机号" />
			    <el-table-column prop="logingName" label="登录账号" />
				<el-table-column label="操作" >
					<template #default="scope">
						<div v-if="getLocalStorage('relevancy')">
							<a href="javascript:;" class="t_d_u color-409EFF tool_btn" v-if="!scope.row.bindUserId" @click="bindWechat(scope.row.encryptionId)">绑定主体账号</a>
							<a href="javascript:;" class="t_d_u color-409EFF tool_btn color-red" v-if="scope.row.bindUserId" @click="unbound(scope.row.encryptionId)">解除绑定</a>
						</div>
					</template>
				</el-table-column>
			</el-table>
			<div class="mt20 rx-ec" v-show="pageData.tableData.length > 0">
			    <el-pagination
			      v-model:currentPage="pageData.searchForm.page"
			      :page-sizes="[15,20,30,50,100]"
			      :page-size="pageData.searchForm.limit"
			      layout="total, sizes, prev, pager, next, jumper"
			      :total="pageData.totalCount"
			      @size-change="pageSizeChange"
			      @current-change="()=>{getList()}"
			    >
			    </el-pagination>
			  </div>
		</div>
		<!-- 新增员工 -->
		<el-dialog
		    v-model="pageData.drawer.show"
		    :title="pageData.drawer.title"
			width="500px"
			:before-close="handle"
		  >
			<div class="p20">
				<el-form :model="pageData.ruleForm" :rules="rules" ref="refRuleForm" label-width="120px">
            	    <el-form-item label="员工姓名：" prop="realName">
            	        <el-input v-model="pageData.ruleForm.realName" maxlength="20" placeholder="请输入员工姓名"></el-input>
            	    </el-form-item>
            	    <el-form-item label="手机号码：" prop="stallPhone">
            	        <el-input v-model="pageData.ruleForm.stallPhone" maxlength="11" placeholder="请输入手机号码"></el-input>
            	    </el-form-item>
            	    <el-form-item label="登录账号：" prop="logingName">
            	        <el-input v-model="pageData.ruleForm.logingName" maxlength="20" placeholder="请输入登录账号"></el-input>
            	    </el-form-item>
            	</el-form>
			</div>
			<template #footer>
				<div class="dialog-footer">
					<el-button @click="handle">取消</el-button>
					<el-button type="primary" @click="saveStaff">确定</el-button>
				</div>
			</template>
		</el-dialog>
		<el-dialog
		 	v-model="pageData.bindWechat.show"
		    title="绑定主体账号"
			width="700px"
			:before-close="handleClose"
		>
			<div class="p20">
				<el-table ref="satffBoundTable" @selection-change="selectPlaceChange" :data="pageData.bindWechat.tableData" stripe height="400" class="single-select-table">
					<el-table-column type="selection" width="55" />
				    <el-table-column prop="realName" label="员工" show-overflow-tooltip />
				    <el-table-column prop="roleName" label="岗位" />
					<el-table-column prop="stallPhone" label="手机号" />
				    <el-table-column prop="logingName" label="登陆账号" />
					<el-table-column label="员工类型" >
			   			<template #default="scope">
							<span>{{scope.row.ifFullTime == 1 ? '全职' : scope.row.ifFullTime == 2 ? '兼职':'未知'}}</span>
						</template>
			   		</el-table-column>
				</el-table>
			</div>
			
			<template #footer>
				<div class="dialog-footer">
					<el-button @click="handleClose">取消</el-button>
					<el-button type="primary" @click="bindWechatSave">确定</el-button>
				</div>
			</template>
		</el-dialog>
		<el-dialog
		 	v-model="pageData.synchro.show"
		    title="同步员工"
			width="700px"
			:before-close="synchroClose"
		>
			<div class="p20">
				<el-table ref="satffSynchroTable" @selection-change="synchroChange" :data="pageData.synchro.tableData" stripe height="400">
					<el-table-column type="selection" width="55" />
				    <el-table-column prop="realName" label="员工" show-overflow-tooltip />
				    <el-table-column prop="roleName" label="岗位" />
					<el-table-column prop="stallPhone" label="手机号" />
					<el-table-column label="员工类型" >
			   			<template #default="scope">
							<span>{{scope.row.ifFullTime == 1 ? '全职' : scope.row.ifFullTime == 2 ? '兼职':'未知'}}</span>
						</template>
			   		</el-table-column>
				</el-table>
			</div>
			
			<template #footer>
				<div class="dialog-footer">
					<el-button @click="synchroClose">取消</el-button>
					<el-button type="primary" @click="synchroSave">确定</el-button>
				</div>
			</template>
		</el-dialog>
		<Confirm ref="confirmRef" :title="pageData.confirmTitle" :text="pageData.confirmText" @save="confirmSave"/>
	</div>
</template>
<script setup>
	import { ref, reactive, onMounted } from 'vue';
	import { ElMessage} from 'element-plus'
	import { Close, Search } from '@element-plus/icons-vue'
	import CommonTip from '@/components/CommonTip.vue'
	import Confirm from '@/components/Confirm.vue'
	import { getLocalStorage } from '@/common/common.js';
	import { unBindUser, getUserToBindList, bindUser, getUserToSynList, allSynUser, synUserByOneHand } from '@/api/market.js';
	import { employeeInsert, getStoreAccountStaff, employeeListStatusUpdate, employeeUpdate, sysUserPasswordReset } from '@/api/staff.js'
	const refRuleForm = ref()
	const satffBoundTable = ref()
	const satffSynchroTable = ref()
	const pageData = reactive({
		tableData:[],
		totalCount : 0,
		searchForm:{
			page:1,
			limit:15,
			searchKeys:'',
		},
		roleList:[],
		addFormData:{
			encryptionId:'',
			realName:'',
			logingName:'',
			stallPhone:'',
			ifFullTime:0,
			attenType:1,
			roleId:[],
			headImgUrl:'https://image.taokatao.cn/imgs/runBusiness/detailPhoto.png'
		},
		drawer:{
			title:'新增员工',
			show:false,
		},
		bindWechat:{
			tableData: [],
			dataList: [],
			encryptionId: '',
			show : false
		},
		synchro: {
			tableData: [],
			dataList: [],
			show : false
		},
		ruleForm: {
			realName: '',
            stallPhone: '',
            logingName: '',
		},
		confirmTitle:'',
		confirmText:'',
		confirmType:'',
		
	})
	//配置自定义规则
    let validatePhone = (rule,value,callback)=>{
        if (!value){
          callback(new Error('手机号不能为空！'));
        }
        //使用正则表达式进行验证手机号码
        if (!/^1[3456789]\d{9}$/.test(value)){
          callback(new Error('手机号不正确！'));
        } else {
          callback() // 添加成功回调
        }
    };
	/**
	 * 同步门店员工
	 */
	function synchro() {
		getUserToSynList().then(res => {
			if (res.code == 0) {
				pageData.synchro.tableData = res.data
				pageData.synchro.show = true
			}
		})
	}
	/**
	 * 取消同步弹框
	 */
	function synchroClose() {
		pageData.synchro.show = false
	}
	/**
	 * 选择同步数据
	 */
	function synchroChange(val) {
		pageData.synchro.dataList = val
	}
	/**
	 * 确定同步
	 */
	function synchroSave() {
		if (!pageData.synchro.dataList.length) {
			ElMessage.warning('请选择同步的数据')
            return
		}
		let arr = []
		pageData.synchro.dataList.forEach(item => {
			arr.push(item.id)
		})
		let params ={
			ids: arr.join(',')
		}
		allSynUser(params).then(res => {
			if (res.code ==0) {
				satffSynchroTable.value.clearSelection()
				ajaxSaveCallBack('同步成功');
				pageData.synchro.show = false
			}
		})
	}
    /**
     * 表单验证规则
     */
    const rules = {
        realName: [{ required: true, message: '请输入员工姓名', trigger: 'blur' }],
        stallPhone: [{ validator: validatePhone, trigger: 'blur' }],
        logingName: [{ required: true, message: "请输入登录账号", trigger: "blur" }],
    };
	
	const confirmRef = ref();
	var selectCheckData = []
	/**
	 * 一键绑定
	 */
	function binding() {
		synUserByOneHand().then(res => {
			if (res.code == 0) {
				ajaxSaveCallBack('绑定成功');
			}
		})
	}
	/**
	 * 解除绑定
	 */
	function unbound(id) {
		unBindUser({ encryptionId: id }).then(res => {
			ajaxSaveCallBack('解绑成功');
		})
	}
	function pageSizeChange(limit){
		pageData.searchForm.limit = limit;
		searchTable();
	}
	//获取员工列表数据
	function searchTable(){
		pageData.searchForm.page = 1;
		getList();
	};
	getList();
	function getList(){
		getStoreAccountStaff(pageData.searchForm).then((res)=>{
			if(res.code == 0){
				pageData.tableData = res.data;
				pageData.totalCount = res.count;
			}
		})
	}
	/**
	 * 拉起绑定弹框
	 **/ 
	function bindWechat(row){
		pageData.bindWechat.encryptionId = row
		getUserToBindList().then(res => {
			if (res.code == 0) {
				pageData.bindWechat.dataList = []
				pageData.bindWechat.tableData = res.data
				pageData.bindWechat.show = true
			}
		})
	}
	/**
	 * 选择绑定的主体数据
	 */
    function selectPlaceChange(val){
		if (val.length > 1) {
          	satffBoundTable.value.clearSelection();
          	satffBoundTable.value.toggleRowSelection(val.pop());
        } else {
          	pageData.bindWechat.dataList = val
        }
    }
	/**
	 * 关闭绑定弹框
	 */
	function handleClose() {
		pageData.bindWechat.dataList = []
		pageData.bindWechat.show = false
	}
	/**
	 * 确定绑定
	 */
	function bindWechatSave(){
		if (!pageData.bindWechat.dataList.length) {
			ElMessage.warning('请选择绑定的数据')
            return
		}
		let params = {
			encryptionId: pageData.bindWechat.encryptionId,
			bindUserId: pageData.bindWechat.dataList[0].id
		}
		bindUser(params).then(res => {
			if (res.code ==0) {
				ajaxSaveCallBack('绑定成功');
				pageData.bindWechat.show = false;
			}
		})
	}
	/**
	 * 取消员工弹框
	 */
	function handle() {
		refRuleForm.value.resetFields()
	    pageData.drawer.show = false
	}
	// 新增弹出窗
	function addStaff(row){
		pageData.ruleForm = {
			encryptionId: row && row.encryptionId || '',
			realName: row && row.realName || '',
            stallPhone: row && row.stallPhone || '',
            logingName: row && row.logingName || '',
		},
		pageData.drawer.title = row ? '编辑员工' : '新增员工';
		pageData.drawer.show = true;
	}
	
	
	//新增员工保存
	function saveStaff(){
		refRuleForm.value.validate((valid) => {
	        if (valid) {
				let params = {
					realName: pageData.ruleForm.realName,
					logingName: pageData.ruleForm.logingName,
					stallPhone: pageData.ruleForm.stallPhone
				}
	        	if(pageData.drawer.title == '编辑员工'){
					params.encryptionId = pageData.ruleForm.encryptionId
					employeeUpdate(params).then((res)=>{
						if(res.code == 0){
							pageData.drawer.show = false;
							ajaxSaveCallBack('编辑成功');
						}
					})
				} else {
					employeeInsert(params).then((res)=>{
						if(res.code == 0){
							pageData.drawer.show = false;
							ajaxSaveCallBack('保存成功');
						}
					})
				}
	        } else {
	          console.log('error submit!!');
	          return false;
	        }
	    });
		
	}
	
	//删除员工
	function deleStaff(){
		CheckLengthCallBack(()=>{
			pageData.confirmTitle = '确定要删除选中的数据吗？';
			var arr = [];
			selectCheckData.map((n)=>{
				if(n.sid == 0){
					arr.push(n.realName);
				}
			})
			pageData.confirmText = arr.length > 0 ?'【'+arr.join('、')+'】为总店创建员工无权删除，将自动过滤。' :'';
			pageData.confirmType = 'dele';
			confirmRef.value.show();
		})
	}
	
	//重置密码
	function resetPassword(){
	 	CheckLengthCallBack(()=>{
			pageData.confirmTitle = '确定要重置选中数据的密码吗？';
			pageData.confirmType = 'reset';
			confirmRef.value.show();
	 	})
	}
	
	function confirmSave(){
		if(pageData.confirmType == 'dele'){
			var arr = [];
			selectCheckData.map((n)=>{
				if(n.sid != 0){
					arr.push(n.encryptionId);
				}
			})
			if(arr.length == 0){
				ElMessage({
					type: 'warning',
					message: '过滤后无可删除数据',
				})
				return false;
			}
			employeeListStatusUpdate({
				encryptionId:arr.join(',')
			}).then((res)=>{
				if(res.code == 0){
					ajaxSaveCallBack('删除成功');
				}
			})
		}else if(pageData.confirmType == 'reset'){
			sysUserPasswordReset({
				encryptionId:getSelectCheckKey('encryptionId')
			}).then((res)=>{
				if(res.code == 0){
					ajaxSaveCallBack('重置成功');
				}
			})
		}
		
	}

	//table通用勾选
	function selectionChange(data){
		selectCheckData = data;
	}
	//获取table勾选集合的某个字段集合
	function getSelectCheckKey(key){
	 	return selectCheckData.map(n=>n[key]).join(',');
	}
	// 验证批量操作是否勾选了数据
	function CheckLengthCallBack(callback){
		if(selectCheckData.length == 0){
			ElMessage({
				type: 'warning',
				message: '请选择需要操作的员工',
			})
			return false;
		}
		callback && callback();
	}
	//ajax提交保存通用提示
	function ajaxSaveCallBack(text,callback){
		ElMessage({
			type: 'success',
			message: text
		})
		searchTable();
		callback && callback();
	}
</script>

<style scoped >
	.single-select-table /deep/ thead .el-table-column--selection .cell{
        display: none;
    }
    .single-select-table /deep/ .el-checkbox__inner { width: 16px; height: 16px; border-radius: 50%; }
    .single-select-table /deep/ .el-checkbox__inner::after{
        height: 8px;
        left: 5px;
    }
</style>
