import {createRouter,createWebHashHistory} from 'vue-router';
import {getUrlQuery} from '../common/common.js';


// 通用页面
import Login from '@/views/login.vue';           // 登录
import WeChart from '@/views/weChart.vue';       // 扫码登录，授权页面
import WeChartPre from '@/views/weChartPre.vue';       // 扫码登录，授权页面
import NotFound from '@/views/404.vue';          // 404页面
import DownloadList from '@/views/downloadList.vue';          // 下载页面
// 总店页面
import Home from '@/views/admin/home.vue';             // 主页
import storeList from '@/views/admin/market/BranchStore.vue'; // 分店管理
import MainStore from '@/views/admin/market/MainStore.vue';       // 总部管理
import System from '@/views/admin/system/Index.vue';       // 员工管理
import Template from '@/views/admin/showroom/template/index.vue';       // 展厅管理-展厅模板
import Recharge from '@/views/admin/showroom/recharge/index.vue';       // 展厅管理-充值模板
import personCre from '@/views/admin/showroom/applet/index.vue';       // 展厅管理-`小程序配置
import contract from '@/views/admin/contract/Index.vue';       // 电子合同

// 分店页面
import BranchHome from '@/views/branch-store/home.vue';
import BranchAssess from '@/views/branch-store/assess.vue';
import BranchStore from '@/views/branch-store/store/employ.vue';
import BranchStoreInfo from '@/views/branch-store/store/storeInfo.vue';
import BranchTemplate from '@/views/branch-store/assess/assessTemplate/template.vue';
import BranchFollow from '@/views/branch-store/crm/follow.vue'; //crm-跟进管理
import BranchMember from '@/views/branch-store/crm/member.vue'; //crm-正式会员
import BranchMemberDetail from '@/views/branch-store/crm/memberDetail.vue';   
import BranchShowroom from '@/views/branch-store/showroom/room.vue'; //展厅管理
import BranchShowroomDetail from '@/views/branch-store/showroom/room/detail.vue'; //展厅管理
import BranchContract from '@/views/branch-store/contract/Index.vue';       // 电子合同
import BranchCreateTemp from '@/views/branch-store/contract/CreateTemp.vue';       // 创建模板
import BranchInitiate from '@/views/branch-store/contract/Initiate.vue';       // 发起合同
import BranchRecord from '@/views/branch-store/contract/Record.vue';       // 发起合同



const routes = [
    { path:'/', redirect:'/login' },
    { path:'/login', name:'login', component:Login },
    { path:'/weChart', name:'weChart', component:WeChart },
    { path:'/weChartPre', name:'weChartPre', component:WeChartPre },
    { path:'/branch-store/home',
      name:'branchHome',
      component:BranchHome,
      redirect:'/branch/assess',
      children:[
        { path:'/branch/record',component:BranchRecord},
        { path:'/branch/initiate',component:BranchInitiate},
        { path:'/branch/createTemp',component:BranchCreateTemp},
        { path:'/branch/contract',component:BranchContract},
        { path:'/branch/assess',component:BranchAssess},
        { path:'/branch/template',component:BranchTemplate},
        { path:'/branch/store',component:BranchStoreInfo},
        { path:'/branch/user',component:BranchStore},
        { path:'/branch/follow',component:BranchFollow},
        { path:'/branch/member',component:BranchMember},
        { path:'/branch/memberDetail',component:BranchMemberDetail},
        { path:'/branch/showroom',component:BranchShowroom},
        { path:'/branch/showroomDetail',component:BranchShowroomDetail},
		{ path:'/branch/downloadList',name:'downloadList',component:DownloadList},
      ]
    }, 
    { path:'/admin/home',
      name:'home',
      component:Home,
      redirect:'/storeList',
      children:[
		{ path:'/contract',name:'contract',component:contract},
        { path:'/storeList',name:'BranchStore',component:storeList},
        { path:'/merchant',name:'MainStore',component:MainStore},
        { path:'/employee',name:'System',component:System},
        { path:'/template',name:'Template',component:Template},
        { path:'/recharge',name:'Recharge',component:Recharge},
        { path:'/person_cre',name:'personCre',component:personCre},
        // { path:'/website',name:'Website',component:Website},
        // { path:'/storeInfo',name:'storeInfo',component:StoreInfo},
	      // { path:'/vipCard', name:'vipCard', component:VipCard },
	      // { path:'/cardDetail', name:'cardDetail', component:CardDetail },
	      // { path:'/updateCourseCard', name:'updateCourseCard', component:UpdateCourseCard },
	      // { path:'/updateEntranceCard', name:'updateEntranceCard', component:UpdateEntranceCard },
	      // { path:'/updateStoreCard', name:'updateStoreCard', component:UpdateStoreCard },
	      // { path:'/updateExperienceCard', name:'updateExperienceCard', component:UpdateExperienceCard },
	      // { path:'/course', name:'course', component:Course },
	      // { path:'/addUpdateCourse', name:'addUpdateCourse', component:AddUpdateCourse },
	      // { path:'/courseDetail', name:'courseDetail', component:CourseDetail },
        // { path:'/applet',name:'applet',component:Applet},
        // { path:'/wechatAccount',name:'wechatAccount',component:WechatAccount},
        // { path:'/downloadList',name:'downloadList',component:DownloadList},
      ]
    },
    { path:'/:pathMatch(.*)*', name:'notFound', component:NotFound }
]
const router = createRouter({
    history:createWebHashHistory(),
    routes
})
export default router