<template>
    <div class="hh100 posi-r o_y_s">
        <div class="w375 box posi-a posi-r " :class="[pageProp.type == 1 ? 'dance' : (pageProp.type == 2 ? 'sports' : (pageProp.type == 3 ? 'basketball' : 'bodybuilding'))]">
            <div class="ww100 hh100 box-item ">
                <div class="top">
                    <span class="serial ft16 mr10">04</span>
                    <span class="title ft16 fwb">附录</span>
                </div>
                <div class="container bg-fff">
                    <div class="message mt10">
                        <div class="rx-bc mb30 text">
                            <div class="color-333 ft15 fwb">附录一：青少年常见体态问题</div>
                            <el-switch v-model="pageProp.info.openPostureDemo" :active-value="1" :inactive-value="0" />
                        </div>
                        <div class="img-box">
                            <img class="" src="@/assets/posture.png" alt="">
                        </div>
                        <div class="rich-text">
                            <div class="color-333">添加内容：</div>
                            <div class="tip mt10 ft12 color-999 bg-F5F5F5">
                                自定义附录内容如若涉及侵权等相关资料，由客户自行承担，请谨慎编写!
                            </div>
                            <div class="mb10 mt10">
                                <div ref="editor"></div>
                            </div>
                            <el-button type="primary" @click="add">确定添加</el-button>
                        </div>
                    </div>
                </div>
            </div>
          
        </div>
    </div>
</template>

<script setup>
    import { ref, reactive, onMounted, onBeforeUnmount } from 'vue';
    import WangEditor from 'wangeditor'
    import { wangEditorUpload } from '@/common/common.js'
    const editor = ref();
    let instance = ''; 
    const pageProp = defineProps({
       info: Object,
       type: Number 
    })
    const pageData = reactive({})
    function add() {
        pageProp.info.content = instance.txt.html()
    }
    onMounted(()=>{
		instance = new WangEditor(editor.value);
        instance.config.menus = [
            'bold',  // 粗体
            'italic',  // 斜体
            'underline',  // 下划线
            'strikeThrough',  // 删除线
            'link',  // 插入链接
            'justify',  // 对齐方式
            'emoticon',  // 表情
            'image',  // 插入图片
        ]
		instance.config.customUploadImg = function (resultFiles, insertImgFn) {
			wangEditorUpload({fileData:resultFiles[0]},(res)=>{
				if(res.data.code == 0){
					insertImgFn(res.data.data.wholeUrl);
				}else{
					ElMessage({
						type: 'warning',
						message: '上传失败',
					})
				}
			});
		}
		Object.assign(instance.config, {
			height:200,
			zIndex:100,
		});
		instance.create();
        instance.txt.html(pageProp.info.content);
    })
    onBeforeUnmount(() => {
	  	instance.destroy();
	  	instance = null;
	});
</script>
<style type='css/style' scoped>
    .box {
        padding: 12px;
	    left: 50%;
        transform: translateX(-50%);	
    }
    .box-item .top{
        background: #FFF;
        border-top-left-radius: 10px;
        padding: 13px;
        padding-bottom: 0;
        -webkit-clip-path: polygon(0% 0%,40% 0%,45% 100%,100% 100%,100% 100%,0% 100%);
        clip-path: polygon(0% 0%,40% 0%,45% 100%,100% 100%,100% 100%,0% 100%);
    }
    .container {
        padding:8px;
        border-top-right-radius: 10px;
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
        margin-top: -2px;
    }
    .serial {
        font-weight: 1000;
    }
    .message .text {
        padding-left: 13px;
        padding-right: 13px;
    }
    .message .img-box{
        width: 336px;
        height: 432px;
        padding: 16px 12px 19px 13px;
        border: 1px dotted #FF6E7B;
    }
    .message .img-box img {
        width: 311px;
        height: 397px;
    }
    .rich-text{
        margin-top: 13px;
    }
    .rich-text .tip {
        padding: 4px 7px;
    }
    /* 主题颜色 */
    .dance {
        background: #FDD2E7;
    }
    .dance .top .serial, .dance .top .title, .percentage {
        color: #ED2169;
    }
    .dance .message-box{
        background: #FFEFF7;
    }
    .sports {
        background: #203C1E;
    }
    .sports .top .serial, .sports .top .title {
        color: #203C1E;
    }
    .sports .message-box{
        background: #F0FCEF;
    }
    
</style>