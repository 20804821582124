<template>
	<div>
		<el-popover
		    ref="popover"
		    placement="bottom"
		    :width="width"
		    trigger="click"
		  >
		    <template #reference>
				<el-input v-model="inputVal" type="text" :placeholder="propData.placeholder || '请输入关键词'" @input="change" clearable @clear="itemclick"></el-input>
		    </template>
			<ul class="ulBox h250 o_a">
				<li class="c_p" :class="inputVal == item.label ? 'active' : ''" v-for="item in list" @click="itemclick(item)">{{item.label}}</li>
			</ul>
		  </el-popover>
	</div>
	
</template>
<script setup>
	const propData = defineProps({
	  list:Array,
	  width:Number,
	  placeholder:String
	})
	import {ref,reactive} from 'vue';
	const inputVal = ref('');
	const emit = defineEmits(['change']);
	var timer = '';
	function change(val){
		clearTimeout(timer);
		timer = setTimeout(()=>{
			emit('change',val);
		},500)
	}
	function itemclick(item){
		inputVal.value = item ? item.label : '';
		emit('itemclick',item ?item.value : '');
	}

</script>

<style scoped>
	.ulBox li{
		padding: 5px 0;
	}
	.ulBox li.active{
		color: #409eff;
	}
	.ulBox li:hover{
		background-color: #f5f7fa;
	}
</style>
