<!-- 会员列表页面 -->
<template>
	<workArea :barlist="barlist">
		<div class="bg-fff pl20 pr20">
			<formQuery :queryOpts="queryOpts" @query="searchCallback"></formQuery>
			<!-- 按钮区域 -->
			<div class="rx-bc mb16">
				<el-button type="primary" @click="creatStoreEvent">新增商户</el-button>
			</div>
			<!-- 数据表格 -->
			<div class="">
				<el-table :data="tableData.list" stripe style="width: 100%" @selection-change="selectionChange">
					<!-- <el-table-column type="selection" width="55" /> -->
					<el-table-column fixed prop="bid" label="ID" min-width="120"></el-table-column>
					<el-table-column fixed prop="businessName" label="商家名称" min-width="120" show-overflow-tooltip>
						<template #default="scope">
							<span class="color-409EFF c_p tool_btn" @click="crossCourseEvnet(scope.row)">{{scope.row.businessName}}</span>
						</template>
					</el-table-column>
					<el-table-column prop="logingName" label="登录账号" min-width="120"></el-table-column>
					<el-table-column prop="stallPhone" label="联系号码" min-width="120"></el-table-column>
					<el-table-column prop="createTime" label="创建时间" min-width="160"></el-table-column>
					<el-table-column prop="updateTime" label="更新时间" min-width="160"></el-table-column>
					<!-- <el-table-column prop="remark" label="备注" min-width="160" show-overflow-tooltip></el-table-column> -->
					<el-table-column label="状态" min-width="120">
						<template #default="scope">
							<span :class="{'color-44D7B6':scope.row.status == 1}">{{scope.row.status == 1 ? '已启用' : '已停用'}}</span>
						</template>
					</el-table-column>
					<el-table-column fixed="right" label="操作" width="150">
						<template #default="scope">
							<a href="javascript:;" class="t_d_u v_a_m color-409EFF tool_btn"
								@click="resetEvent(scope.row)">重置</a>
							<a href="javascript:;" class="t_d_u v_a_m color-409EFF tool_btn"
								@click="stopEvnet(scope.row)">停用</a>
						</template>
					</el-table-column>
					<template #empty>
						<el-empty description="暂无数据"></el-empty>
					</template>
				</el-table>
				<div class="mt20 rx-ec" v-show="tableData.list.length > 0">
					<el-pagination v-model:currentPage="tableData.form.page" :page-sizes="[15,20,30,50,100]"
						:page-size="tableData.form.limit" layout="total, sizes, prev, pager, next, jumper"
						:total="tableData.count" @size-change="pageSizeChange" @current-change="()=>{getList()}">
					</el-pagination>
				</div>
			</div>
			<!-- 新增商户 -->
			<el-dialog v-model="createStore.show" title="新增商户" width="500px" custom-class="popwechat"
				:before-close="()=>{createStore.show = false}" :append-to-body="true">
				<div class="pl20 pt20 pb20 pr60">
					<div class="rx-sc mb12">
						<span class="w110 t_a_r">商户名称：</span>
						<div class="flex1">
							<el-input v-model="createStore.form.storeName" maxlength="20" type="text" placeholder="请输入"></el-input>
						</div>
					</div>
					<div class="rx-sc mb12">
						<span class="w110 t_a_r">店主姓名：</span>
						<div class="flex1">
							<el-input v-model="createStore.form.userName" maxlength="20" type="text" placeholder="请输入"></el-input>
						</div>
					</div>
					<div class="rx-sc mb12">
						<span class="w110 t_a_r">联系电话：</span>
						<div class="flex1">
							<el-input v-model="createStore.form.userTel" maxlength="11" type="number"
								placeholder="请输入"></el-input>
						</div>
					</div>
					<div class="rx-sc mb12">
						<span class="w110 t_a_r">商户地址：</span>
						<div class="flex1">
							<el-input v-model="createStore.form.storeAddress" type="text" placeholder="请输入"></el-input>
						</div>
					</div>
					<div class="rx-sc mb12">
						<span class="w110 t_a_r">邮箱：</span>
						<div class="flex1">
							<el-input v-model="createStore.form.emailNum" type="text" maxlength="20" placeholder="请输入"></el-input>
						</div>
					</div>
				</div>
				<template #footer>
					<div class="dialog-footer">
						<el-button @click="createStore.show = false">取消</el-button>
						<el-button type="primary" @click="createStoreSave">确定</el-button>
					</div>
				</template>
			</el-dialog>
			<!-- 修改 -->
			<el-dialog v-model="updateData.show" title="修改" width="500px" custom-class="popwechat"
				:before-close="()=>{updateData.show = false}" :append-to-body="true">
				<div class="pl20 pt40 pb40 pr60">
					<div class="rx-sc mb12">
						<span class="w110 t_a_r">商户名称：</span>
						<div class="flex1">
							<el-input v-model="updateData.businessName" type="text" placeholder="请输入"></el-input>
						</div>
					</div>
				</div>
				<template #footer>
					<div class="dialog-footer">
						<el-button @click="updateData.show = false">取消</el-button>
						<el-button type="primary" @click="updateDataSave">确定</el-button>
					</div>
				</template>
			</el-dialog>
			<Confirm ref="confirmRef" :title="confirm.confirmTitle" :text="confirm.confirmText" @save="confirmSave" />
		</div>
	</workArea>
</template>

<script setup>
	import {
		ref,
		reactive,
		onMounted
	} from 'vue';
	import {
		useRouter,
		onBeforeRouteLeave
	} from 'vue-router';
	import {
		useStore
	} from 'vuex'
	import {
		ElMessage
	} from 'element-plus'
	import {
		ArrowDown,
		Close,
		Upload,
		ArrowUp
	} from '@element-plus/icons-vue'
	import utils from '@/common/utils.js'
	import {
		getAllBusiness,
		registerAdd,
		businessStop,
		businessPassword,
		businessToEdit
	} from '@/api/store.js';
	import formQuery from '@/components/branch-store/formQuery.vue';
	import Confirm from '@/components/Confirm.vue'
	import workArea from '@/components/branch-store/workArea.vue';
	import {
		fa
	} from 'element-plus/lib/locale';
	const store = useStore();
	const router = useRouter();
	const barlist = [{
		name: '营销后台'
	}, {
		name: '总部管理'
	}];

	const queryOpts = reactive({
		fields: [{
			type: 'input',
			fieldName: 'storeName',
			placehold: '商家名称'
		}, {
			type: 'select',
			fieldName: 'timeIn',
			placehold: '全部日期',
			value: '0',
			options: [{
					label: '全部日期',
					value: '0'
				},
				{
					label: '最近七天',
					value: '1'
				},
				{
					label: '本月',
					value: '2'
				},
				{
					label: '其他',
					value: '3'
				},
			]
		}, {
			showField: 'timeIn', // 根据显示字段
			showValue: '3', // 根据显示字段值
			type: 'dateRange',
			fieldName: 'createTimeStr',
			placeholder: '日期范围',
			value: ''
		}]
	})
	
	var selectCheckData = [],
		confirmType = '',
		storeId = '';
	const confirm = reactive({
		confirmTitle:'',
		confirmText:'',
	}) 
	const confirmRef = ref();
	//新增分店
	const createStore = reactive({
		form: {
			storeName: '',
			userName: '',
			userTel: '',
			storeAddress: '',
			emailNum: '',
		},
		title: '',
		show: false,
	})

	function creatStoreEvent() {
		for (var k in createStore.form) {
			createStore.form[k] = '';
		}
		createStore.show = true;
	}
	// 新增保存
	function createStoreSave() {
		if (!createStore.form.storeName) {
			ElMessage.warning('请输入商户名称')
			return
		}
		if (!createStore.form.userName) {
			ElMessage.warning('请输入店主姓名')
			return
		}
		if (!createStore.form.userTel) {
			ElMessage.warning('请输入手机号')
			return
		}
		const regex = /^1[3456789]\d{9}$/
        if (!regex.test(createStore.form.userTel)) {
			ElMessage.warning('请输入正确的手机号')
			return
		}
		if (!createStore.form.storeAddress) {
			ElMessage.warning('请输入地址')
			return
		}
		registerAdd(createStore.form).then((res) => {
			if (res.code == 0) {
				createStore.show = false;
				ajaxSaveCallBack('新增成功');
			}
		})
	}
	const tableData = reactive({
		list: [],
		count: 0,
		form: {
			page: 1,
			limit: 15,
			storeName: '',
			timeIn: '',
			createTimeStr:''
		}
	})
	//筛选表单回调
	function searchCallback(options) {
		for (var key in options) {
			tableData.form[key] = options[key];
		}
		searchTable();
	}
	//切换分页数量
	function pageSizeChange(limit) {
		tableData.form.limit = limit;
		searchTable();
	}

	function searchTable() {
		tableData.form.page = 1;
		getList();
	}
	getList();

	function getList(exportObj) {
		var formData = JSON.parse(JSON.stringify(tableData.form));
		if (formData.timeIn == 3) {
			if ( formData.createTimeStr && formData.createTimeStr.length > 0) {
				formData.createTime = formData.createTimeStr.join('-')
			} else {
				formData.createTime = ''
			}
			delete formData.createTimeStr
		} else {
			delete formData.createTimeStr
		}
		getAllBusiness(formData).then(function(res) {
			if (res.code == 0) {
				tableData.list = res.data || [];
				tableData.count = res.count;
			}
		});
	}
	const updateData = reactive({
		show:false,
		businessName:'',
		crossCourse:''
	})
	
	function crossCourseEvnet(row){
		storeId = row.encryptionBid;
		updateData.businessName = row.businessName;
		updateData.crossCourse = row.crossCourse;
		updateData.show = true;
	}
	function updateDataSave(){
		businessToEdit({
			encryptionBid:storeId,
			businessName:updateData.businessName,
			crossCourse:updateData.crossCourse
		}).then((res)=>{
			if(res.code == 0){
				updateData.show = false;
				ajaxSaveCallBack('修改成功');
			}
		})
	}
	
	//停用商户
	function stopEvnet(row){
		storeId = row.encryptionBid;
		confirmType = 'stop';
		confirm.confirmTitle = '确定要停用 '+row.businessName+' 吗？';
		confirmRef.value.show();
	}
	//重置商户密码
	function resetEvent(row){
		storeId = row.encryptionId;
		confirmType = 'reset';
		confirm.confirmTitle = '确定要重置 '+row.businessName+' 商家的密码吗？';
		confirmRef.value.show();
	}
	

	//通用确认框确认事件回调
	function confirmSave(){
		if(confirmType == 'stop'){
			businessStop({
				encryptionBid:storeId
			}).then((res)=>{
				if(res.code == 0){
					ajaxSaveCallBack('停用成功');
				}
			})
		}else if(confirmType == 'reset'){
			businessPassword({
				encryptionId:storeId
			}).then((res)=>{
				if(res.code == 0){
					ajaxSaveCallBack('重置成功，请记住新密码:88888888');
				}
			})
		}

	}

	//table通用勾选
	function selectionChange(data) {
		selectCheckData = data;
	}
	//获取table勾选集合的某个字段集合
	function getSelectCheckKey(key) {
		return selectCheckData.map(n => n[key]).join(',');
	}
	// 验证批量操作是否勾选了数据
	function CheckLengthCallBack(callback) {
		if (selectCheckData.length == 0) {
			ElMessage({
				type: 'warning',
				message: '请选择需要操作的会员',
			})
			return false;
		}
		callback && callback();
	}
	//ajax提交保存通用提示
	function ajaxSaveCallBack(text, callback) {
		ElMessage({
			type: 'success',
			message: text
		})
		searchTable();
		callback && callback();
	}
	
	
	
	// 页面缓存
	onBeforeRouteLeave((to, from) => {
		// if (to.path === '/branch/memberDetail') {
		//     store.commit('SetKeep',{keepLiveRoute : ['BranchMemberList']});
		// } else {
		//     store.commit('SetKeep',{keepLiveRoute : []});
		// }
	})
</script>
<style scoped lang="stylus">
	.delTagList {
		li {
			border 1px solid #ccc;

			span:first-child {
				border-right 1px solid #ccc;
			}
		}
	}

	.Sign:hover {
		color: #2D6FB2;
	}
</style>
