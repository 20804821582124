<template>
    <div class="pt20">
        <el-form :inline="true" :model="pageData.formInline">
            <el-form-item>
                <el-input v-model="pageData.formInline.searchKeys" placeholder="学员姓名或手机号" />
            </el-form-item>
            <el-form-item>
                <el-button type="primary" :icon="Search" @click="search">搜索</el-button>
            </el-form-item>
        </el-form>
        <!-- <div class="mb20">
            <el-button type="primary" @click="addStudent">新增线索</el-button>
            <el-button v-if="getLocalStorage('relevancy')" @click="synchro">同步门店潜在会员</el-button>
        </div> -->
        <el-table v-loading="pageData.loading" :data="pageData.tableData" stripe style="width: 100%">
            <el-table-column prop="realName" label="学员姓名" />
            <el-table-column prop="telephoneNum" label="手机号" />
            <el-table-column label="性别">
                <template #default="scope">
                    <span v-if="scope.row.sex == 1">男</span>
                    <span v-if="scope.row.sex == 2">女</span>
                </template>
            </el-table-column>
            <el-table-column label="年龄">
                <template #default="scope">
                    <span>{{ scope.row.age ? scope.row.age : '-' }}</span>
                    <span v-if="scope.row.age && scope.row.ageUnit == 1">岁</span>
                    <span v-if="scope.row.age && scope.row.ageUnit == 2">个月</span>
                </template>
            </el-table-column>
            <el-table-column prop="createTime" label="进入时间" width="140"/>
            <el-table-column prop="assessCount" label="测评次数" />
            <el-table-column label="最新测评时间"  width="160">
                <template #default="scope">
                    {{ scope.row.assessTime ? scope.row.assessTime : '-' }}
                </template>
            </el-table-column>
            <el-table-column label="是否报名">
                <template #default="scope">
                    <el-select v-model="scope.row.enrollState" @change="enroll(scope.row)">
                        <el-option label="已报名" :value="1" />
                        <el-option label="未报名" :value="0" />
                    </el-select>
                </template>
            </el-table-column>
            <el-table-column prop="channelName" label="学员来源" />
            <el-table-column prop="address" label="操作">
                <template #default="scope">
                    <a class="color-409EFF tool_btn t_d_u" href="javaScript:;" @click="modify(scope.row)">编辑</a>
                    <a class="color-red t_d_u " href="javascript:;" @click="remove(scope.row)">删除</a>
                </template>
            </el-table-column>
        </el-table>
        <div class="mt20 rx-ec" v-show="pageData.totalCount > 0">
            <el-pagination
              v-model:currentPage="pageData.page"
              :page-sizes="[15,20,30,50,100]"
              :page-size="pageData.limit"
              layout="total, sizes, prev, pager, next, jumper"
              :total="pageData.totalCount"
              @size-change="pageSizeChange"
              @current-change="pageChange"
            >
            </el-pagination>
        </div>
    </div>
    <!-- 新增/编辑 学员 -->
    <el-dialog
	    v-model="pageData.dialogShow"
	    :title="pageData.title"
	 	width="400px"
	    :before-close="handleClose"
	   >
		<div class="p20 form">
            <el-form :model="pageData.ruleForm" :rules="rules" ref="refruleForm" label-width="120px">
                <el-form-item label="学员姓名：" prop="realName">
                    <el-input v-model="pageData.ruleForm.realName" placeholder="请输入学员姓名"></el-input>
                </el-form-item>
                <el-form-item label="手机号码：" prop="telephoneNum">
                    <el-input v-model="pageData.ruleForm.telephoneNum" placeholder="请输入手机号码"></el-input>
                </el-form-item>
                <el-form-item label="学员生日：" prop="birthday">
                    <el-date-picker
						class="ww100"
      				  	v-model="pageData.ruleForm.birthday"
      				  	type="date"
      				  	placeholder="请选择学员生日"
                        format="YYYY-MM-DD"
                        value-format="YYYY-MM-DD"
      				/>
                </el-form-item>
                <el-form-item label="学员性别：" prop="sex">
                    <el-radio-group v-model="pageData.ruleForm.sex" >
                        <el-radio label="1">
                            <span class="color-333">男</span>
                        </el-radio>
                        <el-radio label="2">
                            <span class="color-333">女</span>
                        </el-radio>
                    </el-radio-group>
                </el-form-item>
            </el-form>
        </div>
	    <template #footer>
	    	<div class="dialog-footer">
	        	<el-button @click="handleClose">取消</el-button>
	        	<el-button type="primary" @click="dialogSave">确定</el-button>
	    	</div>
	    </template>
	</el-dialog>
    <!-- 同步门店潜在会员 -->
    <el-dialog
	    v-model="pageData.dialogPotentialShow"
	    title="同步门店潜在会员"
	 	width="780px"
	    :before-close="handleClosePotential"
	   >
		<div class="p20">
            <el-table ref="multipleTable" 
                :data="pageData.potentialList.slice((pageData.potentialPage - 1) * pageData.potentialLimit, pageData.potentialPage * pageData.potentialLimit)" 
                stripe tooltip-effect="dark" style="width: 100%"
                @selection-change="handleSelectionChange"
                :row-key="getRowKeys"
                height="500"
            >
            <el-table-column type="selection" width="55" :reserve-selection="true"/>
            <el-table-column prop="realName" label="门店潜在会员" />
            <el-table-column prop="telephoneNum" label="手机号" />
            <el-table-column label="意向级别" >
                <template #default="scope">
                    <el-rate v-model="scope.row.ifImportanceDegree" disabled />
                </template>
            </el-table-column>
        </el-table>
        <div class="mt20 rx-ec" v-show="pageData.potentialList.length > 0">
            <el-pagination
              v-model:currentPage="pageData.potentialPage"
              :page-sizes="[15,20,30,50,100]"
              :page-size="pageData.potentialLimit"
              layout="total, sizes, prev, pager, next, jumper"
              :total="pageData.potentialCount"
              @size-change="pageSizeChange1"
              @current-change="pageChange1"
            >
            </el-pagination>
        </div>
        </div>
	    <template #footer>
	    	<div class="dialog-footer">
	        	<el-button @click="handleClosePotential">取消</el-button>
	        	<el-button type="primary" @click="dialogPotentialSave">确定</el-button>
	    	</div>
	    </template>
	</el-dialog>
</template>

<script setup>
    import { ref, reactive, onMounted } from 'vue';
    import { Search } from '@element-plus/icons-vue';
    import { ElMessageBox, ElMessage } from 'element-plus'
    import { getLocalStorage } from '@/common/common.js';
    import { selectStoreMembersAssess, newUserInsert, cardMyInfoUpdate, memberDelete, getSubjectTrailStudent, updateEnrollState, syncSubjectTrailStudent } from '@/api/market.js';
    
    const refruleForm = ref()
    const multipleTable = ref()
    const pageData = reactive({
        loading: false,
        page: 1,
        limit: 15,
        totalCount: 0,
        tableData: [],
        dialogShow: false, // 新增、编辑学员弹框
        title: '', // 新增、编辑弹框 标题
        formInline: {
            searchKeys: ''
        },
        ruleForm: {
            realName: '', // 名称
            telephoneNum: '', // 手机号
            birthday: '', // 年龄
            sex: 1, // 性别
            ageUnit:1
        }, // 新增、编辑 表单
        dialogPotentialShow: false, // 同步弹框
        potentialPage: 1,
        potentialLimit: 15,
        potentialCount: 0,
        potentialList: [],
        synchroList: []
    })
    //配置自定义规则
    let validatePhone = (rule,value,callback)=>{
        if (!value){
          callback(new Error('手机号不能为空！'));
        }
        //使用正则表达式进行验证手机号码
        if (!/^1[3456789]\d{9}$/.test(value)){
          callback(new Error('手机号不正确！'));
        } else {
          callback() // 添加成功回调
        }
    };
    /**
     * 表单验证规则
     */
    const rules = {
        realName: [{ required: true, message: '请输入学员姓名', trigger: 'blur' }],
        telephoneNum: [{ validator: validatePhone, trigger: 'blur' }],
        birthday: [{ required: true, message: "请选择学员生日", trigger: "change" }],
        sex: [{ required: true, message: '请选择学员性别', trigger: 'change' }],
    };
    /**
     * 表单搜索
     */
    function search() {
        pageData.page = 1
        getList()
    }
    /**
     * 获取列表
     */
    function getList() {
        pageData.loading = true
        selectStoreMembersAssess({searchKeys: pageData.formInline.searchKeys }).then(res => {
            pageData.loading = false
            if (res.code == 0) {
                pageData.tableData = res.data
                pageData.totalCount = res.count
            }
        })
    }
    /**
     * 更新报名状态
     */
    function enroll(row) {
        let params = {
            encryptionId: row.encryptionId,
            enrollState: row.enrollState
        }
        updateEnrollState(params).then(res => {
            if (res.code == 0) {
                ElMessage.success('更改成功')
                getList()
            } else {
                getList()
            }
        })
    }
    /**
     * 新增学员
     */
    function addStudent() {
        pageData.title = "新增"
        pageData.ruleForm = {
            realName: '',
            telephoneNum: '', 
            birthday: '',
            sex: '1',
            ageUnit:1
        } 
        pageData.dialogShow = true
    }
    /**
     * 编辑
     */
    function modify(row) {
        console.log(row)
        pageData.title = "编辑"
        pageData.ruleForm = {
            encryptionId: row.encryptionId,
            realName: row.realName,
            telephoneNum: row.telephoneNum, 
            birthday: row.birthday,
            sex: row.sex
        }
        pageData.dialogShow = true
    }
    /**
	 * 取消关闭弹框
	 */
	function handleClose() {
	    refruleForm.value.resetFields()
	    pageData.dialogShow = false
	}
    /**
     * 确定新增、编辑学员
     */
    function dialogSave() {
	    refruleForm.value.validate((valid) => {
	        if (valid) {
                let params = {
                    realName: pageData.ruleForm.realName,
                    telephoneNum: pageData.ruleForm.telephoneNum, 
                    birthday: pageData.ruleForm.birthday,
                    sex: pageData.ruleForm.sex
                }
                if (pageData.title == "编辑") {
                    params.encryptionId = pageData.ruleForm.encryptionId
                    cardMyInfoUpdate(params).then(res => {
                        if (res.code == 0) {
                            ElMessage.success('编辑成功')
                            getList()
                        }
                    })
                } else {
                    newUserInsert(params).then(res => {
                        if (res.code == 0) {
                            ElMessage.success('新增成功')
                            getList()
                        }
                    })
                }
                pageData.dialogShow = false
	        } else {
	          console.log('error submit!!');
	          return false;
	        }
	    });
	}
    
    /**
     * 删除
     */
    function remove(row) {
        memberDelete({ ids: row.encryptionId}).then(res => {
            if (res.code == 0) {
                ElMessage.success('删除成功')
                getList()
            }
        })
    }
    /**
     * 每页条数
     */
	function pageSizeChange(limit){
		pageData.limit = limit;
		getList();
	}
    /**
     * 第几页
     */
    function pageChange(val) {
        pageData.page = val
        getList()
    }
    /**
     * 同步
     */
    function synchro() {
        getMemberList()
        pageData.dialogPotentialShow = true
        
    }
    /**
     * 取消同步
     */
    function handleClosePotential() {
	    pageData.dialogPotentialShow = false
	}
    /**
     * 选择同步的数据
     */
    function handleSelectionChange (val) {
        pageData.synchroList = val
    }
    /**
     * 确定同步
     */
    function dialogPotentialSave() {
        if (!pageData.synchroList.length) {
            ElMessage.warning('请选择需要同步的数据')
            return
        }
        let arr = []
        pageData.synchroList.forEach(item => {
            arr.push(item.cardMyId)
        })
        let params = {
            ids: arr.join(',')
        }
        syncSubjectTrailStudent(params).then(res=> {
            if (res.code == 0) {
                ElMessage.success('同步成功')
                multipleTable.value.clearSelection()
                getList()
                pageData.dialogPotentialShow = false
               if (res.remark.length>0) {
                    ElMessageBox.alert('以下'+ res.remark.length +'位会员同步失败，原因：该手机号在本系统已存在</br>'+ res.remark ,'温馨提示',{
		            	confirmButtonText: '好的',
		            	dangerouslyUseHTMLString: true,
		            });
               }
                
            }
        })
    }
    /**
     * 获取门店潜客列表
     */
    function getMemberList() {
        getSubjectTrailStudent().then(res => {
            if (res.code == 0) {
                pageData.potentialList = res.data
                pageData.potentialCount = res.count
            }
            
        })
    }
    /**
     * 每页条数
     */
	function pageSizeChange1(limit){
		pageData.potentialLimit = limit;
	}
    /**
     * 第几页
     */
    function pageChange1(val) {
        pageData.potentialPage = val
    }
    /**
     * 表格多选记住分页
     */
    function getRowKeys(row) {
      return row.cardMyId;
    }
    onMounted(() => {
        getList()
    })
</script>
<style type='css/style' scoped>
    ::v-deep .el-form-item{
        margin-right: 16px;
    }
    
</style>