<template>
    <div>
        <div id="myChart3" class="h200"></div>
    </div>
</template>

<script setup>
    import { reactive, onMounted } from 'vue';
    import * as echarts from 'echarts';
    let myChart3 = null
    const propData = defineProps({
		info: Array,
	})
    const pageData = reactive({})
    let data = propData.info
    var num = 70/data.length;//求出每个扇叶占比
	var pieData1 = [],pieData2 = [],color=['#FEB103','#36CBFF','#BC003A','#B048FF','#4ED964'];
	data.map((n,i)=>{
	    var lables = ['{labelNum|'+n.score+'%}'];
	    n.tags.map(z=>{
	      lables.push('{labelBr|}','{labelText|'+z+'}');
	    })
	    pieData1.push({ 
	      value:num+i*2,
	      name: n.score+'%',
	      itemStyle:{color:color[i]},
	      label:{
	        rich:{
	          labelNum:{
	            color:color[i],
	            align:'center',
	          },
	          labelBr:{
	            height:5
	          },
	          labelText:{
	            padding:[5,10,],
	            backgroundColor:color[i]+'0D',
	            borderRadius:11,
	            align:'center',
	            verticalAlign:'middle',
	            color:color[i]
	          }
	        },
	        formatter:lables.join('\n')
	      } 
	    })
	    pieData2.push({ value:num+i*2, name: n.name,itemStyle:{color:color[i]} })
	 })
	pieData1.push({ value: 35,itemStyle:{color:'rgba(0,0,0,0)'},label:{show:false} });
	pieData2.push({ value: 35,itemStyle:{color:'rgba(0,0,0,0)'},label:{show:false} });
	var option = getPieOption(100,pieData1,pieData2);
   
    
	
	//     this.pieEchart.init((canvas, width, height, dpr) => {
	//       const pieEchart = echarts.init(canvas, null, {
	//         width: width,
	//         height: height,
	//         devicePixelRatio: dpr // new
	//       });
	//       canvas.setChart(pieEchart);
	//       pieEchart.setOption(option);
	//       return pieEchart;
	// })
			
    onMounted(() => {
        myChart3 = echarts.init(document.getElementById('myChart3'))
        myChart3.setOption(option, true);
            window.addEventListener("resize",function(){
            myChart3.resize();
        });
    })
	function getPieOption(title,data1,data2){
		  return {
		    title: {
		      text:'{titleStyle|'+title+'%}',
		      left: 'center',
		      top: 'center',
		      textStyle: {
		          rich:{
		            titleStyle:{
		              backgroundColor:'#FF91E2',
		              color:'#FFFFFF',
		              align:'center',
		              width:60,
		              height:60,
		              borderRadius:100,
		              fontSize:18,
		              fontWeight:800
		            }
		          },
		      }
		    },
		    series: [
		      {
		        type: 'pie',
		        radius: ['25%','60%'],
		        center: ['50%', '50%'],
		        data: data1,
		        startAngle: 225,
		        roseType: 'radius',
		        labelLine: {
		          	normal: {
						length: 2,
					}
		        },
		      },
		      {
		        type: 'pie',
		        radius: ['25%','60%'],
		        center: ['50%', '50%'],
		        data: data2,
		        startAngle:225,
		        roseType: 'radius',
		        label: {
		          color: '#FFFFFF',
		          position:'inside',
		          fontSize:12,
		          fontWeight:'800'
		        },
		        labelLine: {
                	length:5,
		          	show:false
		        },
		      }
		    ]}
	}
</script>
<style type='css/style' scoped>

</style>