import {postAjax,getAjax}  from "../common/axios.js"

//登录
export const changeToStore = params => {
	return postAjax('/api/system/changeToStore',params)
}
//分店登录
export const loginByStore = params => {
	return postAjax('/api/system/loginByStore',params)
}
//分店信息
export const getBusinessStores = params => {
	return postAjax('/api/system/getBusinessStores',params)
}
//跨店开关
export const updateStore = params => {
	return postAjax('/api/system/updateStore',params)
}

//编辑
export const lineStoreEdit = params => {
	return postAjax('/api/system/lineStoreEdit',params)
}

// 銷售者
export const selectAllManager = params => {
	return postAjax('/api/system/selectAllManager',params)
}

// 售後
export const selectAllPostSales = params => {
	return postAjax('/api/system/selectAllPostSales',params)
}

// 售後
export const storeAllList = params => {
	return postAjax('/api/system/storeAllList',params)
}

// 售後
export const getAllBusiness = params => {
	return postAjax('/api/system/getAllBusiness',params)
}

// 售後
export const getAdminRoleList = params => {
	return postAjax('/api/system/getAdminRoleList',params)
}

// 售後
export const getAllEmployeeAdmin = params => {
	return postAjax('/api/system/getAllEmployeeAdmin',params)
}

// 售後
export const addAdminEmployee = params => {
	return postAjax('/api/system/addAdminEmployee',params)
}

// 售後
export const adminRoleAuthToEdit = params => {
	return postAjax('/api/system/adminRoleAuthToEdit',params)
}

// 售後
export const adminRoleAuthEdit = params => {
	return postAjax('/api/system/adminRoleAuthEdit',params)
}

// 售後
export const businessPassword = params => {
	return postAjax('/api/system/businessPassword',params)
}

// 售後
export const businessStop = params => {
	return postAjax('/api/system/businessStop',params)
}

// 售後
export const delAdminRole = params => {
	return postAjax('/api/system/delAdminRole',params)
}

// 售後
export const employeeStop = params => {
	return postAjax('/api/system/employeeStop',params)
}

// 售後
export const getAdminRoleOne = params => {
	return postAjax('/api/system/getAdminRoleOne',params)
}

// 售後
export const getOneEmployeeAdmin = params => {
	return postAjax('/api/system/getOneEmployeeAdmin',params)
}

// 售後
export const insertAdminRole = params => {
	return postAjax('/api/system/insertAdminRole',params)
}

// 售後
export const newStoreInsert = params => {
	return postAjax('/api/system/newStoreInsert',params)
}

// 售後
export const registerAdd = params => {
	return postAjax('/api/register/registerAdd',params)
}

export const storeQrcodeBind = params => {
	return postAjax('/api/upay/storeQrcode/bind',params)
}

// 售後
export const updateAdminEmployee = params => {
	return postAjax('/api/system/updateAdminEmployee',params)
}

// 售後
export const updateAdminRole = params => {
	return postAjax('/api/system/updateAdminRole',params)
}

// 售後
export const businessToEdit = params => {
	return postAjax('/api/system/businessToEdit',params)
}