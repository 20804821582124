<template>
    <div>

    </div>
</template>

<script setup>
    import {reactive} from 'vue';
    const pageData = reactive({})
</script>
<style type='css/style' scoped>

</style>