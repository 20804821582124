import axios from 'axios'
import {postAjax,getAjax}  from "@/common/axios.js"
import qs from 'qs'
import {logout,getUserMenuListJson} from '../api/login.js';
import {useRouter} from 'vue-router';
// const router = useRouter();

//获取本地存储localStorage
export const getLocalStorage = (name) => {
	var loacl = localStorage.getItem(name)
	return loacl ? ((loacl.indexOf('{') > -1 || loacl.indexOf('[') > -1) ? JSON.parse(loacl) : loacl) : '';
}
//获取地址参数
export const getUrlQuery= (name)=>{
	var reg = new RegExp("(^|&)"+ name +"=([^&]*)(&|$)");
   var r = window.location.search.substr(1).match(reg);
   return r ? decodeURI(r[2]) : '';
}
//通用上传功能-配合elementui上传控件
export const commonUpload = (params,callback) =>{
	axios.request({
	    url: '/api/ali/oss/getUploadParams',
		method: 'GET',
		params:{
			fileType:1,
			access_token:getLocalStorage('businessToken')
		},
	}).then((res)=>{
		if(res.data.code == 0){
			var data = res.data.data;
			var fileType = params.fileData.name && params.fileData.name.lastIndexOf('.') > -1 ? params.fileData.name.substring(params.fileData.name.lastIndexOf('.')) : '';
			callback && callback({
				name:params.fileData.name,
				key: data.filePrefix+fileType,
				policy: data.policy,
				OSSAccessKeyId: data.accessid,
				callback: data.callback,
				signature: data.signature,
			},data.host);
		}
	})
}

// 上传-配合富文本编辑器
export const wangEditorUpload = (file,callback)=>{
	commonUpload(file,(formData,ossUrl)=>{
		let param = new FormData();
		for(var k in formData){
			param.append(k, formData[k]);
		}
		param.append("file", file.fileData);
		axios.request({
		    url: ossUrl,
			method: 'post',
			headers:{"Content-Type": "multipart/form-data"},
			data:param,
		}).then((res)=>{
			callback(res);
		})
	})
}

