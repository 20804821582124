<template>
	<workArea :barlist="pageData.barlist">
	<div class="pt20 pl15 pr15">
		<el-table ref="table" :data="pageData.list" stripe style="width: 100%" height="65vh">
			<el-table-column prop="createTime" label="充值时间" />
			<el-table-column prop="expireTime" label="到期时间" />
			<el-table-column prop="orderNo" label="订单号" />
			<el-table-column prop="salePrice" label="充值金额(元)" />
			<el-table-column prop="num" label="充值份数(份)" />
			<el-table-column prop="consumeNum" label="充值方式" >
				<template #default="scope">
					<img src="@/assets/zhifubaotype.png" alt="">
				</template>
			</el-table-column>
			<el-table-column prop="createName" label="操作人" />
		</el-table>
		<div class="mt20 rx-ec">
			<el-pagination v-model:currentPage="searchForm.page" :page-sizes="[15,20,30,50,100]"
				:page-size="searchForm.limit" layout="total, sizes, prev, pager, next, jumper"
				:total="pageData.count" @size-change="pageSizeChange" @current-change="()=>{getList()}">
			</el-pagination>
		</div>
	</div>
	</workArea>
</template>

<script setup>
	import { ref, reactive } from 'vue';
	import { ElMessage} from 'element-plus'
	import { getEsignStoreOrderList } from '@/api/contract.js';
	import workArea from '@/components/branch-store/workArea.vue';
	const pageData = reactive({
		list:[],
		count:0,
		barlist:[{ name : '首页' },{ name : '电子合同' },{ name : '充值记录' }]// 面包屑
	})
	const searchForm = reactive({
		page:1,
		limit:15,
	})
	function pageSizeChange(limit){
		searchForm.limit = limit;
		getList(1);
	}
	getList();
	function getList(page){
		if(page){
			searchForm.page = 1;
		}
		getEsignStoreOrderList(searchForm).then(res=>{
			pageData.list = res.data;
			pageData.count = res.count;
		})
	}
</script>

<style scoped>
	
</style>