<template>
    <div class="ww100 pt16">
        <div class="ww100 rx-wsc" ref="queryBox">
            <div class="b_query_item_box" v-for="item in recept.queryOpts.fields" :key="item">
                <div class="b_query_item"  v-if="item.type == 'input'">
                    <el-input v-model="item.value" :placeholder="item.placehold" clearable />
                </div>
                <div class="b_query_item"  v-if="item.type == 'select' && queryTempData[item.showField] == item.showValue">
                    <el-select  @change="selectChange(item.fieldName,item.value)" v-model="item.value" :placeholder="item.placehold" >
                        <el-option v-for="select in item.options" :key="select" :label="select.label" :value="select.value"></el-option>
                    </el-select>
                </div>
                <div class="b_query_item" v-if="item.type == 'selectMany' && queryTempData[item.showField] == item.showValue">
                    <el-select multiple collapse-tags clearable filterable v-model="item.value" :placeholder="item.placehold">
                        <el-option v-for="select in item.options" :key="select" :label="select.label" :value="select.value"></el-option>
                    </el-select>
                </div>
                <div class="b_query_item rx-sc datarange bdr4" :style="{width:item.width ? item.width+'px' : '350px',border:'1px solid #e0e0e0'}" v-if="item.type == 'dateRange' && queryTempData[item.showField] == item.showValue">
                    <div class="ml10 color-999">{{item.placeholder}}</div>
                    <el-date-picker class="ml10 hh100" style="width:270px;" format="YYYY-MM-DD" value-format="YYYY-MM-DD"  v-model="item.value" type="daterange" start-placeholder="开始" end-placeholder="结束" clearable>
                  </el-date-picker>
                </div>
				<div class="rx-sc mr16" v-if="item.type == 'dateTime' && queryTempData[item.showField] == item.showValue">
				    <span style="margin-bottom: 12px;" v-if="item.labelName">{{item.labelName}}：</span>
					<div class="b_query_item">
						<el-date-picker
						   v-model="item.value"
						   type="datetime"
						   :placeholder="item.placeholder"
                           clearable
						 />
					</div>
				</div>
            </div>
            <!-- 查询按钮 -->
            <el-button class="mb12" type="primary" @click="queryForm" :icon="Search">搜索</el-button>
            <!-- 收起折叠 -->
            <div v-show="queryItemShowAll < 2" @click="triggerShowItemAll" class="query_btn_trans mb16 ml16 rx-cc">{{queryItemShowAll == 1 ? "收起":"展开"}}<el-icon class="el-icon--right" :style="{transform: 'rotate('+(queryItemShowAll == 1 ? '-90deg':'90deg')+')'}"><DArrowRight /></el-icon></div>
        </div>
    </div>
</template>

<script setup>
import {ref,reactive,onMounted} from 'vue';
import utils from '../../common/utils.js';
import { Search, DArrowRight } from '@element-plus/icons-vue';
// 接受参数
const recept = defineProps({
  queryOpts : Object
})
const queryTempData = reactive({});
// 触发事件
const emits = defineEmits(['query'])
//暴露方法
defineExpose({
  getQueryForm : getQueryParam
})
function getQueryParam(){
    var params = {};
    for(var i=0;i<recept.queryOpts['fields'].length;i++){
        params[recept.queryOpts['fields'][i]['fieldName']] = recept.queryOpts['fields'][i]['value'];
    }
    return params;
}
const queryForm = ()=>{
    emits("query",getQueryParam());
}
// 控制查询区域显示隐藏
var queryBox = ref(null)
var queryItemShowAll = ref(9);
// 触发隐藏和展开
function triggerShowItemAll(){
    for(var i=queryBox.value.children.length - 1;i>0;i--){
        if(queryBox.value.children[i].className.indexOf('query_item_box') > -1){
            queryBox.value.children[i].style.display= queryItemShowAll.value == 1 ? "none" : "";
        }
        if(queryItemShowAll.value == 1 && queryBox.value.offsetHeight < 60){
            break;
        }
    }
    queryItemShowAll.value = queryItemShowAll.value == 1 ? 0 : 1;
}
function selectChange(fieldName,value){
    queryTempData[fieldName] = value;
}
onMounted(()=>{
    utils.initQuery(queryItemShowAll,queryBox);
});
</script>

