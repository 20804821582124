<template>
	<div class="rx-ec pt10">
		<el-button type="primary" @click="appSave">发布小程序</el-button>
	</div>
	<div class="pt10 rx-ss" :style="{height:winH}">
		<div class="w300 mr20 bg-FAFAFC pl20 pr20 pb20 pt20 hh100">
			<div class="color-333 ft16 mb16 fwb">小程序内容配置</div>
			<div class="left_list rx-cc" @click="navChange(0)" :class="leftNum == 0?'active':''">banner配置</div>
			<div class="left_list rx-cc" @click="navChange(1)" :class="leftNum == 1?'active':''">展厅推荐区</div>
		</div>
		<div class="w300 phoneBox mr20">
			<div class="ww100 hh100 cx-sc">
				<div class="bg-FAFAFC flex1 ww100 o_a">
					<div class="bannerBox posi-r" :class="leftNum == 0 ? 'borderActive':''">
						<div class="img posi-c">
							<div class="ww100 hh100 rx-cc bg-F5F5F5 box-shadow child bdr4" v-show="!bannerData.list[1]">
								<img class="ww50" src="@/assets/logo.png" alt="">
							</div>
							<img v-show="bannerData.list[1]" class="ww100 child bdr4" :src="bannerData.list[1]" alt="">
						</div>
						<div class="img posi-a">
							<div class="ww100 hh100 rx-cc bg-F5F5F5 box-shadow child" v-show="!bannerData.list[2]">
								<img class="ww50" src="@/assets/logo.png" alt="">
							</div>
							<img v-show="bannerData.list[2]" class="ww100 child" :src="bannerData.list[2]" alt="">
						</div>
						<div class="img posi-a">
							<div class="ww100 hh100 rx-cc bg-F5F5F5 box-shadow child" v-show="!bannerData.list[3]">
								<img class="ww50" src="@/assets/logo.png" alt="">
							</div>
							<img v-show="bannerData.list[3]" class="ww100 child" :src="bannerData.list[3]" alt="">
						</div>
					</div>
					<div class="pl15 pr15 pt15 pb15 rx-sc">
						<div class="flex1 mr15">
							<img class="ww100" src="@/assets/app_icon1.png" alt="">
						</div>
						<div class="flex1">
							<img class="ww100" src="@/assets/app_icon2.png" alt="">
						</div>
					</div>
					<div class="pl15 pt10 pb10 mb10">
						<div class="rx-sc" style="width:1000px">
							<span class="bg-FC3A3A color-fff pt5 pb5 pl15 pr15 bdr4 fw800">展厅推荐</span>
							<span class="color-666 pt5 pb5 pl15 pr15 fw500">我的收藏</span>
							<span class="color-666 pt5 pb5 pl15 pr15 fw500">我创建的</span>
							<span class="color-666 pt5 pb5 pl15 pr15 fw500">我浏览的</span>
						</div>
					</div>
					<div class="pt10 pb10 pl15 pr15 bg-F7F7F7" :class="leftNum == 1 ? 'borderActive':''">
						<div class="bg-fff bdr4 mb15 pb10" v-for="(item,index) in roomData.checkList" :key="index">
							<img class="ww100 mb15" :src="item.hallCover" alt="">
							<div class="fwb color-333 ft16 maxLength mb10 pl10 pr10">{{item.hallName}}</div>
							<div class="rx-bc mb10 pl10 pr10">
								<div class="flex1">
									<div class="rx-sc mb10">
										<img class="w15 mr5" src="@/assets/app_icon3.png" alt="">
										<div class="color-808080">{{item.sid == 0 ? item.realName:item.storeName}}</div>
									</div>
									<div class="rx-sc">
										<img class="w15 mr5" src="@/assets/app_icon4.png" alt="">
										<div class="color-808080">{{item.sid == 0 ? '无':item.storeName}}</div>
									</div>
								</div>
								<div class="appBtn pl10 pr10 pt5 pb5 bdr100 ft15">进入主页</div>
							</div>
							<div class="rx-sc pl10 pr10">
								<img class="w15 mr5" src="@/assets/app_icon5.png" alt="">
								<span class="color-808080 mr10">0</span>
								<img class="w15 mr5" src="@/assets/app_icon6.png" alt="">
								<span class="color-808080 mr10">0</span>
								<img class="w15 mr5" src="@/assets/app_icon7.png" alt="">
								<span class="color-808080 mr10">0</span>
								<img class="w15 mr5" src="@/assets/app_icon8.png" alt="">
								<span class="color-808080">0</span>
							</div>
						</div>
					</div>
				</div>
				<div class="rx-cc pt10 pb10 ww100">
					<div class="cx-cc mr65">
						<img class="w25 mb5" src="@/assets/app_index.png" alt="">
						<span class="color-FC3737">首页</span>
					</div>
					<div class="cx-cc">
						<img class="w25 mb5" src="@/assets/app_user.png" alt="">
						<span class="color-8C8C8C">个人中心</span>
					</div>
				</div>
			</div>
		</div>
		<div class="flex1 pl20 pr20 pb20 pt20 hh100 bg-FAFAFC o_a">
			<!-- banner设置 -->
			<div class="ww100 bg-fff pt20 pl20 pr20 pb20" v-show="leftNum == 0">
				<div class="color-999 mb20">图片建议尺寸750*420px，最多可添加5张，可拖动调整图片顺序。</div>
				<div v-if="bannerData.show">
					<MoveSort :first="true" :list="bannerData.list" border="left" :hide="6">
						<template #[index] v-for="(item, index) in bannerData.list" :key="index">
							<template v-if="index == 0">
								<div class="h100 pl10 pr10" style="width:198px;">
									<div class="hh100 bdr4 uplod-banner">
										<div class="t_a_c bg-fff upload ml10 mr10  rx-cc hh100 c_p">
											<CommonUpload @change="uploadChange" :autoCropWidth="320" :autoCropHeight="180" :isCropper="true">
												<div>
													<img class="w30" src="@/assets/addBank.png" alt="">
													<div class="color-333">上传图片</div>
												</div>
											</CommonUpload>
										</div>
									</div>
								</div>
							</template>
							<div v-else-if="index > 0" class="h100 rx-cc pl10 pr10">
								<div class="ww100 hh100 uploadLogo posi-r bdr4">
									<img class="ww100 hh100 bdr4" :data-index="index" :src="item" >
									<div class="deleLogo">
										<CommonUpload @change="(url)=>{uploadChange(url,index)}" :autoCropWidth="320" :autoCropHeight="180" :isCropper="true">
											<p class="ft12 reUpload">重新上传</p>
										</CommonUpload>
									</div>
									<img @click="delBanner(index)" class="w20 dele_icon bg-fff" src="@/assets/del2.png" alt="">
								</div>
							</div>
						</template>
					</MoveSort>
				</div>
			</div>
			<!-- 展厅推荐区 -->
			<div class="w500 bg-fff pt30 pr40 pl40" v-show="leftNum == 1">
				<div class="rx-sc mb20">
					<span class="color-333">选择展厅：</span>
					<el-button class="mr10" type="primary" @click="selectRoom">添加展厅</el-button>
					<span class="color-666">已添加 <span class="color-red ft16">{{roomData.checkList.length}}</span> 个展厅</span>
				</div>
				<div v-if="roomData.checkList.length">
					<MoveSort :key="(new Date).getTime()" :list="roomData.checkList" :moveover="(list)=>{moveover(list,2)}" :first="true">
						<template #[index] v-for="(item,index) in roomData.checkList" :key="index">
							<div class="w420 bg-FAFAFC mb10 pt15 pr15 pb15 pl15 rx-sc c_p">
								<img class="w160 mr20 bdr4" style="box-shadow: 0px 4px 9px 0px #C4BFBF;" :src="item.hallCover" alt="">
								<div class="flex1">
									<div class="color-333 ft18 fw500 maxLength mb12">{{item.hallName}}</div>
									<div class="mb12">
										<span class="color-999">创作者：</span>
										<span class="color-666">{{item.sid == 0 ? item.realName:item.storeName}}</span>
									</div>
									<div class="rx-bc">
										<span class="color-fff bdr2 d_i_b pl10 pr10" :style="{backgroundColor:(item.sid == 0 ?'#FC3A3A':'#0FC4F4')}">{{item.sid == 0 ? '个人创作者':'机构端' }}</span>
										<div class="rx-sc pr20">
											<img class="w15 mr15" src="@/assets/sort.png" alt="">
											<img @click="deleItem(index)" class="w15" src="@/assets/dele.png" alt="">
										</div>
									</div>
								</div>
							</div>
						</template>
					</MoveSort>
				</div>
			</div>
		</div>
	</div>
	<el-dialog
	    v-model="roomData.show"
	    title="选择展厅"
	 	width="780px"
	    :before-close="()=>{roomData.show = false}"
	   >
		<div class="p20">
			<div class="rx-sc mb12">
				<span>展厅名称</span>
				<div class="w210 mr10 ml10">
					<el-input v-model="roomData.hallName" placeholder="输入关键词"></el-input>
				</div>
				<el-button type="primary" @click="getShowRoomList">查询</el-button>
			</div>
	        <el-table ref="tableNode" 
	            :data="roomData.list" 
	            stripe tooltip-effect="dark" style="width: 100%"
	            height="500"
				:row-key="row=>row.id"
	        >
				<el-table-column type="selection" width="55" :reserve-selection="true"/>
				<el-table-column prop="hallName" label="展厅名称" />
				<el-table-column label="展厅封面">
					<template #default="scope">
						<img style="box-shadow: 0px 4px 9px 0px #C4BFBF;" class="w100 bdr4" :src="scope.row.hallCover" alt="">
					</template>
				</el-table-column>
				<el-table-column label="创作者" >
					<template #default="scope">
						<span>{{scope.row.sid == 0 ? scope.row.realName:scope.row.storeName  }}</span>
					</template>
				</el-table-column>
				<el-table-column label="创作者类型" >
					<template #default="scope">
						<span class="color-fff bdr2 d_i_b pl10 pr10" :style="{backgroundColor:scope.row.sid == 0 ?'#0FC4F4':'#FC3A3A'}">{{scope.row.sid == 0 ? '个人创作者':'机构端' }}</span>
					</template>
				</el-table-column>
			</el-table>
	    <!-- <div class="mt20 rx-ec" v-show="pageData.potentialList.length > 0">
	        <el-pagination
	          v-model:currentPage="pageData.potentialPage"
	          :page-sizes="[15,20,30,50,100]"
	          :page-size="pageData.potentialLimit"
	          layout="total, sizes, prev, pager, next, jumper"
	          :total="pageData.potentialCount"
	          @size-change="pageSizeChange1"
	          @current-change="pageChange1"
	        >
	        </el-pagination>
	    </div> -->
	    </div>
	    <template #footer>
	    	<div class="dialog-footer">
	        	<el-button @click="roomData.show = false">取消</el-button>
	        	<el-button type="primary" @click="selectRoomSave">确定</el-button>
	    	</div>
	    </template>
	</el-dialog>
</template>

<script setup>
	import {
		ref,
		reactive,
		nextTick
	} from 'vue';
	import {
		ElMessage
	} from 'element-plus'
	import CommonUpload from '@/components/CommonUpload.vue';
	import MoveSort from '@/components/MoveSort.vue'
	import {getHallMiniBannerList,updateHallMiniBanner,getHallMiniRecommendList,updateHallMiniRecommend,getHallContentApplyList} from '@/api/showroom.js'
	const winH = (window.innerHeight-180) < 600 ? '600px' : (window.innerHeight-180)+'px';
	const leftNum = ref(0);
	function navChange(type){
		leftNum.value = type;
		getBannerList();
	}
	//上传banner
	const bannerData = reactive({
		list:[],
		show:false
	})
	getBannerList();
	function getBannerList(){
		if(leftNum.value == 0){
			getHallMiniBannerList().then(res=>{
				bannerData.list = res.data.map(n=>n.picUrl);
				bannerData.list = ['1',...bannerData.list];
				bannerData.show = true;
			})
		}else{
			getHallMiniRecommendList().then(res=>{
				roomData.checkList = res.data.map(n=>{
					n.id = n.applyId;
					return n;
				});
			})
		}
		
	}
	
	function uploadChange(url,index){
		if(index){
			bannerData.list[index] = url;
		}else{
			bannerData.list.push(url);
		}
	}
	function delBanner(index){
		bannerData.list.splice(index,1);
	}
	//配置展厅
	const roomData = reactive({
		list:[],
		show:false,
		hallName:'',
		checkList:[]
	})
	const tableNode = ref();
	function selectRoom(){
		roomData.hallName = '';
		roomData.show = true;
		nextTick(()=>{
			getShowRoomList();
		})
	}
	function getShowRoomList(){
		tableNode.value.clearSelection();
		getHallContentApplyList({
			page:1,
			limit:9999,
			hallName:roomData.hallName
		}).then(res=>{
			roomData.list = res.data.map((n,i)=>{
				var arr = roomData.checkList.filter(z=>z.id == n.id);
				tableNode.value.toggleRowSelection(n,arr.length > 0);
				return n;
			});
		})
	}
	function deleItem(index){
		roomData.checkList.splice(index,1);
	}
	function selectRoomSave(){
		var list = tableNode.value.getSelectionRows();
		roomData.list.forEach(n=>{
			var arr = list.filter(z=>z.id == n.id);
			if(arr.length > 0){ //选中
				var arr = roomData.checkList.filter(z=>z.id == n.id);
				if(arr.length == 0){ //没有加入 已有不管
					roomData.checkList.push(n);
				}
			}else{ //没选中的
				roomData.checkList.forEach((z,i)=>{
					if(z.id == n.id){
						roomData.checkList.splice(i,1);
					}
				});
			}
		})
		roomData.show = false;
	}
	function moveover(list,type){
		if(type == 1){
			bannerData.list = list;
		}else{
			roomData.checkList = list;
		}
	}
	//保存
	function appSave(){
		if(leftNum.value == 0){
			var list = [];
			bannerData.list.map((n,i)=>{
				if(i>0){
					list.push({
						picUrl:n
					})
				}
			})
			if(bannerData.list.length == 0){
				ElMessage.warning('请上传banner图');
				return false;
			}
			updateHallMiniBanner({list}).then(res=>{
				ElMessage.success('发布成功');
			})
		}else{
			if(roomData.checkList.length == 0){
				ElMessage.warning('请选择展厅');
				return false;
			}
			var list = roomData.checkList.map(n=>{
				return {
					applyId:n.id
				}
			})
			updateHallMiniRecommend({list}).then(res=>{
				ElMessage.success('发布成功');
			})
		}
	}
	
</script>

<style scoped>
	.phoneBox{
		padding: 45px 22px 19px 22px;
		height: 592px;
		background:url(../../../../assets/simulator.png) no-repeat center center;
		background-size: contain;
	}
	.phoneBox>div{
		border-radius: 0 0 30px 30px;
		overflow: auto;
	}
	.left_list{
		width: 100%;
		height: 48px;
		line-height: 48px;
		padding: 0 30px;
		background: #FFF;
		margin-bottom: 15px;
		cursor: pointer;
		border-radius: 2px;
	}
	.left_list.active{
		background: #EFF6FF;
		box-shadow: 0 0 6px #cccccc80;
		border-radius: 2px;
		border: 1px dashed #409EFF;
	}
	.bannerBox{
		width: 100%;
		height: 140px;
	}
	.bannerBox .img{
		width: 200px;
		height: 112px;
		transform-style: preserve-3d;
		perspective: 1000px;
	}
	.bannerBox .img:first-child{
		z-index: 100;
	}
	.bannerBox .img:nth-child(2){
		right: 0;
		top: 50%;
		z-index: 50;
		transform: translate(50%,-50%);
	}
	.bannerBox .img:nth-child(2) .child{
		transform: rotateY(-65deg) scale(0.9);
	}
	.bannerBox .img:last-child{
		left: 0;
		top: 50%;
		transform: translate(-50%,-50%);
		z-index: 0;
	}
	.bannerBox .img:last-child .child{
		transform: rotateY(65deg) scale(0.9);
	}
	.borderActive{
		border: 1px dashed #409EFF;
	}
	.box-shadow{
		box-shadow: 0 0 5px #999;
	}
	.appBtn{
		background-color: #FFD4D4;
		border: 1px solid #FC3D3D;
		color: #FC3D3D;
	}
	.uplod-banner{
		border: 1px dashed #D6D6D6;
	}
	.uplod-banner:hover{
		border: 1px dashed #409eff;
	}
	.bannerItems .btns{
		display: none;
	}
	.bannerItems:hover .btns{
		display: block;
	}
	.deleLogo{
		padding: 5px 0;
		position: absolute;
		bottom: 0;
		left: 0;
		right: 0;
		background-color: rgba(0,0,0,.3);
		text-align: center;
		color: #fff;
		display: none;
		cursor: pointer;
	}
	.uploadLogo:hover .deleLogo{
		display: block;
	}
	.dele_icon{
		position: absolute;
		right :-10px;
		top :-10px;
		display :none;
		cursor :pointer;
		border-radius: 100%
	}
	.uploadLogo:hover .dele_icon{
		display: block;
	}
</style>