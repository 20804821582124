<template>
    <div class="mt20">
        <el-form :inline="true" :model="pageData.formInline" class="demo-form-inline">
            <el-form-item>
                <el-input v-model="pageData.formInline.searchKeys" placeholder="学员姓名或手机号" />
            </el-form-item>
            <el-form-item>
                <el-select v-model="pageData.formInline.teacherIds" multiple collapse-tags clearable placeholder="选择测评老师">
                    <el-option v-for="(item, index) in pageData.teacherList" :key="index" :label="item.realName" :value="item.id" />
                </el-select>
            </el-form-item>
            <el-form-item>
                <el-select v-model="pageData.formInline.templateIds" multiple collapse-tags clearable placeholder="选择测评模板">
                    <el-option v-for="(item, index) in pageData.templateLsit" :key="index" :label="item.templateName" :value="item.id" />
                </el-select>
            </el-form-item>
            <el-form-item>
                <el-date-picker
                    style="width:270px;" 
                    format="YYYY-MM-DD" 
                    value-format="YYYY-MM-DD"  
                    v-model="pageData.formInline.searchTime" 
                    type="daterange" 
                    start-placeholder="开始日期" 
                    end-placeholder="结束日期" 
                    clearable
                />
            </el-form-item>
            <el-form-item>
                <el-button type="primary" @click="search" :icon="Search">搜索</el-button>
            </el-form-item>
        </el-form>
        <el-table class="mt20" :data="pageData.tableData" stripe style="width: 100%">
            <el-table-column prop="memberName" label="会员姓名" />
            <el-table-column prop="telephoneNum" label="手机号" />
            <el-table-column prop="createTime" label="测评日期" />
            <el-table-column prop="teacherName" label="测评老师" />
            <el-table-column prop="templateName" label="测评模版" />
            <el-table-column prop="address" label="操作">
                <template #default="scope">
                    <a class="color-409EFF tool_btn t_d_u" href="javaScript:;" @click="modify(scope.row)">查看</a>
                    <a class="color-red t_d_u " href="javascript:;" @click="remove(scope.row)">删除</a>
                </template>
            </el-table-column>
        </el-table>
        <div class="mt20 rx-ec">
		    <el-pagination
		        v-model:currentPage="pageData.page"
		        :page-sizes="[15,20,30,50,100]"
		        :page-size="pageData.limit"
		        layout="total, sizes, prev, pager, next, jumper"
		        :total="pageData.count"
		        @size-change="classPageSizeChange"
                @current-change="pageChange"
		    >
		    </el-pagination>
		</div>
    </div>
    <Preview :show="pageData.show" :type="1" :data="pageData.templateId" @unPreview="unPreview"></Preview>
</template>
<script setup>
    import { ref, reactive, onMounted } from 'vue';
    import { Search } from '@element-plus/icons-vue';
    import { ElMessage } from 'element-plus'
    import Preview from '@/components/branch-store/preview.vue';
    import { assessReportLsit, assessTemplateLsit, assessReportUpdateDel } from '@/api/market.js';
    import { getStoreAccountStaff } from '@/api/staff.js'
    const pageData = reactive({
        formInline:{
            teacherIds: [],
            templateIds: [],
            searchTime: []
        },
        tableData: [],
        teacherList: [],
        templateLsit: [],
        templateId: '',
        page: 1,
        limit: 15,
        count: 0,
        show: false,
    })
    /**
     * 搜索
     */
    function search() {
        pageData.page = 1
        getList()
    }
    /**
     * 模板列表
     */
    function gettemplateList() {
        assessTemplateLsit({ myTemplate: 1 }).then(res => {
            if (res.code == 0) {
                pageData.templateLsit = res.data
            }
        })
    }
    /**
     * 老师列表
     */
    function getTeacherList () {
        let params = {
            page: 1,
            limit: 9999
        }
        getStoreAccountStaff(params).then(res => {
            if (res.code == 0) {
                pageData.teacherList = res.data
            }
        })
    }
    /**
     * 获取列表数据
     */
    function getList() {
        let params = {
            timeIn: '-1',
            searchTime: pageData.formInline.searchTime.length && pageData.formInline.searchTime.join('-') || '',
            teacherIds: pageData.formInline.teacherIds.length && pageData.formInline.teacherIds.join(',') || '',
            templateIds: pageData.formInline.templateIds.length && pageData.formInline.templateIds.join(',') || '',
            searchKeys: pageData.formInline.searchKeys
        }
        assessReportLsit(params).then(res => {
            if (res.code ==0) {
                pageData.tableData = res.data
                pageData.count = res.count
            }
        })
    }
    /**
     * 查看
     */
    function modify(row) {
        pageData.templateId = row.encryptionId
        pageData.show = true
    }
    /**
     * 关闭预览
     */
    function unPreview() {
        pageData.show = false
    }
    /**
     * 删除
     */
    function remove(row) {
        assessReportUpdateDel({encryptionId: row.encryptionId}).then(res => {
            if(res.code == 0) {
                ElMessage.success('删除成功')
                getList()
            }
        })
    }
    /**
     * 每页条数更改
     */
    function classPageSizeChange(limit) {
        pageData.limit = limit
        getList()
    }
    /**
     * 页数更改
     */
    function pageChange(val) {
        pageData.page = val
        getList()
    }
    
    onMounted(() => {
        getTeacherList()
        gettemplateList()
        getList()
    })
</script>
<style type='css/style' scoped>
    ::v-deep .el-form--inline .el-form-item {
        margin-right: 16px;
        margin-bottom: 12px;
    }
</style>