<template>
    <div>
        <div id="myChart" class="h250 mt20"></div>
    </div>
</template>

<script setup>
    import { reactive, watch, onMounted, onBeforeUnmount } from 'vue';
    import { heightList } from '@/api/market.js';
    import * as echarts from 'echarts';
    let myChart = null
	const propData = defineProps({
		info: Object,
		show: Boolean,
		value: Number
	})
    const pageData = reactive({
        option: {
            title: {
                text: '7-18岁+身高对照表',
                textStyle:{ fontSize:12}
            },
		  	tooltip: {
		  	  	trigger: 'axis',
                axisPointer: { type: 'cross', show: true },
		  	},
            color: ['none', 'none', 'none', '#F2399B'],
		  	grid: {
		  	  	left: '3%',
		  	  	right: '4%',
		  	  	bottom: '3%',
		  	  	containLabel: true
		  	},
		  	xAxis: [{
		  	  	  	type: 'category',
		  	  	  	data: [],
					boundaryGap: false,
					splitLine:{
         			  show: true, //让网格显示
         			  lineStyle: {//网格样式
         			    color:'rgba(0, 0, 0, 0.15)', //网格的颜色
         			    width:1, //网格的宽度
         			    type:'solid' //网格是实实线，可以修改成虚线以及其他的类型
         			  }
         			}
		  	  	}],
		  	yAxis: [{
		    	  	    type : 'value',
                        scale : true,
                        max : 190,
                        min : 110,
                        splitNumber : 8,
                        boundaryGap : [ 0.2, 0.2 ]
                    }],
		  	series: [{
		      	name: '矮小',
		      	type: 'line',
				data: [],
		      	symbol: 'none',
		      	itemStyle: {
				  	normal: {
				  	  	lineStyle: {
				  	  	  	color: "#999", //改变折线颜色
				  	  	},
				  	},
				},
				endLabel: {
      			  show: true,
      			  formatter: function (params) {
      			    return '矮小';
      			  },
				  offset: [-30, 10]
      			},
		    }, {
		      	name: '偏矮',
		      	type: 'line',
		      	data: [],
		      	symbol: 'none',
		      	itemStyle: {
				  	normal: {
				  	  	lineStyle: {
				  	  	  	color: "#666", //改变折线颜色
				  	  	},
				  	},
				},
				endLabel: {
      			  show: true,
      			  formatter: function (params) {
      			    return '偏矮';
      			  },
				  offset: [-30, 10]
      			},
		    }, {
		      	name: '标准',
		      	type: 'line',
		      	data: [],
		      	symbol: 'none',
		      	itemStyle: {
				  	normal: {
				  	  	lineStyle: {
				  	  	  	color: "#F2399B", //改变折线颜色
				  	  	},
				  	},
				},
				endLabel: {
      			  show: true,
      			  formatter: function (params) {
      			    return '标准';
      			  },
				  offset: [-30, 10]
      			},
		    }, {
                type:'scatter',
                data:[]
            }]
		}
    })
	watch(() => propData.show, (newVal, oldVal) => {
        if (newVal) {
            getInfo()
        }
    })
    /**
     * 获取身高曲线
     */
    function getInfo(){
        let params = {
            sex: propData.info.sex,
            ageMin: propData.info.ageMin,
            ageMax: propData.info.ageMax
        }
        heightList(params).then((res) => {
            if (res.code == 0) {
				myChart = echarts.init(document.getElementById('myChart'))
				if (propData.info.sex == 1) {
					pageData.option.yAxis[0].max= 180
				}
				if (propData.info.ageMax == 6 ) {
					pageData.option.title.text = '1-6岁身高对照表'
					pageData.option.yAxis[0].max= 140
					pageData.option.yAxis[0].min= 60
				}
				pageData.option.xAxis[0].data = [] // x轴
				pageData.option.series[0].data = [] // 潜在会员
				pageData.option.series[1].data = [] // 正式会员
                let arr = [],index = ''
				res.data.forEach((item, i) => {
					pageData.option.xAxis[0].data.push(item.age)
					pageData.option.series[0].data.push(item.data1)
                    pageData.option.series[1].data.push(item.data2)
					if (item.age == propData.info.age) {
						index = i
					}
                    arr.push(item.data3)
				})
                pageData.option.series[2].data = arr
                pageData.option.yAxis.max = Math.max(...arr)
                pageData.option.series[3].data = [[index, propData.value]]
				myChart.clear()
				myChart.setOption(pageData.option, true);
				window.addEventListener("resize",function(){
        		    myChart.resize();
        		});
			}
        })
    }
	onMounted(() => {
        getInfo()
    })
    onBeforeUnmount(() => {
      if (!myChart) {
        return
      }
      myChart.dispose()
      myChart = null
    })
</script>
<style type='css/style' scoped>

</style>