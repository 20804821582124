<template>
	<div class="potential_member">
		<formQuery :queryOpts="pageData.queryOpts" @query="getTableData"></formQuery>
		<div class="mb12 rx-bc">
			<div>
				<el-button type="primary" @click="createMemberEvent('')">新增潜在会员</el-button>
				<el-button @click="followPeopleEvent" >分配跟进人</el-button>
				<el-button @click="batchFollowEvent" >批量跟进</el-button>
				<el-button @click="sethighSeasEvent">投入公海</el-button>
				<el-button class="mr16" @click="synchro">同步门店潜在会员</el-button>
				<el-dropdown @command="dropdownEvent">
					<el-button type="primary" plain >
						更多操作<el-icon class="el-icon--right"><ArrowDown /></el-icon>
					</el-button>
					<template #dropdown>
						<el-dropdown-menu>
							<el-dropdown-item command="1" >删除会员</el-dropdown-item>
						</el-dropdown-menu>
					</template>
				</el-dropdown>
			</div>
			<el-button @click="exportMember" :icon="Upload" >导出</el-button>
		</div>
		<div>
			<el-table :data="pageData.tableData" stripe style="width: 100%" @selection-change="selectionChange">
				<el-table-column fixed type="selection" width="55"/>
			    <el-table-column fixed label="会员姓名" width="110" show-overflow-tooltip >
					<template #default="scope">
						<span @click="memberDetail(scope.row.encryptionId)" class="color-409EFF c_p tool_btn">{{scope.row.realName}}</span>
					</template>
				</el-table-column>
				<el-table-column prop="telephoneNum" label="手机号" min-width="110" />
				<el-table-column prop="upStatusName" label="跟进状态" min-width="100" >
					<template #default="scope">
						<span>{{ scope.row.upStatusName || '暂无状态'}}</span>
					</template>
				</el-table-column>
			    <el-table-column prop="productTyp" label="意向级别" min-width="150">
					<template #default="scope">
						<el-rate v-model="scope.row.ifImportanceDegree" disabled size="large"></el-rate>
					</template>
				</el-table-column>
			    <el-table-column prop="adviserNames" label="跟进人" min-width="100" show-overflow-tooltip />
			    <el-table-column prop="channelName" label="会员来源" min-width="100" >
					<template #default="scope">
						<span>{{scope.row.channelName || '暂无来源'}}</span>
					</template>
				</el-table-column>
			 <!--   <el-table-column prop="productNum" label="标签" min-width="100">
					<template #default="scope">
						<template v-if="scope.row.userTag && scope.row.userTag.length > 0">
							<span v-for="(item,index) in scope.row.userTag" :key="index" class="pl5 pr5 mr5" :style="{color:'#'+item.fontColor,backgroundColor:'#'+item.backColor}">{{item.tagName}}</span>
						</template>
					</template>
				</el-table-column> -->
			    <el-table-column prop="thisFollowTime" label="上次跟进时间" min-width="110" show-overflow-tooltip />
			    <el-table-column prop="nextFollowTime" label="下次跟进时间" min-width="110" show-overflow-tooltip />
				<el-table-column label="操作" width="160" fixed="right">
					<template #default="scope">
						<a href="javascript:;" class="t_d_u color-409EFF tool_btn" @click="createMemberEvent(scope.row.encryptionId)">编辑</a>
						<a href="javascript:;" class="t_d_u color-409EFF tool_btn" @click="oneFollowEvent(scope.row)">跟进</a>
						<a href="javascript:;" class="t_d_u color-409EFF tool_btn" @click="changeFormal(scope.row.encryptionId)">转正</a>
					</template>
				</el-table-column>
			</el-table>
			<div class="mt20 rx-ec">
			    <el-pagination
			      v-model:currentPage="pageData.searchForm.page"
			      :page-sizes="[15,20,30,50,100]"
			      :page-size="pageData.searchForm.limit"
			      layout="total, sizes, prev, pager, next, jumper"
			      :total="pageData.totalCount"
			      @size-change="pageSizeChange"
			      @current-change="()=>{getList()}"
			    >
			    </el-pagination>
			</div>
		</div>
		
		<!-- 分配跟进人 -->
		 <el-dialog
		     v-model="pageData.followPeopleShow"
		     title="选择跟进人"
		 	width="400px"
		     :before-close="()=>{pageData.followPeopleShow = false}"
		     custom-class="popwechat"
		   >
			<div class="pl20 pt50 pb50 pr20">
				<div class="rx-cc">
					<span class="">跟进人：</span>
					<div>
						<el-select multiple collapse-tags v-model="pageData.followPeopleId" placeholder="请选择">
							<el-option v-for="(item,index) in pageData.followPeopleList" :key="item.id" :label="item.realName" :value="index">
							</el-option>
						</el-select>
					</div>
				</div>
		  </div>
		   <template #footer>
		    	<div class="dialog-footer">
		        	<el-button @click="pageData.followPeopleShow = false">取消</el-button>
		        	<el-button type="primary" @click="followPeopleSave">确定</el-button>
		    	</div>
		    </template>
		</el-dialog>

		<AddMember v-model="pageData.addMemberShow" :title="pageData.addMemberTitle" :id="pageData.memberId" @close="addMemberClose" @save="addMemberSave" flag="2"/>
		<AddFollowStep v-model="pageData.addFollowShow" :star="pageData.followStar" :sid="pageData.memberId" @close="addFollowClose" @save="addFollowSave"/>
		<ExportMember v-model="pageData.exportMemberShow" type="1" :data="pageData.searchForm" @close="exportMemberClose" @save="exportMemberSave"></ExportMember>
		<Confirm ref="confirmRef" :title="pageData.confirmTitle" :text="pageData.confirmText" @save="confirmSave"/>
		<!-- 同步门店潜在会员 -->
		<el-dialog
		    v-model="pageData.dialogPotentialShow"
		    title="同步门店潜在会员"
		 	width="780px"
		    :before-close="handleClosePotential"
		   >
			<div class="p20">
		        <el-table ref="multipleTable" 
		            :data="pageData.potentialList" 
		            stripe tooltip-effect="dark" style="width: 100%"
		            @selection-change="handleSelectionChange"
		            height="500"
					:row-key="row=>row.id"
		        >
		        <el-table-column type="selection" width="55" :reserve-selection="true"/>
		        <el-table-column prop="realName" label="门店潜在会员" />
		        <el-table-column prop="telephoneNum" label="手机号" />
		        <el-table-column label="意向级别" >
		            <template #default="scope">
		                <el-rate v-model="scope.row.ifImportanceDegree" disabled />
		            </template>
		        </el-table-column>
		    </el-table>
		   <!-- <div class="mt20 rx-ec" v-show="pageData.potentialList.length > 0">
		        <el-pagination
		          v-model:currentPage="pageData.potentialPage"
		          :page-sizes="[15,20,30,50,100]"
		          :page-size="pageData.potentialLimit"
		          layout="total, sizes, prev, pager, next, jumper"
		          :total="pageData.potentialCount"
		          @size-change="pageSizeChange1"
		          @current-change="pageChange1"
		        >
		        </el-pagination>
		    </div> -->
		    </div>
		    <template #footer>
		    	<div class="dialog-footer">
		        	<el-button @click="handleClosePotential">取消</el-button>
		        	<el-button type="primary" @click="dialogPotentialSave">确定</el-button>
		    	</div>
		    </template>
		</el-dialog>
	</div>
</template>

<script setup>
	import {ref,reactive,onMounted} from 'vue';
	import {useRouter,onBeforeRouteLeave} from 'vue-router';
	import {useStore} from 'vuex'
	import { ElMessage} from 'element-plus'
	import { ArrowDown, Close, Upload } from '@element-plus/icons-vue'
	import utils from '@/common/utils.js'
	import {selectStoreTrailUser,studentInput,getStoreAccountStaffSimple,assignFollower,vipUpdateTagBatch,followUpTagList,deleteTagDetails,deleteMyCard} from '@/api/intentionList.js'
	import {courseList} from '@/api/course.js'
	import {formalToUser} from '@/api/showroom.js'
	import { getLocalStorage } from '@/common/common.js';
	import CommonTitle from '@/components/CommonTitle.vue'
	import Confirm from '@/components/Confirm.vue'
	import formQuery from '@/components/branch-store/formQuery.vue';
	import AddMember from '@/components/branch-store/member/AddMember.vue';
	import AddFollowStep from '@/components/branch-store/member/AddFollowStep.vue';
	import ExportMember from '@/components/branch-store/member/ExportMember.vue';
	import { syncSubjectTrailStudent ,getSubjectTrailStudent} from '@/api/market.js';
	const store = useStore();
	const router = useRouter();
	const confirmRef = ref();
	const pageData = reactive({
		followStatusIdList: [],
		memberId:'', //编辑潜在会员
		followStar:'',
		addMemberShow:false,
		
		followPeopleShow:false, //分配跟进人
		followPeopleList:[],
		followPeopleId:[],
		
		addFollowShow:false, //批量跟进
		
		setHighSeasShow:false, //批量投入公海
		batchHighSeasList:[],
		batchHighSeasId:'',

		
		confirmTitle:'', //通用确认框
		confirmText:'',
		
		
		exportMemberShow:false,
		
		tableData:[],
		totalCount:0,
		searchForm:{
			page:1,
			limit:15,
			searchKeys:'',
			followStatusId:'',
			ifImportanceDegree:'',
			adviserIds:'',
			channelId:'',
			tagList:''
		},
		queryOpts:{
			fields:[{
				type : 'input',
				fieldName : 'searchKeys',
				placehold : '请输入会员姓名/手机号'
			},{
				type : 'select',
				fieldName : 'followStatusId',
				placehold : '跟进状态',
				options : [],
				value:''
			},{
				type : 'select',
				fieldName : 'ifImportanceDegree',
				placehold : '意向级别',
				options : utils.getDict('starState',{label : '意向级别' ,value : ''}),
				value:''
			},{
				type : 'selectMany',
				fieldName : 'adviserIds',
				placehold : '跟进人',
				options : [],
				value:''
			},{
				type : 'select',
				fieldName : 'channelId',
				placehold : '会员来源',
				options : [],
				value:''
			}
			// ,{
			// 	fieldName : 'tagList',
			// 	placehold : '标签',
			// 	type:'selectMany',
			// 	options : [],
			// 	value:''
			// }
			,{
				type : 'dateRange',
				fieldName : 'lastFollowUpTimeStr',
				placeholder : '上次跟进时间',
				width:380,
				value:''
			},{
				type : 'dateRange',
				fieldName : 'nextFollowUpTimeStr',
				placeholder : '下次跟进时间',
				width:380,
				value:''
			}]
		},
		potentialList:[],
		synchroList: [],
		dialogPotentialShow:false,
		potentialPage:1,
		potentialLimit:15,
		potentialCount:0
	}) 
	// 不需要响应式监听的全局变量
	let confirmType = '',//通用确认调用类型
	nowTableRow = '',//操作-当前行数据
	selectCheckData = [];//批量多选数据集合;
	
	//获取字典
	utils.getDynaDict('followState',{label : '跟进状态' ,value : ''},function(res){
	    pageData.queryOpts.fields[1].options = res
		pageData.followStatusIdList = res
		getList();
	});
	utils.getDynaDict('followerList','',function(res){
	    pageData.queryOpts.fields[3].options = res;
	});
	utils.getDynaDict('channelList',{label : '会员来源' ,value : ''},function(res){
	    pageData.queryOpts.fields[4].options = res;
	});
	utils.getDynaDict('upTagList','',function(res){
	    pageData.queryOpts.fields[5].options = res;
	});
	//获取跟进状态中文名称
	function getFollowStatusName(id){
		pageData.queryOpts.fields[1].options.map((n)=>{
			if(id == n.value){
				return n.label;
			}
		})
	}
	//筛选表单回调
	function getTableData(options){
		options.nextFollowUpTimeStr = options.nextFollowUpTimeStr ? options.nextFollowUpTimeStr.join('-') : '';
		options.lastFollowUpTimeStr = options.lastFollowUpTimeStr ? options.lastFollowUpTimeStr.join('-') : '';
		for(var key in options){
			pageData.searchForm[key] = options[key];
		}
		searchTable();
	}
	
	//搜索table
	function searchTable(){
		pageData.searchForm.page = 1;
		getList();
	}
	//切换分页数量
	function pageSizeChange(limit){
		pageData.searchForm.limit = limit;
		searchTable();
	}
	function getList(exportObj){
		var formData = JSON.parse(JSON.stringify(pageData.searchForm));
		formData.tagList = formData.tagList && formData.tagList.length > 0 ? formData.tagList.join(',') : '';
		formData.adviserIds = formData.adviserIds && formData.adviserIds.length > 0 ? formData.adviserIds.join(',') : '';
		if(exportObj){ //导出
			exportObj.exportData = 1;
			formData.page = 1;
			formData.limit = 9999;
			Object.assign(formData,exportObj);
		}
		selectStoreTrailUser(formData).then((res)=>{
			if(res.code == 0){
				if(exportObj){ //导出
					ElMessage({
						type: 'success',
						message: '正在导出，请稍后前往下载中心下载'
					})
				}else{
					pageData.tableData = res.data && res.data.map((n)=>{
						n.ifImportanceDegree = utils.getStartNum(n.ifImportanceDegree); //特殊处理意向级别
						return n;
					});
					pageData.totalCount = res.count;
				}
			}
		})
	}
	//table通用勾选
	function selectionChange(data){
		selectCheckData = data;
	}
	//获取table勾选集合的某个字段集合
	function getSelectCheckKey(key){
	 	return selectCheckData.map(n=>n[key]).join(',');
	}
	// 验证批量操作是否勾选了数据
	function CheckLengthCallBack(callback){
		if(selectCheckData.length == 0){
			ElMessage({
				type: 'warning',
				message: '请选择需要操作的会员',
			})
			return false;
		}
		callback && callback();
	}
	//ajax提交保存通用提示
	function ajaxSaveCallBack(text,callback){
		ElMessage({
			type: 'success',
			message: text
		})
		searchTable();
		callback && callback();
	}
	
	// 导出
	function exportMember(){
		pageData.exportMemberShow = true;
	}
	function exportMemberSave(res){
		pageData.exportMemberShow = false;
		getList(res);
	}
	function exportMemberClose(){
		pageData.exportMemberShow = false;
	}
	
	// 新增潜在会员
	function createMemberEvent(id){
		pageData.memberId = id;
		pageData.addMemberTitle= id ? '编辑潜在会员' : '新增潜在会员';
		pageData.addMemberShow = true;
	}
	function addMemberSave(res){
		pageData.addMemberShow = false;
		searchTable();
	}
	function addMemberClose(){
		pageData.addMemberShow = false;
	}
	
	// 分配跟进人
	function followPeopleEvent(){
		CheckLengthCallBack(()=>{
			getStoreAccountStaffSimple().then((res)=>{
				if(res.code == 0){
					pageData.followPeopleList = res.data;
				}
			})
			pageData.followPeopleShow = true;
		})
	}
	
	// 分配跟进人保存
	function followPeopleSave(){
		if(pageData.followPeopleId.length == 0){
			ElMessage({
				type: 'warning',
				message: '请选择跟进人',
			})
			return false;
		}
		var arr = [];
		pageData.followPeopleId.map((n)=>{
			arr.push(pageData.followPeopleList[n]);
		})
		assignFollower({
			followerList:arr,
			studentIdList:getSelectCheckKey('cardMyId').split(','),
		}).then((res)=>{
			if(res.code == 0){
				pageData.followPeopleShow = false;
				ajaxSaveCallBack('分配成功');
			}
		})
	}
	// 批量跟进
	function batchFollowEvent(){ //批量
		CheckLengthCallBack(()=>{
			oneFollowEvent({cardMyId:getSelectCheckKey('cardMyId')});
		})
	}
	function oneFollowEvent(row){
		pageData.memberId = row.cardMyId;
		pageData.followStar = row.ifImportanceDegree;
		pageData.addFollowShow = true;
	}
	function addFollowSave(res){
		pageData.addFollowShow = false;
		searchTable();
	}
	function addFollowClose(){
		pageData.addFollowShow = false;
	}

	
	// 批量投入公海
	function sethighSeasEvent(){
		CheckLengthCallBack(()=>{
			pageData.confirmTitle = '是否将选择的会员投入公海？';
			confirmType = 'highSeas';
			confirmRef.value.show();
		})
	}
	// 解绑微信
	function unboundEvent(id){
		nowTableRow = id;
		pageData.confirmTitle = '确定解除该会员的微信绑定关系吗？';
		confirmType = 'unbound';
		confirmRef.value.show();
	}
	
	//下拉框按钮操作事件
	function dropdownEvent(data){
		CheckLengthCallBack(()=>{
			if(data == 1){
				pageData.confirmTitle = '是否删除勾选的会员？';
				confirmType = 'delete';
				confirmRef.value.show();
			}
		})
	}
	//转正
	var changeFormalId = '';
	function changeFormal(id){
		changeFormalId = id;
		pageData.confirmTitle = '是否将该潜在会员转成正式会员？';
		confirmType = 'changeFormal';
		confirmRef.value.show();
	}
	//通用确认框确认事件回调
	function confirmSave(){
		if(confirmType == 'highSeas'){
			studentInput({
				studentIds:getSelectCheckKey('cardMyId')
			}).then((res)=>{
				if(res.code == 0){
					ajaxSaveCallBack('操作成功');
				}
			})
		}else if(confirmType == 'delete'){
			deleteMyCard({
				ids:getSelectCheckKey('encryptionId')
			}).then((res)=>{
				if(res.code == 0){
					ajaxSaveCallBack('删除成功');
				}
			})
		}else  if(confirmType == 'unbound'){
			productDelete({
				encryptionId:nowTableRow
			}).then((res)=>{
				if(res.code == 0){
					ajaxSaveCallBack('解绑成功');
				}
			})
		}else if(confirmType == 'addMember'){
			addMemberEvent(addNowRow,1);
		}else if(confirmType == 'changeFormal'){
			formalToUser({
				encryptionId:changeFormalId
			}).then((res)=>{
				if(res.code == 0){
					ajaxSaveCallBack('操作成功');
				}
			})
		}
		
	}
	//会员详情
	function memberDetail(id){
		router.push({path:'/branch/memberDetail',query:{encryptionId:id}});
	}
	
	//切换分页数量
	function pageSizeChange1(limit){
		pageData.potentialLimit = limit;
		pageData.potentialPage = 1;
		getMemberList();
	}
	function pageChange1(page){
		pageData.potentialPage = page;
		getMemberList();
	}
	/**
	 * 获取门店潜客列表
	 */
	function getMemberList() {
	    getSubjectTrailStudent({
			trialFlag:2,
			page:pageData.potentialPage,
			limit:pageData.potentialLimit,
		}).then(res => {
	        if (res.code == 0) {
	            pageData.potentialList = res.data || [];
	            pageData.potentialCount = res.count;
	        }
	        
	    })
	}
	/**
	 * 同步
	 */
	function synchro() {
	    getMemberList()
	    pageData.dialogPotentialShow = true
	    
	}
	/**
	 * 取消同步
	 */
	function handleClosePotential() {
	    pageData.dialogPotentialShow = false
	}
	/**
	 * 选择同步的数据
	 */
	function handleSelectionChange (val) {
	    pageData.synchroList = val
	}
	/**
	 * 确定同步
	 */
	function dialogPotentialSave() {
	    if (!pageData.synchroList.length) {
	        ElMessage.warning('请选择需要同步的数据')
	        return
	    }
	    let arr = []
	    pageData.synchroList.forEach(item => {
	        arr.push(item.cardMyId)
	    })
	    let params = {
	        ids: arr.join(','),
			trialFlag:2
	    }
	    syncSubjectTrailStudent(params).then(res=> {
	        if (res.code == 0) {
	            ElMessage.success('同步成功')
	            multipleTable.value.clearSelection()
	            getList()
	            pageData.dialogPotentialShow = false
	           if (res.remark.length>0) {
	                ElMessageBox.alert('以下'+ res.remark.length +'位会员同步失败，原因：该手机号在本系统已存在</br>'+ res.remark ,'温馨提示',{
		            	confirmButtonText: '好的',
		            	dangerouslyUseHTMLString: true,
		            });
	           }
	            
	        }
	    })
	}
	
	
	// 页面缓存
	onBeforeRouteLeave((to, from)=>{
	    if (to.path === '/branch/memberDetail') {
	        store.commit('SetKeep',{keepLiveRoute : ['IntentionList']});
	    } else {
	        store.commit('SetKeep',{keepLiveRoute : []});
	    }
	})
	
</script>

<style scoped lang="stylus">
	.delTagList{
		li{
			border 1px solid #ccc;
			span:first-child{
				border-right 1px solid #ccc;
			}
		}
	}
</style>
