<template>
	<workArea :barlist="pageData.barlist">
		<div class="card_manage bg-fff pl16 pt10 pr16">
			<el-tabs v-model="pageData.tabNum" @tab-click="handleClick">
				<el-tab-pane label="套餐配置" name="0"></el-tab-pane>
				<el-tab-pane label="合同剩余" name="1"></el-tab-pane>
				<el-tab-pane label="充值记录" name="2"></el-tab-pane>
			</el-tabs>
			<component :is="components[pageData.tabNum]" :tab="pageData.tabNum"></component>
		</div>
	</workArea>
</template>
<script>
  export default {
    name: 'contractAdmin'
  }
</script>
<script setup>
	import { reactive, onMounted, onBeforeUnmount } from 'vue';
	import {useRouter} from 'vue-router';
	import workArea from '@/components/branch-store/workArea.vue';
	import Package from '@/views/admin/contract/Package.vue'; //套餐配置
	import Residue from '@/views/admin/contract/Residue.vue'; //合同剩余
	import Record from '@/views/admin/contract/Record.vue'; //充值记录
	import utils from '@/common/utils.js';
	const router = useRouter();
	const components = [Package,Residue,Record];
	const pageData = reactive({
		tabNum:'0',
		barlist:[{ name : '营销后台' },{ name : '电子合同' }]// 面包屑
	})
	
	function handleClick(tab) {
      localStorage.setItem('contractAdmin', tab.props.name)
	}
	onMounted(()=> {
        if (localStorage.getItem('contractAdmin') != null) {
            pageData.tabNum = localStorage.getItem('contractAdmin')
	    }
	})
    onBeforeUnmount(() => {
		localStorage.removeItem('contractAdmin')
	})
</script>

<style scoped lang="stylus">
	
</style>
