<template>
	<workArea :barlist="pageData.barlist">
		<div class="card_manage bg-fff pl16 pt10 pr16">
			<el-tabs v-model="tabNum" @tab-click="handleClick">
				<!-- <el-tab-pane label="员工管理" name="0" v-if="utils.getMenuRole(33100)"></el-tab-pane> -->
				<el-tab-pane label="潜在会员" name="0"></el-tab-pane>
				<el-tab-pane label="公海池" name="1"></el-tab-pane>
				<el-tab-pane label="跟进提醒" name="2"></el-tab-pane>
				<el-tab-pane label="跟进记录" name="3"></el-tab-pane>
			</el-tabs>
			<component :is="components[tabNum]" :tab="tabNum"></component>
		</div>
	</workArea>
</template>
<script>
  export default {
    name: 'follow'
  }
</script>
<script setup>
	import { ref, reactive, onMounted, onBeforeUnmount } from 'vue';
	import { useRouter } from 'vue-router';
	import workArea from '@/components/branch-store/workArea.vue';
	import Potential from '@/views/branch-store/crm/follow/potential.vue'; //潜在会员
	import HighSeas from '@/views/branch-store/crm/follow/highSeas.vue'; //公海池
	import Reminder from '@/views/branch-store/crm/follow/reminder.vue'; //跟进提醒
	import Record from '@/views/branch-store/crm/follow/record.vue'; //跟进提醒
	import utils from '@/common/utils.js';
	const router = useRouter();
	const components = [Potential,HighSeas,Reminder,Record];
	const tabNum = ref('0');
	// var menuId = [33100,33101]; //会员有权限tab默认项
	// for(var i = 0;i < menuId.length;i++){
	// 	if(utils.getMenuRole(menuId[i])){
	// 		tabNum.value = i.toString();
	// 		break;
	// 	}
	// }
	const pageData = reactive({
		barlist:[{ name : '首页' },{ name : '会员管理' },{ name : '跟进管理'}]// 面包屑
	})
	function handleClick(tab){
		// pageData.barlist[2].name = tabNum.value == 0 ? '跟进管理' :'岗位管理';
		localStorage.setItem('follow', tab.props.name)
	}
	onMounted(()=> {
      if (localStorage.getItem('follow') != null) {
          tabNum.value = localStorage.getItem('follow')
		  // pageData.barlist[2].name = tabNum.value == 0 ? '员工管理' :'岗位管理';
	    }
	})
    onBeforeUnmount(() => {
		localStorage.removeItem('follow')
	})
</script>

<style scoped lang="stylus">
	
</style>
