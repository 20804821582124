<template>
	<div class="pt20">
		<el-table ref="table" :data="tableData.list" stripe style="width: 100%" height="65vh">
			<el-table-column label="申请人" min-width="110" >
				<template #default="scope">
					<span>{{scope.row.sid == 0 ? scope.row.realName:scope.row.storeName}}</span>
				</template>
			</el-table-column>
			<el-table-column label="申请展厅" min-width="110">
				<template #default="scope">
					<span @click="lookDetail(scope.row.encryptionId)" class="color-409EFF c_p tool_btn">{{scope.row.hallName }}</span>
				</template>
			</el-table-column>
			<el-table-column label="展厅状态" min-width="110">
				<template #default="scope">
					<span>{{scope.row.delFlag == 0 ? '正常':'已删除' }}</span>
				</template>
			</el-table-column>
			<el-table-column prop="createTime" label="申请时间" min-width="110" />
			<el-table-column prop="recommendFlag " label="是否已放置推荐区" min-width="110">
				<template #default="scope">
					<span>{{scope.row.recommendFlag == 1 ? '是':'否' }}</span>
				</template>
			</el-table-column>
		</el-table>
		<div class="mt20 rx-ec">
			<el-pagination v-model:currentPage="searchForm.page" :page-sizes="[15,20,30,50,100]"
				:page-size="searchForm.limit" layout="total, sizes, prev, pager, next, jumper"
				:total="tableData.count" @size-change="pageSizeChange" @current-change="()=>{getList()}">
			</el-pagination>
		</div>
	</div>
</template>

<script setup>
	import {
		ref,
		reactive
	} from 'vue';
	
	import {getHallContentApplyList} from '@/api/showroom.js'
	const searchForm = reactive({
		page: 1,
		limit: 15
	})
	const tableData = reactive({
		list: [],
		count: 0
	})

	//搜索table
	function searchTable() {
		searchForm.page = 1;
		getList();
	}
	//切换分页数量
	function pageSizeChange(limit) {
		searchForm.limit = limit;
		searchTable();
	}
	getList();

	function getList() {
		getHallContentApplyList(searchForm).then((res)=>{
			if(res.data){
				tableData.list = res.data;
				tableData.count = res.count;
			}else{
				tableData.list =[];
				tableData.count = 0;
			}
		})
	}
	function lookDetail(id){
		//预览
		var token = localStorage.getItem('businessToken');
		window.open('https://3dhall.flow360.cn/?hallUID='+id+'&access_token='+token);
	}
	
</script>
<style scoped>
	
</style>