<template>
	<workArea :barlist="pageData.barlist">
		<div id="initiateBox">
			<div class="borderBottom pt25 pb25 rx-cc">
				<div class="rx-sc" :class="{navActive:pageData.tab == 1}">
					<div class="w30 h30 bdr100 mr15 t_a_c ft16 fwb bg-FBFBFD color-666"
						style="line-height: 30px;">1</div>
					<span class="color-333 ft14 fw400">设置关联信息</span>
				</div>
				<div class="borderBottom w80 mr20 ml20"></div>
				<div class="rx-sc" :class="{navActive:pageData.tab == 2}">
					<div class="w30 h30 bdr100 mr15 t_a_c ft16 fwb bg-FBFBFD color-666"
						style="line-height: 30px;">2</div>
					<span class="color-666 ft14 fw400">选择合同模板</span>
				</div>
			</div>
			<div class="rx-cc" v-show="pageData.tab == 1">
				<div class="pt150 pb150">
					<div class="rx-sc mb10 ft16 fw400"><span class="color-red">*</span>会员名称：</div>
					<div class="w420 mb10">
						<Select :list="pageData.memberList" placeholder="输入关键词搜索" :width="420" @change="getUserList" @itemclick="itemclickEvent"></Select>
					</div>
					<div class="color-999 mb50"><span class="color-red">提示：</span>选择会员后，若合同内容通过系统配置了会员信息，则修改信息可自动带入</div>
				</div>
			</div>
			<div v-show="pageData.tab == 2">
				<ul class="bg-FBFBFD pl25 pr25 pt25 rx-wss" id="tempBox">
					<li class="mr35 mb35 w240 bg-fff temp" @click="pageData.templateId = item.id" v-for="item in pageData.tempList">
						<div class="imgBox posi-r">
							<img class="ww100" src="@/assets/template_bg.png" alt="">
							<div class="radioBox" :class="{active:pageData.templateId == item.id}"></div>
						</div>
						<div class="pt10 pb10 pl15 pr15">
							<div class="h50 color-333 ft16" style="line-height: 20px;">{{item.fileName }}</div>
							<div class="color-999 ft14">创建时间：{{item.createTime }}</div>
						</div>
					</li>
					<div id="noData" class="pt100 pb100 rx-cc ww100" v-if="pageData.tempList.length == 0">
						<img src="@/assets/noData2.png" alt="">
					</div>
				</ul>
			</div>
		</div>
		<div v-show="pageData.width" class="rx-ec bg-FAFAFC posi-f right0 bottom0" :style="{width:pageData.width+'px'}" style="z-index: 400;">
			<div class="color-fff ft16 bg-00a3ff pt20 pb20 pl40 pr40 c_p"
				style="border-right: 1px solid white;" id="prevBtn" v-show="pageData.tab == 2" @click="pageData.tab = 1">上一步</div>
			<div class="color-fff ft16 bg-00a3ff pt20 pb20 pl40 pr40 c_p" @click="btnEvent">{{pageData.tab == 1 ? '下一步' :'生成合同'}}</div>
		</div>
	</workArea>
</template>

<script setup>
	import { ref, reactive,onMounted } from 'vue';
	import workArea from '@/components/branch-store/workArea.vue';
	import Select from '@/components/Select.vue';
	import { ElMessage,ElMessageBox } from 'element-plus'
	import { getCardMyList,getEsignFileTemplateList,draftEsignFlow} from '@/api/contract.js';
	import {useRouter } from 'vue-router';
	const router = useRouter();
	const pageData = reactive({
		width:'',
		cardMyId:'',
		templateId:'',
		tab:1,
		memberList:[],
		tempList:[],
		barlist:[{ name : '首页' },{ name : '电子合同' },{ name : '发起合同' }]// 面包屑
	})
	//会员列表
	//远程搜索会员
	function itemclickEvent(val){
		pageData.cardMyId = val;
	}
	getUserList();
	function getUserList(query){
		getCardMyList({
			searchKey:query
		}).then(res=>{
			pageData.memberList = res.data.map(n=>{
				return {
					label:n.realName,
					value:n.cardMyId
				}
			});
		})
	}
	//合同模板
	getEsignFileTemplateList().then(res=>{
		pageData.tempList = res.data;
	})
	
	function btnEvent(){
		if(pageData.tab == 1){
			if(!pageData.cardMyId){
				ElMessage.warning('请选择会员');
				return false;
			}else{
				pageData.tab = 2
			}
		}else{
			if(!pageData.templateId){
				ElMessage.warning('请选择合同模板');
				return false;
			}else{
				draftEsignFlow({
					cardMyId:pageData.cardMyId,
					templateId:pageData.templateId
				}).then(res=>{
					window.open(res.data.shortUrl);
					router.go(-1);
				})
			}
		}
	}
	onMounted(()=>{
		setTimeout(function(){
			pageData.width = document.getElementById('initiateBox').clientWidth;
		},500)
		
	})
	
</script>

<style scoped>
	.navActive div {
		background-color: #00a3ff !important;
		color: white !important;
	}
	
	.navActive span {
		color: #333333 !important;
	}
	.radioBox{
		position: absolute;
		right: 10px;
		top: 10px;
		width: 20px;
		height: 20px;
		border-radius: 100px;
		border: 1px solid #ccc;
	}
	.radioBox.active{
		background-color: #03A3FF;
		border: none;
	}
	.radioBox.active::after{
		content: '';
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%,-50%);
		width: 7px;
		height: 7px;
		border-radius: 100px;
		background-color: white;
	}
</style>