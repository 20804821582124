<template>
	<workArea :barlist="pageData.barlist">
		<div class="card_manage bg-fff pl16 pt10 pr16">
			<el-tabs v-model="pageData.tabNum" @tab-click="handleClick">
				<el-tab-pane label="员工管理" name="0"></el-tab-pane>
				<el-tab-pane label="岗位管理" name="1"></el-tab-pane>
			</el-tabs>
			<component :is="components[pageData.tabNum]" :tab="pageData.tabNum"></component>
		</div>
	</workArea>
</template>
<script>
  export default {
    name: 'System'
  }
</script>
<script setup>
	import { reactive, onMounted, onBeforeUnmount } from 'vue';
	import {useRouter} from 'vue-router';
	import workArea from '@/components/branch-store/workArea.vue';
	import StaffList from '@/views/admin/system/StaffList.vue'; //潜在会员
	import PostList from '@/views/admin/system/PostList.vue'; //公海池
	import utils from '@/common/utils.js';
	const router = useRouter();
	const components = [StaffList,PostList];
	const pageData = reactive({
		tabNum:'0',
		barlist:[{ name : '系统管理' },{ name : '员工管理' }]// 面包屑
	})
	
	function handleClick(tab) {
      localStorage.setItem('system', tab.props.name)
	}
	onMounted(()=> {
        if (localStorage.getItem('system') != null) {
            pageData.tabNum = localStorage.getItem('system')
	    }
	})
    onBeforeUnmount(() => {
		localStorage.removeItem('system')
	})
</script>

<style scoped lang="stylus">
	
</style>
