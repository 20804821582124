<template>
	<ul class="move_sort rx-wss">
		<template v-for="(item,index) in MoveData.lists" :key="index">
			<li v-if="item" 
				:draggable="item == -1 ? false :first && index == 0 ? false : true"
				@dragenter="enter($event, index)"
				@dragstart="start($event, index)"
				@dragend="end"
				:class="{active:MoveData.activeIndex == index && !border,leftBorder:MoveData.activeIndex == index && border,borderCss:border}"
				:data-index="index"
				:style="{display:index == 0 && MoveData.lists.length >= hide ? 'none' : ''}"
			>
				<slot :name="index"></slot>
			</li>
		</template>
	</ul>
</template>

<script setup>
	const propData = defineProps({
	  list:Array,
	  first:Boolean,//是否第一个不移动
	  border:String,//有显示左边框状态  无显示顶部边框状态
	  hide:Number //小于几隐藏第一个
	})
	import {ref,reactive} from 'vue'
	const MoveData = reactive({
		activeIndex:-1,
		startIndex:-1,
		lists:[]
	})
	MoveData.lists = propData.list;
	const emit = defineEmits(['moveover'])
	function enter(e,index){
		MoveData.activeIndex = index
		// e.path.map((n)=>{
		// 	if(n.nodeName === 'LI'){
		// 		if(propData.first && n.dataset.index == 0){
		// 			return false;
		// 		}
		// 		MoveData.activeIndex = n.dataset.index;
		// 		return false;
		// 	}
		// })
		
	}
	function start(e, index){
		MoveData.startIndex = index
		// console.log(e.path)
		// e.path.map((n)=>{
		// 	console.log(n.dataset)
		// 	if(n.nodeName === 'LI'){
		// 		if(propData.first && n.dataset.index == 0){
		// 			return false;
		// 		}
		// 		MoveData.startIndex = n.dataset.index;
		// 		return false;
		// 	}
		// })
	}
	function end(e){
		// if(propData.first && MoveData.activeIndex == 0){
		// 	return false;
		// }
		if(MoveData.startIndex != MoveData.activeIndex) {
			var sLi = MoveData.lists[MoveData.startIndex];
			MoveData.lists.splice(MoveData.activeIndex,0,sLi);
			var index = MoveData.startIndex < MoveData.activeIndex ? MoveData.startIndex : Number(MoveData.startIndex)+1; 
			MoveData.lists.splice( index,1);
			emit('moveover',MoveData.lists);
		}
		MoveData.startIndex = -1;
		MoveData.activeIndex = -1;
	}
</script>

<style lang="stylus" scoped>
	.move_sort {
		width: 100%;
		li.active{
			border-top: 2px dashed blue;
		}
		li.borderCss{
			float: left;
			margin-bottom: 15px;
		}
		li.leftBorder{
			border-left: 2px dashed blue !important;
		}
	}
	
</style>
