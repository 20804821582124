<template>
	<el-input v-model="value" type="text" :placeholder="propData.placeholder || '请输入'" @input="change">
		<template #append v-if="propData.unit">{{propData.unit}}</template>
		<template #append v-if="propData.slot"><slot></slot></template>
	</el-input>
</template>
<script setup>
	const propData = defineProps({
	  value:String,
	  type: String,
	  node: String,
	  placeholder:String,
	  max:String,
	  length:String,
	  unit:String,
	  slot:Boolean,
	})
	import {ref,reactive} from 'vue';
	const emit = defineEmits(['change']);
	const value = ref(propData.value || '');
	var oldValue = '';
	function change(val){
		var valStr = val.toString();
		if(propData.type == 1){ //正整数
			if(isNaN(Number(val)) || valStr.indexOf('.') > -1 || Number(valStr) < 0 || Number(valStr) > propData.max){
				value.value = oldValue;
			}else{
				oldValue = value.value;
			}
		}
		if(propData.type == 2){ //小数保留
			var arr = valStr.indexOf('.') > -1 ? valStr.split('.')[1].length : 0;
			if(isNaN(Number(val)) || Number(valStr) < 0 || Number(valStr) > propData.max || arr > propData.length){
				value.value = oldValue;
			}else{
				oldValue = value.value;
			}
		}
		callback(oldValue);
	}
	function callback(val){
		emit('change',{
			key:propData.node,
			value:val
		});
	}

</script>

<style scoped>

</style>
