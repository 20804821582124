<template>
    <workArea :barlist="pageData.barlist">
        <div class="p20 pl45 pr45">
            <div class="rx-bc nav">
                <span class="ft18 fwb color-333 pt10 pb10 pl30">模板配置</span>
                <el-button class="mr25" type="primary" @click="onSubmit">{{ type == 0 ? '存为我的模板' : '保存模板' }}</el-button>
            </div>
            <div class="mt16 rx-c hh100">
                <div class="w390 p20 pl35 pr35 bg-FAFAFC left-box">
                    <div>
                        <span class="color-333 fwb">模板名称</span>
                        <span class="color-999 ft12">（仅用于老师选择模板时显示）</span>
                    </div>
                    <div class="bg-fff box mt20">
                        <div class="rx-bc">
                            <span v-if="!pageData.leftNameEdit" class="l_h32">{{ pageData.leftName }}</span>
                            <el-input v-else v-model="pageData.leftName" maxlength="20" placeholder="请输入模板名称" />
                            <el-icon v-if="!pageData.leftNameEdit" :size="14" color="#409EFC" class="c_p" @click="pageData.leftNameEdit = true">
                                <EditPen />
                            </el-icon>
                            <el-button v-else class="ml5" type="primary"  @click="pageData.leftNameEdit = false">完成</el-button>
                        </div>
                    </div>
                    <div class="mt30">
                        <span class="color-333 fwb">配置项目</span>
                    </div>
                    <div>
                        <div class="bg-fff box mt15 c_p" :class="pageData.activeName == index ? 'active': '' " v-for="(item, index) in pageData.leftList" :key="index" @click="getContentList(index)"> 
                            <div class="rx-bc">
                                <span class="l_h32">{{ item.itemName }}</span>
                                <el-switch v-if="item.allowHide == 1" v-model="item.hideState" :active-value="0" :inactive-value="1" />
                            </div>
                        </div>
                        <div class="color-849EAD ft12 t_a_c mt40">—— 测评内容模版建议由深圳久仰教育提供 ——</div>
                    </div>
                </div>
                <div class="flex1 ml20 w0 right-box">
                    <item1 v-if="pageData.activeName == 0" :info="pageData.item1" :type="pageData.templateType" />
                    <item2 v-if="pageData.activeName == 1" :info="pageData.item2" :type="pageData.templateType" />
                    <item3 v-if="pageData.activeName == 2" :info="pageData.item3" :type="pageData.templateType" :qualityList="pageData.qualityList" :pliableList="pageData.pliableList" />
                    <item4 v-if="pageData.activeName == 3" :info="pageData.item4" :type="pageData.templateType" />
                    <item5 v-if="pageData.activeName == 4" :info="pageData.item5" :type="pageData.templateType" />
                    <item6 v-if="pageData.activeName == 5" :info="pageData.item6" :type="pageData.templateType" />
                    <item7 v-if="pageData.activeName == 6" :info="pageData.item7" :type="pageData.templateType" />
                    <item8 v-if="pageData.activeName == 7" :info="pageData.item8" :type="pageData.templateType" />
                </div>
            </div>
        </div>
    </workArea>
</template>

<script setup>
    import { reactive, onMounted, onBeforeUnmount } from 'vue';
    import { useRouter } from 'vue-router';
    import { EditPen } from '@element-plus/icons-vue'
    import { ElMessage } from 'element-plus'
    import workArea from '@/components/branch-store/workArea.vue';
    import item1 from '@/components/branch-store/template/item1.vue';
    import item2 from '@/components/branch-store/template/item2.vue';
    import item3 from '@/components/branch-store/template/item3.vue';
    import item4 from '@/components/branch-store/template/item4.vue';
    import item5 from '@/components/branch-store/template/item5.vue';
    import item6 from '@/components/branch-store/template/item6.vue';
    import item7 from '@/components/branch-store/template/item7.vue';
    import item8 from '@/components/branch-store/template/item8.vue';
    import { templateInfo, insertMine, templateUpdate } from '@/api/market.js';
    
    const router = useRouter();
    const { encryptionId, type } = router.currentRoute.value.query;
    const pageData = reactive({
        barlist:[{type:0, name: '返回'},{ name : type == 0 ? '使用模板' : '编辑模板' }],// 面包屑
        activeName: 0,
        templateType: 0, // 模板类型
        leftName: '舞蹈测评报告',
        leftNameEdit: false,
        leftList: [],
        item1: '',
        item2: '',
        item3: '',
        item4: '',
        item5: '',
        item6: '',
        item7: '',
        item8: '',
        pliableList: [],
        qualityList: [],
    })
    /**
     * 获取模板详情
     */
    function getInfo() {
        templateInfo({ encryptionId: encryptionId }).then(res => {
            if (res.code == 0) {
                pageData.leftName = res.data.templateName
                pageData.leftList = res.data.itemList
                pageData.templateType = res.data.templateType
                pageData.item1 = res.data.item1
                pageData.item2 = res.data.item2
                pageData.item3 = res.data.item3
                pageData.item4 = res.data.item4
                pageData.item5 = res.data.item5
                pageData.item6 = res.data.item6
                pageData.item7 = res.data.item7
                pageData.item8 = res.data.item8
                pageData.pliableList = res.data.pliableList
                pageData.qualityList = res.data.qualityList
            }
        })
    }
    /**
     * 切换功能模块
     */
    function getContentList(val) { // 切换tab (数据更换)
        pageData.activeName = val
        localStorage.setItem('template', val)
        // getList(val)
    }
    /**
     * 保存
     */
    function onSubmit() {
        let apiName = type == 0 ? insertMine : templateUpdate
        let params = {
            encryptionId: encryptionId,
            templateType: pageData.templateType,
            templateName: pageData.leftName,
            itemList: pageData.leftList,
            item1: pageData.item1,
            item2: pageData.item2,
            item3: pageData.item3,
            item4: pageData.item4,
            item5: pageData.item5,
            item6: pageData.item6,
            item7: pageData.item7,
            item8: pageData.item8,
            pliableList: pageData.pliableList,
            qualityList: pageData.qualityList
        }
        apiName(params).then(res => {
            if (res.code == 0) {
                ElMessage.success('保存成功')
                history.back();
            }
        })
    }
    onMounted(() => {
        if (localStorage.getItem('template') != null) {
            pageData.activeName = localStorage.getItem('template')
	    }
        getInfo()
    })
    onBeforeUnmount(() => {
		localStorage.removeItem('template')
	})
</script>
<style type='css/style' scoped>
    .nav{
        background: #D0F0FF;
    }
    .box {
        padding: 5px 19px 5px 28px;
    }
    .active {
        border: 1px dotted #409EFF;
        background: #EFF6FF;
    }
    .right-box, .left-box {
        height: 77.3vh;
    }
</style>