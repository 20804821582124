<template>
	<div class="rx-ss pt20">
		<span>客服二维码：</span>
		<div class="">
			<div v-if="!url">
				<CommonUpload @change="uploadChange" :autoCropWidth="300" :autoCropHeight="300"
					:isCropper="true">
					<div class="uploadBox cx-cc">
						<img class="upload1 w25 mb10" src="@/assets/upload1.png" alt="">
						<img class="upload2 w25 mb10" src="@/assets/upload2.png" alt="">
						<p>上传二维码</p>
					</div>
				</CommonUpload>
			</div>
			<div v-else class="imgBox">
				<img class="w120 h120 bdr2" :src="url" alt="">
				<div class="del" @click="url = ''">
					<img class="del1 w15" src="@/assets/del1.png" alt="">
					<img class="del2 w15" src="@/assets/del2.png" alt="">
				</div>
				<CommonUpload @change="uploadChange" :autoCropWidth="300" :autoCropHeight="300"
					:isCropper="true">
					<p>重新上传</p>
				</CommonUpload>
			</div>
			<p class="color-999 mt15">尺寸要求：300*300px</p>
		</div>
	</div>
</template>

<script setup>
	import {
		ref,
		reactive
	} from 'vue';
	import {
		ElMessage
	} from 'element-plus'
	import CommonUpload from '@/components/CommonUpload.vue';
	import {getKeFuUrl,setKeFuUrl} from '@/api/showroom.js'
	//上传封面
	function uploadChange(url) {
		setKeFuUrl({
			url
		}).then((res)=>{
			ElMessage.success('上传成功');
			getUrl();
		})
	}
	getUrl();
	const url = ref('');
	function getUrl(){
		getKeFuUrl().then((res)=>{
			if(res.data){
				url.value = res.data.url;
			}
		})
	}
</script>

<style scoped>
	.uploadBox {
		border: 1px dashed #D6D6D6;
		height: 120px;
		width: 120px;
		border-radius: 2px;
		color: #999999;
		cursor: pointer;
	}
	
	.uploadBox:hover {
		border: 1px dashed #1989FA;
		color: #1989FA;
	}
	
	.uploadBox .upload2 {
		display: none;
	}
	
	.uploadBox:hover .upload2 {
		display: block;
	}
	
	.uploadBox:hover .upload1 {
		display: none;
	}
	
	.imgBox {
		position: relative;
		width: 120px;
		height:120px;
	}
	
	.imgBox .del,
	.imgBox:hover .del2 {
		display: none;
	}
	
	.imgBox:hover .del {
		display: block;
		cursor: pointer;
	}
	
	.imgBox .del:hover .del2 {
		display: block;
	}
	
	.imgBox .del:hover .del1 {
		display: none;
	}
	
	.imgBox .del {
		position: absolute;
		right: 0;
		top: 0;
		transform: translate(50%, -50%);
	}
	
	.imgBox p {
		position: absolute;
		bottom: 0;
		left: 0;
		right: 0;
		padding: 5px 0;
		text-align: center;
		color: #FFFFFF;
		background-color: rgba(0, 0, 0, 0.4);
		display: none;
		cursor: pointer;
	}
	
	.imgBox:hover p {
		display: block;
	}
</style>