<template>
    <div class="p20 bg-fff">
      <div>
            <el-table ref="satffTable" :data="pageData.tableData" stripe style="width: 100%">
                <el-table-column prop="remark" label="文件描述" ></el-table-column>
                <el-table-column prop="createTimeStr" label="生成时间" ></el-table-column>
                <el-table-column label="操作" >
                    <template #default="scope">
                        <a :href="scope.row.httpPath" style="text-decoration:none;" class="t_d_u color-409EFF tool_btn" :class="{'color-red':scope.row.openId}">立即下载</a>
                    </template>
                </el-table-column>
            </el-table>
            <div class="mt20  rx-ec" v-show="pageData.tableData.length > 0">
                <el-pagination
                  v-model:currentPage="pageData.searchForm.page"
                  :page-sizes="[15,20,30,50,100]"
                  :page-size="pageData.searchForm.limit"
                  layout="total, sizes, prev, pager, next, jumper"
                  :total="pageData.totalCount"
                  @size-change="pageSizeChange"
                  @current-change="searchTable"
                >
                </el-pagination>
              </div>
        </div>
    </div>
</template>
<script setup>
    import {ref,reactive,onMounted} from 'vue';
    import { ElMessage} from 'element-plus'
    import CommonTip from '@/components/CommonTip.vue'
    import utils from '@/common/utils.js'
    import { getDownloadList,getDownloadNow } from '@/api/downloadList.js';
    const pageData = reactive({
        tableData:[],
        totalCount : 0,
        searchForm:{
            page:1,
            limit:15
        },
    })
    downloadList();
    function downloadList(){
        getDownloadList().then(function(res){
            if(res.code  == 0){
                pageData.tableData = res.data;
                pageData.totalCount = res.count;
            }
        });
    }
    function downloadNow(encryptionId){
        getDownloadNow({encryptionId : encryptionId}).then(function(res){
            if(res.code == 0){
                console.log('下载成功');
            }
        });
    }
    function pageSizeChange(limit){
        pageData.searchForm.page = 1;
        pageData.searchForm.limit = limit;
        downloadList();
    }
</script>

<style scoped lang="stylus">
    
</style>
