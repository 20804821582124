<template>
    <div class="hh100 posi-r">
        <div class="w375 box posi-a posi-r" :class="[pageProp.type == 1 ? 'dance' : (pageProp.type == 2 ? 'sports' : (pageProp.type == 3 ? 'basketball' : 'bodybuilding'))]">
            <img class="banc" :src="pageProp.info.backPic" alt="">
            <div class="top posi-a">
                <span class="title ft16 fwb">封底</span>
            </div>
            <div class="top-text pl30 pr30 posi-a">
                <div class="color-666">
                    <span v-if="!pageData.show1" class="v_a_m l-h22">{{ pageProp.info.title }}</span>
                    <el-input
                        v-else
                        class="w230"
                        v-model="pageProp.info.title"
                        type="text"
                        placeholder="请输入"
                    />
                    <el-icon v-if="!pageData.show1" :size="20" color="#409EFC" class="c_p ml5 v_a_m" @click="pageData.show1 = true">
                        <EditPen />
                    </el-icon>
                    <el-button v-else class="ml5" type="primary"  @click="pageData.show1 = false">完成</el-button>
                </div>
                <div class="color-666 t_a_r mt20"> 
                   <span class="v_a_m"  v-if="!pageData.show2"> ——  {{ pageProp.info.titleSign }}</span>
                   <el-input
                        v-else
                        class="w230"
                        v-model="pageProp.info.titleSign"
                        type="text"
                        placeholder="请输入"
                    />
                    <el-icon v-if="!pageData.show2" :size="20" color="#409EFC" class="c_p ml5 v_a_m" @click="pageData.show2 = true">
                        <EditPen />
                    </el-icon>
                    <el-button v-else class="ml5" type="primary"  @click="pageData.show2 = false">完成</el-button>
                </div>
            </div>
            <div class="avatar posi-a posi-r">
                <div class="avatar-img">
                    <img :src="pageProp.info.logo" alt="">
                    <div class="upload posi-a">
			        	<CommonUpload @change="uploadChange" :autoCropWidth="60" :autoCropHeight="60" :isCropper="true">
			        		<el-button type="primary">替换封面</el-button>
			        	</CommonUpload>
			        </div>
                </div>
            </div>
            <div class="avatar-text posi-a">
                <div class="rx-c mt10">
                    <el-icon v-if="!pageData.show3" :size="20" color="#409EFC" class="c_p" @click="pageData.show3 = true">
                        <EditPen />
                    </el-icon>
                    <div v-if="!pageData.show3" class="color-666 ml10">{{ pageProp.info.storeName }}</div>
                    <el-input
                        v-else
                        class="w230"
                        v-model="pageProp.info.storeName"
                        type="text"
                        placeholder="请输入"
                    />
                    <el-button v-if="pageData.show3" class="ml5" type="primary"  @click="pageData.show3 = false">完成</el-button>
                </div>
                <div class="rx-c mt10">
                    <el-icon v-if="!pageData.show4" :size="20" color="#409EFC" class="c_p" @click="pageData.show4 = true">
                        <EditPen />
                    </el-icon>
                    <div v-if="!pageData.show4" class="color-666 ml10">{{ pageProp.info.storePhone }}</div>
                    <el-input
                        v-else
                        class="w230"
                        v-model="pageProp.info.storePhone"
                        type="text"
                        placeholder="请输入"
                    />
                    <el-button v-if="pageData.show4" class="ml5" type="primary"  @click="pageData.show4 = false">完成</el-button>
                </div>
                <div class="rx-c mt10">
                    <el-icon v-if="!pageData.show5" :size="20" color="#409EFC" class="c_p" @click="pageData.show5 = true">
                        <EditPen />
                    </el-icon>
                    <div v-if="!pageData.show5" class="color-666 ml10">{{ pageProp.info.storeAddress }}</div>
                    <el-input
                        v-else
                        class="w230"
                        v-model="pageProp.info.storeAddress"
                        type="text"
                        placeholder="请输入"
                    />
                    <el-button v-if="pageData.show5" class="ml5" type="primary"  @click="pageData.show5 = false">完成</el-button>
                </div>
            </div>
            <div class="upload1 posi-a">
				<CommonUpload @change="uploadChange1" :autoCropWidth="351" :autoCropHeight="528" :isCropper="true">
					<el-button type="primary">替换背景</el-button>
				</CommonUpload>
			</div>
        </div>
    </div>
</template>

<script setup>
    import { reactive } from 'vue';
    import { EditPen } from '@element-plus/icons-vue'
    import CommonUpload from '@/components/CommonUpload.vue';
    const pageProp = defineProps({
       info: Object,
       type: Number 
    })
    const pageData = reactive({
        show1: false,
        show2: false,
        show3: false,
        show4: false,
        show5: false,
    })
    /**
     * 替换封面
     */
    function uploadChange(url) {
        pageProp.info.logo = url
    }
    /**
     * 替换封面
     */
    function uploadChange1(url) {
        pageProp.info.backPic = url
    }
</script>
<style type='css/style' scoped>
    .box {
        padding: 12px;
	    left: 50%;
        transform: translateX(-50%);
    }
    .banc{
        width: 351px;
        height: 528px;
        padding: 5px;
        padding-bottom: 8px;
        border: 1px dotted #FF6E7B;
    }
    .top {
        top: 33px;
        left: 30px;
    }
    .top-text{ 
        top: 85px;
        left: 7px;
    }
    .avatar {
        left: 31px;
        bottom: 216px;
    }
    .avatar-text {
        left: 31px;
        top: 340px;
    }
    .avatar-img {
        width: 84px;
        height: 84px;
        border: 1px dotted #FF6E7B;
        padding: 12px;
    }
    .avatar-img img {
        width: 60px;
        height: 60px;
        border-radius: 50%;
    }
    .upload {
        top: -16px;
        right: -70px;
    }
    .upload1 {
        top: -16px;
        right: -45px;
    }
    /* 主题颜色 */
    .dance {
        background: #FDD2E7;
    }
    .dance .top .serial, .dance .top .title, .percentage {
        color: #ED2169;
    }
    .dance .message-box{
        background: #FFEFF7;
    }
    .sports {
        background: #203C1E;
    }
    .sports .top .serial, .sports .top .title {
        color: #203C1E;
    }
    .sports .message-box{
        background: #F0FCEF;
    }
    
</style>