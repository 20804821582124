<template>
	<div class="pt20">
		<!-- <div class="rx-wsc mb16"> -->
			<!-- <el-button class="mr12" type="primary" @click="addUpdate('')">新增模板</el-button> -->
		<!-- </div> -->
		<div>
			<el-table ref="table" :data="tableData.list" stripe style="width: 100%" height="65vh">
				<el-table-column fixed label="展厅封面" min-width="200">
					<template #default="scope">
						<img class="w160 bdr4" style="box-shadow: 0px 4px 9px 0px #C4BFBF;" :src="scope.row.hallCover" alt="">
					</template>
				</el-table-column>
				<el-table-column label="模板售价" width="280">
					<template #default="scope">
						<div class="mb10">机构端使用：<span class="color-red">￥{{scope.row.storePrice }}</span>，免费打开<span
								class="color-red">{{scope.row.storeOpenNum}}次</span></div>
						<div>个人创作者：<span class="color-red">￥{{scope.row.personPrice }}</span>，免费打开<span class="color-red">{{scope.row.personOpenNum}}次</span></div>
					</template>
				</el-table-column> 
				<el-table-column prop="picNum" label="放置图片数" min-width="110" />
				<el-table-column label="浏览数据" width="150">
					<template #default="scope">
						<div class="rx-ss">
							<div class="mr10">
								<div class="rx-sc mb10">
									<img class="w20 mr5" src="@/assets/show1.png" alt="">
									<span>0</span>
								</div>
								<div class="rx-sc">
									<img class="w20 mr5" src="@/assets/show3.png" alt="">
									<span>0</span>
								</div>
							</div>
							<div>
								<div class="rx-sc mb10">
									<img class="w20 mr5" src="@/assets/show2.png" alt="">
									<span>0</span>
								</div>
								<div class="rx-sc">
									<img class="w20 mr5" src="@/assets/show4.png" alt="">
									<span>0</span>
								</div>
							</div>
						</div>
					</template>
				</el-table-column>
				<el-table-column prop="createTime" label="创建时间" min-width="120" />
				<el-table-column prop="openStoreNum" label="开通机构数" min-width="110" />
				<el-table-column label="操作" width="180" fixed="right">
					<template #default="scope">
						<div>
							<a href="javascript:;" class="t_d_u color-409EFF tool_btn" @click="addUpdate(scope.row)">模板信息</a>
							<a href="javascript:;" v-if="scope.row.encryptionDemoId" class="t_d_u color-409EFF tool_btn" @click="lookEvent(scope.row.encryptionId)">预览</a>
						</div>
						<div>
							<a href="javascript:;" class="t_d_u color-409EFF tool_btn"
								@click="toDetail(scope.row.encryptionDemoId,scope.row.id,'')">案例配置</a>
							<a href="javascript:;" class="t_d_u color-409EFF tool_btn" v-if="scope.row.encryptionDemoId && scope.row.state == 1" @click="shareEvent(scope.row.encryptionDemoId)">分享</a>
							<a href="javascript:;" class="t_d_u tool_btn"
								:class="scope.row.state == 1 ? 'color-red': 'color-25D384'" @click="showNone(scope.row)">
								{{scope.row.state == 1 ? '禁用' : '启用'}}</a>
						</div>
					</template>
				</el-table-column>
			</el-table>
			<div class="mt20 rx-ec">
				<el-pagination v-model:currentPage="searchForm.page" :page-sizes="[15,20,30,50,100]"
					:page-size="searchForm.limit" layout="total, sizes, prev, pager, next, jumper"
					:total="tableData.count" @size-change="pageSizeChange" @current-change="()=>{getList()}">
				</el-pagination>
			</div>
		</div>

		<!-- 新增。编辑 -->
		<el-dialog v-model="formData.show" :title="formData.form.encryptionId ? '编辑模板':'新增模板'" width="780px"
			:before-close="()=>{formData.show = false}" custom-class="popwechat">
			<div class="pl20 pr20 pb20 pt20" style="max-height: 500px;overflow: auto;">
				<div class="rx-ss mb20">
					<span class="w95 t_a_r"><span class="color-red">*</span>模板封面：</span>
					<div class="">
						<CommonUpload v-if="!formData.form.hallCover" @change="uploadChange" :autoCropWidth="320" :autoCropHeight="180"
							:isCropper="true">
							<div class="uploadBox cx-cc">
								<img class="upload1 w25 mb10" src="@/assets/upload1.png" alt="">
								<img class="upload2 w25 mb10" src="@/assets/upload2.png" alt="">
								<p>上传图片</p>
							</div>
						</CommonUpload>
						<div class="imgBox" v-else>
							<img class="w160 h90 bdr2" :src="formData.form.hallCover" alt="">
							<div class="del" @click="formData.form.hallCover = ''">
								<img class="del1 w15" src="@/assets/del1.png" alt="">
								<img class="del2 w15" src="@/assets/del2.png" alt="">
							</div>
							<CommonUpload @change="uploadChange" :autoCropWidth="320" :autoCropHeight="180"
								:isCropper="true">
								<p>重新上传</p>
							</CommonUpload>
						</div>
						<p class="color-999 mt15">尺寸要求：1600*900px</p>
					</div>
				</div>
				<div class="rx-sc mb20">
					<span class="w95 t_a_r"><span class="color-red">*</span>放置图片数：</span>
					<div class="w240">
						<el-input type="text" v-model="formData.form.picNum" placeholder="请输入"></el-input>
					</div>
				</div>
				<div class="rx-ss mb20">
					<span class="w95 t_a_r"><span class="color-red">*</span>模板售价：</span>
					<div class="flex1 bg-F5F5F5 pt35 pb30 pl25" v-if="formData.show">
						<div class="rx-sc mb30">
							<!-- <el-checkbox v-model="formData.form." label="" size="large" /> -->
							<span class="mr10 ml10">机构端使用</span>
							<span class="color-red">*</span>
							<span>价格：</span>
							<Input  class="w125" :value="formData.form.storePrice"  unit="元" type="2" max="9999.9" length="1" node="storePrice" @change="inputChange">
							</Input>
							<span class="color-red ml10">*</span>
							<span>免费打开次数：</span>
							<Input  class="w125" :value="formData.form.storeOpenNum" unit="次" type="1" max="9999" node="storeOpenNum" @change="inputChange">
							</Input>
						</div>
						<div class="rx-sc">
							<!-- <el-checkbox v-model="formData.form." label="" size="large" /> -->
							<span class="mr10 ml10">个人创作者</span>
							<span class="color-red">*</span>
							<span>价格：</span>
							<Input  class="w125" :value="formData.form.personPrice" unit="元" type="2" max="9999.9" length="1" node="personPrice" @change="inputChange">
							</Input>
							<span class="color-red ml10">*</span>
							<span>免费打开次数：</span>
							<Input  class="w125" :value="formData.form.personOpenNum" unit="次" type="1" max="9999" node="personOpenNum" @change="inputChange">
							</Input>
						</div>
					</div>
				</div>
			</div>
			<template #footer>
				<div class="dialog-footer">
					<el-button @click="formData.show = false">取消</el-button>
					<el-button type="primary" @click="addUpdateSave">确定</el-button>
				</div>
			</template>
		</el-dialog>
		
		<!-- 分享展厅 -->
		<el-dialog v-model="shareData.show" title="分享展厅" width="500px" :before-close="()=>{shareData.show = false}"
			custom-class="popwechat">
			<div class="pl100 pr100 pb20 pt20" style="max-height: 600px;overflow: auto;">
				<div class="pt30 pb40 pl30 pr30 posi-r bdr8 o_h" ref="shareBox" style="border:1px solid #D2D2DA;">
					<img class="ww100 hh100 posi-a left0 top0" style="z-index:-1" src="@/assets/shareBg.png" alt="">
					<div class="rx-sc mb20">
						<img class="w45 h45 mr15" src="@/assets/logo2.png" alt="">
						<span class="fwb ft18">总店</span>
					</div>
					<img class="ww100" :src="shareData.form.hallCover" alt="">
					<div>
						<img class="w25" src="@/assets/mark1.png" alt="">
					</div>
					<div class="fwb ft18">{{shareData.form.name}}</div>
					<div class="rx-ec mb20">
						<img class="w25" src="@/assets/mark2.png" alt="">
					</div>
					<div class="bdr100 pt5 pb5 t_a_c bg-FEEAC6 mb20 color-D48712">参展作品&nbsp;&nbsp;&nbsp;{{shareData.form.picNum}}</div>
					<div class="bdr4 pt15 pb15 pl35 pr35 bg-fff rx-bc">
						<div>
							<div class="mb10">长按识别二维码</div>
							<div class="rx-sc">
								<span>查看作品</span>
								<img class="w15 ml10" src="@/assets/zw.png" alt="">
							</div>
						</div>
						<img class="w60" src="@/assets/ewm.png" alt="">
					</div>
				</div>
			</div>
			<template #footer>
				<div class="dialog-footer">
					<el-button @click="shareData.show = false">取消</el-button>
					<el-button type="primary" @click="shareSave">下载</el-button>
				</div>
			</template>
		</el-dialog>
		<Confirm ref="confirm" :title="confirmTitle" @save="confirmSave"></Confirm>
	</div>
</template>

<script setup>
	import {
		ref,
		reactive,
		nextTick
	} from 'vue';
	import { useRouter } from 'vue-router';
	import {
		Search
	} from '@element-plus/icons-vue'
	import {
		ElMessage
	} from 'element-plus'
	import html2canvas from 'html2canvas'
	import Confirm from '@/components/Confirm.vue';
	import CommonUpload from '@/components/CommonUpload.vue';
	import Input from '@/components/Input.vue';
	import {getHallTemplateList,getHallTemplateInfo,insertHallTemplate,updateHallTemplate,getHallWorksList,insertHallContent,updateHallContent,getHallContentInfo,updateStateHallTemplate} from '@/api/showroom.js'
	import {getLocalStorage} from '@/common/common.js';
	const router = useRouter();
	const userInfo = getLocalStorage('businessUserData');
	const confirm = ref(),
		confirmTitle = ref('');
	const searchForm = reactive({
		page: 1,
		limit: 15
	})
	const tableData = reactive({
		list: [],
		count: 0
	})

	//搜索table
	function searchTable() {
		searchForm.page = 1;
		getList();
	}
	//切换分页数量
	function pageSizeChange(limit) {
		searchForm.limit = limit;
		searchTable();
	}
	getList();

	function getList() {
		getHallTemplateList(searchForm).then((res)=>{
			if(res.data){
				tableData.list = res.data;
				tableData.count = res.count;
			}else{
				tableData.list =[];
				tableData.count = 0;
			}
		})
	}
	function toDetail(encryptionId,tid,hallType){
		router.push({
			path:'/branch/showroomDetail',
			query:{
				encryptionId,tid,hallType
			}
		})
	}

	//新增/编辑模板
	const formData = reactive({
		form: {},
		show: false,
	})

	function getAddForm(data,callback) {
		var form = {
			encryptionId: '',
			hallCover: '',
			picNum: '',
			storePrice:'',
			storeOpenNum:'',
			personPrice:'',
			personOpenNum:'',
		}
		if (data) {
			getHallTemplateInfo({
				encryptionId:data.encryptionId
			}).then(()=>{
				for (var k in form) {
					form[k] = data[k];
				}
				callback(form);
			})
			
		}else{
			callback(form);
		}
	}

	function addUpdate(row) {
		getAddForm(row,(data)=>{
			formData.form = data;
			formData.show = true;
		});
	}
	
	function inputChange(data){
		formData.form[data.key] = data.value;
	}
	function addUpdateSave() {
		var form = JSON.parse(JSON.stringify(formData.form));
		var pass = verification(form);
		if (!pass) {
			return false;
		}
		if (form.encryptionId) {
			updateHallTemplate(form).then((res) => {
				formData.show = false;
				ajaxSaveCallBack('编辑成功');
			})
		} else {
			insertHallTemplate(form).then((res) => {
				console.log(res)
				formData.show = false;
				ajaxSaveCallBack('保存成功');

			})
		}
	}
	function verification(data) {
		var form = {
			hallCover: '封面',
			picNum: '放置图片数',
			storePrice: '机构端售价',
			storeOpenNum: '机构端免费打开次数',
			personPrice: '个人售价',
			personOpenNum: '个人免费打开次数',
		}
		var pass = true;
		for (var k in form) {
			if (!data[k] || data[k].length == 0) {
				pass = false;
				ElMessage({
					type: 'warning',
					message: (k == 'hallCover' ? '请上传' : '请输入') + form[k]
				})
				return false;
			}

		}
		return pass;
	}
	var confirmRow = '';
	//停用、启用
	function showNone(row) {
		confirmRow = row;
		confirmTitle.value = row.state == 1 ? '确定禁用该模板吗?' : '确定启用该模板吗?';
		confirm.value.show();
	}
	/**
	 * 弹框确认
	 */
	function confirmSave() {
		updateStateHallTemplate({
			encryptionId: confirmRow.encryptionId,
			state: confirmRow.state == 1 ? 0 : 1
		}).then((res) => {
			ajaxSaveCallBack(confirmRow.state == 1 ? '禁用成功' : '启用成功');
		})
	}
	//预览
	function lookEvent(id){
		var token = localStorage.getItem('businessToken');
		window.open('https://exhibition-work-test.yaoyoumeng.com/index.html?hallUID='+id+'&access_token='+token);
	}

	//分享
	const shareData = reactive({
		show: false,
		form: {}
	})
	function shareEvent(id) {
		getHallContentInfo({
			encryptionId:id
		}).then(res=>{
			shareData.form = res.data;
			shareData.show = true;
		})
	}
	
	const shareBox = ref();
	function shareSave(){
		nextTick(()=>{
			html2canvas(shareBox.value).then(canvas => {
				const dataURL = canvas.toDataURL('image/png');
				const createDom = document.createElement('a');
				document.body.appendChild(createDom);
				createDom.href = dataURL;
				createDom.download = shareData.form.name;
				createDom.click();
				document.body.removeChild(createDom);
			});
		})
	}
	
	//上传封面
	function uploadChange(url) {
		formData.form.hallCover = url;
	}
	//ajax提交保存通用提示
	function ajaxSaveCallBack(text, callback) {
		ElMessage.success({
			type: 'success',
			message: text
		})
		searchTable();
		callback && callback();
	}
	
</script>
<style scoped>
	.uploadBox {
		border: 1px dashed #D6D6D6;
		height: 90px;
		width: 160px;
		border-radius: 2px;
		color: #999999;
		cursor: pointer;
	}

	.uploadBox:hover {
		border: 1px dashed #1989FA;
		color: #1989FA;
	}

	.uploadBox .upload2 {
		display: none;
	}

	.uploadBox:hover .upload2 {
		display: block;
	}

	.uploadBox:hover .upload1 {
		display: none;
	}

	.imgBox {
		position: relative;
		width: 160px;
		height: 90px;
	}

	.imgBox .del,
	.imgBox:hover .del2 {
		display: none;
	}

	.imgBox:hover .del {
		display: block;
		cursor: pointer;
	}

	.imgBox .del:hover .del2 {
		display: block;
	}

	.imgBox .del:hover .del1 {
		display: none;
	}

	.imgBox .del {
		position: absolute;
		right: 0;
		top: 0;
		transform: translate(50%, -50%);
	}

	.imgBox p {
		position: absolute;
		bottom: 0;
		left: 0;
		right: 0;
		padding: 5px 0;
		text-align: center;
		color: #FFFFFF;
		background-color: rgba(0, 0, 0, 0.4);
		display: none;
		cursor: pointer;
	}

	.imgBox:hover p {
		display: block;
	}
	.worksImgBox::after{
		content:'';
		position: absolute;
		top:0;
		right:0;
		bottom:0;
		left:0;
		z-index:1000
	}
</style>