<template>
	<div class="pt10">
		<div class="mb20">
			<span class="color-333 fwb ft14 mr30">配置套餐</span>
			<span class="color-999">最多8条，最少1条</span>
		</div>
		<div class="rx-ss w800 ulBox ofh layui-form">
			<el-radio-group v-model="pageData.ifDefault" class="ml-4" @change="defaultChange">
				<MoveSort :key="(new Date).getTime()" :list="pageData.list" @moveover="moveover" border="1">
					<template #[index] v-for="(item,index) in pageData.list" :key="index">
						<div class="li" v-if="item != -1">
							<div style="box-sizing: border-box;" class="h105 bdr4 pt10 pl10 pb10" :class="item.ifDefault  == 1 ? 'bg':'border-D6D6D6'">
								<div class="mb10 rx-bc">
									<div class="flex1">
										<span :class="item.ifDefault  == 1 ? 'color-fff':'color-red'" class="ft16 fwb mr5">{{item.salePrice }}元</span>
										<span :class="item.ifDefault  == 1 ? 'color-ccc':'color-999'" class="ft12">{{item.num}}份</span>
									</div>
									<div :class="item.tagDesc ? '':'none'" class="label">{{item.tagDesc}}</div>
								</div>
								<div class="mb10">
									<span :class="item.ifDefault  == 1 ? 'color-fff':'color-333'" class="ft14">{{(item.salePrice/item.num).toFixed(2) }}/份</span>
									<del :class="item.ifDefault  == 1 ? 'color-ccc':'color-999'" class="ft12">{{(item.origPrice/item.num).toFixed(2) }}/份</del>
								</div>
								<div class="time">有效期：{{item.validTime +(item.validUnit == 1 ? '年' : item.validUnit == 2 ? '月' : '日') }}</div>
							</div>
							<div class="rx-bc">
								<el-radio :label="item.id">用户默认勾选</el-radio>
								<div class="rx-sc">
									<img class="mr5 w15" src="@/assets/contract_move.png" alt="">
									<img class="w15 cursor-pointer tab1del" @click="delEvent(index)" src="@/assets/contract_del.png" alt="">
								</div>
							</div>
						</div>
						<div v-else class="w175" id="liAdd" @click="addEvent" v-if="pageData.list.length < 9">
							<div class="cx-cc bdr4 btn1 h100 border-D6D6D6">
								<span class="ft30 fwb">+</span>
								<span class="ft12">新增</span>
							</div>
						</div>
					</template>
				</MoveSort>
			</el-radio-group>
		</div>
		<el-dialog v-model="pageData.show" title="设置套餐档位" width="450px"
			:before-close="()=>{pageData.show = false}">
			<div class="pt20 pl20 pr20 pb20">
				<div class="rx-sc mb20">
					<span class="w95 t_a_r"><span class="color-red">*</span>合同份数：</span>
					<div class="w240">
						<Input type="1" v-model="pageData.form.num" placeholder="请输入数量(1~10000)" unit="份" max="10000" node="num" @change="inputChange"></Input>
					</div>
				</div>
				<div class="rx-sc mb20">
					<span class="w95 t_a_r"><span class="color-red">*</span>套餐售价：</span>
					<div class="w240">
						<Input type="2" v-model="pageData.form.salePrice" placeholder="请输入金额(总价)" unit="元" max="9999.9" length="1" node="salePrice" @change="inputChange"></Input>
					</div>
				</div>
				<div class="rx-sc mb20">
					<span class="w95 t_a_r"><span class="color-red">*</span>套餐划线价：</span>
					<div class="w240">
						<Input type="2" v-model="pageData.form.origPrice" placeholder="请输入金额(总价)用于计算单价划线价" unit="元" max="9999.9" length="1" node="origPrice" @change="inputChange"></Input>
					</div>
				</div>
				<div class="rx-sc mb20">
					<span class="w95 t_a_r"><span class="color-red">*</span>套餐有效期：</span>
					<div class="w240">
						<Input type="1" v-model="pageData.form.validTime" placeholder="输入正整数(最长3年)" slot max="2000" node="validTime" @change="inputChange">
							<el-select v-model="pageData.form.validUnit" style="width: 60px">
							  <el-option label="年" :value="1" />
							  <el-option label="月" :value="2" />
							  <el-option label="日" :value="3" />
							</el-select>
						</Input>
					</div>
				</div>
				<div class="rx-sc">
					<span class="w95 t_a_r">标签：</span>
					<div class="w240">
						<el-input type="text" placeholder="请输入内容(6字内)" maxlength="6" v-model="pageData.form.tagDesc"></el-input>
					</div>
				</div>
			</div>
			<template #footer>
				<div class="dialog-footer">
					<el-button @click="pageData.show = false">取消</el-button>
					<el-button type="primary" @click="addSave">确定</el-button>
				</div>
			</template>
		</el-dialog>
	</div>
</template>

<script setup>
	import { ref, reactive } from 'vue';
	import { ElMessage } from 'element-plus'
	import { getEsignProductList,updateEsignProduct } from '@/api/contract.js';
	import MoveSort from '@/components/MoveSort.vue'
	import Input from '@/components/Input.vue';
	const pageData = reactive({
		list:[],
		ifDefault:'',
		show:false,
		form:{
			num:'',
			salePrice:'',
			origPrice:'',
			validUnit:1,
			validTime:'',
			tagDesc:'',
		}
	})
	getList();
	function getList(){
		getEsignProductList({
			page:1,
			limit: 9999
		}).then(res=>{
			var arr = res.data.filter(n=>n.ifDefault == 1);
			pageData.ifDefault = arr.length > 0 ? arr[0].id : '';
			res.data.push(-1);
			pageData.list = res.data;
		})
	}
	function addEvent(){
		pageData.form = {
			num:'',
			salePrice:'',
			origPrice:'',
			validUnit:1,
			validTime:'',
			tagDesc:'',
		};
		pageData.show = true;
	}
	function inputChange(data){
		pageData.form[data.key] = data.value;
	}
	function addSave(){
		var data = pageData.form;
		if(!data.num){
			ElMessage.warning('请输入合同份数');
			return false;
		}
		if(!data.salePrice){
			ElMessage.warning('请输入套餐售价');
			return false;
		}
		if(!data.origPrice){
			ElMessage.warning('请输入套餐划线价');
			return false;
		}
		if(!data.validTime){
			ElMessage.warning('请输入套餐有效期');
			return false;
		}
		if((data.validUnit == 1 && data.validTime > 3) || (data.validUnit == 2 && data.validTime > 36) || (data.validUnit == 3 && data.validTime > 1095)){
			ElMessage.warning('套餐有效期最长为3年');
			return false;
		}
		data.ifDefault = 0;
		pageData.list.splice(pageData.list.length -1,0,data);
		updateEvent(()=>{
			ElMessage.success('保存成功');
			pageData.show = false;
		});
	}
	//设置默认
	function defaultChange(val){
		pageData.list.forEach(n=>{
			n.ifDefault = n.id == val ? 1 : 0;
		})
		updateEvent(()=>{
			ElMessage.success('设置成功');
		});
	}
	//删除
	function delEvent(index){
		pageData.list.splice(index,1);
		updateEvent(()=>{
			ElMessage.success('删除成功');
		});
	}
	//移动
	function moveover(list){
		pageData.list = list;
		updateEvent(()=>{
			ElMessage.success('设置成功');
		});
	}
	//更新
	function updateEvent(callback){
		var arr = pageData.list.filter(n=>n != -1);
		updateEsignProduct(arr).then(res=>{
			getList();
			callback && callback();
		})
	}
</script>

<style scoped>
	.ulBox .li{
		padding-left: 10px;
		padding-right: 10px;
		box-sizing: border-box;
	}
	.ulBox .li:nth-child(4n+1){
		padding-left: 0px;
	}
	.ulBox .li:nth-child(4n){
		padding-right: 0px;
	}
	.bg{
		background: linear-gradient(to right,#FD3546,#FC8434);
	}
	.border-D6D6D6{
		border: 1px solid #D6D6D6;
	}
	.time{
		background-color: #FEEFD6;
		color: #8B5A2C;
		display: inline-block;
		padding: 2px 15px;
		clip-path: polygon(5% 0%,100% 0%,95% 100%,0% 100%);
	}
	.label{
		background: linear-gradient(to right,#CA6CF4,#F524F6);
		color:white;
		border-radius: 100px 0 0 100px;
		padding:2px 3px 2px 6px;
		font-size: 10px;
		display: inline-block;
	}
	.liItem.active{
		border-left: 2px dashed #00a3ff;
	}
</style>