<template>
	<div class="pt20">
		<div class="mb12 rx-sc">
			<div class="w220 mr12">
				<el-input type="text" v-model="searchForm.searchKeys" placeholder="请输入ID、分店名称"></el-input>
			</div>
			<el-button type="primary" @click="getList(1)">搜索</el-button>
			<el-button type="primary" @click="giveEvent">赠送体验合同</el-button>
		</div>
		<el-table ref="table" :data="pageData.list" stripe style="width: 100%" height="65vh">
			<el-table-column prop="storeId" label="机构ID" />
			<el-table-column prop="storeName" label="分店名称" />
			<el-table-column prop="createTime" label="充值时间" />
			<el-table-column prop="expireTime" label="到期时间" />
			<el-table-column prop="orderNo" label="订单号" />
			<el-table-column prop="salePrice" label="充值金额(元)" />
			<el-table-column prop="num" label="充值份数(份)" />
			<el-table-column prop="consumeNum" label="充值方式" >
				<template #default="scope">
					<img src="@/assets/zhifubaotype.png" alt="">
				</template>
			</el-table-column>
			<el-table-column prop="consumeNum" label="订单来源" >
				<template #default="scope">
					<span>{{scope.row.ifGive == 1 ? '卡蜜赠送' : '机构充值'}}</span>
					<span v-if="scope.row.ifGive == 1" class="lookReason btn1 ml10" @click="lookReason(scope.row)">查看原因</span>
				</template>
			</el-table-column>
			<el-table-column prop="createName" label="操作人" />
		</el-table>
		<div class="mt20 rx-ec">
			<el-pagination v-model:currentPage="searchForm.page" :page-sizes="[15,20,30,50,100]"
				:page-size="searchForm.limit" layout="total, sizes, prev, pager, next, jumper"
				:total="pageData.count" @size-change="pageSizeChange" @current-change="()=>{getList()}">
			</el-pagination>
		</div>
		<el-dialog v-model="giveData.show" title="赠送合同" width="450px"
			:before-close="()=>{giveData.show = false}">
			<div class="pt20 pl20 pr20 pb20">
				<div class="rx-sc mb20">
					<span class="w95 t_a_r"><span class="color-red">*</span>赠送机构：</span>
					<div class="w240">
						<Select :list="giveData.list" :width="240" @change="getStoreList" @itemclick="itemclickEvent"></Select>
					</div>
				</div>
				<div class="rx-sc mb20">
					<span class="w95 t_a_r"><span class="color-red">*</span>赠送份数：</span>
					<div class="w240">
						<Input type="1" v-model="giveData.form.num" placeholder="请输入数量(1~1000)" unit="份" max="1000" node="num" @change="inputChange"></Input>
					</div>
				</div>
				<div class="rx-sc mb20">
					<span class="w95 t_a_r"><span class="color-red">*</span>套餐有效期：</span>
					<div class="w240">
						<Input type="1" v-model="giveData.form.validTime" placeholder="输入正整数(最长3年)" slot max="2000" node="validTime" @change="inputChange">
							<el-select v-model="giveData.form.validUnit" style="width: 60px">
							  <el-option label="年" :value="1" />
							  <el-option label="月" :value="2" />
							  <el-option label="日" :value="3" />
							</el-select>
						</Input>
					</div>
				</div>
				<div class="rx-ss">
					<span class="w95 t_a_r">赠送原因：</span>
					<div class="w240">
						<el-input type="textarea" placeholder="请输入内容" show-word-limit="100" maxlength="100" v-model="giveData.form.remark"></el-input>
					</div>
				</div>
			</div>
			<template #footer>
				<div class="dialog-footer">
					<el-button @click="giveData.show = false">取消</el-button>
					<el-button type="primary" @click="addSave">确定</el-button>
				</div>
			</template>
		</el-dialog>
	</div>
</template>

<script setup>
	import { ref, reactive } from 'vue';
	import { ElMessage,ElMessageBox  } from 'element-plus'
	import { getEsignAdminOrderList,giveEsignProductOrder,selectAllStoreSimple } from '@/api/contract.js';
	import Input from '@/components/Input.vue';
	import Select from '@/components/Select.vue';
	const pageData = reactive({
		list:[],
		count:0,
	})
	const searchForm = reactive({
		page:1,
		limit:15,
		searchKeys:''
	})
	function pageSizeChange(limit){
		searchForm.limit = limit;
		getList(1);
	}
	getList();
	function getList(page){
		if(page){
			searchForm.page = 1;
		}
		getEsignAdminOrderList(searchForm).then(res=>{
			pageData.list = res.data;
			pageData.count = res.count;
		})
	}
	//查看原因
	function lookReason(row){
		console.log(row)
		ElMessageBox.alert(row.remark, '查看原因', {
		    confirmButtonText: '关闭',
		})
	}
	//赠送
	const giveData = reactive({
		show:false,
		loading:false,
		form:{}
	})
	function giveEvent(){
		giveData.form = {
			sid:'',
			num:'',
			validTime:'',
			validUnit:1,
			remark:'',
		}
		giveData.show = true;
	}
	function addSave(){
		var data = giveData.form;
		if(!data.sid){
			ElMessage.warning('请选择赠送机构');
			return false;
		}
		if(!data.num){
			ElMessage.warning('请输入赠送份数');
			return false;
		}
		if(!data.validTime){
			ElMessage.warning('请输入套餐有效期');
			return false;
		}
		if((data.validUnit == 1 && data.validTime > 3) || (data.validUnit == 2 && data.validTime > 36) || (data.validUnit == 3 && data.validTime > 1095)){
			ElMessage.warning('套餐有效期最长为3年');
			return false;
		}
		giveEsignProductOrder(data).then(res=>{
			ElMessage.success('赠送成功');
			giveData.show = false;
			getList(1);
		})
	}
	//远程搜索机构
	function itemclickEvent(val){
		giveData.form.sid = val;
	}
	getStoreList();
	function getStoreList(query){
		selectAllStoreSimple({
			searchKeys:query
		}).then(res=>{
			giveData.list = res.data.map(n=>{
				return {
					label:n.storeName,
					value:n.id
				}
			});
		})
	}
	function inputChange(data){
		giveData.form[data.key] = data.value;
	}
</script>

<style scoped>
	
</style>