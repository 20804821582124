<template>
	<!-- 新增潜在会员 -->
	<el-dialog
	    :title="propData.title"
	 	width="750px"
	    custom-class="popwechat"
		:append-to-body="true"
		@opened="opened"
	   >
		<div class="pl20 pt20 pb20 pr60">
			<CommonTitle text="基本信息"/> 
			<div class="rx-bc mb12">
				<div class="rx-sc ">
					<span class="w110 t_a_r"><span class="color-red">*</span>会员姓名：</span>
					<div class="w220">
						<el-input v-model="pageData.addMemberForm.realName" maxlength="10" type="text" placeholder="请输入姓名"></el-input>
					</div>
				</div>
				<div class="rx-sc">
					<span class="w110 t_a_r"><span class="color-red">*</span>手机号：</span>
					<div class="w220">
						<el-input v-model="pageData.addMemberForm.telephoneNum" type="text" maxlength="11" placeholder="请输入手机号"></el-input>
					</div>
				</div>
			</div>
			<div class="rx-ss mb12">
				<span class="w110 t_a_r">备注：</span>
				<div class="flex1">
					<el-input
					    v-model="pageData.addMemberForm.remark"
					    :rows="5"
						maxlength="300"
					    type="textarea"
						show-word-limit
					    placeholder="请输入备注"
					  />
				</div>
			</div>
			<CommonTitle text="其他信息"/>
			<div class="rx-bc mb12">
				<div class="rx-sc ">
					<span class="w110 t_a_r">来源方式：</span>
					<div class="w220">
						<el-select v-model="pageData.addMemberForm.channelId" placeholder="请选择">
							<el-option v-for="item in pageData.interimUserSource" :key="item.value" :label="item.label" :value="item.value" />
						</el-select>
					</div>
				</div>
				<div class="rx-sc">
					<span class="w110 t_a_r">跟进人：</span>
					<div class="w220">
						<el-select v-model="pageData.addMemberForm.adviserIds" multiple collapse-tags placeholder="请选择">
							<el-option v-for="item in pageData.followerList" :key="item.value" :label="item.label" :value="item.value" />
						</el-select>
					</div>
				</div>
			</div>
			<div class="rx-bc mb12">
				<div class="rx-sc ">
					<span class="w110 t_a_r">性别：</span>
					<div class="w220">
						<el-radio v-model="pageData.addMemberForm.sex" label="男" size="large">男</el-radio>
						<el-radio v-model="pageData.addMemberForm.sex" label="女" size="large">女</el-radio>
					</div>
				</div>
				<div class="rx-sc">
					<span class="w110 t_a_r">生日：</span>
					<div class="w220">
						<el-date-picker value-format="YYYY-MM-DD" v-model="pageData.addMemberForm.userBirthday" type="date" placeholder="请选择"></el-date-picker>
					</div>
				</div>
			</div>
			<div class="rx-bc mb12">
				<div class="rx-sc ">
					<span class="w110 t_a_r">身高：</span>
					<div class="w220">
						<el-input v-model="pageData.addMemberForm.height" type="text" placeholder="请输入身高">
							<template #append>CM</template>
						</el-input>
					</div>
				</div>
				<div class="rx-sc">
					<span class="w110 t_a_r">体重：</span>
					<div class="w220">
						<el-input v-model="pageData.addMemberForm.weight" type="text" placeholder="请输入体重">
							<template #append>KG</template>
						</el-input>
					</div>
				</div>
			</div>
			<div class="rx-bc mb12">
				<div class="rx-sc ">
					<span class="w110 t_a_r">国家：</span>
					<div class="w220">
						<el-input v-model="pageData.addMemberForm.country" type="text" placeholder="请输入国家"/>
					</div>
				</div>
				<div class="rx-sc">
					<span class="w110 t_a_r">民族：</span>
					<div class="w220">
						<el-input v-model="pageData.addMemberForm.nation" type="text" placeholder="请输入民族"/>
					</div>
				</div>
			</div>
			<div class="rx-bc mb12">
				<div class="rx-sc ">
					<span class="w110 t_a_r">学历：</span>
					<div class="w220">
						<el-input v-model="pageData.addMemberForm.education" type="text" placeholder="请输入学历"/>
					</div>
				</div>
				<div class="rx-sc">
					<span class="w110 t_a_r">职业：</span>
					<div class="w220">
						<el-input v-model="pageData.addMemberForm.job" type="text" placeholder="请输入职业"/>
					</div>
				</div>
			</div>
			<div class="rx-bc mb12">
				<div class="rx-sc ">
					<span class="w110 t_a_r">收入水平：</span>
					<div class="w220">
						<el-input v-model="pageData.addMemberForm.income" type="text" placeholder="请输入收入水平"/>
					</div>
				</div>
				<div class="rx-sc">
					<span class="w110 t_a_r">家庭地址：</span>
					<div class="w220">
						<el-cascader
							class="ww100"
							v-model="pageData.addMemberForm.city"
						      placeholder="请选择"
						      :options="pageData.areaList"
						      filterable
						    />
					</div>
				</div>
			</div>
			<div class="rx-sc mb12">
				<span class="w110 t_a_r">详细地址：</span>
				<div class="w220">
					<el-input v-model="pageData.addMemberForm.userAddress" type="text" placeholder="请输入详细地址"/>
				</div>
			</div>
		</div>
	   <template #footer>
			<div class="dialog-footer">
				<el-button @click="close">取消</el-button>
				<el-button type="primary" @click="save">确定</el-button>
			</div>
		</template>
	</el-dialog>
</template>

<script setup>
	import { ref, reactive, onMounted } from 'vue';
	import { ElMessage, ElMessageBox } from 'element-plus'
	import CommonTitle from '@/components/CommonTitle.vue'
	import utils from '@/common/utils.js'
	import { newUserInsert, selectOneUser, cardMyInfoUpdate } from '@/api/intentionList.js'
	import { getStoreAccountStaffSimple } from '@/api/staff.js'
	import { getAllAddress } from '@/api/applet.js';
	const emit = defineEmits(['close','save']);
	const propData = defineProps({
		title:String,
		flag:String,
		id: String,
		userInfo : Object
	})
	const pageData = reactive({
		interimUserSource:[],
		followerList:[],
		areaList:[],
		addMemberForm:{
			realName:'',
			telephoneNum:'',
			remark:'',
			channelId:'',
			adviserIds: [],
			sex:'',
			userBirthday:'',
			height:'',
			weight:'',
			country:'',
			nation:'',
			education:'',
			job:'',
			income:'',
			city:[],
			userAddress:'',
			trialFlag:''
		},
	})
	// function change(e){
	// 	console.log(e);
	// 	console.log(pageData.addMemberForm.userBirthday);
	// }
	//获取地区json数据
	getAllAddress().then((res)=>{
		if(res.code == 0){
			pageData.areaList = res.data;
		}
	})
	function getPersonnel() {
		pageData.followerList = []
        let params = {
            page:1,
            limit:1000,
            delFlag: 0
        }
        getStoreAccountStaffSimple(params).then((res) => {
            if (res.code == 0) {
                res.data.forEach(item => {
                    pageData.followerList.push({
                       label: item.realName,
                       value: item.id
                    })
               })
            }
        })
    }
	utils.getDynaDict('channelList','',function(res){
		pageData.interimUserSource = res;
	});
	
	function close(){
		emit('close');
	}
  var time = 0;
	function save(){
    var nowTime = (new Date()).getTime();
    if(time != 0 && nowTime-time < 2000){
      return false;
    }else {
      time = nowTime;
    }
		var formData = JSON.parse(JSON.stringify(pageData.addMemberForm));
		if(formData.city){
			formData.homeProvinceId = formData.city[0];
			formData.homeCityId = formData.city[1];
			formData.homeAreaId = formData.city[2];
			delete formData.city;
		}
		if(!formData.realName){
			ElMessage({
				type: 'warning',
				message: '请输入会员名称'
			})
			return false;
		}
		if(!formData.telephoneNum){
			ElMessage({
				type: 'warning',
				message: '请输入会员手机号'
			})
			return false;
		}
		if(formData.userBirthday){
			formData.userBirthday = formData.userBirthday.substring(0,10);
		}
		formData.adviserIds = formData.adviserIds&&formData.adviserIds.join()
		if(propData.flag){ //有 新增  无 编辑
			formData.trialFlag = propData.flag;
		}
		formData.device = 0;
		if(propData.id){
			formData.encryptionId = propData.id;//data.encryptionId;
			cardMyInfoUpdate(formData).then((res)=>{
				if(res.code == 0){
					ElMessage({
						type: 'success',
						message: '编辑成功'
					})
					initForm();
					emit('save');
				}
			})
		}else{
			newUserInsert(formData).then((res)=>{
				if(res.code == 0){
					if (res.data == 1) {
                        ElMessageBox.confirm('检测到会员信息在其他门店已存在，请直接同步信息！', '提示', {
                            confirmButtonText: '确定',
                            cancelButtonText: '取消',
                            type: 'warning',
                        }).then(() => {
							formData.forceAdd = 1
							newUserInsert(formData).then((res)=>{
								if(res.code == 0){
									ElMessage.success('同步成功')
									initForm();
									emit('save');
								}
							})
                        })
					} else {
                        ElMessage({
					    	type: 'success',
					    	message: '新增成功'
					    })
					    initForm();
					    emit('save');
					}
 					
				}
			})
		}
	}
	function initForm(){
		for(var key in pageData.addMemberForm){
			pageData.addMemberForm[key] = '';
		}
		pageData.addMemberForm.city = [];
	}
	// 编辑时赋值
	function initUser(obj){
		for(var key in pageData.addMemberForm){
			pageData.addMemberForm[key] = obj[key] || '';
		}
		pageData.addMemberForm.adviserIds = obj.adviserIds ? obj.adviserIds.split(',').map((n)=>Number(n)):[]
		var {homeProvinceId,homeCityId,homeAreaId} = obj;
		if(homeProvinceId && homeCityId && homeAreaId){ //回显地区
			pageData.addMemberForm.city = [homeProvinceId,homeCityId,homeAreaId];
		}
	}
	function opened(){
		getPersonnel()
		if(propData.userInfo){
			initUser(propData.userInfo);
			return;
		}else{
			if(propData.id){
				selectOneUser({
					encryptionId:propData.id
				}).then((res)=>{
					if(res.code == 0){
						initUser(res.data);
					}
				})
			}else{
				initUser({});
			}
		}
	}
</script>

<style lang="stylus">
</style>
