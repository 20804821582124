<template>
    <div class="hh100 posi-r">
        <div class="posi-a posi-r cover">
            <el-image class="hh100 ww100" :src="pageProp.info.backPic" />
            <div class="posi-a img">
                <img class="ww100 hh100" v-if="pageProp.type == 1" src="@/assets/dance.png" alt="">
                <img class="ww100 hh100" v-if="pageProp.type == 2" src="@/assets/sports.png" alt="">
                <img class="ww100 hh100" v-if="pageProp.type == 3" src="@/assets/basketball.png" alt="">
                <img class="ww100 hh100" v-if="pageProp.type == 4" src="@/assets/bodybuilding.png" alt="">
                <div class="mt30 rx-c">
                    <div v-if="!pageData.show" class="color-fff img-title">
                        <div :class="[pageProp.info.title && pageProp.info.title.length > 12 ? 'ft20' : 'ft24']">{{ pageProp.info.title }}</div>
                    </div>
                    <el-input v-else v-model="pageProp.info.title" maxlength="15" placeholder="请输入模板名称" />
                    <el-icon v-if="!pageData.show" :size="20" color="#FFF" class="c_p ml5" @click="pageData.show = true">
                        <EditPen />
                    </el-icon>
                    <el-button v-else class="ml5" type="primary"  @click="pageData.show = false">完成</el-button>
                </div>
            </div>
            <div class="posi-a title">
                <div class="ft18 fwb mb12 color-fff">姓名</div>
                <div class="color-fff">测评时间：xxxx-xx-xx</div>
                <div class="color-fff">测评老师：xxxx</div>
            </div>
           <div class="upload posi-a">
				<CommonUpload @change="uploadChange" :autoCropWidth="375" :autoCropHeight="562" :isCropper="true">
					<el-button type="primary">替换封面</el-button>
				</CommonUpload>
			</div>
            
        </div>
    </div>
</template>

<script setup>
    import { reactive } from 'vue';
    import { EditPen } from '@element-plus/icons-vue'
    import CommonUpload from '@/components/CommonUpload.vue';
    const pageProp = defineProps({
       info: Object,
       type: Number 
    })
    const pageData = reactive({
        show: false
    })
    /**
     * 替换封面
     */
    function uploadChange(url) {
        pageProp.info.backPic = url
    }
</script>
<style type='css/style' scoped>
.cover {
    width: 412px;
    height: 600px;
    border: 1px dotted #FF6E7B;
    padding: 20px;
	top: 50%;
	left: 50%;
    transform: translate(-50%, -50%);	
}
.img {
    left: 46px;
    top: 165px;
}
.img img {
    width: 105px;
    height: 50px;
}
.img-title div {
    padding-bottom:2px;
    border-bottom:1px solid #fff;
}
.img-title {
    padding-bottom:2px;
    border-bottom:1px solid #fff;
}
.title {
    left: 46px;
    bottom: 66px;
}
.upload {
    top: -16px;
    right: -45px;
}
</style>