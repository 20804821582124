import {postAjax,getAjax}  from "../common/axios.js"

//获取配置套餐列表
export const getEsignProductList = params => {
    return postAjax('/api/esignProduct/getEsignProductList',params);
}
//更新配置套餐列表
export const updateEsignProduct = params => {
    return postAjax('/api/esignProduct/updateEsignProduct',params,'json');
}
//合同剩余列表
export const getEsignAdminRemainList = params => {
    return postAjax('/api/esignProductOrder/getEsignAdminRemainList',params);
}
//充值记录
export const getEsignAdminOrderList = params => {
    return postAjax('/api/esignProductOrder/getEsignAdminOrderList',params);
}
//赠送合同
export const giveEsignProductOrder = params => {
    return postAjax('/api/esignProductOrder/giveEsignProductOrder',params);
}
//机构列表
export const selectAllStoreSimple = params => {
    return postAjax('/api/system/selectAllStoreSimple',params);
}
//获取机构合同剩余情况
export const getStoreUseInfo = params => {
    return postAjax('/api/esignProductOrder/getStoreUseInfo',params);
}
//充值
export const esignProductOrderPay = params => {
    return postAjax('/api/esignProductOrder/pay',params);
}
//获取个人认证状态
export const getEsignCertPsnInfo = params => {
    return postAjax('/api/esignCertPsn/getEsignCertPsnInfo',params);
}
//获取机构认证状态
export const getEsignCertOrgInfo = params => {
    return postAjax('/api/esignCertOrg/getEsignCertOrgInfo',params);
}
//获取委托认证
export const getEsignCertSealInfo = params => {
    return postAjax('/api/esignCertSeal/getEsignCertSealInfo',params);
}
//获取合同模板
export const getEsignFileTemplateList = params => {
    return postAjax('/api/esignFileTemplate/getEsignFileTemplateList',params);
}
//获取模板编辑地址
export const updateEsignFileTemplate = params => {
    return postAjax('/api/esignFileTemplate/updateEsignFileTemplate',params);
}
//获取模板预览地址
export const getEsignFileTemplateInfo = params => {
    return postAjax('/api/esignFileTemplate/getEsignFileTemplateInfo',params);
}
//模板删除
export const updateDelEsignFileTemplate = params => {
    return postAjax('/api/esignFileTemplate/updateDelEsignFileTemplate',params);
}
//模板创建
export const insertEsignFileTemplate = params => {
    return postAjax('/api/esignFileTemplate/insertEsignFileTemplate',params,'json');
}
//合同列表
export const getEsignFlowList = params => {
    return postAjax('/api/esignFlow/getEsignFlowList',params);
}
//学员列表
export const getCardMyList = params => {
    return postAjax('/api/esignFlow/getCardMyList',params);
}
//发起人列表
export const getAdviserList = params => {
    return postAjax('/api/associator/getAdviserList',params);
}
//撤回
export const cancelEsignFlow = params => {
    return postAjax('/api/esignFlow/cancelEsignFlow',params);
}
//解约
export const rescissionEsignFlow = params => {
    return postAjax('/api/esignFlow/rescissionEsignFlow',params);
}
//删除
export const updateDelEsignFlow = params => {
    return postAjax('/api/esignFlow/updateDelEsignFlow',params);
}
//重新发起
export const reInsertEsignFlow = params => {
    return postAjax('/api/esignFlow/reInsertEsignFlow',params);
}
//发起合同
export const insertEsignFlow = params => {
    return postAjax('/api/esignFlow/insertEsignFlow',params);
}
//下载
export const downloadSignFile = params => {
    return postAjax('/api/esignFlow/downloadSignFile',params);
}
//是否学员端展示
export const stuShowFlagUpdate = params => {
    return postAjax('/api/esignFlow/stuShowFlagUpdate',params);
}
//查看合同详情
export const getEsignFlowInfo = params => {
    return postAjax('/api/esignFlow/getEsignFlowInfo',params);
}
//发起保存合同
export const draftEsignFlow = params => {
    return postAjax('/api/esignFlow/draftEsignFlow',params);
}
//机构合同充值记录
export const getEsignStoreOrderList = params => {
    return postAjax('/api/esignProductOrder/getEsignStoreOrderList',params);
}