<template>
	<div class="pt20">
		<div class="mb20">
			<el-button type="primary" @click="addGearEvent">新增档位</el-button>
		</div>
		<div class="bg-FAFAFC w460 pl40 pt35 pr40 box_body">
			<div class="color-999 ft12 fw500 mb15">提示：最多配置8个，可移动位置，可用户默认勾选1个</div>
			<el-radio-group v-model="tableData.checkId" class="ml-4" @change="defaultChange">
				<MoveSort :key="(new Date).getTime()" :list="tableData.list" :first="false" @moveover="moveoverEvent">
					<template #[index] v-for="(item, index) in tableData.list" :key="index">
						<div class="box_container">
							<div class="container_card" :class="item.ifDefault == 1  ? 'container_active' : ''">
								<div class="rx-cc mb10">
									<div class="bdr100" :style="{width:'24px',height:'4px',backgroundColor:(item.ifDefault == 1 ?'#FFFFFF':'#FFDDDE') }"></div>
								</div>
								<div class="price t_a_c">{{ item.actualMoney }}元</div>
								<div class="mt5 t_a_c">
									<span class="obtain ft14">得{{ item.openNumBuy+'次+'+ (item.openNumGive||0) }}次</span>
								</div>
							</div>
							<div class="rx-bc container_footer">
								<el-radio :label="item.id">
									<span class="color-333 ft12">用户默认勾选</span>
								</el-radio>
								<div>
									<el-link :underline="false" class="mr5">
										<el-icon class="c_p color-b3b3b3 icon" :size="12">
											<Rank />
										</el-icon>
									</el-link>
									<el-link :underline="false">
										<el-icon class="c_p color-b3b3b3 icon" :size="12"
											@click="remove(item.encryptionId)">
											<Delete />
										</el-icon>
									</el-link>
								</div>
							</div>
						</div>
					</template>
				</MoveSort>
			</el-radio-group>
		</div>
	</div>
	<el-dialog v-model="tableData.show" :title="tableData.form.encryptionId ? '编辑档位':'新增档位'" width="400px"
		:before-close="()=>{tableData.show = false}">
		<div class="pt20 pl20 pr20 pb20" v-if="tableData.show">
			<div class="rx-sc mb20">
				<span class="w95 t_a_r"><span class="color-red">*</span>充值金额：</span>
				<div class="w240">
					<Input type="2" :value="tableData.form.actualMoney" unit="元" max="9999.9" length="1" node="actualMoney" @change="inputChange"></Input>
				</div>
			</div>
			<div class="rx-sc mb20">
				<span class="w95 t_a_r"><span class="color-red">*</span>充值次数：</span>
				<div class="w240">
					<Input type="1" :value="tableData.form.openNumBuy" unit="次" max="9999" node="openNumBuy" @change="inputChange"></Input>
				</div>
			</div>
			<div class="rx-sc">
				<span class="w95 t_a_r">赠送次数：</span>
				<div class="w240">
					<Input type="1" :value="tableData.form.openNumGive" unit="次" max="9999" node="openNumGive" @change="inputChange"></Input>
				</div>
			</div>
		</div>
		<template #footer>
			<div class="dialog-footer">
				<el-button @click="tableData.show = false">取消</el-button>
				<el-button type="primary" @click="preserve">确定</el-button>
			</div>
		</template>
	</el-dialog>
	<Confirm ref="confirm" :title="confirmTitle" @save="confirmSave"></Confirm>
</template>

<script setup>
	import {
		ref,
		reactive,
		onMounted,
	} from 'vue';
	import {
		Rank,
		Delete
	} from '@element-plus/icons-vue'
	import MoveSort from '@/components/MoveSort.vue';
	import Confirm from '@/components/Confirm.vue';
	import Input from '@/components/Input.vue';
	import {
		ElMessage
	} from 'element-plus'
	import {
		getHallRechargeModelInfo,
		getHallRechargeModelList,
		insertHallRechargeModel,
		updateHallRechargeModel,
		updateHallRechargeModelSort,
		updateDelHallRechargeModel
	} from '@/api/showroom.js'
	const confirm = ref(),
		confirmTitle = ref('');
	/**
	 * 获取充值模板列表
	 */
	const tableData = reactive({
		list: [],
		form: {},
		checkId:0,
		show: false,
	})
	getList();

	function getList() {
		getHallRechargeModelList({
			page: 1,
			limit: 9999
		}).then(res => {
			tableData.list = res.data;
			var arr = res.data.filter(n=>n.ifDefault == 1);
			if(arr.length > 0){
				tableData.checkId = arr[0].id;
			}
		})
	}
	//新增编辑档位
	function addGearEvent() {
		if(tableData.list.length >= 8){
			ElMessage.warning('档位最多配置8个');
			return false;
		}
		tableData.form = {
			actualMoney: '',
			openNumBuy: '',
			openNumGive: '',
			orderNum:0,
			ifDefault: 0
		};
		tableData.show = true;
	}
	function inputChange(data){
		tableData.form[data.key] = data.value;
	}
	/**
	 * 保存配置
	 */
	function preserve() {
		if (!tableData.form.actualMoney) {
			ElMessage.warning('请输入充值金额');
			return
		}
		if (!tableData.form.openNumBuy) {
			ElMessage.warning('请输入充值次数')
			return
		}
		tableData.form.orderNum = tableData.list.length+1;
		if (tableData.form.encryptionId) {
			updateHallRechargeModel(tableData.form).then(res => {
				ElMessage.success('保存成功');
				tableData.show = false;
				getList();
			})
		} else {
			insertHallRechargeModel(tableData.form).then(res => {
				ElMessage.success('保存成功');
				tableData.show = false;
				getList();
			})
		}
	}
	function moveoverEvent(list){
		tableData.list = list;
		defaultChange('',2);
	}
	//更新排序和默认
	function defaultChange(item,type){ //type 1 更新默认 2排序
		var arr = tableData.list.filter(n=>n.id == tableData.checkId);
		var list = tableData.list.map((n,i)=>{
			return {
				encryptionId:n.encryptionId,
				orderNum: i+1,
				ifDefault:type == 2 ? (n.ifDefault ? 1 : 0) : (n.encryptionId == arr[0].encryptionId ? 1 : 0),
			}
		})
		updateHallRechargeModelSort(list).then(res => {
			ElMessage.success('更新成功');
			getList();
		})
	}
	//删除
	var deleId = '';
	function remove(id) {
		deleId = id;
		confirmTitle.value = '确定删除该档位吗?';
		confirm.value.show();
	}

	function confirmSave() {
		updateDelHallRechargeModel({
			encryptionId: deleId
		}).then(res => {
			ElMessage.success('删除成功');
			tableData.show = false;
			getList();
		})
	}
</script>
<style lang='stylus' scoped>
	.nav {
		background: #EEF9FE;
		padding: 6px 25px 6px 10px;
	}

	.top {
		background: #FEE3E3;
		padding: 8px 26px;

		img {
			width: 32px;
			height: 32px;
			margin-right: 7px;
		}
	}
	.box_container {
		width: 156px;
		margin-bottom: 15px;
		margin-right: 10px;

		.container_card {
			padding-top: 20px;
			padding-bottom: 20px;
			background: #FFF;
			border-radius: 4px;
			border: 1px solid #D6D6D6;

			.price {
				color: #F85016;
				font-size: 16px;
				font-weight: bold;
				line-height: 22px;
				margin-left: 6px;
			}

			.obtain {
				color: #333;
			}

			.gold {
				margin-left: 3px;
				color: #FD3546;
			}

			.give {
				margin-top: 7px;

				span {
					color: #8A5A30;
					line-height: 17px;
					font-size: 12px;
					background: #FEEFD6;
					display: inline-block;
					padding: 1px 21px 2px 15px;
					-webkit-clip-path: polygon(10% 0%, 100% 0%, 90% 100%, 100% 100%, 100% 100%, 0% 100%);
					clip-path: polygon(10% 0%, 100% 0%, 90% 100%, 100% 100%, 100% 100%, 0% 100%);
				}
			}

			.mark {
				background: linear-gradient(90deg, #CA6CF4 0%, #F524F6 100%);
				border-radius: 100px 0px 0px 100px;
				padding: 4px 8px;
				right: -7px;
				top: 12px;
				transform: scale(0.7);
			}

			.mark-active {
				background: linear-gradient(90deg, #6CB3F4 0%, #245BF6 100%)
			}
		}

		.plus {
			height: 98px;

			div {
				top: 50%;
				left: 50%;
				cursor: pointer;
				transform: translate(-50%, -50%);

				img {
					width: 21px;
					height: 21px;
					margin-bottom: 6px;
				}

				p {
					color: #1989FA;
				}
			}
		}

		.container_active {
			background: #FC3D3D;
			border: 1px solid #FD3546;

			.price,
			.obtain,
			.gold {
				color: #FFF;
			}
		}
		
		.container_activeCard {
			border: 1px solid #FD3546;
			background: #FFF7F7;
		}

		.container_footer {
			:deep(.el-radio) {
				margin-right: 8px;
			}

			.sort {
				width: 12px;
				height: 12px;
			}

			.delImg {
				margin-left: 13px;
				cursor: pointer;
				width: 14px;
				height: 14px;
			}
		}

	}


	.dialog_nav {
		background: #F2F2F2;

		span {
			padding: 10px 20px 11px 18px;
			color: #666;
		}

		.active {
			background: #03A3FF;
			color: #FFF;
			border-radius: 2px;
		}
	}

	.dialog-box {
		width: 156px;
		margin-bottom: 15px;
		margin-right: 15px;

		.container_card {
			padding-top: 12px;
			padding-left: 7px;
			padding-bottom: 14px;
			background: #FFF;
			border-radius: 4px;
			height: 94px;
			border: 1px solid #D6D6D6;

			.price {
				color: #F85016;
				font-size: 16px;
				font-weight: bold;
				line-height: 22px;
				margin-left: 6px;
			}

			.obtain {
				color: #333;
				margin-left: 6px;
			}

			.gold {
				margin-left: 3px;
				color: #FD3546;
			}

			.give {
				margin-top: 7px;
				margin-left: 6px;

				span {
					color: #1989FA;
					font-size: 12px;
				}

				.span {
					color: #999;
				}
			}

			.mark {
				background: linear-gradient(90deg, #CA6CF4 0%, #F524F6 100%);
				border-radius: 100px 0px 0px 100px;
				padding: 4px 8px;
				right: -7px;
				top: 12px;
				transform: scale(0.7);
			}

			.mark-active {
				background: linear-gradient(90deg, #6CB3F4 0%, #245BF6 100%)
			}
		}

		.plus {
			height: 98px;

			div {
				top: 50%;
				left: 50%;
				cursor: pointer;
				transform: translate(-50%, -50%);

				img {
					width: 21px;
					height: 21px;
					margin-bottom: 6px;
				}

				p {
					color: #1989FA;
				}
			}
		}

		.container_active {
			background: #FBE8C7;
			border: 1px solid #956A45;

		}

		.container_activeCard {
			border: 1px solid #FD3546;
			background: #FFF7F7;
		}

		.container_footer {
			right: 12px;
			bottom: 10px;

			img {
				width: 17px;
				height: 16px;
			}
		}
	}

	.icon:hover {
		color: #409eff;
	}
	.box_body ul{
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between !important;
	}
</style>