<template>
	<div class="pt20">
		<el-table ref="table" :data="tableData.list" stripe style="width: 100%" height="65vh">
			<el-table-column prop="storeName" label="充值人" min-width="110" />
			<el-table-column label="充值模板" min-width="150">
				<template #default="scope">
					<img class="w90 bdr4" style="box-shadow: 0px 4px 9px 0px #C4BFBF;" :src="scope.row.hallCover" alt="">
				</template>
			</el-table-column>
			<el-table-column prop="actualMoney" label="充值售价" min-width="110" />
			<el-table-column label="充值次数" width="280"> 
				<template #default="scope">
					<span>{{scope.row.openNumBuy+'次'+scope.row.openNumGive+'次赠送'}}</span>
				</template>
			</el-table-column>
			<el-table-column prop="openNumRemain" label="剩余未使用总次数" min-width="110" />
			<el-table-column prop="createTime" label="充值时间" min-width="120" />
		</el-table>
		<div class="mt20 rx-ec">
			<el-pagination v-model:currentPage="searchForm.page" :page-sizes="[15,20,30,50,100]"
				:page-size="searchForm.limit" layout="total, sizes, prev, pager, next, jumper"
				:total="tableData.count" @size-change="pageSizeChange" @current-change="()=>{getList()}">
			</el-pagination>
		</div>
	</div>
</template>

<script setup>
	import {
		ref,
		reactive
	} from 'vue';
	
	import {getHallTemplateAddNumOrderLogList} from '@/api/showroom.js'
	const searchForm = reactive({
		page: 1,
		limit: 15
	})
	const tableData = reactive({
		list: [],
		count: 0
	})

	//搜索table
	function searchTable() {
		searchForm.page = 1;
		getList();
	}
	//切换分页数量
	function pageSizeChange(limit) {
		searchForm.limit = limit;
		searchTable();
	}
	getList();

	function getList() {
		getHallTemplateAddNumOrderLogList(searchForm).then((res)=>{
			if(res.data){
				tableData.list = res.data;
				tableData.count = res.data;
			}else{
				tableData.list =[];
				tableData.count = 0;
			}
		})
	}

	
</script>
<style scoped>
	
</style>