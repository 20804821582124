<template>
	<div class="pt20">
		<div class="layui-sms">
			<div class="item">
				<p class="val" id="company-sms">{{pageData.remainAll}}</p>
				<p class="name">公司账户剩余合同数</p>
			</div>
			<div class="item" style="margin-left: 40px;">
				<p class="val" id="store-sms">{{pageData.remainStore}}</p>
				<p class="name">全部分店剩余合同数</p>
			</div>
		</div>
		<div class="mb12 rx-sc">
			<div class="w220 mr12">
				<el-input type="text" v-model="searchForm.searchKeys" placeholder="请输入ID、分店名称"></el-input>
			</div>
			<el-button type="primary" @click="getList(1)">搜索</el-button>
		</div>
		<el-table ref="table" :data="pageData.list" stripe style="width: 100%" height="65vh">
			<el-table-column prop="storeId" label="机构ID" />
			<el-table-column prop="storeName" label="分店名称" />
			<el-table-column prop="salePrice" label="充值金额(元)" />
			<el-table-column prop="num" label="充值份数(份)" />
			<el-table-column prop="consumeNum" label="消耗总数量(份)" >
				<template #default="scope">
					{{scope.row.num - scope.row.remainNum}}
				</template>
			</el-table-column>
			<el-table-column prop="remainNum" label="现剩余数量(份)" />
		</el-table>
		<div class="mt20 rx-ec">
			<el-pagination v-model:currentPage="searchForm.page" :page-sizes="[15,20,30,50,100]"
				:page-size="searchForm.limit" layout="total, sizes, prev, pager, next, jumper"
				:total="pageData.count" @size-change="pageSizeChange" @current-change="()=>{getList()}">
			</el-pagination>
		</div>
	</div>
</template>

<script setup>
	import { ref, reactive } from 'vue';
	import { ElMessage } from 'element-plus'
	import { getEsignAdminRemainList } from '@/api/contract.js';
	const pageData = reactive({
		list:[],
		count:0,
		remainAll:0,
		remainStore:0,
	})
	const searchForm = reactive({
		page:1,
		limit:15,
		searchKeys:''
	})
	function pageSizeChange(limit){
		searchForm.limit = limit;
		getList(1);
	}
	getList();
	function getList(page){
		if(page){
			searchForm.page = 1;
		}
		getEsignAdminRemainList(searchForm).then(res=>{
			pageData.list = res.data;
			pageData.count = res.count;
			pageData.remainAll = formatValue(res.remark.remainAll);
			pageData.remainStore = formatValue(res.remark.remainStore);
		})
	}
	function formatValue(opt) {
		if (opt !== '') {
			const str = opt + ''; //把数字变成string类型
			if (str.indexOf('.') !== -1) { //判断是否附带小数
				const intSum = str
					.substring(0, str.indexOf('.'))
					.replace(/\B(?=(?:\d{3})+$)/g, ','); //取到整数部分
				const dot = str.substring(str.length, str.indexOf('.')); //取到小数部分搜索
				const ret = intSum + dot;
				return ret;
			} else {
				const ret = str.replace(/\B(?=(?:\d{3})+$)/g, ',');
				return ret;
			}
		} else {
			return '';
		}
	}
</script>

<style scoped>
	.layui-sms  {
		margin-bottom: 12px;
		display: flex;
	}
	
	.layui-sms  .item {
		padding: 29px 0 29px 30px;
		width: 352px;
		background: #F7F7FA;
		border-radius: 6px;
	}
	
	.layui-sms .item .val {
		font-weight: bold;
		color: #33B6FF;
		font-size: 24px;
	}
	
	.layui-sms .item .name {
		margin-top: 15px;
		color: #4A545F;
		font-size: 14px;
	}
</style>