<template>
    <workArea :barlist="barlist">
    <div class="bg-fff pl16 pt10 pr16">
        <el-tabs v-model="pageData.tabNum" @tab-click="handleClick">
            <el-tab-pane label="测评模版" name="0"></el-tab-pane>
            <el-tab-pane label="测评报告" name="1"></el-tab-pane>
            <el-tab-pane label="测评线索" name="2"></el-tab-pane>
        </el-tabs>
        <component :is="components[pageData.tabNum]" :tab="pageData.tabNum"></component>
    </div>
    </workArea>
</template>
<script setup>
  import { reactive, onMounted, onBeforeUnmount } from 'vue';
  import revenueGather from '@/views/branch-store/assess/assessTemplate.vue' // 模板中心
  import income from '@/views/branch-store/assess/assessReport.vue' // 测评报告
  import expend from '@/views/branch-store/assess/assessClue.vue' // 测评线索
  import workArea from '@/components/branch-store/workArea.vue'; 
  import utils from '@/common/utils.js'; 
  const barlist = [{ name : '首页' },{ name : '测评中心' }];
  const components = [revenueGather, income, expend];
  const pageData = reactive({
      tabNum : '0'
  });

    function handleClick(tab) {
      localStorage.setItem('assess', tab.props.name)
	}
	onMounted(()=> {
      if (localStorage.getItem('assess') != null) {
          pageData.tabNum = localStorage.getItem('assess')
	    }
	})
  onBeforeUnmount(() => {
		localStorage.removeItem('assess')
	})
    
</script>

<style>
</style>

