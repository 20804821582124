<template>
	<div class="pt20">
		<div class="rx-sc mb20">
			<CommonUpload @change="uploadChange" :isCropper="false">
				<el-button type="primary">添加本地图片</el-button>
			</CommonUpload>
			<div class="d_i_b posi-r">
				<el-button class="ml20">批量导入</el-button>
				<input class="posi-a top0 left0 right0 bottom0" accept="image/png,image/jpeg,image/jpg" style="opacity: 0;" type="file" multiple @change="uploadChangeNew" name="" id="">
			</div>
			
		</div>
		<div class="rx-wss" v-loading="pageData.loading">
			<div class="mr40 mb40 posi-r imgBox" v-for="item in pageData.list" :key="item.worksPic">
				<div class="w300 h300 o_h posi-r">
					<img class="hh100 posi-c" :src="item.worksPic" alt="">
				</div>
				<div class="del" @click="delEvent(item.encryptionId)">
					<img class="del1 w25" src="@/assets/del1.png" alt="">
					<img class="del2 w25" src="@/assets/del2.png" alt="">
				</div>
			</div>
		</div>
	</div>
	<Confirm ref="confirm" title="确定删除该作品吗？" @save="confirmSave"></Confirm>
</template>

<script setup>
	import {
		ref,
		reactive
	} from 'vue';
	import {
		ElMessage
	} from 'element-plus'
	import axios from 'axios'
	import CommonUpload from '@/components/CommonUpload.vue';
	import Confirm from '@/components/Confirm.vue';
	import {getHallWorksList,insertHallWorks,updateDelHallWorks} from '@/api/showroom.js'
	import {commonUpload} from '@/common/common.js'
	const pageData = reactive({
		list:[],
		loading:false
	})
	getList();
	function getList() {
		getHallWorksList({
			page:1,
			limit:99999
		}).then((res)=>{
			if(res.data){
				pageData.list = res.data;
			}
		})
	}
	//上传封面
	function uploadChange(url,callback) {
		insertHallWorks({
			worksPic:url
		}).then(res=>{
			if(!callback){
				ElMessage.success('上传成功');
				getList();
			}else{
				callback && callback();
			}
		})
	}
	//批量上传
	function uploadChangeNew(e){
		if(e.target.files.length > 0){
			startUpload(e.target.files,0);
			pageData.loading = true;
		}
	}
	function startUpload(files,num){
		if(files[num]){
			commonUpload({
				fileData: files[num]
			},(formData,ossUrl)=>{
				let param = new FormData();
				for(var k in formData){
					param.append(k, formData[k]);
				}
				param.append("file",files[num]);
				axios.request({
					url: ossUrl,
					method: 'post',
					headers:{"Content-Type": "multipart/form-data;"},
					data:param,
				}).then((res)=>{
					var url = res.data.data.wholeUrl;
					uploadChange(url,()=>{
						if(files[num+1]){
							startUpload(files,num+1);
						}else{
							pageData.loading = false;
							ElMessage.success('上传成功');
							getList();
						}
					});
				})
			})
		}
	}
	
	const confirm = ref();
	var id = '';
	function delEvent(encryptionId){
		id = encryptionId;
		confirm.value.show();
	}
	function confirmSave(){
		updateDelHallWorks({
			encryptionId:id
		}).then(res=>{
			ElMessage.success('删除成功');
			getList();
		})
	}
</script>

<style scoped>
	.uploadBox {
		border: 1px dashed #D6D6D6;
		height: 120px;
		width: 120px;
		border-radius: 2px;
		color: #999999;
		cursor: pointer;
	}
	
	.uploadBox:hover {
		border: 1px dashed #1989FA;
		color: #1989FA;
	}
	
	.uploadBox .upload2 {
		display: none;
	}
	
	.uploadBox:hover .upload2 {
		display: block;
	}
	
	.uploadBox:hover .upload1 {
		display: none;
	}
	
	.imgBox {
		position: relative;
		width: 300px;
		height:300px;
	}
	
	.imgBox .del,
	.imgBox:hover .del2 {
		display: none;
	}
	
	.imgBox:hover .del {
		display: block;
		cursor: pointer;
	}
	
	.imgBox .del:hover .del2 {
		display: block;
	}
	
	.imgBox .del:hover .del1 {
		display: none;
	}
	
	.imgBox .del {
		position: absolute;
		right: 0;
		top: 0;
		transform: translate(50%, -50%);
	}
	
</style>