<template>
	<workArea :barlist="pageData.barlist">
		<div class="pt60 pb100 rx-cc" id="templateUpload">
			<div class="w950">
				<div class="color-33 ft16 mb20">上传基础合同：</div>
				<div class="cx-cc pb20 mb20" id="contractUploadBox"
					style="background-color:rgba(0, 163, 255, 0.03);border: 1px dashed #D9D9DA;">
					<img class="w120 mb10" src="@/assets/upload_yun.png" alt="">
					<p class="color-333 ft16 mb20">点击或文件拖拽到这里上传</p>
					<el-button class="mb10 posi-r" type="primary">
						点击上传
						<input class="posi-a left0 bottom0 top0 right0 opacity0" type="file" name="" id="uploadBtn"
							accept=".pdf" @change="inputUploadChange">
					</el-button>
					<p class="ft12 color-999">仅支持pdf，文件小于10M</p>
				</div>
				<div class="rx-bc bg-FAFAFC pt20 pb20 pr20 pl20 mb50" v-show="pageData.fileName" id="fileInfo">
					<div class="rx-sc">
						<img class="w40 mr10" src="@/assets/upload_pdf.png" alt="">
						<div class="flex1">
							<p class="ft16 color-333 fw700 mb5" id="fileName">{{pageData.fileName}}</p>
							<p class="color-999 ft14" id="fileSize">{{pageData.fileSize}}</p>
						</div>
					</div>
					<div class="rx-sc">
						<span class="color-red mr15 ft16" id="deleteFile" @click="delFile">删除</span>
						<span class="btn1 ft16 cursor-pointer" @click="uploadAgain">重新上传</span>
					</div>
				</div> 
				<div class="color- 33 ft16 mb20">模板名称：</div>
				<el-input v-model="pageData.name" type="text" placeholder="请输入模板名称" ></el-input>
			</div>
		</div>
		<div v-show="pageData.width" class="rx-ec bg-FAFAFC posi-f right0 bottom0" :style="{width:pageData.width+'px'}" style="z-index: 400;">
			<div class="color-fff ft16 bg-00a3ff pt20 pb20 pl40 pr40 c_p" id="nextBtn" @click="saveEvent">保存模板</div>
		</div>
	</workArea>
</template>

<script setup>
	import { ref, reactive,onMounted } from 'vue';
	import workArea from '@/components/branch-store/workArea.vue';
	import { ElMessage,ElMessageBox } from 'element-plus'
	import { insertEsignFileTemplate} from '@/api/contract.js';
	import {useRouter } from 'vue-router';
	const router = useRouter();
	const pageData = reactive({
		width:'',
		fileSize:0,
		fileName:'',
		name:'',
		barlist:[{ name : '首页' },{ name : '电子合同' },{ name : '上传模板' }]// 面包屑
	})
	
	var inputFileObj = '';
	function inputUploadChange(e){
		var fileData = e.target.files[0];
		setFeilInfo(fileData)
	}
	function setFeilInfo(fileData){
		if(fileData.size > 10485760){
			ElMessage.warning('上传文件不能超过10M');
			return false;
		}
		inputFileObj = fileData;
		pageData.fileSize = Math.floor(fileData.size / 1024)+'KB';
		pageData.fileName = fileData.name;
	}
	function delFile(){
		inputFileObj = '';
		pageData.fileName = '';
		pageData.fileSize = 0;
	}
	//重新上传
	function uploadAgain(){
		document.getElementById('uploadBtn').click();
	}
	//保存
	function saveEvent(){
		if(!inputFileObj){
			ElMessage.warning('请上传文件');
			return false;
		}
		var formData = new FormData();
		formData.append('file',inputFileObj);
		if(!pageData.fileName){
			ElMessage.warning('请输入模板名称');
			return false;
		}
		formData.append('fileName',pageData.name);
		insertEsignFileTemplate(formData).then(res=>{
			ElMessageBox.alert('创建成功，请前往设置模板内容！', '提示', {
			    confirmButtonText: '去设置',
				callback: () => {
				    window.open(res.data.docTemplateUrl);
					router.go(-1);
				}
			})
		})
	}
	onMounted(()=>{
		setTimeout(function(){
			pageData.width = document.getElementById('templateUpload').clientWidth;
		},500)
		//拖拽上传
		document.addEventListener('drop',function(e){
			e.preventDefault();
		})
		document.addEventListener('dragleave',function(e){
			e.preventDefault();
		})
		document.addEventListener('dragenter',function(e){
			e.preventDefault();
		})
		document.addEventListener('dragover',function(e){
			e.preventDefault();
		})
		var contractUploadBox = document.getElementById('contractUploadBox');
		contractUploadBox.addEventListener('drop',function(e){
			var fileList = e.dataTransfer.files; //获取文件对象    
			    //检测是否是拖拽文件到页面的操作            
			if(fileList.length == 0){       
				ElMessage.warning("未检测到文件");
				return false;            
			}      
			setFeilInfo(fileList[0])
		})
	})
	
</script>

<style scoped>
	.navActive div{
			background-color: #00a3ff !important;
			color: white !important;
	}
	.navActive span{
		color: #666666 !important;
	}
</style>