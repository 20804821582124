<template>
    <div class="hh100 posi-r">
        <div class="w375 box posi-a posi-r" :class="[pageProp.type == 1 ? 'dance' : (pageProp.type == 2 ? 'sports' : (pageProp.type == 3 ? 'basketball' : 'bodybuilding'))]">
            <div class="ww100 hh100 box-item">
                <div class="top">
                    <span class="serial ft16 mr10">03</span>
                    <span class="title ft16 fwb">测评结果</span>
                </div>
                <div class="container bg-fff">
                    <div class="message mt10">
                        <div class="mt60 h195 posi-r score">
                            <img class="w265 hh100 posi-a" src="@/assets/score.png" alt="">
                            <div class="posi-r w55 score-branch ft44 fwb">88</div>
                            <div class="posi-r w60 score-text fwb">综合评分</div>
                        </div>
                        <div class="t_a_c fwb cft15 color-333 mb20 mt10"> 超过 <span class="percentage">92%</span> 同龄孩子</div>
                        <div class="message-box rx-bc">
                            <div class="cx-cc">
                                <div class="color-999 mb10">测评项</div>
                                <div class="color-333 fwb">18项目</div>
                            </div>
                            <div class="cx-cc">
                                <div class="color-999 mb10">测评时长</div>
                                <div class="color-333 fwb">30分钟</div>
                            </div>
                        </div>
                        <div class="rx-bc mt30">
                            <div class="color-333 ft18 fwb">老师评语</div>
                            <el-switch v-model="pageProp.info.openComment" :active-value="1" :inactive-value="0" />
                        </div>
                        <div class="rx-bc mt30">
                            <div class="color-333 ft18 fwb">测评建议</div>
                            <el-switch v-model="pageProp.info.openSuggest" :active-value="1" :inactive-value="0" />
                        </div>
                    </div>
                </div>
            </div>
          
        </div>
    </div>
</template>

<script setup>
    import { reactive } from 'vue';
    const pageProp = defineProps({
       info: Object,
       type: Number 
    })
    const pageData = reactive({})
</script>
<style type='css/style' scoped>
    .box {
        padding: 12px;
	    left: 50%;
        transform: translateX(-50%);	
    }
    .box-item .top{
        background: #FFF;
        border-top-left-radius: 10px;
        padding: 13px;
        padding-bottom: 0;
        -webkit-clip-path: polygon(0% 0%,40% 0%,45% 100%,100% 100%,100% 100%,0% 100%);
        clip-path: polygon(0% 0%,40% 0%,45% 100%,100% 100%,100% 100%,0% 100%);
    }
    .container {
        padding: 13px;
        border-top-right-radius: 10px;
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
        margin-top: -2px;
    }
    .serial {
        font-weight: 1000;
    }
    .message-box {
        padding: 12px 44px;
        border-radius: 6px;
    }
    .score img {
        left: 50%;
        transform: translateX(-50%);	
    }
    .score .score-branch {
        left: 51%;
        top: 46%;
        transform: translate(-50%,-50%);
        color: #D3358F;
    }
    .score .score-text {
        left: 51%;
        top: 36%;
        transform: translate(-50%,-50%);
        color: #8E79D8;	
    }
    /* 主题颜色 */
    .dance {
        background: #FDD2E7;
    }
    .dance .top .serial, .dance .top .title, .percentage {
        color: #ED2169;
    }
    .dance .message-box{
        background: #FFEFF7;
    }
    .sports {
        background: #203C1E;
    }
    .sports .top .serial, .sports .top .title {
        color: #203C1E;
    }
    .sports .message-box{
        background: #F0FCEF;
    }
    
</style>