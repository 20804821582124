<template>
    <!-- 新增潜在会员 -->
    <el-dialog
         :title="propData.id ? '编辑跟进' : '添加跟进'"
         width="700px"
         custom-class="popwechat"
         :append-to-body="true"
         @opened="opened"
       >
        <div class="pl20 pt20 pb20 pr60"> 
            <div class="rx-ss mb16">
                <span class="w110 t_a_r fwb">跟进内容：</span>
                <div class="flex1">
                    <el-input
                        v-model="pageData.batchFollowForm.content"
                        :rows="5"
                        type="textarea"
                        placeholder="请输入跟进内容"
                      />
                </div>
            </div>
            <div class="rx-ss mb16">
                <span class="w110 t_a_r fwb">添加图片：</span>
                <div class="flex1">
                    <ul class="rx-sc mb16">
                        <li class="mr10" v-for="(item, index) in pageData.batchFollowForm.picUrlList" :key="index">
                            <div class="w80 h80 bdr2 imgBox" style="border: 1px solid #ccc;position: relative;">
                                <img class="ww100 hh100" :src="item" alt="">
                                <div class="deleImg rx-cc" @click="deleImg(index)">
                                    <unicon name="trash-alt" fill="#fff" hover-fill="#fff"></unicon>
                                </div>
                            </div>
                        </li>
                        <li v-show="pageData.batchFollowForm.picUrlList.length < 3">
                            <CommonUpload @change="uploadChange" :isCropper="false">
                                <div class="w80 h80 rx-cc bdr2 t_a_c" style="border: 1px solid #ccc;" v-loading="uploading">
                                    <div>
                                        <img class="w25" src="@/assets/addBank.png" alt="">
                                        <p>上传图片</p>
                                    </div>
                                </div>
						</CommonUpload>
                        </li>   
                    </ul>
                    <div class="uploadTips">
                        支持 jpg、png格式图片，图片应该小于100M，最多支持上传3张图片 
                    </div>
                </div>
            </div>
            <div class="rx-sc mb16">
                <span class="w110 t_a_r fwb">跟进状态：</span>
                <div class="w220">
                    <el-select v-model="pageData.batchFollowForm.followUpStatusId" placeholder="请选择">
                        <el-option v-for="item in pageData.followStatusList" :key="item.value" :label="item.label" :value="item.value">
                        </el-option>
                    </el-select>
                </div>
            </div>
            <div class="rx-sc mb16">
                <span class="w110 t_a_r fwb">意向级别：</span>
                <div class="w220">
                    <el-rate v-model="pageData.batchFollowForm.ifImportanceDegree" size="large"></el-rate>
                </div>
            </div>
            <div class="rx-sc mb16">
                <span class="w110 t_a_r fwb">下次跟进时间：</span>
                <div class="w220 ">
                    <el-date-picker
                        v-model="pageData.batchFollowForm.nextFollowUpDate"
                        type="datetime"
                        placeholder="请选择"
                        format="YYYY-MM-DD HH:mm:ss"
                        value-format="YYYY-MM-DD HH:mm:ss"
                        :disabled-date="pageData.disabledDate"
                        :disabled-hours="disabledHours"
                        :disabled-minutes="disabledMinutes"
                        :disabled-seconds="disabledSeconds"
                    />
                </div>
            </div>
            <div class="rx-sc mb16" v-show="pageData.batchFollowForm.nextFollowUpDate">
                <span class="w110 t_a_r fwb">微信消息提醒：</span>
                <div class="w220">
                    <el-radio v-model="pageData.batchFollowForm.needRemind" :label="true" size="large">是</el-radio>
                    <el-radio v-model="pageData.batchFollowForm.needRemind" :label="false" size="large">否</el-radio>
                </div>
            </div>
            <div class="rx-sc mb16" v-show="pageData.batchFollowForm.needRemind == true">
                <span class="w110 t_a_r fwb">提醒时间：</span>
                <div class="w220">
                    <el-select v-model="pageData.batchFollowForm.remindTimeType" placeholder="请选择">
                        <el-option label="提前15分钟" value="BEFORE_15_MINUTES"/>
                        <el-option label="提前30分钟" value="BEFORE_30_MINUTES"/>
                        <el-option label="提前1小时" value="BEFORE_1_HOUR"/>
                        <el-option label="提前1天" value="BEFORE_1_DAY"/>
                        <el-option label="自定义时间" value="CUSTOM_TIME"/>
                    </el-select>
                </div>
            </div>
            <div class="rx-sc mb16" v-show="pageData.batchFollowForm.remindTimeType == 'CUSTOM_TIME'">
                <span class="w110 t_a_r fwb">自定义时间：</span>
                <div class="w220">
                    <el-time-picker
                      v-model="pageData.batchFollowForm.remindTime"
                      :disabled-hours="disabledHours"
                      :disabled-minutes="disabledMinutes"
                      :disabled-seconds="disabledSeconds"
                      format="HH:mm:ss"
                      value-format="HH:mm:ss"
                    />
                </div>
            </div>
            <div class="rx-sc mb16" v-show="pageData.batchFollowForm.needRemind == true">
                <span class="w110 t_a_r fwb">提醒对象：</span>
                <div class="w220">
                    <el-select multiple collapse-tags v-model="pageData.batchFollowForm.remindUserId" placeholder="请选择">
                        <el-option v-for="item in pageData.followPeople" :key="item.value" :label="item.label" :value="item.value">
                        </el-option>
                    </el-select>
                </div>
            </div>
        </div>
       <template #footer>
            <div class="dialog-footer">
                <el-button @click="close">取消</el-button>
                <el-button type="primary" @click="save">确定</el-button>
            </div>
        </template>
    </el-dialog>
</template>

<script setup>
    import {ref,reactive,onMounted} from 'vue';
    import { ElMessage} from 'element-plus'
    import utils from '@/common/utils.js'
    import {followRecordDetail,followRecordEdit,followRecordAdd} from '@/api/intentionList.js'
    import { number } from 'echarts';
	 import CommonUpload from '@/components/CommonUpload.vue';
    const emit = defineEmits(['close','save']);
    const propData = defineProps({
        sid:String,
        recordid:String,
        star:String,
    })
    const pageData = reactive({
        interimUserSource:[],
        followStatusList:[],
        followPeople:[],
        uploading:false,
        batchFollowForm:{
            content:'',
            followUpStatusId:'',
            ifImportanceDegree:'',
            needRemind:false,
            nextFollowUpDate: '',
            picUrlList:[],
            remindTimeType:'BEFORE_15_MINUTES',
            remindUserId:'',
            studentId:'',
            studentIds:[],
        },
        disabledDate(time) {
          return disDate(time)
        }
    })
    const makeRange = (start, end) => {
        const result = []
        for (let i = start; i <= end; i++) {
            result.push(i)
        }
        return result
    }
    let dataTimer = new Date() // 获取当前实际
    let hours = dataTimer.getHours();
    let minutes = dataTimer.getMinutes();
    let second = dataTimer.getSeconds();
    function disabledHours(){
        return makeRange(0, hours-1)
    }
    function disabledMinutes(hour){
        if (hours == hour) {
            return makeRange(0, minutes-1)
        }
    }
    function disabledSeconds(hour, minute){
        if (hours == hour && minutes == minute) {
            return makeRange(0, second)
        }
    }
    const disDate = (time) => {
        return time.getTime() < new Date().getTime() - 8.64e7 
    }
    //获取字典
    utils.getDynaDict('followerList','',function(res){
        pageData.followPeople = res;
    });
    utils.getDynaDict('followState','',function(res){
        pageData.followStatusList = res;
    });
    function close(){
        emit('close');
    }
    function save(){
        var formData = JSON.parse(JSON.stringify(pageData.batchFollowForm));
        formData.remindUserId = formData.remindUserId ? formData.remindUserId.join(',') : '';
        formData.ifImportanceDegree = utils.getStartNum(formData.ifImportanceDegree);
        formData.picUrlList = formData.picUrlList.length > 0 ? formData.picUrlList.join(',') : ''

        if(!formData.content){
            ElMessage({
                type: 'warning',
                message: '请输入跟进内容'
            })
            return false;
        }
        if(!formData.followUpStatusId){
            ElMessage({
                type: 'warning',
                message: '请选择跟进状态'
            })
            return false;
        }
        
        if(propData.recordid){ //编辑
            formData.id = propData.recordid; //data.encryptionId;
            followRecordEdit(formData).then((res)=>{
                if(res.code == 0){
                    ElMessage({
                        type: 'success',
                        message: '编辑成功'
                    })
                    initUser({});
                    emit('save');
                }
            })

        }else{
            formData.studentIds = Number.isFinite(propData.sid) ? [propData.sid] : propData.sid.split(',').map(Number)
            followRecordAdd(formData).then((res)=>{
                if(res.code == 0){
                    ElMessage({
                        type: 'success',
                        message: '新增成功'
                    })
                    initUser({});
                    emit('save');
                }
            })
        }
        
    }
    //上传封面
    function uploadChange(url) {
		pageData.batchFollowForm.picUrlList.push(url);
		pageData.uploading = false;
        
    }
    //删除图片
    function deleImg(index){
        pageData.batchFollowForm.picUrlList.splice(index,1);
    }
    // 编辑时赋值
    function initUser(obj){
        for(var key in pageData.batchFollowForm){
            pageData.batchFollowForm[key] = obj[key] || '';
        }
		pageData.batchFollowForm.remindTimeType = obj.remindTimeType || 'BEFORE_15_MINUTES';
		pageData.batchFollowForm.needRemind =  obj.needRemind || false;
		pageData.batchFollowForm.picUrlList =  obj.picUrlList ? obj.picUrlList.split(',') : []; // picUrlList
		pageData.batchFollowForm.studentIds =  obj.studentIds || [];
        pageData.batchFollowForm.followUpStatusId =  obj.followUpStatusId || ''
        pageData.batchFollowForm.ifImportanceDegree = propData.star || utils.getStartNum(obj.ifImportanceDegree || 5);
        pageData.batchFollowForm.remindUserId =  obj.needRemind && obj.remindUserId.split(',').map(Number) || [];

    }
    function opened(){
        if(propData.recordid){
            followRecordDetail({ id: propData.recordid}).then((res)=>{
                if(res.code == 0){
                    initUser(res.data);
                }
            })
        }else{
			initUser({});
		}
    }
</script>

<style scoped lang="stylus">
    .uploadTips{
        border 1px solid #00A3FF;
        border-radius 2px;
        padding-left 5px;
        background #EBF8FF;
        color #00A3FF;
    }
    .deleImg{
        position absolute;
        left 0;
        top 0;
        right 0;
        bottom 0;
        background-color rgba(0,0,0,.3);
        display none;
        text-align center;
        line-height 80px;
        cursor pointer;
    }
    .imgBox:hover .deleImg{
        display inline-block;
    }
</style>
