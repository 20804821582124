import {postAjax,getAjax}  from "../common/axios.js"

// 展厅模板-查询列表
export const getHallTemplateList = params => {
	return postAjax('/api/hallTemplate/getHallTemplateList',params);
}

// 展厅模板-添加
export const insertHallTemplate = params => {
	return postAjax('/api/hallTemplate/insertHallTemplate',params);
}
// 展厅模板-更新
export const updateHallTemplate = params => {
	return postAjax('/api/hallTemplate/updateHallTemplate',params);
}
// 展厅模板-查询单个详情
export const getHallTemplateInfo = params => {
	return postAjax('/api/hallTemplate/getHallTemplateInfo',params);
}

// 展厅模板-删除
export const updateDelHallTemplate = params => {
	return postAjax('/api/hallTemplate/updateDelHallTemplate',params);
}

// 作品管理-查询列表
export const getHallWorksList = params => {
	return postAjax('/api/hallWorks/getHallWorksList',params);
}
// 作品管理-更新
export const updateHallWorks = params => {
	return postAjax('/api/hallWorks/updateHallWorks',params);
}

// 作品管理-删除
export const updateDelHallWorks = params => {
	return postAjax('/api/hallWorks/updateDelHallWorks',params);
}
// 作品管理-添加
export const insertHallWorks = params => {
	return postAjax('/api/hallWorks/insertHallWorks',params);
}
// 机构展厅模板-查询单个详情
export const getHallTemplateStoreInfo = params => {
	return postAjax('/api/hallTemplateStore/getHallTemplateStoreInfo',params);
}
// 机构展厅模板-查询已开通列表
export const getHallTemplateStoreList = params => {
	return postAjax('/api/hallTemplateStore/getHallTemplateStoreList',params);
}
// 展厅管理-查询单个详情
export const getHallContentInfo = params => {
	return postAjax('/api/hallContent/getHallContentInfo',params);
}
// 展厅管理-查询列表
export const getHallContentList = params => {
	return postAjax('/api/hallContent/getHallContentList',params);
}
// 展厅管理-更新-JSON格式
export const updateHallContent = params => {
	return postAjax('/api/hallContent/updateHallContent',params,'json');
}
// 展厅管理-添加-JSON格式
export const insertHallContent = params => {
	return postAjax('/api/hallContent/insertHallContent',params,'json');
}
// 展厅管理-删除
export const updateDelHallContent = params => {
	return postAjax('/api/hallContent/updateDelHallContent',params);
}

// 展厅线索列表
export const selectStoreMembersHall = params => {
	return postAjax('/api/member/selectStoreMembersHall',params);
}

// 充值档位-更新排序默认-JSON
export const updateHallRechargeModelSort = params => {
	return postAjax('/api/hallRechargeModel/updateHallRechargeModelSort',params,'json');
}

// 充值档位-查询单个详情
export const getHallRechargeModelInfo = params => {
	return postAjax('/api/hallRechargeModel/getHallRechargeModelInfo',params);
}
// 充值档位-查询列表
export const getHallRechargeModelList = params => {
	return postAjax('/api/hallRechargeModel/getHallRechargeModelList',params);
}

// 充值档位-更新
export const updateHallRechargeModel = params => {
	return postAjax('/api/hallRechargeModel/updateHallRechargeModel',params);
}

// 充值档位-删除
export const updateDelHallRechargeModel = params => {
	return postAjax('/api/hallRechargeModel/updateDelHallRechargeModel',params);
}
// 充值档位-添加
export const insertHallRechargeModel = params => {
	return postAjax('/api/hallRechargeModel/insertHallRechargeModel',params);
}

// 充值-查询订单列表
export const getHallTemplateAddNumOrderLogList = params => {
	return postAjax('/api/hallTemplateOrderLog/getHallTemplateAddNumOrderLogList',params);
}
// 查询创作者列表
export const selectHallPersonSimple = params => {
	return postAjax('/api/member/selectHallPersonSimple',params);
}

// 展厅模板-停用/启用
export const updateStateHallTemplate = params => {
	return postAjax('/api/hallTemplate/updateStateHallTemplate',params);
}

// 展厅模板-充值次数支付发起
export const toPay = params => {
	return postAjax('/api/hallTemplate/toPay',params);
}

// 展厅模板-咨询客服-获取
export const getKeFuUrl = params => {
	return postAjax('/api/hallTemplate/getKeFuUrl',params);
}
// 展厅模板-咨询客服-设置
export const setKeFuUrl = params => {
	return postAjax('/api/hallTemplate/setKeFuUrl',params);
}

// 机构展厅模板-查询已开通列表
export const getHallTemplateOrderList = params => {
	return postAjax('/api/hallTemplateOrder/getHallTemplateOrderList',params);
}

// 意向转正式
export const formalToUser = params => {
	return postAjax('/api/associator/formalToUser',params);
}

// 小程序Banner-查询
export const getHallMiniBannerList = params => {
	return postAjax('/api/hallMiniBanner/getHallMiniBannerList',params);
}
// 小程序Banner-更新
export const updateHallMiniBanner = params => {
	return postAjax('/api/hallMiniBanner/updateHallMiniBanner',params,'json');
}
// 小程序推荐区-查询
export const getHallMiniRecommendList = params => {
	return postAjax('/api/hallMiniRecommend/getHallMiniRecommendList',params);
}
// 小程序推荐区-更新-JSON格式
export const updateHallMiniRecommend = params => {
	return postAjax('/api/hallMiniRecommend/updateHallMiniRecommend',params,'json');
}


// 展厅申请-查询
export const getHallContentApplyList = params => {
	return postAjax('/api/hallContentApply/getHallContentApplyList',params);
}

