<!-- 会员列表页面 -->
<template>
		<div class="bg-fff">
			<formQuery :queryOpts="queryOpts" @query="searchCallback"></formQuery>
			<!-- 按钮区域 -->
			<div class="rx-bc mb16">
				<el-button type="primary" @click="()=>{creatEvent()}">新增员工</el-button>
			</div>
			<!-- 数据表格 -->
			<div class="">
				<el-table :data="tableData.list" stripe style="width: 100%;min-height: 500px" @selection-change="selectionChange">
					<!-- <el-table-column type="selection" width="55" /> -->
					<el-table-column prop="logingName" label="登录账号" min-width="120" show-overflow-tooltip>
						<template #default="scope">
							<span class="color-409EFF c_p tool_btn" @click="creatEvent(scope.row.encryptionId)">{{scope.row.logingName}}</span>
						</template>
					</el-table-column>
					<el-table-column prop="realName" label="姓名" min-width="120"></el-table-column>
					<el-table-column prop="stallPhone" label="手机号码" min-width="120"></el-table-column>
					<el-table-column prop="roleName" label="岗位" min-width="120"/>
					<el-table-column prop="remark" label="备注" min-width="160" show-overflow-tooltip></el-table-column>
					<el-table-column label="操作" width="90">
						<template #default="scope">
							<a href="javascript:;" class="t_d_u v_a_m color-409EFF tool_btn"
								@click="stopEvnet(scope.row)">删除</a>
						</template>
					</el-table-column>
					<template #empty>
						<el-empty description="暂无数据"></el-empty>
					</template>
				</el-table>
				<div class="mt20 rx-ec" v-show="tableData.list.length > 0">
					<el-pagination v-model:currentPage="tableData.form.page" :page-sizes="[15,20,30,50,100]"
						:page-size="tableData.form.limit" layout="total, sizes, prev, pager, next, jumper"
						:total="tableData.count" @size-change="pageSizeChange" @current-change="()=>{getList()}">
					</el-pagination>
				</div>
			</div>
			<!-- 新增、编辑员工 -->
			<el-dialog v-model="createData.show" :title="createData.title" width="500px" custom-class="popwechat"
				:before-close="()=>{createData.show = false}" :append-to-body="true">
				<div class="pl20 pt20 pb20 pr60">
					<div class="rx-sc mb12">
						<span class="w110 t_a_r">登录账号：</span>
						<div class="flex1">
							<el-input v-model="createData.form.logingName" :disabled="createData.title == '修改员工'" type="text" placeholder="请输入"></el-input>
						</div>
					</div>
					<div class="rx-sc mb12">
						<span class="w110 t_a_r">员工姓名：</span>
						<div class="flex1">
							<el-input v-model="createData.form.realName" type="text" placeholder="请输入"></el-input>
						</div>
					</div>
					<div class="rx-sc mb12">
						<span class="w110 t_a_r">员工手机：</span>
						<div class="flex1">
							<el-input v-model="createData.form.stallPhone" maxlength="11" type="number"
								placeholder="请输入"></el-input>
						</div>
					</div>
					<div class="rx-sc mb12">
						<span class="w110 t_a_r">岗位：</span>
						<div class="flex1">
							<el-select v-model="createData.form.roleId" placeholder="请选择">
							    <el-option
							      v-for="item in queryOpts.fields[0].options"
							      :key="item.value"
							      :label="item.label"
							      :value="item.value">
							    </el-option>
							</el-select>
						</div>
					</div>
					<div class="rx-sc mb12" v-if="createData.form.encryptionId">
						<span class="w110 t_a_r">商家数据：</span>
						<div class="flex1">
							<el-radio v-model="createData.form.instData" :label="1">查看全部</el-radio>
							<el-radio v-model="createData.form.instData" :label="2">只看自己</el-radio>
							<el-radio v-model="createData.form.instData" :label="3">禁止查看</el-radio>
						</div>
					</div>
					<div class="rx-sc mb12" v-if="createData.form.encryptionId">
						<span class="w110 t_a_r">分店管理：</span>
						<div class="flex1">
							<el-radio v-model="createData.form.instList" :label="1">查看全部</el-radio>
							<el-radio v-model="createData.form.instList" :label="2">只看自己</el-radio>
							<el-radio v-model="createData.form.instList" :label="3">禁止查看</el-radio>
						</div>
					</div>
					<div class="rx-sc mb12" v-if="createData.form.encryptionId">
						<span class="w110 t_a_r">总店管理：</span>
						<div class="flex1">
							<el-radio v-model="createData.form.merchantList" :label="1">查看全部</el-radio>
							<el-radio v-model="createData.form.merchantList" :label="2">只看自己</el-radio>
							<el-radio v-model="createData.form.merchantList" :label="3">禁止查看</el-radio>
						</div>
					</div>
					<div class="rx-sc mb12" v-if="createData.form.encryptionId">
						<span class="w110 t_a_r">手机号码：</span>
						<div class="flex1">
							<el-radio v-model="createData.form.hideAccountTel" :label="1">脱敏</el-radio>
							<el-radio v-model="createData.form.hideAccountTel" :label="3">不脱敏</el-radio>
						</div>
					</div>
					<div class="rx-ss mb12">
						<span class="w110 t_a_r">备注：</span>
						<div class="flex1">
							<el-input v-model="createData.form.remark" :rows="5" maxlength="100" type="textarea"
								show-word-limit placeholder="请输入备注" />
						</div>
					</div>
				</div>
				<template #footer>
					<div class="dialog-footer">
						<el-button @click="createData.show = false">取消</el-button>
						<el-button type="primary" @click="createSave">确定</el-button>
					</div>
				</template>
			</el-dialog>
			
			<Confirm ref="confirmRef" :title="confirm.confirmTitle" :text="confirm.confirmText" @save="confirmSave" />
		</div>
</template>

<script setup>
	import { ref, reactive, onMounted } from 'vue';
	import { useRouter, onBeforeRouteLeave } from 'vue-router';
	import { ElMessage } from 'element-plus'
	import { ArrowDown, Close, Upload, ArrowUp } from '@element-plus/icons-vue'
	import utils from '@/common/utils.js'
	import { getAdminRoleList, getAllEmployeeAdmin, addAdminEmployee, getOneEmployeeAdmin, updateAdminEmployee, employeeStop } from '@/api/store.js';
	import formQuery from '@/components/branch-store/formQuery.vue';
	import Confirm from '@/components/Confirm.vue'
	const router = useRouter();
	

	const queryOpts = reactive({
		fields: [{
			type: 'select',
			fieldName: 'roleId',
			placehold: '全部岗位',
			value: '',
			options: []
		}]
	})
	//岗位
	getAdminRoleList({
		page: 1,
		limit: 9999
	}).then((res)=>{
		if(res.code == 0){
			var list = [{
					label: '全部岗位',
					value: ''
				}]
			res.data.map(n=>{
				list.push({
					label: n.roleName,
					value: n.id
				})
			})
			queryOpts.fields[0].options = list;
		}
	})

	var selectCheckData = [],
		confirmType = '',
		storeId = '';
	const confirm = reactive({
		confirmTitle:'',
		confirmText:'',
	}) 
	const confirmRef = ref();
	//新增分店
	const createData = reactive({
		form: {
			encryptionId:'',
			logingName: '',
			realName: '',
			stallPhone: '',
			roleId: '',
			remark: '',
			instData:'',
			instList:'',
			merchantList:'',
			hideAccountTel:'',
		},
		title: '',
		show: false,
	})

	function creatEvent(id) {
		for (var k in createData.form) {
			createData.form[k] = '';
		}
		createData.title = id ? '修改员工' :'新增员工';
		createData.show = true;
		if(id){
			getStallDetail(id);
		}
	}
	function getStallDetail(id){
		getOneEmployeeAdmin({
			encryptionId:id
		}).then((res) => {
			if (res.code == 0) {
				for (var k in createData.form) {
					createData.form[k] = res.data[k];
				}
				createData.form.roleId = Number(res.data.roleId);
			}
		})
	}
	// 新增、修改保存
	function createSave() {
		var {encryptionId} = createData.form;
		if (!createData.form.logingName) {
			ElMessage.warning('请输入登录账号')
			return
		}
		if (!createData.form.realName) {
			ElMessage.warning('请输入员工姓名')
			return
		}
		if (!createData.form.stallPhone) {
			ElMessage.warning('请输入手机号')
			return
		}
		if (!/^1[3456789]\d{9}$/.test(createData.form.stallPhone)) {
			ElMessage.warning('手机号不正确')
			return
		}
		if(encryptionId){
			updateAdminEmployee(createData.form).then((res) => {
				if (res.code == 0) {
					createData.show = false;
					ajaxSaveCallBack('修改成功');
				}
			})
		}else{
			addAdminEmployee(createData.form).then((res) => {
				if (res.code == 0) {
					createData.show = false;
					ajaxSaveCallBack('新增成功');
				}
			})
		}
	}
	const tableData = reactive({
		list: [],
		count: 0,
		form: {
			page: 1,
			limit: 15,
			roleId: '',
		}
	})
	//筛选表单回调
	function searchCallback(options) {
		for (var key in options) {
			tableData.form[key] = options[key];
		}
		searchTable();
	}
	//切换分页数量
	function pageSizeChange(limit) {
		tableData.form.limit = limit;
		searchTable();
	}

	function searchTable() {
		tableData.form.page = 1;
		getList();
	}
	getList();

	function getList(exportObj) {
		var formData = JSON.parse(JSON.stringify(tableData.form));
		getAllEmployeeAdmin(formData).then(function(res) {
			if (res.code == 0) {
				tableData.list = res.data || [];
				tableData.count = res.count;
			}
		});
	}


	//停用商户
	function stopEvnet(row){
		storeId = row.encryptionId;
		confirmType = 'stop';
		confirm.confirmTitle = '确定要删除 '+row.realName+' 吗？';
		confirmRef.value.show();
	}
	
	

	//通用确认框确认事件回调
	function confirmSave(){
		if(confirmType == 'stop'){
			employeeStop({
				encryptionId:storeId,
				status:0
			}).then((res)=>{
				if(res.code == 0){
					ajaxSaveCallBack('删除成功');
				}
			})
		}
	}

	//table通用勾选
	function selectionChange(data) {
		selectCheckData = data;
	}
	//获取table勾选集合的某个字段集合
	function getSelectCheckKey(key) {
		return selectCheckData.map(n => n[key]).join(',');
	}
	// 验证批量操作是否勾选了数据
	function CheckLengthCallBack(callback) {
		if (selectCheckData.length == 0) {
			ElMessage({
				type: 'warning',
				message: '请选择需要操作的会员',
			})
			return false;
		}
		callback && callback();
	}
	//ajax提交保存通用提示
	function ajaxSaveCallBack(text, callback) {
		ElMessage({
			type: 'success',
			message: text
		})
		searchTable();
		callback && callback();
	}
	
	// 页面缓存
	onBeforeRouteLeave((to, from) => {
		// if (to.path === '/branch/memberDetail') {
		//     store.commit('SetKeep',{keepLiveRoute : ['BranchMemberList']});
		// } else {
		//     store.commit('SetKeep',{keepLiveRoute : []});
		// }
	})
</script>
<style scoped lang="stylus">
	
</style>
