<template>
    <div class="follow_record mt16" v-loading="pageData.loading" element-loading-text="正在努力加载中。。。">
        <div class="ww100 ml40 rx-sc">
            <el-button type="primary" @click="oneFollowEvent">添加跟进</el-button>
        </div>
        <div class="follow_list mt30" v-if="pageData.followList && pageData.followList.length">
            <el-timeline class="ml150">
                <el-timeline-item
                    v-for="(activity, index) in pageData.followList"
                    :key="index"
                    :hollow="true"
                    type="success"
                >
                    <div class="follow_box t_a_c">
                        <el-avatar :size="60" :src="activity.followerHeadPhotoUrl" @error="errorHandler">
                            <img :src="pageData.circleUrl"/>
                        </el-avatar>
                        <p class="color-666">{{ activity.followerName }}</p>
                        <p class="color-999 mt5">{{ utils.getFormatDate(activity.followUpDate) }}</p>
                    </div>
                    <el-card :body-style="pageData.card_back" shadow="never">
                      <p v-if="activity.titel" class="color-333  mb10">{{ activity.titel }}</p>
                      <p v-if="activity.content" class="color-333 mb10">{{ activity.content }}</p>
                      <template v-if="activity.picUrlList.length">
                        <el-image class="mr10 v_a_t mb10" v-for="(item, index) in activity.picUrlList" :key="index" :src="item" fit="cover"></el-image>
                      </template>
                      <p class="color-666 mb10" v-if="activity.followUpRecordTypeName">跟进状态：{{ activity.followUpRecordTypeName }} </p>
                      <p class="color-666" v-if="activity.nextFollowUpDate">下次跟进时间： {{ utils.getFormatDate(activity.nextFollowUpDate) }}</p>
                    </el-card>
                </el-timeline-item>
                <el-timeline-item style="display: none"></el-timeline-item>
            </el-timeline>
        </div>
        <el-empty v-else description="暂无更进记录"></el-empty>
    </div>
    <AddFollowStep v-model="pageData.addFollowShow" :sid="propData.memberId+''" @close="addFollowClose" @save="addFollowSave"/>
</template>

<script setup>
    import { reactive, onMounted } from 'vue';
    import { useRouter } from 'vue-router';
    import { getFollowListStudent } from '@/api/intentionList.js';
    import utils from '@/common/utils.js'
    import AddFollowStep from '@/components/branch-store/member/AddFollowStep.vue';
    const propData = defineProps({ // props 父子通信
	    memberId: String
	  })
    const errorHandler = () => true // 开启图片加载失败时默认图片
    const router = useRouter();
    const { encryptionId } = router.currentRoute.value.query; // 获取url带的参数
    const pageData = reactive({
        loading: false,
        addFollowShow: false, // 跟进弹框显隐
        circleUrl: 'https://cube.elemecdn.com/3/7c/3ea6beec64369c2642b92c6726f1epng.png', // 头像
        followList: [], // 跟进记录列表
        card_back : {
          background: '#EDF6FF',
          padding: '11px 16px 10px',
        }
    })
    // 获取跟进记录列表
    function getFollowList() {
        let params = {
            encryptionStudentId: encryptionId,
            page: 1,
            limit:20
        }
        pageData.loading = true
        getFollowListStudent(params).then((res) => {
            pageData.loading = false
            if(res.code == 0) {
                pageData.followList = res.data;
            }
        }).catch(() => {
            pagaData.loading = false
        })
    }
    // 拉起添加跟进弹框
    function oneFollowEvent(){
	  	pageData.addFollowShow = true;
	  }
    // 确定添加跟进并刷新跟进记录列表
	  function addFollowSave(res){
	  	pageData.addFollowShow = false;
	  	getFollowList();
	  }
    // 取消添加跟进弹框
	  function addFollowClose(){
	  	pageData.addFollowShow = false;
	  }
    // mounted 钩子函数
    onMounted(()=> {
        getFollowList();
    })
</script>

<style scoped type="text/scss">
    .follow_box {
      width: 77px;
      margin-top: 8px;
      position: absolute;
      left: -110px;
      top: -5px;
      font-size: 14px;
    }
    .el-timeline-item {
      padding-bottom: 40px;
      min-height: 150px;
    }
    .el-image {
      width: 72px; 
      height: 72px;
    }
    .el-card {
        border: 0
    }
</style>