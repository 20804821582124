<template>
    <div>
        <div id="myChart1" class="h250 mt20"></div>
    </div>
</template>

<script setup>
    import { reactive, onMounted, onBeforeUnmount, watch } from 'vue';
    import { weightList } from '@/api/market.js';
    import * as echarts from 'echarts';
    let myChart1 = null
	const propData = defineProps({
		info: Object,
		show: Boolean,
		value: Number
	})
    const pageData = reactive({
        option: {
            title: {
                text: '7-18岁+体重对照表',
                textStyle:{ fontSize:12}
            },
		  	tooltip: {
		  	  	trigger: 'axis',
                axisPointer: { type: 'cross', show: true },
		  	},
            color: ['none', 'none', 'none', '#9723CA'],
		  	grid: {
		  	  	left: '3%',
		  	  	right: '4%',
		  	  	bottom: '3%',
		  	  	containLabel: true
		  	},
		  	xAxis: [{
		  	  	type: 'category',
		  	  	data: [],
				boundaryGap: false,
				splitLine:{
         		  show: true, //让网格显示
         		  lineStyle: {//网格样式
         		    color:'rgba(0, 0, 0, 0.15)', //网格的颜色
         		    width:1, //网格的宽度
         		    type:'solid' //网格是实实线，可以修改成虚线以及其他的类型
         		  }
         		}
		  	}],
		  	yAxis: [{
		        type : 'value',
                scale : true,
                max : 100,
                min : 10,
                splitNumber : 8,
                boundaryGap : [ 0.2, 0.2 ]
            }],
		  	series: [{
		      	name: '偏瘦',
		      	type: 'line',
				data: [],
		      	symbol: 'none',
		      	itemStyle: {
				  	normal: {
				  	  	lineStyle: {
				  	  	  	color: "#999", //改变折线颜色
				  	  	},
				  	},
				},
				endLabel: {
      			  show: true,
      			  formatter: function (params) {
      			    return '偏瘦';
      			  },
				  offset: [-30, 10]
      			},
		    }, {
		      	name: '标准',
		      	type: 'line',
		      	data: [],
		      	symbol: 'none',
		      	itemStyle: {
				  	normal: {
				  	  	lineStyle: {
				  	  	  	color: "#9723CA", //改变折线颜色
				  	  	},
				  	},
				},
				endLabel: {
      			  show: true,
      			  formatter: function (params) {
      			    return '标准';
      			  },
				  offset: [-30, 10]
      			},
		    }, {
		      	name: '超重',
		      	type: 'line',
		      	data: [],
		      	symbol: 'none',
		      	itemStyle: {
				  	normal: {
				  	  	lineStyle: {
				  	  	  	color: "#666", //改变折线颜色
				  	  	},
				  	},
				},
				endLabel: {
      			  show: true,
      			  formatter: function (params) {
      			    return '超重';
      			  },
				  offset: [-30, 10]
      			},
		    }, {
                type:'scatter',
                data:[]
            }]
		}
    })
	watch(() => propData.show, (newVal, oldVal) => {
        if (newVal) {
            getInfo()
        }
    })
    /**
     * 获取身高曲线
     */
    function getInfo(){
        let params = {
            sex: propData.info.sex,
            ageMin: propData.info.ageMin,
            ageMax: propData.info.ageMax
        }
        weightList(params).then((res) => {
            if (res.code == 0) {
				myChart1 = echarts.init(document.getElementById('myChart1'))
				if (propData.info.sex == 1) {
					pageData.option.yAxis[0].max= 80
				}
				if (propData.info.ageMax == 6 ) {
					pageData.option.title.text = '1-6岁体重对照表'
					pageData.option.yAxis[0].max= 40
					pageData.option.yAxis[0].min= 0
				}
				pageData.option.xAxis[0].data = [] // x轴
				pageData.option.series[0].data = [] // 潜在会员
				pageData.option.series[1].data = [] // 正式会员
                let arr = [], index =''
				res.data.forEach((item, i) => {
					pageData.option.xAxis[0].data.push(item.age)
					pageData.option.series[0].data.push(item.data1)
                    pageData.option.series[1].data.push(item.data2)
                    arr.push(item.data3)
					if (item.age == propData.info.age) {
						index = i
					}
				})
                pageData.option.series[2].data = arr
                pageData.option.yAxis.max = Math.max(...arr)
                pageData.option.series[3].data = [[index, propData.value]]
				myChart1.clear()
				myChart1.setOption(pageData.option, true);
				window.addEventListener("resize",function(){
        		    myChart1.resize();
        		});
			}
        })
    }
    onMounted(() => {
        getInfo()
    })
    onBeforeUnmount(() => {
      if (!myChart1) {
        return
      }
      myChart1.dispose()
      myChart1 = null
    })
</script>
<style type='css/style' scoped>

</style>