import {postAjax,getAjax}  from "../common/axios.js"

// 查询门店会员列表 用于下拉
export const selectStoreMembersSimple = params => {
    return postAjax('/api/member/selectStoreMembersSimple',params);
}
// 正式会员列表
export const getMemberList = params => {
    return postAjax('/api/member/selectStoreMembers',params);
}

// 班课批量预约
export const storeBookBatch = params => {
    return postAjax('/api/member/appointment/storeBookBatch',params, 'json');
}

// 会员详情接口
export const getMemberDetail = params => {
    return postAjax('/api/member/selectOneMember',params);
}
// 会员签单-销售员列表
export const getSysUserList = params => {
    return postAjax('/api/associator/getSysUserList',params);
}
// 会员签单-商品-卡列表
export const listSimple = params => {
    return postAjax('/api/run/product/listSimple',params);
}
// 会员签单-商品-课程列表
export const listCourseSimple = params => {
    return postAjax('/api/run/product/listCourseSimple',params);
}
// 会员签单-签单买卡保存
export const productBuy = params => {
    return postAjax('/api/order/product/buy',params);
}
// 会员签单-签单买课保存
export const productBuyCourse = params => {
    return postAjax('/api/order/product/buyCourse',params);
}
// 正式会员导出
export const selectStudents = params => {
    return postAjax('/api/excel/selectStoreMembers/export',params);
}
// 获取有无可用消费的储值卡
export const listStoreCard4Order = params => {
    return postAjax('/api/run/product/listStoreCard4Order',params);
}
// 会员已购卡项
export const queryBuyCardList = params => {
    return postAjax('/api/order/product/queryBuyCardList',params);
}
// 会员已购课程
export const queryBuyCourseList = params => {
    return postAjax('/api/order/product/queryBuyCourseList',params);
}
// 会员详情获取商品可消费门店
export const queryConsumeSid = params => {
    return postAjax('/api/run/product/queryConsumeSid',params);
}
// 会员详情累计购买、累计消费、卡、课数量
export const queryMemberOrderSummary = params => {
    return postAjax('/api/order/product/queryMemberOrderSummary',params);
}
// 会员退款商品详情
export const info4Ref = params => {
    return postAjax('/api/order/product/info4Ref',params);
}
// 会员退款发起
export const refund = params => {
    return postAjax('/api/order/product/refund',params);
}
// 正式会员-会员课程
export const queryAllBuyCourseList = params => {
    return postAjax('/api/order/product/queryAllBuyCourseList',params);
}
// 正式会员-会员卡项
export const queryAllBuyCardList = params => {
    return postAjax('/api/order/product/queryAllBuyCardList',params);
}
// 正式会员-冻结
export const frozen = params => {
    return postAjax('/api/orderFrozen/frozen',params);
}
// 正式会员-解冻
export const unFrozen = params => {
    return postAjax('/api/orderFrozen/unFrozen',params);
}
// 正式会员-自动冻结
export const autoFrozen = params => {
    return postAjax('/api/orderFrozen/autoFrozen',params);
}
// 正式会员-会员卡延期
export const delay = params => {
    return postAjax('/api/order/product/delay',params);
}
// 会员详情-查询所属门店列表
export const selectOneMemberStore = params => {
    return postAjax('/api/member/selectOneMemberStore',params);
}

// 签单购买班级
export const buyCourseCycle = params => {
    return postAjax('/api/order/product/buyCourseCycle',params);
}

// 我的班级
export const myCourseCycleList = params => {
    return postAjax('/api/order/product/getMyCourseCycleList',params);
}

// 续课
export const buyCourseCycleAdd = params => {
    return postAjax('/api/order/product/buyCourseCycleAdd',params);
}
