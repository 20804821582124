<template>
    <div class="mt20">
        <div class="order_nav mb20">
            <span class="d_i_b c_p" :class="pageData.activeName == item.val ? 'active': '' " v-for="(item, index) in pageData.tabeList" :key="index" @click="getContentList(item.val)">{{ item.label }}</span>
        </div>
        <div class="pt20 pb20 order_box o_y_s rx-wsc">
            <template v-if="pageData.templateLsit.length">
                <el-card class="box-card posi-r" v-for="(item, index) in pageData.templateLsit" :key="index">
                    <div class="h485 ww100 posi-r c_p box-img">
                        <el-image class="hh100 ww100" :src="item.backPic" />
                        <div class="posi-a img">
                            <img class="ww100 hh100" v-if="item.templateType == 1" src="@/assets/dance.png" alt="">
                            <img class="ww100 hh100" v-if="item.templateType == 2" src="@/assets/sports.png" alt="">
                            <img class="ww100 hh100" v-if="item.templateType == 3" src="@/assets/basketball.png" alt="">
                            <img class="ww100 hh100" v-if="item.templateType == 4" src="@/assets/bodybuilding.png" alt="">
                            <div class="mt20 color-fff img-title">
                                <div :class="item.title.length > 12 ? 'ft20' : 'ft24' ">{{ item.title }}</div>
                            </div>
                        </div>
                        <div class="posi-a title">
                            <div class="ft18 fwb mb12 color-fff">姓名</div>
                            <div class="color-fff">测评时间：xxxx-xx-xx</div>
                            <div class="color-fff">测评老师：xxxx</div>
                        </div>
                        <div class="hh100 ww100 mask posi-r" v-if="pageData.activeName == 0 && item.state == 1">
                            <div class="buttonList posi-a">
                               <div class="mb40"><el-button type="primary" @click="goTemplate(item, 0)">立即使用</el-button></div>
                               <div><el-button plain @click="modify(item)">预览模板</el-button></div>
                            </div>
                        </div>
                        <div class="hh100 ww100 mask posi-r" v-else>
                            <div class="buttonList posi-a">
                               <div class="mb30"><el-button type="primary" @click="goTemplate(item, 1)">编辑</el-button></div>
                               <div class="mb30"><el-button plain @click="onOff(item)">{{ item.state == 0 ? '启用' : '停用' }}</el-button></div>
                               <div><el-button type="danger" plain @click="remove(item)">删除</el-button></div>
                            </div>
                        </div>
                    </div>
                    <div class="posi-a tip bg-7F909A color-fff" v-if="item.state == 0">已停用</div>
                    <div class="mt25 fwb color-333 ft18">{{ item.templateName }}</div>
                </el-card>
            </template>
            <template v-else>
                <div class="ww100">
                    <el-empty description="暂无数据" :image-size="300" />
                </div>
            </template>
        </div>
    </div>
    <Preview :show="pageData.show" :type="2" :data="pageData.templateId" @unPreview="unPreview"></Preview>
</template>

<script setup>
    import { reactive, onMounted, onBeforeUnmount } from 'vue';
    import { ElMessage } from 'element-plus'
    import { useRouter } from 'vue-router'
    import Preview from '@/components/branch-store/preview.vue';
    import { assessTemplateLsit, updateState, updateDel } from '@/api/market.js';
	const router = useRouter();
    const pageData = reactive({
        activeName: 0,
        tabeList: [{
            label: '通用模板',
            val: 0
        }, {
            label: '我的模板',
            val: 1
        }],
        templateLsit: [],
        templateId: '',
        show: false,
    })
    /**
     * 关闭预览
     */
    function unPreview() {
        pageData.show = false
    }
    /**
     * 查看
     */
    function modify(row) {
        pageData.templateId = row.encryptionId
        pageData.show = true
    }
    /**
     * 切换tab
     */
    function getContentList(val) { // 切换tab (数据更换)
        pageData.activeName = val
        localStorage.setItem('assessTemplate', val)
        getList(val)
    }
    /**
     * 获取模板列表
     */
    function getList(val) {
        assessTemplateLsit({ myTemplate: val }).then(res => {
            if (res.code == 0) {
                pageData.templateLsit = res.data
            }
        })
    }
    /**
     * @param (Object) row-我的模板数据
     * 启用开关
     */
    function onOff(row) {
        let params = {
            encryptionId: row.encryptionId,
            state: row.state == 1 ? 0 : 1
        }
        updateState(params).then(res => {
            if (res.code == 0) {
                let text = params.state == 0 ? '停用成功' : '启用成功'
                ElMessage.success(text)
                getList(1)
            }
        })
    }
    /**
     * @param (Object) row-我的模板数据
     * 删除模板
     */
    function remove(row) {
        let params = {
            encryptionId: row.encryptionId
        }
        updateDel(params).then(res => {
            if (res.code ==0) {
                ElMessage.success('删除成功')
                getList(1)
            }
        })
    }
    /**
     * @param (Object, Number) row-模板数据, type 0-立即使用 1-编辑
     * 立即使用或者是编辑模板
     */
    function goTemplate(row, type) {
        router.push('/branch/template?encryptionId='+ row.encryptionId +'&type='+ type)
    }
	onMounted(()=> {
        if (localStorage.getItem('assessTemplate') != null) {
            pageData.activeName = localStorage.getItem('assessTemplate')
	    }
        getList(pageData.activeName)
	})
    onBeforeUnmount(() => {
		localStorage.removeItem('assessTemplate')
	})
</script>
<style type='css/style' scoped>
.order_nav, .order_box {
    background: #F2F2F2
}
.order_nav span{
    padding: 10px 20px 11px 18px;
    color: #666;
}
.order_nav .active {
    background: #03A3FF;
    color: #FFF;
    border-radius: 2px;
}
.order_box{
    height: 72.5vh;
}
.box-card {
    width: 372px;
    height: 602px;
    margin: 20px;
    margin-top: 0;
}
.mask {
    position: absolute;
    top:0;
    left: 0;
    background-color: rgba(0,0,0,.5);
    display: none;
}
.box-img:hover .mask{
    display: block;
}
.buttonList {
	top: 50%;
	left: 50%;
    transform: translate(-50%, -50%);	
}
.tip{
    top: 0;
    right: 0;
    padding: 6px 12px 7px 24px;
    border-radius: 0px 4px 0px 100px;
}
.img {
    left: 20px;
    top: 130px;
}
.img img {
    width: 79px;
    height: 42px;
}
.img-title div {
    padding-bottom:2px;
    border-bottom:1px solid #fff;
}
.img-title {
    padding-bottom:2px;
    border-bottom:1px solid #fff;
}
.title {
    left: 20px;
    bottom: 66px;
}
</style>