<template>
	<div class="pt20">
		<div class="rx-bc bg-f2f2f2 bdr2 mb20">
			<div class="rx-sc">
				<div class="pl20 pr20 pt10 pb10 c_p" :class="tab == 0 ? 'bg-03A3FF color-fff bdr2' : 'color-666'" @click="tabChange(0)">展厅模板</div>
				<div class="pl20 pr20 pt10 pb10 c_p" :class="tab == 1 ? 'bg-03A3FF color-fff bdr2' : 'color-666'" @click="tabChange(1)">机构展厅</div>
				<div class="pl20 pr20 pt10 pb10 c_p" :class="tab == 2 ? 'bg-03A3FF color-fff bdr2' : 'color-666'" @click="tabChange(2)">会员专属展厅</div>
			</div>
			<div v-show="tab != 0">
				<el-input
				        v-model="searchForm.name"
				        class="w210"
				        placeholder="输入展厅名称"
				        :prefix-icon="Search"
						@input="searchTable"
				      />
			</div>
			<div class="pr20" v-show="tab == 0">
				<el-popover placement="bottom" :width="300" trigger="click">
				  <template #reference>
					  <div class="color-409EFF c_p" id="">进入展厅小程序</div>
				  </template>
				  <div id="appLogin" class="pl10"></div>
				</el-popover>
			</div>
		</div>
		<div class="rx-wss pt25 pb25 pl25 pr25 bg-F5F5F5" v-show="tab == 0" style="min-height: 500px;">
			 <div class="w370 bg-fff pt25 pb25 pl25 pr25 mr20 mb20 posi-r" v-for="(item,index) in tempData.list">
				 <div class="posi-r mb15 tempBox c_p">
					<img class="ww100" :src="item.hallCover" alt="">
					<div class="tempItem posi-a left0 top0 right0 bottom0" style="background-color: rgba(0, 0, 0, 0.5);">
						<div class="posi-c rx-cc ww100">
							<div class="pl10 pr10 pt5 pb5 color-fff bg-03A3FF ft16 bdr2 c_p mr15" @click="toUse(item)" v-if="item.hasBuy == 1">立即使用</div> 
							<el-dropdown @command="previewEvent">
								<div class="pl10 pr10 pt5 pb5 bg-fff bg-03A3FF ft16 bdr2 c_p">展厅预览</div>
								<template #dropdown>
								  <el-dropdown-menu>
									<el-dropdown-item :command="item.encryptionId+'-1'">PC端预览</el-dropdown-item>
									<el-dropdown-item :command="item.encryptionId+'-2'">小程序预览</el-dropdown-item>
								  </el-dropdown-menu>
								</template>
							  </el-dropdown>
						</div>
					</div>
				 </div>
				<div class="rx-bc">
					<span class="fwb" v-if="item.hasBuy == 1">剩余打开次数：{{item.openNumRemain}}次</span>
					<el-button type="primary" @click="rechargeEvent(item)" v-if="item.hasBuy == 1">充值次数</el-button>
					<span class="color-666" v-if="item.hasBuy == 0">咨询客服可开通</span>
					<el-button class="btnBlue" @click="serviceEvent" v-if="item.hasBuy == 0">咨询开通</el-button>
				</div>
				<div v-if="item.hasBuy == 1" class="bg-FD8923 color-fff ft16 pl20 pt5 pb5 pr10 posi-a right0 top0" style="border-radius: 0 0 0 100px;">已开通</div>
				<div v-if="item.hasBuy == 0" class="bg-7F909A color-fff ft16 pl20 pt5 pb6 pr10 posi-a right0 top0" style="border-radius: 0 0 0 100px;">未开通</div>
			 </div>
		</div>
		<div v-show="tab != 0">
			<div class="rx-wsc mb16">
				<el-button class="mr12" type="primary" @click="toDetail('','',tab)">新增{{tab == 1 ?'机构展厅':'会员专属展厅'}}</el-button>
			</div>
			<div>
				<el-table ref="table" :data="tableData.list" stripe style="width: 100%" height="65vh">
					<el-table-column fixed label="展厅名称" min-width="110">
						<template #default="scope">
							<span @click="toDetail(scope.row.encryptionId,scope.row.tid,tab)" class="color-409EFF c_p tool_btn">{{scope.row.name }}</span>
						</template>
					</el-table-column>
					<el-table-column fixed label="展厅封面" min-width="200">
						<template #default="scope">
							<img class="w160 bdr4" style="box-shadow: 0px 4px 9px 0px #C4BFBF;" :src="scope.row.hallCover" alt="">
						</template>
					</el-table-column>
					<el-table-column prop="realName" label="所属会员" min-width="160" v-if="tab == 2"/>
					<el-table-column label="展示权限" min-width="150">
						<template #default="scope">
							<div v-if="scope.row.showIndex == 1">放入机构主页</div>
							<div v-if="scope.row.showPlatform == 1">放入平台展厅推荐区</div>
						</template>
					</el-table-column>
					<el-table-column prop="picNum" label="放置图片数" min-width="110" />
					<el-table-column label="有效期" min-width="150">
						<template #default="scope">
							{{scope.row.startTime ? scope.row.startTime+'至'+scope.row.endTime :'永久有效' }}
						</template>
					</el-table-column>
					<el-table-column label="浏览数据" width="150">
						<template #default="scope">
							<div class="rx-ss">
								<div class="mr10">
									<div class="rx-sc mb10">
										<img class="w20 mr5" src="@/assets/show1.png" alt="">
										<span>{{scope.row.openCount|| 0 }}</span>
									</div>
									<div class="rx-sc">
										<img class="w20 mr5" src="@/assets/show3.png" alt="">
										<span>{{scope.row.shareCount || 0 }}</span>
									</div>
								</div>
								<div>
									<div class="rx-sc mb10">
										<img class="w20 mr5" src="@/assets/show2.png" alt="">
										<span>{{scope.row.likeCount || 0 }}</span>
									</div>
									<div class="rx-sc">
										<img class="w20 mr5" src="@/assets/show4.png" alt="">
										<span>{{scope.row.collectCount || 0 }}</span>
									</div>
								</div>
							</div>
						</template>
					</el-table-column>
					<el-table-column prop="createTime" label="创建时间" min-width="120" />
					<el-table-column label="操作" width="230" fixed="right">
						<template #default="scope">
							<a href="javascript:;" class="t_d_u color-409EFF tool_btn" @click="lookEvent(scope.row.encryptionId)">预览</a>
							<a href="javascript:;" class="t_d_u color-409EFF tool_btn" @click="shareEvent(scope.row.encryptionId)">分享</a>
							<a href="javascript:;" class="t_d_u color-red tool_btn" @click="deleEvent(scope.row)">删除</a>
						</template>
					</el-table-column>
				</el-table>
				<div class="mt20 rx-ec">
					<el-pagination v-model:currentPage="searchForm.page" :page-sizes="[15,20,30,50,100]"
						:page-size="searchForm.limit" layout="total, sizes, prev, pager, next, jumper"
						:total="tableData.count" @size-change="pageSizeChange" @current-change="()=>{getList()}">
					</el-pagination>
				</div>
			</div>
		</div>
		<!-- 分享展厅 -->
		<el-dialog v-model="shareData.show" title="分享展厅" width="500px" :before-close="()=>{shareData.show = false}"
			custom-class="popwechat">
			<div class="pl100 pr100 pb20 pt20" style="max-height: 600px;overflow: auto;">
				<div class="pt30 pb40 pl30 pr30 posi-r bdr8 o_h" ref="shareBox" style="border:1px solid #D2D2DA;">
					<img class="posi-a ww100 hh100 left0 top0 " style="z-index:-1" src="@/assets/shareBg.png" alt="">
					<div class="rx-sc mb20">
						<img class="w45 h45 mr15 bdr4" :src="userInfo.logoUrl" alt="">
						<span class="fwb ft18">{{userInfo.storeName}}</span>
					</div>
					<img class="ww100" :src="shareData.form.hallCover" alt="">
					<div>
						<img class="w25" src="@/assets/mark1.png" alt="">
					</div>
					<div class="fwb ft18">{{shareData.form.name}}</div>
					<div class="rx-ec mb20">
						<img class="w25" src="@/assets/mark2.png" alt="">
					</div>
					<div class="bdr100 pt5 pb5 t_a_c bg-FEEAC6 mb20 color-D48712">参展作品&nbsp;&nbsp;&nbsp;{{shareData.form.picNum}}</div>
					<div class="bdr4 pt15 pb15 pl35 pr35 bg-fff rx-bc">
						<div>
							<div class="mb10">长按识别二维码</div>
							<div class="rx-sc">
								<span>查看作品</span>
								<img class="w15 ml10" src="@/assets/zw.png" alt="">
							</div>
						</div>
						<div id="qrcode" class="w60 h60" v-loading="shareData.codeLoading"></div>
						<!-- <img class="w60" src="@/assets/ewm.png" alt=""> -->
					</div>
				</div>
			</div>
			<template #footer>
				<div class="dialog-footer">
					<el-button @click="shareData.show = false">取消</el-button>
					<el-button type="primary" @click="shareSave">下载</el-button>
				</div>
			</template>
		</el-dialog>
		<!-- 充值次数 -->
		<el-dialog v-model="rechargeData.show" title="充值次数" width="500px" :before-close="()=>{rechargeData.show = false}"
			custom-class="popwechat">
			<div class="pl80 pr80 pb20 pt20" style="max-height: 500px;overflow: auto;">
				<template v-for="item in rechargeData.list" :key="index">
					<div class="box_container" @click="rechargeData.rId = item.encryptionId">
						<div class="container_card" :class="[item.encryptionId == rechargeData.rId  ? 'container_active' : '']">
							<div class="rx-cc mb10">
								<div class="bdr100" :style="{width:'24px',height:'4px',backgroundColor:(item.ifDefault == 1 ?'#FFFFFF':'#FFDDDE') }"></div>
							</div>
							<div class="price t_a_c">{{ item.actualMoney }}元</div>
							<div class="mt5 t_a_c">
								<span class="obtain ft14">得{{ item.openNumBuy+'次+'+ (item.openNumGive||0) }}次</span>
							</div>
						</div>
					</div>
				</template>
			</div>
			<template #footer>
				<div class="dialog-footer">
					<el-button @click="rechargeData.show = false">取消</el-button>
					<el-button type="primary" @click="rechargeSave">确定</el-button>
				</div>
			</template>
		</el-dialog>
		<!-- 咨询客服 -->
		<el-dialog v-model="serviceData.show" title="咨询客服" width="370px" :before-close="()=>{serviceData.show = false}"
			custom-class="popwechat">
			<div class="pl60 pr60 pb55 pt55">
				<div class="pl25 pr25 pt25 pb25" style="box-shadow: 0px 0px 6px 0px rgba(179,179,179,0.5);">
					<div class="pl10 pr10 pt10 pb10 mb25" style="border: 1px solid #D6D6D6;">
						<img class="ww100" :src="serviceData.url" alt="">
					</div>
					<div class="color-333 rx-cc">微信扫码联系运营老师</div>
				</div>
			</div>
			<template #footer>
				<div class="dialog-footer">
					<el-button type="primary" @click="serviceData.show = false">关闭</el-button>
				</div>
			</template>
		</el-dialog>
		<!-- 选择展厅类型 -->
		<el-dialog v-model="useData.show" title="选择创建的展厅类型" width="780px" :before-close="()=>{useData.show = false}"
			custom-class="popwechat">
			<div class="pl40 pr40 pb60 pt60 rx-ss">
				<div class='flex1 mr50 c_p' @click="useData.type = 1">
					<div class="mb20" style="box-shadow: 0px 0px 6px 0px rgba(179,179,179,0.5);">
						<img class="ww100" src="@/assets/hallType1.png" alt="">
						<div class="pt25 pb25 pl10 pr10">机构展厅：适用于机构通过好的作品做招生引流，可以集成机构整体的作品展览厅</div>
					</div>
					<div class="rx-cc" v-show="useData.type == 1">
						<img class="w15 mr10" src="@/assets/select.png" alt="">
						<span class="color-333">已选择</span>
					</div>
				</div>
				<div class='flex1 c_p' @click="useData.type = 2">
					<div class="mb20" style="box-shadow: 0px 0px 6px 0px rgba(179,179,179,0.5);">
						<img class="ww100" src="@/assets/hallType2.png" alt="">
						<div class="pt25 pb25 pl10 pr10">会员专属展厅：适用于机构端更好的服务会员，给会员生成专属的个人作品展览厅</div>
					</div>
					<div class="rx-cc" v-show="useData.type == 2">
						<img class="w15 mr10" src="@/assets/select.png" alt="">
						<span class="color-333">已选择</span>
					</div>
				</div>
			</div>
			<template #footer>
				<div class="dialog-footer">
					<el-button @click="useData.show = false">关闭</el-button>
					<el-button type="primary" @click="toDetail('',useData.tid,useData.type)">确定</el-button>
				</div>
			</template>
		</el-dialog>
		<!-- 小程序预览码 -->
		<el-dialog v-model="appletData.show" title="小程序预览码" width="300px" :before-close="()=>{appletData.show = false}"
			custom-class="popwechat">
			<div class="pl20 pr20 pb20 pt20" v-loading="appletData.loading">
				<div id="appEwm"></div>
			</div>
		</el-dialog>
		<Confirm ref="confirm" :title="confirmTitle" @save="confirmSave"></Confirm>
	</div>
</template>

<script setup>
	import {
		ref,
		reactive,
		nextTick,
		onMounted,
		onUnmounted
	} from 'vue';
	import { useRouter } from 'vue-router';
	import {
		Search
	} from '@element-plus/icons-vue'
	import {
		ElMessage
	} from 'element-plus'
	import QRCode from 'qrcodejs2'
	import html2canvas from 'html2canvas'
	import Confirm from '@/components/Confirm.vue';
	import CommonUpload from '@/components/CommonUpload.vue';
	import {getHallTemplateList,getHallContentInfo,toPay,getHallRechargeModelList,getKeFuUrl,getHallContentList,updateDelHallContent} from '@/api/showroom.js'
	import utils from '@/common/utils.js';
	import {getLocalStorage} from '@/common/common.js';
	const router = useRouter();
	const userInfo = getLocalStorage('businessUserData');
	const tabIndex = getLocalStorage('tabIndex');
	const confirm = ref(),
		confirmTitle = ref('');
	const tab = ref(0);
	tabChange(tabIndex ? tabIndex : 0);
	function tabChange(val){
		tab.value = val;
		if(val == 0){
			getTemplate();
		}else{
			searchTable();
			
		}
	}
	
	const tempData = reactive({
		list:[]
	})
	function getTemplate(){
		getHallTemplateList({
			page:1,
			limit:9999
		}).then((res)=>{
			if(res.data){
				tempData.list = res.data.filter(n=>n.state == 1);
			}
		})
	}
	const searchForm = reactive({
		name:'',
		page: 1,
		limit: 15
	})
	const tableData = reactive({
		list: [],
		count: 0
	})

	//搜索table
	var timer = '';
	function searchTable() {
		clearTimeout(timer);
		timer = setTimeout(()=>{
			searchForm.page = 1;
			getList();
		},500);
	}
	//切换分页数量
	function pageSizeChange(limit) {
		searchForm.limit = limit;
		searchTable();
	}
	function getList() {
		searchForm.hallType = tab.value;
		getHallContentList(searchForm).then((res)=>{
			tableData.list = res.data;
			tableData.count = res.count;
		})
	}
	
	
	function toDetail(encryptionId,tid,hallType){
		router.push({
			path:'/branch/showroomDetail',
			query:{
				encryptionId,tid,hallType
			}
		})
	}
	
	
	const useData = reactive({
		show:false,
		tid:0,
		type:1
	})
	//立即使用
	function toUse(row){
		useData.show = true;
		useData.tid = row.id;
	}
	
	var confirmRow = '';
	//删除
	function deleEvent(row) {
		confirmRow = row;
		confirmTitle.value = '确定删除【' + row.name + '】吗?';
		confirm.value.show();
	}
	/**
	 * 弹框确认
	 */
	function confirmSave() {
		updateDelHallContent({
			encryptionId: confirmRow.encryptionId
		}).then((res) => {
			ajaxSaveCallBack('删除成功');
		})
	}
	const appletData = reactive({
		show:false,
		loading:false
	})
	//预览
	function previewEvent(val){
		var arr = val.split('-');
		if(arr[1] == 1){ //pc预览
			lookEvent(arr[0]);
		}else{ //小程序预览
			appletData.show = true;
			appletData.loading = true;
			setTimeout(()=>{
				new QRCode('appEwm', {
				  width: 260,
				  height: 260, // 高度
				  text: location.origin+ '/share?sId'+userInfo.sid+'&id='+arr[0],
				  background: '#fff'
				})
				appletData.loading = false;
			},500)
		}
	}
	function lookEvent(id){
		var token = localStorage.getItem('businessToken');
		window.open('https://exhibition-work-test.yaoyoumeng.com/index.html?hallUID='+id+'&access_token='+token);
	}
	
	//分享
	const shareData = reactive({
		codeLoading:false,
		show: false,
		form: {}
	})
	function shareEvent(id) {
		getHallContentInfo({
			encryptionId:id
		}).then(res=>{
			shareData.form = res.data;
			shareData.show = true;
			shareData.codeLoading = true;
			setTimeout(()=>{
				new QRCode('qrcode', {
				  width: 60,
				  height: 60, // 高度
				  text: location.origin+ '/share?sId'+userInfo.sid+'&id='+id,
				  background: '#fff'
				})
				shareData.codeLoading = false;
			},500)
		})
	}
	const shareBox = ref();
	function shareSave(){
		nextTick(()=>{
			html2canvas(shareBox.value).then(canvas => {
				const dataURL = canvas.toDataURL('image/png');
				const createDom = document.createElement('a');
				document.body.appendChild(createDom);
				createDom.href = dataURL;
				createDom.download = shareData.form.name;
				createDom.click();
				document.body.removeChild(createDom);
			});
		})
	}
	//咨询客服
	const serviceData = reactive({
		show: false,
		url:''
	})
	function serviceEvent() {
		getKeFuUrl().then((res) => {
			serviceData.url = res.data.url;
			serviceData.show = true;
		})
	}
	
	//充值次数
	const rechargeData = reactive({
		show: false,
		list:[],
		tId: '',
		rId:''
	})
	function rechargeEvent(row) {
		rechargeData.tId = row.id;
		getHallRechargeModelList({
			page: 1,
			limit: 9999
		}).then((res) => {
			rechargeData.list = res.data;
			var item = res.data.filter(n=>n.ifDefault);
			if(item.length > 0){
				rechargeData.rId = item[0].encryptionId;
			}else{
				rechargeData.rId = '';
			}
			rechargeData.show = true;
		})
	}
	function rechargeSave(){
		toPay({
			encryptionId: rechargeData.rId,
			tid: rechargeData.tId
		}).then((res) => {
			var newWindow=window.open('','','');
			newWindow.document.write(res.data);
		})
	}
	
	//ajax提交保存通用提示
	function ajaxSaveCallBack(text, callback) {
		ElMessage({
			type: 'success',
			message: text
		})
		searchTable();
		callback && callback();
	}
	onMounted(()=>{
		new QRCode('appLogin', {
		  width: 260,
		  height: 260, // 高度
		  text: location.origin+ '/login',
		  background: '#fff'
		})
	})
	onUnmounted(()=>{
		localStorage.setItem('tabIndex','');
	})
</script>
<style lang='stylus' scoped>
	.tempItem{
		display:none;
	}
	.tempBox:hover .tempItem{
		display:block !important;
	}
	.uploadBox {
		border: 1px dashed #D6D6D6;
		height: 90px;
		width: 160px;
		border-radius: 2px;
		color: #999999;
		cursor: pointer;
	}

	.uploadBox:hover {
		border: 1px dashed #1989FA;
		color: #1989FA;
	}

	.uploadBox .upload2 {
		display: none;
	}

	.uploadBox:hover .upload2 {
		display: block;
	}

	.uploadBox:hover .upload1 {
		display: none;
	}

	.imgBox {
		position: relative;
		width: 160px;
		height: 90px;
	}

	.imgBox .del,
	.imgBox:hover .del2 {
		display: none;
	}

	.imgBox:hover .del {
		display: block;
		cursor: pointer;
	}

	.imgBox .del:hover .del2 {
		display: block;
	}

	.imgBox .del:hover .del1 {
		display: none;
	}

	.imgBox .del {
		position: absolute;
		right: 0;
		top: 0;
		transform: translate(50%, -50%);
	}

	.imgBox p {
		position: absolute;
		bottom: 0;
		left: 0;
		right: 0;
		padding: 5px 0;
		text-align: center;
		color: #FFFFFF;
		background-color: rgba(0, 0, 0, 0.4);
		display: none;
		cursor: pointer;
	}

	.imgBox:hover p {
		display: block;
	}
	.box_container:nth-child(2n){
		padding-left:10px;
		padding-right:0px !important;
	}
	.box_container {
		float: left;
		padding-right:10px;
		width: 50%;
		margin-bottom: 15px;
	
		.container_card {
			padding-top: 15px;
			padding-bottom: 15px;
			background: #FAF9F9;
			border-radius: 4px;
			border: 1px solid #D6D6D6;
	
			.price {
				color: #F85016;
				font-size: 16px;
				font-weight: bold;
				line-height: 22px;
				margin-left: 6px;
			}
	
			.obtain {
				color: #333;
			}
		}
		.container_active {
			background:#FC3D3D;
			border: 1px solid #FD3546;
	
			.price,
			.obtain,
			.gold {
				color: #FFF;
			}
		}
		
	}
	.selectTempBox{
		width: 50%;
		float: left;
		padding-left: 20px;
		margin-bottom: 20px;
	}
	
	.selectTempBox:nth-child(2n-1){
		padding-right: 20px;
	}
	.worksImgBox::after{
		content:'';
		position: absolute;
		top:0;
		right:0;
		bottom:0;
		left:0;
		z-index:1000
	}
</style>