<!-- 会员列表页面 -->
<template>
	<workArea :barlist="barlist">
		<div class="bg-fff pl20 pr20">
			<formQuery :queryOpts="queryOpts" @query="searchCallback"></formQuery>
			<!-- 按钮区域 -->
			<div class="rx-bc mb16">
				<el-button type="primary" @click="creatStoreEvent(1)">新增分店</el-button>
			</div>
			<!-- 数据表格 -->
			<div class="">
				<el-table :data="tableData.list" stripe style="width: 100%" @selection-change="selectionChange">
					<!-- <el-table-column type="selection" width="55" /> -->
					<el-table-column fixed prop="id" label="分店ID" min-width="120"></el-table-column>
					<el-table-column fixed prop="storeName" label="名称" min-width="120" show-overflow-tooltip>
						<template #default="scope">
							<span class="color-409EFF c_p tool_btn" @click="creatStoreEvent(scope.row)">{{scope.row.storeName}}</span>
						</template>
					</el-table-column>
					<el-table-column prop="logingName" label="登录账号" min-width="120"></el-table-column>
					<el-table-column prop="realName" label="分店管理员" min-width="120"></el-table-column>
					<el-table-column prop="salesUserName" label="销售者" min-width="120"></el-table-column>
					<el-table-column prop="postSalesName" label="售后" min-width="120"></el-table-column>
					<el-table-column label="来源" min-width="120">
						<template #default="scope">
							<span>{{getSourceFrom(scope.row.sourceFrom)}}</span>
						</template>
					</el-table-column>
					<el-table-column prop="createTimeString" label="注册时间" min-width="160"></el-table-column>
					<el-table-column prop="deadTimesString" label="截止时间" min-width="160"></el-table-column>
					<el-table-column prop="lastDay" label="剩余天数" min-width="120"></el-table-column>
					<el-table-column prop="openNumRemain" label="展厅剩余浏览总次数" min-width="120"></el-table-column>
					<el-table-column label="状态" min-width="120">
						<template #default="scope">
							<span>{{getStoreType(scope.row.storeType)}}</span>
						</template>
					</el-table-column>
					<el-table-column fixed="right" label="操作" width="150">
						<template #default="scope">
							<!-- <a href="javascript:;" class="t_d_u v_a_m color-409EFF tool_btn"
								@click="toEdit(scope.row.encryptionId)">进店</a> -->
							<a href="javascript:;" class="t_d_u v_a_m color-409EFF tool_btn"
								@click="bindStoreEvent(scope.row.encryptionId)">绑定商户号</a>
						</template>
					</el-table-column>
					<template #empty>
						<el-empty description="暂无数据"></el-empty>
					</template>
				</el-table>
				<div class="mt20 rx-ec" v-show="tableData.list.length > 0">
					<el-pagination v-model:currentPage="tableData.form.page" :page-sizes="[15,20,30,50,100]"
						:page-size="tableData.form.limit" layout="total, sizes, prev, pager, next, jumper"
						:total="tableData.count" @size-change="pageSizeChange" @current-change="()=>{getList()}">
					</el-pagination>
				</div>
			</div>
			<!-- 新增分店 -->
			<el-dialog v-model="createStore.show" :title="createStore.title" width="500px" custom-class="popwechat"
				:before-close="()=>{createStore.show = false}" :append-to-body="true">
				<div class="pl20 pt20 pb20 pr60" style="max-height: 500px;overflow: auto;">
					<div class="rx-sc mb12" v-if="createStore.type == 0">
						<span class="w110 t_a_r">总店名称：</span>
						<div class="flex1">
							<el-input v-model="createStore.form.businessName" type="text" placeholder="请输入"></el-input>
						</div>
					</div>
					<div class="rx-sc mb12">
						<span class="w110 t_a_r">分店名称：</span>
						<div class="flex1">
							<el-input v-model="createStore.form.storeName" :disabled="createStore.type == 1" type="text" placeholder="请输入"></el-input>
						</div>
					</div>
					<div class="rx-ss mb12">
						<span class="w110 l_h32 t_a_r">所属行业：</span>
						<div class="flex1">
							<div class="rx-c mb12" v-for="(item, index) in createStore.list" :key="index">
								<el-cascader
								    :class="[createStore.type == 1 ? 'ww100' : '']"
									ref="setItemRef"
									v-model="item.industryIds" 
									:options="createStore.options" 
									:props="props"
									:disabled='createStore.hsEdit'
									placeholder="请选择"
									size="default" 
									filterable 
									collapse-tags
									collapse-tags-tooltip
								/>
								<div class="dialogBox_img rx-c" v-if="createStore.type == 0">
                        		    <img class="w15 h15 ml20 c_p" src="@/assets/newAdd.png" v-if="index == createStore.list.length-1 && index<2" @click="addRulesList">
                        		    <img class="w15 h15 ml20 c_p" src="@/assets/delete.png" v-if="index != 0" @click="deleteRulesList(index)">
                        		</div>
							</div>
						</div>
					</div>
					<div class="rx-sc mb12" v-if="createStore.type == 1">
						<span class="w110 t_a_r">功能权限：</span>
						<div class="flex1">
							<el-checkbox-group v-model="createStore.storesFunIds" @change="checkboxPower">
							  	<el-checkbox :label="item.id" v-for="(item, index) in createStore.funListAll" :key="index">
									<span class="color-333">{{ item.name }}</span>
								</el-checkbox>
							</el-checkbox-group>
						</div>
					</div>
					<div class="rx-ss mb12" v-if="createStore.type == 1 && createStore.rowShow">
						<span class="w110 t_a_r l_h32">可见测评模版：</span>
						<div class="flex1">
							<el-checkbox-group v-model="createStore.accessList">
							  	<el-checkbox :label="1">
									<span class="color-333">舞蹈行业模版</span>
								</el-checkbox>
								<el-checkbox :label="2">
									<span class="color-333">体育行业模版</span>
								</el-checkbox>
								<el-checkbox :label="3">
									<span class="color-333">篮球行业模版</span>
								</el-checkbox>
								<el-checkbox :label="4">
									<span class="color-333">健身行业模版</span>
								</el-checkbox>
							</el-checkbox-group>
						</div>
					</div>
					<div class="rx-ss mb12" v-show="createStore.type == 1 && createStore.storesFunIds.indexOf(2002) > -1">
						<span class="w110 t_a_r l_h32">可见展厅模板：</span>
						<div class="flex1">
							<el-checkbox-group v-model="createStore.templateIds">
								<div class="mb10 rx-sc" v-for="item in createStore.tempList" :key="item.id">
									<el-checkbox :label="item.id"><span></span></el-checkbox>
									<img class="w90 mr10" :src="item.hallCover" alt="">
								</div>
							</el-checkbox-group>
						</div>
					</div>
					<div class="rx-sc mb12" v-if="createStore.type == 1">
						<span class="w110 t_a_r">状态：</span>
						<div class="flex1">
							<el-select v-model="createStore.form.storeType" placeholder="请选择状态">
								<el-option label="试用" :value="1" />
								<el-option label="已付费商用" :value="2" />
								<!-- <el-option label="未付费商用" :value="3" /> -->
								<el-option label="催米中" :value="4" />
								<el-option label="GG" :value="5" />
								<el-option label="已付定金" :value="6" />
								<el-option label="未续费" :value="7" />
 							</el-select>
						</div>
					</div>
					<div class="rx-sc mb12" v-if="createStore.type == 1">
						<span class="w110 t_a_r">截止时间：</span>
						<div class="flex1">
							<el-date-picker
								class="ww100"
      						  	v-model="createStore.form.deadTimesString"
      						  	type="date"
      						  	placeholder="请选择截止时间"
                                format="YYYY-MM-DD"
                                value-format="YYYY-MM-DD"
      						/>
						</div>
					</div>
					<div class="rx-sc mb12" v-if="createStore.type == 1">
						<span class="w110 t_a_r">关注主体：</span>
						<div class="flex1">
							<el-radio-group v-if="createStore.form.radio == 0" v-model="createStore.form.subjectType">
  							  	<el-radio :label="1"><span class="color-333">卡蜜</span></el-radio>
  							  	<el-radio :label="2"><span class="color-333">跃犀云</span></el-radio>
  							  	<el-radio :label="0"><span class="color-333">不关联</span></el-radio>
  							</el-radio-group>
							<span v-else>{{ createStore.form.subjectType == 1 ? '卡蜜' : '跃犀云' }}</span>
						</div>
					</div>
					<div class="rx-sc mb12" v-if="createStore.type == 1 && createStore.form.subjectType !=0">
						<span class="w110 t_a_r">关联分店：</span>
						<div class="flex1">
							<el-input  v-model="createStore.form.subjectSid" :disabled="createStore.form.radio != 0" type="number" placeholder="请输入"></el-input>
						</div>
					</div>
					<div class="rx-sc mb12" v-if="createStore.type == 0">
						<span class="w110 t_a_r">账号：</span>
						<div class="flex1">
							<el-input v-model="createStore.form.logingName" type="text" placeholder="请输入"></el-input>
						</div>
					</div>
					<div class="rx-sc mb12" v-if="createStore.type == 0">
						<span class="w110 t_a_r">姓名：</span>
						<div class="flex1">
							<el-input v-model="createStore.form.realName" type="text" placeholder="请输入"></el-input>
						</div>
					</div>
					<div class="rx-sc mb12" v-if="createStore.type == 0">
						<span class="w110 t_a_r">手机：</span>
						<div class="flex1">
							<el-input v-model="createStore.form.stallPhone" maxlength="11" type="number"
								placeholder="请输入"></el-input>
						</div>
					</div>
					<div class="rx-ss mb12" v-if="createStore.type == 0">
						<span class="w110 t_a_r">备注：</span>
						<div class="flex1">
							<el-input v-model="createStore.form.remark" :rows="5" maxlength="100" type="textarea"
								show-word-limit placeholder="请输入备注" />
						</div>
					</div>
				</div>
				<template #footer>
					<div class="dialog-footer">
						<el-button @click="createStore.show = false">取消</el-button>
						<el-button type="primary" @click="createStoreSave">确定</el-button>
					</div>
				</template>
			</el-dialog>
			<!-- 绑定商户号 -->
			<el-dialog v-model="bindStore.show" title="绑定商户号" width="500px" custom-class="popwechat"
				:before-close="()=>{bindStore.show = false}" :append-to-body="true">
				<div class="pl20 pt40 pb40 pr60">
					<div class="rx-sc mb12">
						<span class="w110 t_a_r">商户号：</span>
						<div class="flex1">
							<el-input v-model="bindStore.code" type="text" placeholder="请输入"></el-input>
						</div>
					</div>
				</div>
				<template #footer>
					<div class="dialog-footer">
						<el-button @click="bindStore.show = false">取消</el-button>
						<el-button type="primary" @click="bindStoreSave">确定</el-button>
					</div>
				</template>
			</el-dialog>
			<!-- <Confirm ref="confirmRef" :title="pageData.confirmTitle" :text="pageData.confirmText" @save="confirmSave" /> -->
		</div>
	</workArea>
</template>

<script setup>
	import { ref, reactive, onMounted, getCurrentInstance } from 'vue';
	import { useRouter, onBeforeRouteLeave } from 'vue-router';
	import { useStore } from 'vuex'
	import { ElMessage } from 'element-plus'
	import { ArrowDown, Close, Upload, ArrowUp } from '@element-plus/icons-vue'
	import utils from '@/common/utils.js'
	import { storeAllList, newStoreInsert, selectAllPostSales, selectAllManager, storeQrcodeBind } from '@/api/store.js';
	import { getIndustry, feeToEdit, storeFeeUpdate } from '@/api/market.js';
	import { getHallTemplateList } from '@/api/showroom.js';
	import formQuery from '@/components/branch-store/formQuery.vue';
	import Confirm from '@/components/Confirm.vue'
	import workArea from '@/components/branch-store/workArea.vue';
	let { ctx } = getCurrentInstance();
	const store = useStore();
	const router = useRouter();
	const props = { value: 'id', label: 'name', children: 'list' }
	const barlist = [{
		name: '营销后台'
	}, {
		name: '分店管理'
	}];

	const queryOpts = reactive({
		fields: [{
			type: 'input',
			fieldName: 'storeName',
			placehold: '分店名称'
		}, {
			type: 'select',
			fieldName: 'storeType',
			placehold: '全部状态 ',
			options: [{
					label: '全部状态',
					value: ''
				},
				{
					label: '试用',
					value: '1'
				},
				{
					label: '已付费商用',
					value: '2'
				},
				{
					label: '催米中',
					value: '4'
				},
				{
					label: 'GG',
					value: '5'
				},
				{
					label: '已付定金',
					value: '6'
				},
				{
					label: '未续费',
					value: '7'
				},
			],
			value: ''
		}, {
			type: 'select',
			fieldName: 'salesUserId',
			placehold: '全部销售者 ',
			options: [],
			value: ''
		}, {
			type: 'select',
			fieldName: 'afterAaleId',
			placehold: '全部售后 ',
			options: [],
			value: ''
		}, {
			type: 'select',
			fieldName: 'sourceFrom',
			placehold: '全部来源 ',
			options: [{
					label: '全部来源',
					value: ''
				},
				{
					label: '默认',
					value: '0'
				},
				{
					label: '官网注册',
					value: '1'
				},
				{
					label: '卡蜜后台',
					value: '2'
				},
				{
					label: '今日头条',
					value: '3'
				},
				{
					label: '妙招',
					value: '4'
				},
				{
					label: '百度推广',
					value: '5'
				},
			],
			value: ''
		}, {
			type: 'select',
			fieldName: 'timeIn',
			placehold: '注册时间选择',
			value: '0',
			options: [{
					label: '注册时间选择',
					value: '0'
				},
				{
					label: '最近七天',
					value: '1'
				},
				{
					label: '本月',
					value: '2'
				},
				{
					label: '其他',
					value: '3'
				},
			]
		}, {
			showField: 'timeIn', // 根据显示字段
			showValue: '3', // 根据显示字段值
			type: 'dateRange',
			fieldName: 'createTimeStr',
			placeholder: '日期范围',
			value: ''
		}]
	})
	
	//销售者
	selectAllManager().then((res)=>{
		if(res.code == 0){
			var list = [{
					label: '全部销售者',
					value: ''
				}]
			res.data.map(n=>{
				list.push({
					label: n.realName,
					value: n.salesUserId
				})
			})
			queryOpts.fields[2].options = list;
		}
	})
	//售后
	selectAllPostSales().then((res)=>{
		if(res.code == 0){
			var list = [{
					label: '全部售后',
					value: ''
				}]
			res.data.map(n=>{
				list.push({
					label: n.name,
					value: n.id
				})
			})
			queryOpts.fields[3].options = list;
		}
	})
	var selectCheckData = [],
		confirmType = '',
		storeId = '';
	const confirmRef = ref();
	//新增分店
	const createStore = reactive({
		form: {
			businessName: '',
			storeName: '',
			logingName: '',
			realName: '',
			stallPhone: '',
			remark: '',
		},
		options: [],
		funListAll: [],
		accessList: [],
		storesFunIds:[],
		list: [{
			industryIds: []
		}],
		storeName: '',
		title: '',
		type: '',
		rowId: '',
		rowShow: false,
		show: false,
		hsEdit: false,
		tempList:[],
		templateIds:[]
	})
	/**
	 * 选择功能权限
	 */
	function checkboxPower(val) {
		if (val.indexOf(createStore.rowId)>-1) {
			createStore.rowShow = true
		} else {
			createStore.accessList = []
			createStore.rowShow = false
		}
	}
	/**
	 * 添加行业
	 */
	function addRulesList() {
		createStore.list.push({
			industryIds: []
		})
	}
	/**
	 * 删除行业
	 */
	function deleteRulesList(index) {
		createStore.list.splice(index,1);
	}
	/**
	 * 新增、编辑分店
	 */
	function creatStoreEvent(type) {
		getIndustry().then(res => {
			if (res.code ==0) {
				res.data.forEach(item => {
					item.list = item.list || [];
					item.list.unshift({
						name: '全部',
						id: item.id
					})
				})
				createStore.options = res.data
				if (type == 1) {
					for (var k in createStore.form) {
						createStore.form[k] = '';
					}
					createStore.list = [{
						industryIds: []
					}]
					createStore.type = 0
					createStore.hsEdit = false
					createStore.title = '新增分店'
				} else {
					getHallTemplateList({page:1,limit:9999}).then(res=>{
						createStore.tempList = res.data.filter(n=>n.state == 1).map(n=>{
							n.number = '';
							return n;
						});
					})
					feeToEdit({encryptionId: type.encryptionId}).then(res => {
						if (res.code == 0) {
							createStore.form =  JSON.parse(JSON.stringify(res.data))
							createStore.funListAll = JSON.parse(JSON.stringify(res.results.funListAll))
							createStore.accessList = []
							res.results.accessList && res.results.accessList.forEach(item => {
								createStore.accessList.push(item.type)
							})
							createStore.accessList = [...new Set(createStore.accessList)]
							createStore.templateIds = res.results.tidList || [];
							createStore.storeName = res.results.storeName || ''
							createStore.form.subjectType = res.results.relate&&res.results.relate.subjectType || 0
							createStore.form.subjectSid = res.results.relate&&res.results.relate.subjectSid
							createStore.form.radio = res.results.relate&&res.results.relate.subjectType || 0
							createStore.storesFunIds = res.data.storesFunIds
							res.results.industryList.forEach(item => {
								item.industryIds = utils.getPath(createStore.options, item.industryId);
							})
							createStore.funListAll.forEach(item=>{
								if (item.name == '测评中心') {
									createStore.rowId = item.id
								}
							})
							if (createStore.storesFunIds.indexOf(createStore.rowId)>-1) {
								createStore.rowShow = true
							} else {
								createStore.rowShow = false
							}
							createStore.hsEdit = res.results.industryList.length ? true : false
							createStore.list = res.results.industryList.length && res.results.industryList || [{
								industryIds: []
							}]
						}
					})
					createStore.type = 1
					createStore.title = '分店信息修改'
				}
			}
		})
		createStore.show = true;
		
	}
	// 新增保存
	function createStoreSave() {
		if (createStore.type == 1) {
			let params = {
				encryptionId: createStore.form.encryptionId,
				storeGrade: createStore.form.storeGrade,
				storeType: createStore.form.storeType,
				deadTimesString: createStore.form.deadTimesString,
				funType: createStore.storesFunIds.join(','),
				assessTypes: createStore.accessList.join(','),
				subjectType: createStore.form.subjectType,
				subjectSid: createStore.form.subjectSid,
			}
			let arr = []
			if (!createStore.hsEdit) {
				for(let i =0; i < createStore.list.length; i++){
					if (!ctx._.refs.setItemRef[i].getCheckedNodes(true).length) {
						ElMessage.warning('请选择行业')
						return
					}
        		    arr.push(ctx._.refs.setItemRef[i].getCheckedNodes(true)[0].value)
        		}
				if (utils.isRepeat(arr)) {
					ElMessage.warning('请选择的不同的行业')
					return
				}
				params.industryIds = arr.join(',')
			}
			params.hallTemplates = createStore.templateIds.join(',');
			storeFeeUpdate(params).then(res=> {
				if (res.code == 0) {
					createStore.show = false;
					ajaxSaveCallBack('编辑成功');
				}
			})
		} else {
			let params = {
				storeName: createStore.form.storeName,
				logingName: createStore.form.logingName,
				realName: createStore.form.realName,
				stallPhone: createStore.form.stallPhone,
				businessName: createStore.form.businessName,
				remark: createStore.form.remark
			}
			if (!params.businessName) {
				ElMessage.warning('请输入总店名称')
				return
			}
			if (!params.storeName) {
				ElMessage.warning('请输入分店名称')
				return
			}
			
			let arr = []
			for(let i =0; i < createStore.list.length; i++){
				if (!ctx._.refs.setItemRef[i].getCheckedNodes(true).length) {
					ElMessage.warning('请选择行业')
					return
				}
        	    arr.push(ctx._.refs.setItemRef[i].getCheckedNodes(true)[0].value)
        	}
			if (utils.isRepeat(arr)) {
				ElMessage.warning('请选择的不同的行业')
				return
			}
			if (!params.logingName) {
				ElMessage.warning('请输入登录账号')
				return
			}
			if (!params.realName) {
				ElMessage.warning('请输入姓名')
				return
			}
			if (!params.stallPhone) {
				ElMessage.warning('请输入手机号')
				return
			}
			const regex = /^1[3456789]\d{9}$/
        	if (!regex.test(params.stallPhone)) {
				ElMessage.warning('请输入正确的手机号')
				return
			}
			params.industryIds = arr.join(',')
			newStoreInsert(params).then((res) => {
				if (res.code == 0) {
					createStore.show = false;
					ajaxSaveCallBack('新增成功');
				}
			})
		}
	}
	const tableData = reactive({
		list: [],
		count: 0,
		form: {
			page: 1,
			limit: 15,
			storeName: '',
			storeType: '',
			salesUserId: '',
			afterAaleId: '',
			sourceFrom: '',
			timeIn: '',
			createTimeStr:''
		}
	})
	//筛选表单回调
	function searchCallback(options) {
		for (var key in options) {
			tableData.form[key] = options[key];
		}
		searchTable();
	}
	//切换分页数量
	function pageSizeChange(limit) {
		tableData.form.limit = limit;
		searchTable();
	}

	function searchTable() {
		tableData.form.page = 1;
		getList();
	}
	getList();

	function getList(exportObj) {
		var formData = JSON.parse(JSON.stringify(tableData.form));
		if (formData.timeIn == 3) {
			if ( formData.createTimeStr && formData.createTimeStr.length > 0) {
				formData.createTimeStr = formData.createTimeStr.join('-')
			} else {
				formData.createTimeStr = ''
			}
		}
		storeAllList(formData).then(function(res) {
			if (res.code == 0) {
				tableData.list = res.data || [];
				tableData.count = res.count;
			}
		});
	}

	//绑定商户号
	const bindStore = reactive({
		show:false,
		code:''
	})
	
	function bindStoreEvent(id){
		storeId = id;
		bindStore.code = '';
		bindStore.show = true;
	}
	function bindStoreSave(){
		if(!bindStore.code){
			ElMessage({
				type: 'warning',
				message: '请输入商户号',
			})
			return  false;
		}
		storeQrcodeBind({
			encryptionId:storeId,
			code:bindStore.code,
			type:0,
		}).then(function(res) {
			if (res.code == 0) {
				bindStore.show = false;
				ajaxSaveCallBack('绑定成功');
			}
		});
	}

	//通用确认框确认事件回调
	// function confirmSave(){
	// 	if(confirmType == 'delete'){
	// 		deleteMyCard({
	// 			ids:getSelectCheckKey('encryptionId')
	// 		}).then((res)=>{
	// 			if(res.code == 0){
	// 				ajaxSaveCallBack('删除成功');
	// 			}
	// 		})
	// 	}

	// }

	//table通用勾选
	function selectionChange(data) {
		selectCheckData = data;
	}
	//获取table勾选集合的某个字段集合
	function getSelectCheckKey(key) {
		return selectCheckData.map(n => n[key]).join(',');
	}
	// 验证批量操作是否勾选了数据
	function CheckLengthCallBack(callback) {
		if (selectCheckData.length == 0) {
			ElMessage({
				type: 'warning',
				message: '请选择需要操作的会员',
			})
			return false;
		}
		callback && callback();
	}
	//ajax提交保存通用提示
	function ajaxSaveCallBack(text, callback) {
		ElMessage({
			type: 'success',
			message: text
		})
		searchTable();
		callback && callback();
	}
	
	function getStoreType(storeType) {
	  if (storeType == 1) {
	    return '试用';
	  } else if (storeType == 2) {
	    return '已付费商用';
	  } else if (storeType == 4) {
	    return '催米中';
	  } else if (storeType == 5) {
	    return 'GG';
	  } else if (storeType == 6) {
	    return '已付定金';
	  } else if (storeType == 7) {
	    return '未续费';
	  }
	};
	function getSourceFrom(sourceFrom) {
	  if (sourceFrom == 0) {
	    return '默认';
	  } else if (sourceFrom == 1) {
	    return '官网注册';
	  } else if (sourceFrom == 2) {
	    return '卡蜜后台';
	  } else if (sourceFrom == 3) {
	    return '今日头条';
	  } else if (sourceFrom == 4) {
	    return '妙招';
	  } else if (sourceFrom == 5) {
	    return '百度推广';
	  }
	};
	
	// 页面缓存
	onBeforeRouteLeave((to, from) => {
		// if (to.path === '/branch/memberDetail') {
		//     store.commit('SetKeep',{keepLiveRoute : ['BranchMemberList']});
		// } else {
		//     store.commit('SetKeep',{keepLiveRoute : []});
		// }
	})
</script>

<style lang="stylus" scoped>
	.delTagList {
		li {
			border 1px solid #ccc;
			span:first-child {
				border-right 1px solid #ccc;
			}
		}
	}
	.Sign:hover {
		color: #2D6FB2;
	}
	::v-deep .el-date-editor.el-input {
		width: 100%
	}
</style>
