<template>
    <div class="hh100 posi-r o_y_s">
        <div class="w375 box posi-a posi-r" :class="[pageProp.type == 1 ? 'dance' : (pageProp.type == 2 ? 'sports' : (pageProp.type == 3 ? 'basketball' : 'bodybuilding'))]">
            <div class="ww100 hh100 box-item">
                <div class="top">
                    <span class="serial ft16 mr10">01</span>
                    <span class="title ft16 fwb">学生基本信息</span>
                </div>
                <div class="container bg-fff">
                    <div class="message mt10" v-if="pageProp.info.openBase == 1">
                        <div class="rx-c mb20">
                            <img class="mr20 avatar" src="@/assets/avatar.png" alt="">
                            <div class="rx-ce">
                                <span class="ft16 color-333 fwb mr16">学生姓名</span>
                                <div>
                                    <span class="color-999 ft12 mr20">女</span>
                                    <span class="color-999 ft12">8岁</span>
                                </div>
                            </div>
                        </div>
                        <div class="message-box rx-bc">
                            <div class="cx-cc">
                                <div><img class="img mb15" src="@/assets/weight.png" alt=""></div>
                                <div class="ft16 fwb">25kg</div>
                            </div>
                            <div class="cx-cc">
                                <div class="ft16 fwb h35 mb15 l_h35">128cm</div>
                                <div class="stature ft12 color-fff t_a_c">身高</div>
                            </div>
                            <div class="cx-cc">
                                <div><img class="img mb15" src="@/assets/bmi.png" alt=""></div>
                                <div class="ft16 fwb">15</div>
                            </div>
                        </div>
                        <div class="message-tip mt20 ft12 color-999 bg-F5F5F5">
                            以下对照标准范围将划分为3-6岁，7-18岁+，将自动根据输入的学生年龄性别展示对应的范围区间
                        </div>
                    </div>
                    <div class="height-chart" v-if="pageProp.info.openHeight == 1">
                        <heightChart :info="pageData.info" :value="128"></heightChart>
                    </div>
                    <div class="weight-chart" v-if="pageProp.info.openWeight == 1">
                        <weightChart :info="pageData.info" :value="25"></weightChart>
                    </div>
                    <div class="bmi-chart" v-if="pageProp.info.openBmi == 1">
                        <bmiChart :info="pageData.info" :value="15"></bmiChart>
                        <div class="message-tip ft12 color-999 bg-F5F5F5">温馨提示：BMI是常用的衡量人体胖瘦程度以及是否健康的一个标准</div>
                    </div>
                </div>
            </div>
          
        </div>
    </div>
</template>

<script setup>
    import { reactive } from 'vue';
    import heightChart from '@/components/branch-store/template/echarts/heightChart.vue';
    import weightChart from '@/components/branch-store/template/echarts/weightChart.vue';
    import bmiChart from '@/components/branch-store/template/echarts/bmiChart.vue';
    const pageProp = defineProps({
       info: Object,
       type: Number 
    })
    const pageData = reactive({
        info: {
            sex: 1,
            ageMin: 7,
            ageMax: 18,
            age: 8
        },
    })
</script>
<style type='css/style' scoped>
    .box {
        padding: 12px;
	    left: 50%;
        transform: translateX(-50%);	
    }
    .box-item .top{
        background: #FFF;
        border-top-left-radius: 10px;
        padding: 13px;
        padding-bottom: 0;
        -webkit-clip-path: polygon(0% 0%,40% 0%,45% 100%,100% 100%,100% 100%,0% 100%);
        clip-path: polygon(0% 0%,40% 0%,45% 100%,100% 100%,100% 100%,0% 100%);
    }
    .container {
        padding: 13px;
        border-top-right-radius: 10px;
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
        margin-top: -2px;
    }
    .message .avatar {
        width: 44px;
        height: 44px;
    }
    .message .img {
        width: 32px;
    }
    .message .stature {
        background: #AAE642;
        border-radius: 12px;
        width: 52px;
        height: 23px;
        line-height: 23px;
    }
    .serial {
        font-weight: 1000;
    }
    .message-box {
        padding: 12px 44px;
        border-radius: 6px;
    }
    .message-tip {
        padding: 4px 7px;
    }
    /* 主题颜色 */
    .dance {
        background: #FDD2E7;
    }
    .dance .top .serial, .dance .top .title {
        color: #ED2169;
    }
    .dance .message-box{
        background: #FFEFF7;
    }
    .sports {
        background: #203C1E;
    }
    .sports .top .serial, .sports .top .title {
        color: #203C1E;
    }
    .sports .message-box{
        background: #F0FCEF;
    }
    
</style>