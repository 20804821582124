import {postAjax,getAjax}  from "../common/axios.js"

//总店员工列表
export const getBessAccountStaff = params => {
    return postAjax('/api/run/system/getBessAccountStaff',params)
}

//所属架构下拉
export const getFrameworkAllList = params => {
	return postAjax('/api/run/system/getFrameworkAllList',params)
}

//岗位下拉框
export const getAllRoleNoReList = params => {
	return postAjax('/api/run/system/getAllRoleNoReList',params)
}

//新增员工
export const employeeInsert = params => {
	return postAjax('/api/run/system/employeeInsert',params)
}
//编辑员工
export const employeeUpdate = params => {
	return postAjax('/api/run/system/employeeUpdate',params)
}

//新增组织架构
export const getFrameworkRoleList = params => {
	return postAjax('/api/run/system/getFrameworkRoleList',params)
}

//监管门店下拉
export const getFrameworkStoreList = params => {
    return postAjax('/api/run/system/getFrameworkStoreList',params)
}

// 员工绑定链接
export const getLinkWechat = params => {
    return postAjax('/api/order/linkWeChat',params)
}

// 解绑微信
export const removeLinkWeChat = params => {
    return postAjax('/api/order/removeLinkWeChat',params)
}

// 删除员工
export const employeeListStatusUpdate = params => {
    return postAjax('/api/run/system/employeeListStatusUpdate',params)
}
// 查询授课教练
export const teacherList = params => {
    return postAjax('/api/user/getLessonCoachList',params)
}

// 门店-员工列表
export const getStoreAccountStaff = params => {
    return postAjax('/api/run/system/getStoreAccountStaff',params)
}

// 查询门店员工列表 用于下拉
export const getStoreAccountStaffSimple = params => {
    return postAjax('/api/run/system/getStoreAccountStaffSimple',params);
}

// 查询门店会员列表 用于下拉
export const selectStoreMembersSimple = params => {
    return postAjax('/api/member/selectStoreMembersSimple',params);
}



// 门店-编辑数据权限
export const userMenuDetailInsert = params => {
    return postAjax('/api/system/userMenuDetailInsert',params)
}

// 门店-获取数据权限
export const menuDetailManager = params => {
    return postAjax('/api/system/menuDetailManager',params)
}
// 门店-获取岗位列表
export const roleManageStore = params => {
    return postAjax('/api/run/system/roleManageStore',params)
}

// 门店-设置考勤方式
export const employeeUpdateAttendance = params => {
    return postAjax('/api/run/system/employeeUpdateAttendance',params)
}
// 门店-重置密码
export const sysUserPasswordReset = params => {
    return postAjax('/api/system/sysUserPasswordReset',params)
}
// 门店-考勤记录列表
export const selectUserAttendance = params => {
    return postAjax('/api/system/selectUserAttendance',params)
}

// 门店-查询考勤规则
export const attendanceSetting = params => {
    return postAjax('/api/system/attendanceSetting',params)
}

// 门店-修改考勤规则
export const updateAttendanceSetting = params => {
    return postAjax('/api/system/updateAttendanceSetting',params)
}

