<template>
  <div class="login rx-c">
	<div class="login_left ww50 hh100"></div>
	<div class="login_right ww50 hh100 posi-r">
		<div class="right posi-a">
			<div class="ft42 color-333 mb65">欢迎登录</div>
			<el-form :model="pageData.form" ref="ruleFormRef" :rules="pageData.rules">
				<el-form-item prop="userName">
    			  	<el-input class="formInput" v-model="pageData.form.userName" placeholder="请输入账号">
						<template #prefix>
							<img class="img" src="../assets/user.png" alt="">
						</template>
					</el-input>
    			</el-form-item>
				<el-form-item prop="password">
    			  	<el-input class="formInput" v-model="pageData.form.password" type="password" autocomplete="off" placeholder="请输入密码">
						<template #prefix>
							<img class="img" src="../assets/pass.png" alt="">
						</template>
					</el-input>
    			</el-form-item>
			</el-form>
			<div class="color-999">
				<el-checkbox class="v_a_m checkbox" v-model="pageData.checked" label="Option 1"> {{''}}</el-checkbox>
				<span>
					阅读并同意 
					<span class="color-409EFF c_p">《用户协议》</span> 
					及 
					<span class="color-409EFF c_p">《隐私政策》</span>
				</span>
			</div>
			<div class="loginButton c_p" @click="loginClick">登录</div>
		</div>
	</div>
  </div>
</template>

<script setup>
	import { ref, reactive } from 'vue';
	import { login, getUserMenuListJson, loginByToken } from '@/api/login.js'
	import { ElMessage } from 'element-plus'
	import { useStore } from 'vuex'
	import { useRouter, useRoute } from 'vue-router'
	const router = useRouter();
	const route = useRoute();
	const ruleFormRef = ref()
	const store = useStore();
	store.state.storeLogo.value = '';
	localStorage.clear();
	const pageData = reactive({
		form: {
			userName: '',
      		password: '',
		}, // 登录表单
		checked: false,
		rules: {
    	  	userName: [
    	  	  	{ required: true, message: '请输入账号!', trigger: 'blur' },
    	  	],
    	  	password: [
    	  	  	{ required: true, message: '请输入密码!', trigger: 'blur' },
    	  	]
    	}
	})
	/**
	 * 登录
	 */
	function loginClick() {
		ruleFormRef.value.validate((valid) => {
      	  	if (valid) {
				if (!pageData.checked) {
					ElMessage.warning('请先勾选同意后再登录')
					return
				}
      	  	  	let paramss = {
					logingName: pageData.form.userName,
					password: pageData.form.password,
					version: 2
			 	}
			 	login(paramss).then((res) => {
					if ( res.code == 0) {
						localStorage.setItem('businessToken',res.data.access_token);
						localStorage.setItem('businessLoginData',JSON.stringify(res.data));
						if (res.data.relateStore && Object.values(res.data.relateStore).length > 0) {
							localStorage.setItem('relevancy', true);
						} else {
							localStorage.setItem('relevancy', false);
						}
						getUserMenuListJson({
							access_token: res.data.access_token
						}).then((reserve)=>{
							localStorage.setItem('businessMenuList',JSON.stringify(reserve.data));
							localStorage.setItem('businessUserData',JSON.stringify(reserve.remark));
							ElMessage.success('登录成功')
							setTimeout(()=>{
								if (reserve.remark.userType == 1) {
									router.push('/storeList');
								} else {
									router.push('/branch/assess')
								}
							},1000)
						})
						
					}
			 	})
      	  	} else {
      	  	  return false;
      	  	}
      	});
	}
	if (route.query.info) {
		let params = {
			token: route.query.info,
			from: route.query.from
		}
		loginByToken(params).then(res => {
			if ( res.code == 0) {
				localStorage.setItem('businessToken',res.data.access_token);
				localStorage.setItem('businessLoginData',JSON.stringify(res.data));
				getUserMenuListJson({
					access_token: res.data.access_token
				}).then((reserve)=>{
					localStorage.setItem('businessMenuList',JSON.stringify(reserve.data));
					localStorage.setItem('businessUserData',JSON.stringify(reserve.remark));
					ElMessage.success('登录成功')
					router.push('/branch/assess')
				})
			} else {
				ElMessage.warning('请先绑定账号')
			}
		})
	}
</script>
<style type='css/style' scoped>
	.login{
		height: 100vh;
	}
	.login_left {
		background-image: url(../assets/loginBac.png);
		background-size: 100% 100%;
	}
	.right {
		width: 50%;
		top: 50%;
		left: 50%;
        transform: translate(-50%, -50%);		
	}
	.formInput{
		height: 58px;
	}
	.img {
		width: 16px;
	}
	.img1{
		width: 16px;
	}
	::v-deep .el-form-item{
		margin-bottom: 36px;
	}
	::v-deep .el-checkbox {
		height: 14px;
	}
	.loginButton {
		margin-top: 72px;
		height: 60px;
		background: #409EFF;
		border-radius: 30px;
		font-size: 27px;
		font-weight: bold;
		color: #FFFFFF;
		text-align: center;
		line-height: 60px;
	}
</style>