<template>
	<div class="potential_member">
		<formQuery :queryOpts="pageData.queryOpts" @query="getTableData"></formQuery>
		<div class="mb12 rx-ec">
			<el-button @click="getList(true)"  :icon="Upload" >导出</el-button>
		</div>
		<div>
			<el-table :data="pageData.tableData" stripe style="width: 100%" @selection-change="selectionChange">
				<el-table-column fixed label="会员姓名" width="110" show-overflow-tooltip >
					<template #default="scope">
						<span @click="memberDetail(scope.row.encryptionCardMyId)" class="color-409EFF c_p tool_btn">{{scope.row.realName}}</span>
					</template>
				</el-table-column>
				<el-table-column prop="telephoneNum" label="手机号" min-width="110" />
				<el-table-column prop="lastFollowUpTimeStr" label="跟进时间" min-width="110"/>
				<el-table-column prop="followUpContent" label="跟进内容" width="110" show-overflow-tooltip />
			    <el-table-column prop="ifImportanceDegree" label="意向级别" min-width="150">
					<template #default="scope">
						<el-rate v-model="scope.row.ifImportanceDegree" disabled size="large"></el-rate>
					</template>
				</el-table-column>
			    <el-table-column prop="upStatusName" label="跟进状态" min-width="100" >
					<template #default="scope">
						<span>{{scope.row.upStatusName || '暂无状态'}}</span>
					</template>
				</el-table-column>
				<el-table-column prop="adviserNames" label="跟进人" min-width="100" show-overflow-tooltip />
				<el-table-column label="操作" width="160">
					<template #default="scope">
						<a href="javascript:;" class="t_d_u color-409EFF tool_btn" @click="oneFollowEvent(scope.row.id)">修改</a>
						<a href="javascript:;" class="t_d_u color-409EFF tool_btn" @click="deleteEvent(scope.row.id)">删除</a>
					</template>
				</el-table-column>
			</el-table>
			<div class="mt20 rx-ec">
			    <el-pagination
			      v-model:currentPage="pageData.searchForm.page"
			      :page-sizes="[15,20,30,50,100]"
			      :page-size="pageData.searchForm.limit"
			      layout="total, sizes, prev, pager, next, jumper"
			      :total="pageData.totalCount"
			      @size-change="pageSizeChange"
			      @current-change="()=>{getList()}"
			    >
			    </el-pagination>
			</div>
		</div>
		<AddFollowStep v-model="pageData.addFollowShow" :recordid="pageData.recordId" type="update" @close="addFollowClose" @save="addFollowSave"/>
		<Confirm ref="confirmRef" :title="pageData.confirmTitle" :text="pageData.confirmText" @save="confirmSave"/>
	</div>
</template>

<script setup>
	import { ref, reactive, onMounted } from 'vue';
	import { useRouter, onBeforeRouteLeave } from 'vue-router';
	import { useStore } from 'vuex'
	import { ElMessage } from 'element-plus'
	import { ArrowDown, Close, Upload } from '@element-plus/icons-vue'
	import utils from '@/common/utils.js'
	import {getStudentFollowList,deleteFollow, studentExport} from '@/api/intentionList.js'
	import CommonTitle from '@/components/CommonTitle.vue'
	import Confirm from '@/components/Confirm.vue'
	import formQuery from '@/components/branch-store/formQuery.vue';
	import AddFollowStep from '@/components/branch-store/member/AddFollowStep.vue';
	const store = useStore();
	const router = useRouter();
	const confirmRef = ref();
	const pageData = reactive({
		recordId:'', //编辑潜在会员
		
		addFollowShow:false, //跟进
		addFollowType:'',
		
		confirmTitle:'', //通用确认框
		confirmText:'',
		
		tableData:[],
		totalCount:0,
		searchForm:{
			page:1,
			limit:15,
			searchKeys:'',
			followStatusId:'',
			ifImportanceDegree:'',
			adviserIds:'',
			channelId:''
		},
		queryOpts:{
			fields:[{
				type : 'input',
				fieldName : 'searchKeys',
				placehold : '请输入会员姓名/手机号'
			},{
				type : 'select',
				fieldName : 'followStatusId',
				placehold : '跟进状态',
				options : [],
				value:''
			},{
				type : 'select',
				fieldName : 'ifImportanceDegree',
				placehold : '意向级别',
				options : utils.getDict('starState',{label : '意向级别' ,value : ''}),
				value:''
			},{
				type : 'selectMany',
				fieldName : 'adviserIds',
				placehold : '跟进人',
				options : [],
				value:''
			},{
				type : 'date',
				fieldName : 'time',
				placehold : '跟进时间',
				value:''
			}]
		}
	}) 
	// 不需要响应式监听的全局变量
	let nowTableRow = '';//操作-当前行数据
	
	//获取字典
	utils.getDynaDict('followState',{label : '跟进状态' ,value : ''},function(res){
	    for(var i=0;i<res.length;i++){
	        pageData.queryOpts.fields[1].options.push(res[i]);
	    }
	});
	utils.getDynaDict('followerList','',function(res){
	    for(var i=0;i<res.length;i++){
	        pageData.queryOpts.fields[3].options.push(res[i]);
	    }
	});
    /**
	 * 导出
	 */
	function deletSomeOne() {
		studentExport({limit: 999999}).then((res) => {
			if (res.code == 0) {
                ElMessage({
		        	type: 'success',
		        	message: '正在导出，请稍后前往下载中心下载'
		        })
			}
		})
	}
	//筛选表单回调
	function getTableData(options){
		for(var key in options){
			pageData.searchForm[key] = options[key];
		}
		pageData.searchForm.adviserIds = pageData.searchForm.adviserIds && pageData.searchForm.adviserIds.join(',') || ''
		searchTable();
	}
	
	//搜索table
	function searchTable(){
		pageData.searchForm.page = 1;
		getList();
	}
	//切换分页数量
	function pageSizeChange(limit){
		pageData.searchForm.limit = limit;
		searchTable();
	}
	getList();
	function getList(isExport){
		var formData = JSON.parse(JSON.stringify(pageData.searchForm));
		if(isExport){ //导出
			formData.page = 1;
			formData.limit = 9999;
			formData.exportData = 1;
		}
		getStudentFollowList(formData).then((res)=>{
			if(res.code == 0){
				if(isExport){ //导出
					ElMessage({
						type: 'success',
						message: '正在导出，请稍后前往下载中心下载'
					})
				}else{
					pageData.tableData = res.data.map((n)=>{
						n.ifImportanceDegree = utils.getStartNum(n.ifImportanceDegree); //特殊处理意向级别
						return n;
					});
					pageData.totalCount = res.count;
				}
			}
		})
	}
	
	//ajax提交保存通用提示
	function ajaxSaveCallBack(text,callback){
		ElMessage({
			type: 'success',
			message: text
		})
		searchTable();
		callback && callback();
	}

	// 修改跟进
	function oneFollowEvent(id){
		pageData.recordId = id;
		pageData.addFollowShow = true;
	}
	function addFollowSave(res){
		pageData.addFollowShow = false;
		searchTable();
	}
	function addFollowClose(){
		pageData.addFollowShow = false;
	}
	
	
	//删除跟进记录
	function deleteEvent(id){
		nowTableRow = id;
		pageData.confirmTitle = '是否删除该记录？';
		confirmRef.value.show();
	}
	//通用确认框确认事件回调
	function confirmSave(){
		deleteFollow({
			id:nowTableRow
		}).then((res)=>{
			if(res.code == 0){
				ajaxSaveCallBack('删除成功');
			}
		})
		
	}
	
	
	//会员详情
	function memberDetail(id){
    router.push({path:'/branch/memberDetail',query:{encryptionId:id}});
	}


	
	// 页面缓存
	onBeforeRouteLeave((to, from)=>{
	    if (to.path === '/branch/memberDetail') {
	        store.commit('SetKeep',{keepLiveRoute : ['IntentionList']});
	    } else {
	        store.commit('SetKeep',{keepLiveRoute : []});
	    }
	})
	
</script>

<style scoped lang="stylus">
	.potential_member{
		.uploadTips{
			border 1px solid #00A3FF;
			border-radius 2px;
			padding-left 5px;
			background #EBF8FF;
			color #00A3FF;
		}
	}
</style>
