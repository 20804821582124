<template>
	<div class="pt20">
		<el-button class="mb12" type="primary" @click="createEvent">上传合同模板</el-button>
		<ul class="bg-FBFBFD pl25 pr25 pt25 rx-wss">
			<li class="mr35 mb35 w240 bg-fff" v-for="(item,index) in pageData.list">
				<div class="imgBox posi-r">
					<img class="ww100" src="@/assets/template_bg.png" alt="">
					<div class="cx-cc posi-a top0 left0 bottom0 right0 none" style="background-color: rgba(0, 0, 0, 0.4);">
						<div class="bdr2 w65 h30 btn2 mb20 toUpdate rx-cc" @click="updateEvent(item.encryptionId)">编辑</div>
						<div class="bdr2 w65 h30 bg-fff btn1 cursor-pointer mb20 look rx-cc" @click="lookEvent(item.encryptionId)">查看</div>
						<div class="bdr2 w65 h30 bg-fff color-red cursor-pointer rx-cc del" @click="deleteEvent(item.encryptionId)">删除</div>
					</div>
				</div>
				<div class="pt10 pb10 pl15 pr15">
					<div class="h50 color-333 ft16" style="line-height: 20px;">{{item.fileName }}</div>
					<div class="color-999 ft14">创建时间：{{item.createTime }}</div>
				</div>
			</li>
			<div id="noData" class="pt100 pb100 rx-cc ww100" v-show="pageData.list.length == 0">
				<img src="@/assets/noData2.png" alt="">
			</div>
		</ul>
	</div>
</template>

<script setup>
	import { ref, reactive } from 'vue';
	import { ElMessage, ElMessageBox } from 'element-plus'
	import { getEsignFileTemplateList,updateEsignFileTemplate,getEsignFileTemplateInfo,updateDelEsignFileTemplate } from '@/api/contract.js';
	import {useRouter } from 'vue-router';
	const router = useRouter();
	const pageData = reactive({
		list:[]
	})
	getList();
	function getList(){
		getEsignFileTemplateList().then(res=>{
			pageData.list = res.data;
		})
	}
	function createEvent(){
		router.push('/branch/createTemp');
	}
	function updateEvent(encryptionId){
		updateEsignFileTemplate({encryptionId}).then(res=>{
			window.open(res.data.docTemplateUrl);
		})
	}
	function lookEvent(encryptionId){
		getEsignFileTemplateInfo({encryptionId}).then(res=>{
			window.open('https://s.cardmee.net/cardmee_stu_m/contract/js/pdf/web/viewer.html?file='+res.data.fileDownloadUrl+'&title=模板详情');
		})
	}
	function deleteEvent(encryptionId){
		ElMessageBox.confirm(
		    '确定删除该模板吗?',
		    '提示',
		    {
		      confirmButtonText: '确定',
		      cancelButtonText: '取消',
		      type: 'warning',
		    }
		).then(() => {
		    updateDelEsignFileTemplate({encryptionId}).then(res=>{
				ElMessage.success('删除成功');
				getList();
		    })
		})
	}
</script>

<style scoped>
	.imgBox:hover .none{
		display: flex !important;
	}
</style>