import {postAjax,getAjax}  from "../common/axios.js"

// 所属行业
export const getIndustry= params => {
	return postAjax('/api/commonIndustry/getListAll', params)
}

// 店铺详情
export const feeToEdit= params => {
	return postAjax('/api/system/feeToEdit', params)
}

// 分店编辑
export const storeFeeUpdate= params => {
	return postAjax('/api/system/storeFeeUpdate', params)
}

// 模板列表
export const assessTemplateLsit= params => {
	return postAjax('/api/assessTemplate/list', params)
}

// 测评模板启用/停用
export const updateState= params => {
	return postAjax('/api/assessTemplate/updateState', params)
}

// 测评模板删除
export const updateDel= params => {
	return postAjax('/api/assessTemplate/updateDel', params)
}

// 测评模板详情
export const templateInfo= params => {
	return postAjax('/api/assessTemplate/info', params)
}

// 身高曲线
export const heightList= params => {
	return postAjax('/api/assessDataHeight/list', params)
}

// 体重曲线
export const weightList= params => {
	return postAjax('/api/assessDataWeight/list', params)
}

// BMI曲线
export const bmiList= params => {
	return postAjax('/api/assessDataBmi/list', params)
}

// 保存为我的模板
export const insertMine= params => {
	return postAjax('/api/assessTemplate/insertMine', params, 'json')
}

// 模板更新
export const templateUpdate= params => {
	return postAjax('/api/assessTemplate/update', params, 'json')
}

// 模板报告列表
export const assessReportLsit= params => {
	return postAjax('/api/assessReport/list', params)
}

// 员工解绑
export const unBindUser= params => {
	return postAjax('/api/system/unBindUser', params)
}

// 待邦定员工列表
export const getUserToBindList= params => {
	return postAjax('/api/system/getUserToBindList', params)
}

// 查询门店会员列表 用于下拉
export const selectStoreMembersAssess = params => {
    return postAjax('/api/member/selectStoreMembersAssess',params);
}

// 测评报告删除
export const assessReportUpdateDel= params => {
	return postAjax('/api/assessReport/updateDel', params)
}

//新增线索
export const newUserInsert = params => {
    return postAjax('/api/member/memberAdd', params)
}

//编辑线索
export const cardMyInfoUpdate = params => {
    return postAjax('/api/member/memberUpdate', params)
}

//删除线索
export const memberDelete = params => {
    return postAjax('/api/member/memberDelete', params)
}

// 潜在会员列表
export const getSubjectTrailStudent = params => {
    return postAjax('/api/member/getSubjectTrailStudent', params)
}

// 同步主体潜在会员信息
export const syncSubjectTrailStudent = params => {
    return postAjax('/api/member/syncSubjectTrailStudent', params)
}

// 更新报名状态
export const updateEnrollState = params => {
    return postAjax('/api/memberCardsMyAssess/updateEnrollState', params)
}

// 模板报告详情
export const assessReportInfo = params => {
    return postAjax('/api/assessReport/info', params)
}

// 模板预览
export const infoDemo = params => {
    return postAjax('/api/assessReport/infoDemo', params)
}

// 绑定员工
export const bindUser = params => {
    return postAjax('/api/system/bindUser', params)
}

// 待同步员工列表
export const getUserToSynList = params => {
    return postAjax('/api/system/getUserToSynList', params)
}

// 一键绑定
export const synUserByOneHand = params => {
    return postAjax('/api/system/synUserByOneHand', params)
}

// 同步门店员工
export const allSynUser = params => {
    return postAjax('/api/system/synUser', params)
}
