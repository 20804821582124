<template v-loading="pageData.loading">
	<workArea :barlist="pageData.barlist">
		<div v-if="pageData.state == 0">
			<div class="rx-bc bg-fff pt15 pr40 pb15 pl15" style="border-bottom: 15px solid #f0f5f9;">
				<div class="rx-sc">
					<img class="w80 mr15" src="@/assets/contract_logo.png" alt="">
					<div>
						<p class="color-333 ft20 mb15 fw700">电子合同</p>
						<p class="ft14 color-666">合同在线签署管理工具</p>
					</div>
				</div>
				<el-button type="primary" @click="rechargeEvent">立即充值 </el-button>
			</div>
			<div class="rx-bc rechargeBg">
				<div class="flex1 pl130">
					<h1 class="color-333 ft40 mb20 fw700">电子合同，解决签约难题</h1>
					<div class="mb60 o_h">
						<div class="pt10 pl20 pr20 pb10 color-666 ft20" style="background-color: #F2F2F2;border: 1px solid #F2F2F2;float:left;">随时随地在线签署</div>
						<div class="pt10 pl20 pr20 pb10 color-666 border-box ft20" style="border: 1px solid #F2F2F2;float:left;">自助归档安全合规</div>
					</div>
					<el-button type="primary" size="large" @click="rechargeEvent">立即充值</el-button>
				</div>
				<img class="h400" src="@/assets/contract_bg.png" alt="">
			</div>
			<div class="pt100 pb180 cx-cc" style="background-color: #F8FBFF;">
				<div class="rx-sc mb20">
					<img class="w55 mr30" src="@/assets/contract_icon.png" alt="">
					<span class="color-333 ft700 ft36">产品介绍</span>
				</div>
				<img class="w180 mb40 " src="@/assets/contract_bg2.png" alt="">
				<div class="rx-sc">
					<div class="cx-cc pl60 pr60 pt40 pb40" style="background-color: #69B3FF;">
						<div class="color-fff fw700 ft26 mb20">在线签署</div>
						<div class="color-fff ft18">随时随地在线签署，报名体验好</div>
					</div>
					<div class="cx-cc pl60 pr60 pt40 pb40" style="background-color: #2A93FF;">
						<div class="color-fff fw700 ft26 mb20">自动归档</div>
						<div class="color-fff ft18">全流程自动化，提升管理效率</div>
					</div>
					<div class="cx-cc pl60 pr60 pt40 pb40" style="background-color: #69B3FF;">
						<div class="color-fff fw700 ft26 mb20">合规合法</div>
						<div class="color-fff ft18">合法合规有保障，有效避免纠纷</div>
					</div>
				</div>
			</div>
			<div class="pl100 pt65 bg-fff pb150">
				<div class="rx-se posi-r">
					<span class="bgtext">ZHAIXIANQIANSHU</span>
					<div class="rx-se posi-a">
						<span class="fwb ft50 mr20">在线签署</span>
						<span class="ft18 color-999">ZHAIXIANQIANSHU</span>
					</div>
				</div>
				<div class="posi-r pl100 pt80 pb80" style="background:linear-gradient(270deg, #5172FF 0%, #57B7FF 100%);">
					<div class="color-fff fw700 ft26 mb20">随时随地在线签署，报名体验好</div>
					<div class="color-fff fw400 ft18 mb20 w500" style="line-height: 24px;">通过系统办理报名后，系统智能匹配生成合同:家长收到短 信，点击即可签署</div>
					<img class="w430 posi-a" style="right: 7%;bottom: 35px;" src="@/assets/contract_bg3.png" alt="">
				</div>
			</div>
			<div class="pr100 pt65 bg-fff pb150">
				<div class="posi-r rx-ec">
					<span class="bgtext">ZIDONGGEDANG</span>
					<div class="rx-se posi-a right0 bottom0">
						<span class="ft18 color-999 mr20">ZIDONGGEDANG</span>
						<span class="fwb ft50">自动归档</span>
					</div>
				</div>
				<div class="posi-r pr100 pt80 pb80 rx-ec" style="background:linear-gradient(270deg, #57B7FF 0%, #5172FF 100%);">
					<div>
						<div class="color-fff fw700 ft26 mb20" style="text-align: right;">全流程自动化，提升管理效率</div>
						<div class="color-fff fw400 ft18 mb20 w500" style="line-height: 24px;text-align: right;">合同签署、归档全流程自动化，进度可查，方便管理:线上 储存降低运营成本，可以随时查看、下载</div>
					</div>
					<img class="w430 posi-a" style="left: 7%;bottom: 35px;" src="@/assets/contract_bg4.png" alt="">
				</div>
			</div>
			<div class="pl100 pt65 bg-fff pb150">
				<div class="rx-se posi-r">
					<span class="bgtext">HEFAHEGUI</span>
					<div class="rx-se posi-a">
						<span class="fwb ft50 mr20">合法合规</span>
						<span class="ft18 color-999">HEFAHEGUI</span>
					</div>
				</div>
				<div class="posi-r pl100 pt80 pb80" style="background:linear-gradient(270deg, #5172FF 0%, #57B7FF 100%);">
					<div class="color-fff fw700 ft26 mb20">合法合规有保障，有效避免纠纷</div>
					<div class="color-fff fw400 ft18 mb20 w500" style="line-height: 24px;">实名认证，数字证书，电子签名多重技术，为机构提供有 效法律依据，规避经营风险</div>
					<img class="w430 posi-a" style="right: 7%;bottom: 35px;" src="@/assets/contract_bg5.png" alt="">
				</div>
			</div>
		</div>
		<div v-else class="card_manage bg-fff pl16 pt10 pr16">
			<el-tabs v-model="tabNum" @tab-click="handleClick">
				<el-tab-pane label="合同管理" name="0" v-show="pageData.authStatus == 1"></el-tab-pane>
				<el-tab-pane label="合同模板" name="1" v-show="pageData.authStatus == 1"></el-tab-pane>
				<el-tab-pane label="认证管理" name="2"></el-tab-pane>
			</el-tabs>
			<component :is="components[tabNum]" :tab="tabNum" :info="pageData.info" @recharge="rechargeEvent"></component>
		</div>
		<el-dialog v-model="rechargeData.show" title="充值" width="840px"
			:before-close="()=>{rechargeData.show = false}">
			<ul class="pt20 pl20 pr20 pb20 ulBox">
				<li class="ww25 c_p" :class="{active:rechargeData.id == data.encryptionId}" v-for="data in rechargeData.list" @click="rechargeData.id = data.encryptionId">
					<div class="h105 bdr4 pt10 pl10 pb10">
						<div class="mb10 rx-bc">
							<div class="flex1">
								<span class="money ft16 fwb mr5">{{data.salePrice }}元</span>
								<span class="old ft12">{{data.num}}份</span>
							</div>
							<div class="label ${data.tagDesc ? '':'layui-hide'}">{{data.tagDesc}}</div>
						</div>
						<div class="mb10">
							<span class="num ft14">{{(data.salePrice/data.num).toFixed(2) }}/份</span>
							<del class="old ft12">{{(data.origPrice/data.num).toFixed(2) }}/份</del>
						</div>
						<div class="time">有效期：{{data.validTime +(data.validUnit == 1 ? '年' : data.validUnit == 2 ? '月' : '日') }}</div>
					</div>
				</li>
			</ul>
			<template #footer>
				<div class="dialog-footer">
					<el-button @click="rechargeData.show = false">取消</el-button>
					<el-button type="primary" @click="rechargeSave">确定</el-button>
				</div>
			</template>
		</el-dialog>
	</workArea>
</template>
<script>
  export default {
    name: 'BranchContract'
  }
</script>
<script setup>
	import { ref, reactive, onMounted, onBeforeUnmount } from 'vue';
	import workArea from '@/components/branch-store/workArea.vue';
	import Manage from '@/views/branch-store/contract/Manage.vue'; //合同管理
	import Template from '@/views/branch-store/contract/Template.vue'; //合同模板
	import Authentication from '@/views/branch-store/contract/Authentication.vue'; //认证管理
	import { getStoreUseInfo,getEsignCertSealInfo,getEsignProductList,esignProductOrderPay } from '@/api/contract.js';
	const components = [Manage,Template,Authentication];
	const tabNum = ref('0');
	const pageData = reactive({
		state:0,
		authStatus:false,
		loading:true,
		info:{},
		barlist:[{ name : '首页' },{ name : '电子合同' }]// 面包屑
	})
	getStoreUseInfo().then(res=>{
		pageData.state = res.data.num;
		pageData.loading = false;
		if(res.data.num > 0){
			pageData.info = res.data;
			getEsignCertSealInfo().then(res=>{
				pageData.authStatus = res.data.authStatus;
				var tab = localStorage.getItem('BranchContract') || '0';
				tabNum.value = res.data.authStatus == 1 ? tab : '2';
			})
		}
	})
	
	function handleClick(tab){
		localStorage.setItem('BranchContract', tab.props.name)
	}
	onMounted(()=> {
      if (localStorage.getItem('BranchContract') != null) {
          tabNum.value = localStorage.getItem('BranchContract')
	    }
	})
    onBeforeUnmount(() => {
		localStorage.removeItem('BranchContract')
	})
	
	//充值
	const rechargeData = reactive({
		show:false,
		id:'',
		list:[]
	})
	function rechargeEvent(){
		if(!rechargeData.list.length){
			getEsignProductList().then(res=>{
				rechargeData.list = res.data;
				var arr = res.data.filter(n=>n.ifDefault);
				if(arr.length){
					rechargeData.id = arr[0].encryptionId
				}
			})
		}
		rechargeData.show = true;
	}
	function rechargeSave(){
		esignProductOrderPay({
			encryptionId:rechargeData.id
		}).then(res=>{
			var newWindow=window.open('','','');
			newWindow.document.write(res.data);
			rechargeData.show = false;
		})
	}
</script>

<style scoped>
	.ulBox{
		display: block;
		overflow: hidden;
	}
	.ulBox li{
		float: left;
		padding-left: 10px;
		padding-right: 10px;
		box-sizing: border-box;
		height: 130px;
	}
	.ulBox li:nth-child(4n+1){
		padding-left: 0px;
	}
	.ulBox li:nth-child(4n){
		padding-right: 0px;
	}
	.ulBox li>div{
		border: 1px solid #D6D6D6;
	}
	.ulBox li>div .money{
		color:red;
	}
	.ulBox li>div .num{
		color:#333;
	}
	.ulBox li>div .old{
		color:#999;
	}
	.ulBox li.active>div{
		background: linear-gradient(to right,#FD3546,#FC8434);
		border: none;
	}
	.ulBox li.active>div .money,.ulBox li.active>div .num{
		color:white;
	}
	.ulBox li.active>div .old{
		color:#ccc;
	}
	.time{
		background-color: #FEEFD6;
		color: #8B5A2C;
		display: inline-block;
		padding: 2px 15px;
		clip-path: polygon(5% 0%,100% 0%,95% 100%,0% 100%);
	}
	.label{
		background: linear-gradient(to right,#CA6CF4,#F524F6);
		color:white;
		border-radius: 100px 0 0 100px;
		padding:2px 3px 2px 6px;
		font-size: 10px;
		display: inline-block;
	}
	.rechargeBg{
		background: linear-gradient(134deg, #FFFFFF 39%, #DAE1F1 100%);
	}
	.bgtext{
		opacity: 0.3;
		font-size: 85px;
	}
</style>
