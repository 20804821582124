<template>
	<workArea :barlist="pageData.barlist">
		<div class="card_manage bg-fff pl16 pt10 pr16">
			<el-tabs v-model="tabNum" @tab-click="handleClick">
				<el-tab-pane label="展厅模板" name="0"></el-tab-pane>
				<el-tab-pane label="作品库" name="1"></el-tab-pane>
				<el-tab-pane label="客服咨询配置" name="2"></el-tab-pane>
			</el-tabs>
			<component :is="components[tabNum]" :tab="tabNum"></component>
		</div>
	</workArea>
</template>
<script>
  export default {
    name: 'follow'
  }
</script>
<script setup>
	import { ref, reactive, onMounted, onBeforeUnmount } from 'vue';
	import { useRouter } from 'vue-router';
	import workArea from '@/components/branch-store/workArea.vue';
	import template from './template.vue'; //展厅模板
	import works from './works.vue'; //作品库
	import service from './service.vue'; //跟进提醒
	import utils from '@/common/utils.js';
	const router = useRouter();
	const components = [template,works,service];
	const tabNum = ref('0');
	// var menuId = [33100,33101]; //会员有权限tab默认项
	// for(var i = 0;i < menuId.length;i++){
	// 	if(utils.getMenuRole(menuId[i])){
	// 		tabNum.value = i.toString();
	// 		break;
	// 	}
	// }
	const pageData = reactive({
		barlist:[{ name : '首页' },{ name : '展厅管理' },{ name : '展厅模板'}]// 面包屑
	})
	function handleClick(tab){
		// pageData.barlist[2].name = tabNum.value == 0 ? '跟进管理' :'岗位管理';
		localStorage.setItem('template', tab.props.name)
	}
	onMounted(()=> {
      if (localStorage.getItem('template') != null) {
          tabNum.value = localStorage.getItem('template')
		  // pageData.barlist[2].name = tabNum.value == 0 ? '员工管理' :'岗位管理';
	    }
	})
    onBeforeUnmount(() => {
		localStorage.removeItem('template')
	})
</script>

<style scoped lang="stylus">
	
</style>
