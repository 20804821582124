<!-- 会员详情页面 -->
<template>
    <workArea :barlist="barlist">
        <div class="ww100 cx-sc">
            <div class="ww100 h130 mt20 pl20 pr20 rx-bc">
                <div class="ww100 h130 rx-sc posi-r">
                    <div class="w400 h30 posi-a rx-ec right0 top0 c_p">
                        <el-button type="primary" @click="toEditMember">编辑会员信息</el-button>
                    </div>
                    <div class="w60 h130">
                        <img :src="pageData.userInfo.headImgUrl" class="w60 h60 b_r_100">
                    </div>
                    <div class="h130 ml10 rx-ss">
                        <div class="w230 cx-ss">
                            <div class="ww100 h30 rx-sc">
                                <span class="fwb ft16">{{pageData.userInfo.realName}}</span>
                                <div class="tag_blue bdr2 ml5" v-if="pageData.userInfo.trialFlag == 1">正式会员</div>
                                <div class="tag_red bdr2 ml5" v-else>潜在会员</div>
                            </div>
                            <div class="ww100 h20 mt5 rx-sc">
                                <Iphone class="w15 h15"></Iphone>
                                <span>{{pageData.userInfo.telephoneNum}}</span>
                            </div>
                            <div class="ww100 h40 mt10 rx-sc">
                                <span>会员生日：</span>
                                <span>{{pageData.userInfo.userBirthday}}</span>
                            </div>
                        </div>
                        <div class=" cx-ss">
                            <div class="ww100 h30 rx-sc"></div>
                            <el-dropdown trigger="click">
                                <div class="ww100 h20 mt5 rx-sc color-main tool_btn c_p" style="text-decoration:underline">
                                    更多信息
                                    <el-icon><d-arrow-right /></el-icon>
                                </div>
                                <template #dropdown>
                                  <div class="w235 bdr4 pl10 pt15 pb20 pr10 cx-sc">
                                      <div class="ww100 rx-sc h30">
                                          <div class="w80 rx-ec color-666">绑定微信：</div>
                                          <div class="color-333">{{ pageData.userInfo.storeWechatBind ? '已绑定' : '未绑定' }}<span v-if="pageData.userInfo.storeWechatBind && utils.getMenuRole(3130008)" class="color-red ml5 c_p t_d_u" @click="bindWechat">解除绑定</span></div>
                                      </div>
                                      <div class="ww100 rx-sc h30">
                                          <div class="w80 rx-ec color-666">所属门店：</div>
                                          <div class="color-main c_p t_d_u tool_btn" @click="pageData.isSellShow = true">共{{ pageData.userInfo.storeCount }}家门店</div>
                                      </div>
                                      <div class="ww100 rx-sc h30">
                                          <div class="w80 t_a_r color-666">跟进员工：</div>
                                          <div class="w120 color-333">
                                              <el-tooltip
                    				            class="box-item"
                    				            effect="dark"
                    				            :content="pageData.userInfo.adviserNames"
                    				            placement="right"
                    				          >
                    				             <div class="right-text">{{ pageData.userInfo.adviserNames }}</div>
                    				          </el-tooltip>
                                          </div>
                                      </div>
                                      <div class="ww100 rx-sc h30">
                                          <div class="w80 rx-ec color-666">会员来源：</div>
                                          <div class="color-333">{{ pageData.userInfo.channelName }}</div>
                                      </div>
                                      <div class="ww100 rx-sc h30">
                                          <div class="w80 rx-ec t_a_r color-666">备注信息：</div>
                                          <div class="w120 color-333">
                                              <el-tooltip
                    				            class="box-item"
                    				            effect="dark"
                    				            :content="pageData.userInfo.remark"
                    				            placement="right"
                    				          >
                    				             <div class="right-text">{{ pageData.userInfo.remark }}</div>
                    				          </el-tooltip>
                                          </div>
                                      </div>
                                  </div>
                                </template>
                            </el-dropdown>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="ww100 h10 bg-gray"></div>
        <div class="ww100 pl20 pr20 pt16 pb12">
            <div class="mt10">
                <el-tabs v-model="pageData.tabNum">
                    <el-tab-pane label="跟进记录" name="0"></el-tab-pane>
                </el-tabs>
                <component v-if="pageData.userInfo" :is="components[pageData.tabNum]" :tab="pageData.tabNum" :memberId="pageData.userInfo.cardMyId" :info="pageData.userInfo" :num="pageData.cordOrCourse"></component>             
                <div class="ww100 h60"></div>
            </div>
        </div>
       
        <!-- 所属门店-->
		<el-dialog
		    v-model="pageData.isSellShow"
		    title="查看门店"
			width="776px"
		    :before-close="()=>{pageData.isSellShow = false}"
		    custom-class="popwechat"
		  >
		    <div class="p20">
				<div>
					<el-table :data="pageData.isSellTableData" border stripe style="width: 100%">
						<el-table-column type="index" label="序号" width="80" />
					    <el-table-column prop="storeName" label="门店名称">
                            <template #default="scope">
                                {{ scope.row.storeName ? scope.row.storeName : '-' }}
							</template>
                        </el-table-column>
                        <el-table-column prop="storeAdd" label="门店名称">
                            <template #default="scope">
                                {{ scope.row.storeAdd ? scope.row.storeAdd : '-' }}
							</template>
                        </el-table-column>
					</el-table>
					<div class="mt20 rx-ec">
					    <el-pagination
					      v-model:currentPage="pageData.isSellPage"
					      page-size="15"
						  :total="pageData.totalCount"
					      layout="prev, pager, next"
					      @current-change="shelfChange"
					    >
					    </el-pagination>
					</div>
				</div>
		    </div>
			<template #footer>
    		  	<span class="dialog-footer"></span>
    		</template>
		</el-dialog>
    </workArea>
    <AddMember v-model="pageData.editMemberShow" :title="pageData.editMemberTitle" :id="pageData.userInfo.encryptionId" @close="editMemberClose" @save="editMemberSave"/>
</template>

<script setup>
    import { ref, reactive, onMounted, onActivated } from 'vue';
    import { useRouter, onBeforeRouteLeave, onBeforeRouteUpdate } from "vue-router";
    import { useStore } from 'vuex'
    import { ElMessage, ElMessageBox } from 'element-plus'
    import formQuery from '@/components/branch-store/formQuery.vue';
    import workArea from '@/components/branch-store/workArea.vue';
    import { getMemberDetail,queryMemberOrderSummary, selectOneMemberStore } from '@/api/member.js';
    import { allotTagToBatch, vipUpdateTagBatch, deleteTagDetails, unBindUser } from '@/api/intentionList.js';
    import { Search, Iphone, Plus, DArrowRight, DArrowLeft, CircleCloseFilled } from '@element-plus/icons-vue';
    import utils from '@/common/utils.js';
    import AddMember from '@/components/branch-store/member/AddMember.vue';
    import FollowRecord from '@/views/branch-store/crm/memberDetail/followRecord.vue';//跟进记录
    const router = useRouter();
    const store = useStore();
    const barlist = reactive([{ name : '返回', type:0 },{ name : '会员详情' }]); // 面包屑
    const components = [FollowRecord]
    const queryOpts = reactive({});
    const { encryptionId } = router.currentRoute.value.query;
    const confirmRef = ref()
    const ruleFormRef = ref()
    const pageData = reactive({
        isSellPage:1,
        totalCount: 0,
        isSellTableData: [],
        isSellShow: false, // 所属门店弹框
        dropdown: false,
        dialogVisible: false, // 手动扣除弹框
        batchSetLabelShow: false, // 添加标签弹框
        batchSetLabelId:'', // 选中的标签ID
        batchSetLabelList: [], // 标签列表
        editMemberTitle : '编辑会员信息',
        editMemberShow : false,
        userInfo : {},
        buycardsList : [], // 已购卡项
        followList : [], // 跟进记录
        tabNum: '0',
		updateKey:'' ,//用来更新组件
		cordOrCourse:{}, //持有卡课数量
        ruleForm:{
            region: '',
            name: '',
            remark: ''
        }, // 手动消费表单
        rules: {
            region: [{ required: true, message: '请选择可用资产', trigger: 'change' }],
            name: [{ required: true, message: '请输入本次消费次数', trigger: 'blur' }]
        }
    });
 
   
    getUserInfo(1);
    // 获取会员信息
    function getUserInfo(type){
        var params = {encryptionId};
        getMemberDetail(params).then((res)=>{
            pageData.userInfo = res.data
            if (type) {
                pageData.tabNum = '0'
            }
            pageData.updateKey = (new Date()).getTime();
        });
    }
    function getTagsList() {
        allotTagToBatch().then((res)=>{
            if(res.code == 0){
                var typeArray = [];
                for(var i=0;i<res.data.length;i++){
                    typeArray.push({
                        value : res.data[i]['id'],
                        label : res.data[i]['tagName'],
						eId:res.data[i]['encryptionId'],
                    });
                }
                pageData.batchSetLabelList = typeArray;
            }
        })
    }
    
    /**
     * 解绑微信
     */
    function bindWechat() {
        ElMessageBox.confirm('确定解绑微信吗？', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning',
        }).then(() => {
			unBindUser({ encryptionId: encryptionId }).then((res)=>{
				if(res.code == 0){
					ElMessage({
						type: 'success',
						message: '解绑成功',
					})
                    getUserInfo();
				}
			})
        })
	   
    }
  
    /**
     * 查看所属门店
     */
    shelfChange()
    function shelfChange() {
        let params = {
            page: pageData.isSellPage,
            encryptionId: encryptionId
        }
        selectOneMemberStore(params).then((res) => {
            if (res.code == 0) {
                pageData.isSellTableData = res.data
                pageData.totalCount = res.count
            }
        })
    }
	
	// 显示编辑会员
    function toEditMember(){
        pageData.editMemberShow = true;
    }
    // 编辑会员后
    function editMemberSave(res){
        pageData.editMemberShow = false;
        getUserInfo();
    }
    // 关闭新增会员
    function editMemberClose(){
        pageData.editMemberShow = false;
    }
	
    // mounted 钩子函数
    onMounted(()=>{
        getTagsList()
    })
</script>

<style type="text/css" scoped>
    .itemtable{width: 100%;border:1px solid #eaeaea;border-radius:5px;}
    .dot{width:6px;height:6px;border-radius:6px;background-color: #666;}
    .cardbox{ border:2px solid #e0e0e099; border-radius:4px;width:15%;}
    .cardbar{width:90px;height:4px;margin-top:10px;border-radius:5px;}
    .arrowdown{
        transform:rotate(90deg);
        -ms-transform:rotate(90deg);     /* IE 9 */
        -moz-transform:rotate(90deg);    /* Firefox */
        -webkit-transform:rotate(90deg); /* Safari 和 Chrome */
        -o-transform:rotate(90deg);  /* Opera */
        width:13px;
        height:13px;
        margin-left:2px;
        }
    .manualForm /deep/ .el-input__wrappe {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        text-align: left
    }
    .manualForm .price {
        width: 93%;
    }
    .right-text{
        white-space:nowrap;
        overflow:hidden;
        text-overflow:ellipsis;
    }
</style>