<template>
    <div v-show="propData.show" ref="Mask" class="mask ww100 hh100" @click="unPreview" @touchmove.prevent ></div>
    <div v-show="propData.show" class="mask-box" @touchmove.prevent >
        <div v-if="pageData.show" class="ww100 hh100 mask-phone o_y_s" :class="[pageData.info.templateType == 1 ? 'dance' : (pageData.info.templateType == 2 ? 'sports' : (pageData.info.templateType == 3 ? 'basketball' : 'bodybuilding'))]">
            <div class="item1 posi-r">
                <el-image class="hh100 ww100" :src="pageData.info.item1.backPic" />
                <div class="posi-a logo">
                    <img class="w80 h40" v-if="pageData.info.templateType == 1" src="@/assets/dance.png" alt="">
                    <img class="w80 h40" v-if="pageData.info.templateType == 2" src="@/assets/sports.png" alt="">
                    <img class="w80 h40" v-if="pageData.info.templateType == 3" src="@/assets/basketball.png" alt="">
                    <img class="w80 h40" v-if="pageData.info.templateType == 4" src="@/assets/bodybuilding.png" alt="">
                    <div class="mt20 rx-c">
                        <div class="color-fff img-title">
                            <div :class="[pageData.info.item1.title && pageData.info.item1.title.length > 12 ? 'ft16' : 'ft20']">{{ pageData.info.item1.title }}</div>
                        </div>
                    </div>
                </div>
                <div class="posi-a title">
                    <div class="ft14 fwb mb10 color-fff">{{ pageData.info.memberName }}</div>
                    <div class="color-fff ft12">测评时间：{{ pageData.info.createTime }}</div>
                    <div class="color-fff ft12">测评老师：{{ pageData.info.teacherName }}</div>
                </div>
            </div>
            <div class="item2 posi-r" v-if="!pageData.info.itemList[1].hideState">
                <div class="ww100 hh100 box-item">
                    <div class="top">
                        <span class="serial ft12 mr10">01</span>
                        <span class="title ft12 fwb">基本信息</span>
                    </div>
                    <div class="container bg-fff">
                        <div class="message mt10" v-if="pageData.info.item2.openBase == 1">
                            <div class="rx-c mb20">
                                <img class="mr20 avatar" src="@/assets/avatar.png" alt="">
                                <div class="rx-ce">
                                    <span class="ft14 color-333 fwb mr16">{{ pageData.info.memberName }}</span>
                                    <div>
                                        <span class="color-999 ft12 mr20">{{ pageData.info.item2.sex == 1 ? '女' : '男' }}</span>
                                        <span class="color-999 ft12">{{ pageData.info.item2.age }} {{ pageData.info.item2.ageUnit == 1 ? '岁' : '月' }}</span>
                                    </div>
                                </div>
                            </div>
                            <div class="message-box rx-bc">
                                <div class="cx-cc">
                                    <div><img class="img mb15" src="@/assets/weight.png" alt=""></div>
                                    <div class="ft14 fwb">{{ pageData.info.item2.weight }}</div>
                                </div>
                                <div class="cx-cc">
                                    <div class="ft14 fwb h35 mb15 l_h35">{{ pageData.info.item2.height }}</div>
                                    <div class="stature ft10 color-fff t_a_c">身高</div>
                                </div>
                                <div class="cx-cc">
                                    <div><img class="img mb15" src="@/assets/bmi.png" alt=""></div>
                                    <div class="ft14 fwb">{{ pageData.info.item2.bmi }}</div>
                                </div>
                            </div>
                        </div>
                        <div class="height-chart" v-if="pageData.info.item2.openHeight == 1">
                            <heightChart :show="propData.show" :info="pageData.dataInfo" :value="pageData.info.item2.height"></heightChart>
                        </div>
                        <div class="weight-chart" v-if="pageData.info.item2.openWeight == 1">
                            <weightChart :show="propData.show" :info="pageData.dataInfo" :value="pageData.info.item2.weight"></weightChart>
                        </div>
                        <div class="bmi-chart" v-if="pageData.info.item2.openBmi == 1 && pageData.dataInfo.age >6">
                            <bmiChart :show="propData.show" :info="pageData.dataInfo" :value="pageData.info.item2.bmi"></bmiChart>
                        </div>
                    </div>
                </div>
            </div>
            <div class="item3" v-if="pageData.info.item3">
                <div class="ww100 hh100 box-item">
                    <div class="top">
                        <span class="serial ft12 mr10">02</span>
                        <span class="title ft12 fwb">教学评定基础测试</span>
                    </div>
                    <div class="container bg-fff">
                        <div class="message mt10">
                            <div v-if="pageData.info.item3.openPliable == 1">
                                <span class="color-333 fwb">{{ pageData.info.item3.pliableTitle }}</span>
                                <flexibleChart :info="pageData.info.pliableList"></flexibleChart>
                            </div>
                            <div v-if="pageData.info.item3.openQuality == 1">
                                <span class="color-333 fwb">{{ pageData.info.item3.qualityTitle }}</span>
                                <div>
                                    <div class="card mt20" v-for="(item, index) in pageData.info.qualityList" :key="index">
                                        <div class="card-top rx-bc pl10 pr15">
                                            <div class="ft14 fwb">{{ item.name }}</div>
                                        </div>
                                        <div class="card-box bg-fff">
                                            <div class="card-box-top rx-bc">
                                                <div>
                                                    <div class="ft15 color-666">{{ item.tags.join(',') }}</div>
                                                </div>
                                            </div>
                                            
                                            <div class="card-box-container cx-c pb35">
                                                <div class="rx-bc mb12">
                                                    <div class="ww25 t_a_c color-808080" :class="[ item.level == 1 ? ' color-ED2169 fwb ft16' : '' ]">标准</div>
                                                    <div class="ww25 t_a_c color-808080" :class="[ item.level == 2 ? ' color-ED2169 fwb ft16' : '' ]">中等</div>
                                                    <div class="ww25 t_a_c color-808080" :class="[ item.level == 3 ? ' color-ED2169 fwb ft16' : '' ]">良好</div>
                                                    <div class="ww25 t_a_c color-808080" :class="[ item.level == 4 ? ' color-ED2169 fwb ft16' : '' ]">优秀</div>
                                                </div>
                                                <div class="rx-bc bar">
                                                    <div class="ww25 h10 div1 bg-e6e6e6" :class="[ item.level ? 'bg1' : '' ]"></div>
                                                    <div class="ww25 h10 div2 bg-e6e6e6" :class="[ item.level > 1 ? 'bg2' : '' ]"></div>
                                                    <div class="ww25 h10 div3 bg-e6e6e6" :class="[ item.level > 2 ? 'bg3' : '' ]"></div>
                                                    <div class="ww25 h10 div4 bg-e6e6e6" :class="[ item.level > 3 ? 'bg4' : '' ]"></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="mt20" v-if="!pageData.info.itemList[3].hideState && pageData.info.item4.openPosture.length > 0">
                                <span class="color-333 fwb mb10">体态测试</span>
                                <div class="item4 rx-wbs">
                                    <div class="w70 cx-cc" v-for="(item, index) in pageData.list" :key="index">
                                        <div class="ft15 mb20 color-666">{{ item.name }}</div>
                                        <div class="img">
                                            <img class="ww100 hh100" :src="`https://image.taokatao.cn/imgs/market/icon${item.val}.png`" alt="">
                                        </div>
                                        <div class="d_i_b rx-bc w70 mb15 mt15">
                                            <div class="cx-c">
                                                <div>
                                                    <img v-if="item.grade == 1" class="iocn" src="@/assets/normal_open.png" alt="">
                                                    <img v-else class="iocn" src="@/assets/normal.png" alt="">
                                                </div>
                                                <div class="color-ccc ft8">正常</div>
                                            </div>
                                            <div class="cx-c">
                                                <div>
                                                    <img v-if="item.grade == 2" class="iocn" src="@/assets/slight_open.png" alt="">
                                                    <img v-else class="iocn" src="@/assets/slight.png" alt="">
                                                </div>
                                                <div class="color-ccc ft8">轻微</div>
                                            </div>
                                            <div class="cx-c">
                                                <div>
                                                    <img v-if="item.grade == 3" class="iocn" src="@/assets/grave_open.png" alt="">
                                                    <img v-else class="iocn" src="@/assets/slight.png" alt="">
                                                </div>
                                                <div class="color-ccc ft8">严重</div>
                                            </div>
			                        	</div>
                                    </div> 
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="item5" v-if="pageData.info.item5">
                <div class="ww100 hh100 box-item">
                    <div class="top">
                        <span class="serial ft12 mr10">03</span>
                        <span class="title ft12 fwb">测评结果</span>
                    </div>
                    <div class="container bg-fff">
                        <div class="message mt10">
                            <div class="mt30 h150 posi-r score">
                                <img class="ww100 h150 posi-a" src="@/assets/score.png" alt="">
                                <div class="posi-r w55 score-branch ft37 fwb">{{ pageData.info.item5.comScore }}</div>
                                <div class="posi-r w60 score-text ft12 fwb">综合评分</div>
                            </div>
                            <div class="t_a_c fwb cft15 color-333 mb20 mt10"> 超过 <span class="percentage">{{ pageData.info.item5.exceedRatio }}%</span> 同龄孩子</div>
                            <div class="message-box rx-bc">
                                <div class="cx-cc">
                                    <div class="color-999 mb10">测评项</div>
                                    <div class="color-333 fwb">{{ pageData.info.item5.assessCount }}项目</div>
                                </div>
                                <div class="cx-cc">
                                    <div class="color-999 mb10">测评时长</div>
                                    <div class="color-333 fwb">{{ pageData.info.item5.assessTime }}分钟</div>
                                </div>
                            </div>
                            <div class="mt30" v-if="pageData.info.item5.openComment">
                                <div class="color-333 ft16 fwb t_a_c mb20">老师评语</div>
                                <div>
                                    <div class="ft12 color-666 mb20 comment">{{ pageData.info.item5.comment }}</div>
                                    <img class="ww100" :src="pageData.info.item5.assessPic" alt="">
                                </div>
                            </div>
                            <div class="mt30" v-if="pageData.info.item5.openSuggest">
                                <div class="color-333 ft16 fwb t_a_c mb20">测评建议</div>
                                <div>
                                    <div class="ft12 color-666 mb20">{{ pageData.info.item5.suggest }}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="item6" v-if="!pageData.info.itemList[5].hideState || !pageData.info.itemList[6].hideState">
                <div class="ww100 hh100 box-item">
                    <div class="top">
                        <span class="serial ft12 mr10">04</span>
                        <span class="title ft12 fwb">附录</span>
                    </div>
                    <div class="container bg-fff">
                        <div class="message mt10">
                            <div v-if="pageData.info.item6.openPostureDemo">
                                <div class="rx-bc mb20">
                                    <div class="color-333 ft12 fwb">附录：青少年常见体态问题</div>
                                </div>
                                <div class="img-box">
                                    <img class="" src="@/assets/posture.png" alt="">
                                </div>
                                <div v-if="pageData.info.item6.content" v-html="pageData.info.item6.content"></div>
                            </div>
                            <div v-if="pageData.info.item7">
                                <div class="rx-bc mb20">
                                    <div class="color-333 ft12 fwb">附录：舞蹈教育优势</div>
                                </div>
                                <div class="img-box">
                                    <img class="" src="@/assets/advantage.png" alt="">
                                </div>
                                <div v-html="pageData.info.item7.content"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="item7 posi-r" v-if="pageData.info.item8">
                <div class="ww100 box posi-a posi-r">
                    <img class="banc" :src="pageData.info.item8.backPic" alt="">
                    <div class="top posi-a">
                        <span class="title ft14 fwb">封底</span>
                    </div>
                    <div class="top-text pl10 pr10 posi-a">
                        <div class="color-666">
                            <span class="v_a_m l-h22">{{ pageData.info.item8.title }}</span>
                        </div>
                        <div class="color-666 t_a_r mt10 mr20"> 
                           <span class="v_a_m"> ——  {{ pageData.info.item8.titleSign }}</span>
                        </div>
                    </div>
                    <div class="avatar posi-a">
                        <div class="avatar-img">
                            <img :src="pageData.info.item8.logo" alt="">
                        </div>
                    </div>
                    <div class="avatar-text posi-a">
                        <div class="rx-c mt10">
                            <div v-if="!pageData.show3" class="color-666 ml10 ft12">{{ pageData.info.item8.storeName }}</div>
                        </div>
                        <div class="rx-c mt10">
                            <div class="color-666 ml10 ft12">{{ pageData.info.item8.storePhone }}</div>
                        </div>
                        <div class="rx-c mt10">
                            <div class="color-666 ml10 ft12">{{ pageData.info.item8.storeAddress }}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
    import { ref, reactive, watch } from 'vue';
    import { assessReportInfo, infoDemo } from '@/api/market.js';
    import heightChart from '@/components/branch-store/template/echarts/heightChart.vue';
    import weightChart from '@/components/branch-store/template/echarts/weightChart.vue';
    import bmiChart from '@/components/branch-store/template/echarts/bmiChart.vue';
    import flexibleChart from '@/components/branch-store/template/echarts/flexibleChart.vue';
    const Mask = ref(null)
    const propData = defineProps({
		data: String,
        show: Boolean,
        type: Number // 1-报告预览 2-模板预览
	})
    const emit = defineEmits(['unPreview']);
    const pageData = reactive({
        show: false,
        info: {},
        dataInfo: {
            sex: '',
            ageMin: '',
            ageMax: '',
            age: ''
        },
        allList: [{
            name: '驼背',
            val: 1,
        }, {
            name: '骨盆前倾',
            val: 2,
        }, {
            name: '骨盆后倾',
            val: 3,
        }, {
            name: '颈椎侧曲',
            val: 4,
        }, {
            name: '圆肩探颈',
            val: 5,
        }, {
            name: '弯腰弓背',
            val: 6,
        }, {
            name: '膝超伸',
            val: 7,
        }, {
            name: 'O型腿',
            val: 8,
        }, {
            name: 'X型腿',
            val: 9,
        }],
        lsit: []
    })
    watch(() => propData.show, (newVal, oldVal) => {
        if (newVal) {
            getInfo()
        }
    })
     
    function getInfo() {
        let apiName = propData.type == 1 ? assessReportInfo : infoDemo
        apiName({encryptionId: propData.data}).then(res => {
            if (res.code == 0) {
                pageData.show = true
                pageData.info = res.data
                pageData.dataInfo.sex = pageData.info.item2.sex
                if (pageData.info.item2.ageUnit != 1) {
                    pageData.dataInfo.ageMin = 1
                    pageData.dataInfo.ageMax = 6
                    pageData.dataInfo.age = 1
                } else {
                    if (pageData.info.item2.age <= 6) {
                        pageData.dataInfo.ageMin = 1
                        pageData.dataInfo.ageMax = 6
                    } else {
                        pageData.dataInfo.ageMin = 7
                        pageData.dataInfo.ageMax = 18
                    }
                    pageData.dataInfo.age = pageData.info.item2.age
                }
                pageData.list = []
                pageData.info.item4.postureOpens && pageData.info.item4.postureOpens.split(',').forEach((item,index) => {
                    pageData.allList.find((v) => {
                        if (item == v.val) {
                            v.grade = pageData.info.item4.postureScore.split(',')[index]
                            pageData.list.push(v)
                        }
                    })
                });
            }
        })
    }
    
    /**
     * 关闭报告
     */
    function unPreview() {
        emit('unPreview');
    }
</script>
<style type='css/style' scoped>
    .mask{
        position: fixed;
        top: 0;
        left: 0;
        background: rgba(0,0,0,0.5);
        z-index: 2275;
    }
    .mask-box {
        position: fixed;
        width: 300px;
		height: 600px;
        padding: 48px 22px 20px 22px;
        background: url(../../assets/simulator.png) no-repeat center center;
        background-size: contain;
    	top: 50%;
    	left: 50%;
        transform: translate(-50%, -50%);	
        z-index: 2276;
    }
    .mask-phone{
        border-radius: 2px 2px 35px 35px
    }
    /* 模块一 */
    .item1 .logo {
        left: 14px;
        top: 90px;
    }
    .item1 .img-title div {
        padding-bottom:2px;
        border-bottom:1px solid #fff;
    }
    .item1 .img-title {
        padding-bottom:2px;
        border-bottom:1px solid #fff;
    }
    .item1 .title {
        left: 14px;
        bottom: 60px;
    }
    /* 模块二 */
    .item2{
        padding: 0 2%;
        margin-top: -38px;
    }
    .item2 .box-item .top{
        background: #FFF;
        border-top-left-radius: 10px;
        padding: 10px;
        padding-bottom: 0;
        -webkit-clip-path: polygon(0% 0%,40% 0%,45% 100%,100% 100%,100% 100%,0% 100%);
        clip-path: polygon(0% 0%,40% 0%,45% 100%,100% 100%,100% 100%,0% 100%);
    }
    .item2 .container {
        padding: 10px;
        border-top-right-radius: 10px;
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
        margin-top: -1px;
    }
    .item2 .message .avatar {
        width: 44px;
        height: 44px;
    }
    .item2 .message .img {
        width: 32px;
    }
    .item2 .message .stature {
        background: #AAE642;
        border-radius: 12px;
        width: 52px;
        height: 23px;
        line-height: 23px;
    }
    .item2 .serial {
        font-weight: 1000;
    }
    .item2 .message-box {
        padding: 10px 22px;
        border-radius: 6px;
    }
    .item2 .message-tip {
        padding: 4px 7px;
    }
    /* 模块三 */
    .item3{
        padding: 0 2%;
        margin-top: 16px;
    }
    .item3 .box-item .top{
        background: #FFF;
        border-top-left-radius: 10px;
        padding: 10px;
        padding-bottom: 0;
        -webkit-clip-path: polygon(0% 0%,40% 0%,45% 100%,100% 100%,100% 100%,0% 100%);
        clip-path: polygon(0% 0%,60% 0%,65% 100%,100% 100%,100% 100%,0% 100%);
    }
    .item3 .container {
        padding: 10px;
        border-top-right-radius: 10px;
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
        margin-top: -1px;
    }
    .item3 .card {
        background: #FFF3F9;
        box-shadow: 0px 0px 13px 0px rgba(215,207,211,0.5000);
    }
    .item3 .card-top {
        padding-top: 16px;
        padding-bottom: 14px;
    }
    .item3 .card-top div {
        color: #2E3F51
    }
    .item3 .card-box {
        padding-left: 11px;
        padding-right: 15px;
        border-radius: 8px
    }
    .item3 .card-box-top {
        padding-top: 19px;
        padding-bottom: 19px;
    }
    .item3 .card-box-container .img {
        width: 30%;
    }
    .item3 .bar .div1{
        border-right: 2px solid #FFF;
        border-top-left-radius: 10px;
        border-bottom-left-radius: 10px;
    }
    .item3 .bar .div2{
        border-right: 2px solid #FFF;
    }
    .item3 .bar .div3{
        border-right: 2px solid #FFF;
    }
    .item3 .bar .div4{
        border-top-right-radius: 10px;
        border-bottom-right-radius: 10px;
    }
    .item3 .bar .bg1{
        background: linear-gradient(90deg, #EB5835 0%, #F6BC49 100%)
    }
    .item3 .bar .bg2{
        background: linear-gradient(90deg,  #F6BC49 0%, #CFDD4D 100%)
    }
    .item3 .bar .bg3{
        background: linear-gradient(90deg, #CFDD4D 0%, #6CD547 100%)
    }
    .item3 .bar .bg4{
        background: linear-gradient(90deg, #6CD547 0%, #01C4F2 100%)
    }
    .item4 .img {
        width: 40px;
        height: 211px;
    }
    .item4 .iocn {
        width: 16px;
        height: 16px;
    }
    /* 模块四 */
    .item5{
        padding: 0 2%;
        margin-top: 16px;
    }
    .item5 .box-item .top{
        background: #FFF;
        border-top-left-radius: 10px;
        padding: 10px;
        padding-bottom: 0;
        -webkit-clip-path: polygon(0% 0%,40% 0%,45% 100%,100% 100%,100% 100%,0% 100%);
        clip-path: polygon(0% 0%,40% 0%,45% 100%,100% 100%,100% 100%,0% 100%);
    }
    .item5 .container {
        padding: 10px;
        border-top-right-radius: 10px;
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
        margin-top: -1px;
    }
    .item5 .serial {
        font-weight: 1000;
    }
    .item5 .message-box {
        padding: 12px 20px;
        border-radius: 6px;
    }
    .item5 .score img {
        left: 50%;
        transform: translateX(-50%);	
    }
    .item5 .score .score-branch {
        left: 53%;
        top: 45%;
        transform: translate(-50%,-50%);
        color: #D3358F;
    }
    .item5 .score .score-text {
        left: 53%;
        top: 33%;
        transform: translate(-50%,-50%);
        color: #8E79D8;	
    }
    .item5 .comment{
        word-break:break-word;
    }
    /* 模块五 */
    .item6{
        padding: 0 2%;
        margin-top: 16px;
    }
    .item6 .box-item .top{
        background: #FFF;
        border-top-left-radius: 10px;
        padding: 10px;
        padding-bottom: 0;
        -webkit-clip-path: polygon(0% 0%,40% 0%,45% 100%,100% 100%,100% 100%,0% 100%);
        clip-path: polygon(0% 0%,40% 0%,45% 100%,100% 100%,100% 100%,0% 100%);
    }
    .item6 .container {
        padding: 10px;
        border-top-right-radius: 10px;
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
        margin-top: -1px;
    }
    .item6 .message .img-box{
        width: 210px;
        padding: 16px 12px 19px 13px;
    }
    .item6 .message .img-box img {
        width: 100%;
    }
    /* 模块六 */
    .item7{
        padding: 0 2%;
        margin-top: 16px;
    }
    .item7 .box {
	    left: 50%;
        transform: translateX(-50%);
    }
    .item7 .banc{
        width: 100%;
        height: 100%;
        padding: 5px;
        padding-bottom: 8px;
    }
    .item7 .top {
        top: 15px;
        left: 15px;
    }
    .item7 .top-text{ 
        top: 40px;
        left: 7px;
    }
    .item7 .avatar {
        left: 20px;
        bottom: 150px;
    }
    .item7 .avatar-text {
        left: 20px;
        top: 240px;
    }
    .item7 .avatar-img img {
        width: 60px;
        height: 60px;
        border-radius: 50%;
    }
    /* 主题颜色 */
    .dance {
        background: #FDD2E7;
    }
    .dance .top .serial, .dance .top .title {
        color: #ED2169;
    }
    .dance .message-box{
        background: #FFEFF7;
    }
    .sports {
        background: #203C1E;
    }
    .sports .top .serial, .sports .top .title {
        color: #203C1E;
    }
    .sports .message-box{
        background: #F0FCEF;
    }
</style>