<template>
	<div class="potential_member">
		<formQuery :queryOpts="pageData.queryOpts" @query="getTableData"></formQuery>
		<div class="mb12 rx-bc">
			<div>
				<el-button type="primary" @click="followPeopleEvent">分配跟进人</el-button>
				<el-button type="danger" plain @click="batchDeleMember">批量删除</el-button>
			</div>
			<el-button @click="exportMember" :icon="Upload" >导出</el-button>
		</div>
		<div>
			<el-table :data="pageData.tableData" stripe style="width: 100%" @selection-change="selectionChange">
				<el-table-column type="selection" width="55"/>
				<el-table-column fixed prop="realName" label="会员姓名" width="110" show-overflow-tooltip >
					<template #default="scope">
						<span @click="memberDetail(scope.row.encryptionId)" class="color-409EFF c_p tool_btn">{{scope.row.realName}}</span>
					</template>
				</el-table-column>
				<el-table-column prop="telephoneNum" label="手机号" min-width="110" />
				<el-table-column prop="followUpStatusId" label="跟进状态" min-width="100" >
					<template #default="scope">
						<span>{{scope.row.upStatusName || '暂无状态'}}</span>
					</template>
				</el-table-column>
			    <el-table-column prop="productTyp" label="意向级别" min-width="150">
					<template #default="scope">
						<el-rate v-model="scope.row.ifImportanceDegree" disabled size="large"></el-rate>
					</template>
				</el-table-column>
			    <el-table-column prop="lastAdviserNames" label="前跟进人" min-width="100" show-overflow-tooltip />
			    <el-table-column prop="channelName" label="会员来源" min-width="100" >
					<template #default="scope">
						<span>{{scope.row.channelName || '暂无来源'}}</span>
					</template>
				</el-table-column>
			    <!-- <el-table-column prop="productNum" label="标签" min-width="100">
					<template #default="scope">
						<template v-if="scope.row.userTag && scope.row.userTag.length > 0">
							<span v-for="(item,index) in scope.row.userTag" :key="index" class="pl5 pr5 mb12" :style="{color:'#'+item.fontColor,backgroundColor:'#'+item.backColor}">{{item.tagName}}</span>
						</template>
					</template>
				</el-table-column> -->
        		<el-table-column prop="enterPoolTime" label="投入公海时间" min-width="110" />
				<el-table-column fixed="right" label="操作" width="90">
					<template #default="scope">
						<a href="javascript:;" class="t_d_u color-409EFF tool_btn" @click="gainEvent(scope.row.cardMyId)">捞取</a>
					</template>
				</el-table-column>
			</el-table>
			<div class="mt20 rx-ec">
			    <el-pagination
			      v-model:currentPage="pageData.searchForm.page"
			      :page-sizes="[15,20,30,50,100]"
			      :page-size="pageData.searchForm.limit"
			      layout="total, sizes, prev, pager, next, jumper"
			      :total="pageData.totalCount"
			      @size-change="pageSizeChange"
			      @current-change="()=>{getList()}"
			    >
			    </el-pagination>
			</div>
		</div>
		
		<!-- 分配跟进人 -->
		 <el-dialog
		    v-model="pageData.followPeopleShow"
		    title="选择跟进人"
		 	width="400px"
		    :before-close="()=>{pageData.followPeopleShow = false}"
		    custom-class="popwechat"
		   >
			<div class="pl20 pt50 pb50 pr20">
				<div class="rx-cc">
					<span class="">跟进人：</span>
					<div>
						<el-select multiple collapse-tags v-model="pageData.followPeopleId" placeholder="请选择">
							<el-option v-for="(item,index) in pageData.followPeopleList" :key="item.id" :label="item.realName" :value="index">
							</el-option>
						</el-select>
					</div>
				</div>
		  </div>
		   <template #footer>
		    	<div class="dialog-footer">
		        	<el-button @click="pageData.followPeopleShow = false">取消</el-button>
		        	<el-button type="primary" @click="followPeopleSave">确定</el-button>
		    	</div>
		    </template>
		</el-dialog>
		<ExportMember v-model="pageData.exportMemberShow" type="2" :data="pageData.searchForm" @close="exportMemberClose" @save="exportMemberSave"></ExportMember>
		<Confirm ref="confirmRef" :title="pageData.confirmTitle" :text="pageData.confirmText" @save="confirmSave"/>
	</div>
</template>

<script setup>
	import { ref, reactive, onMounted } from 'vue';
	import { useRouter, onBeforeRouteLeave } from 'vue-router';
	import { useStore } from 'vuex'
	import { ElMessage } from 'element-plus'
	import { ArrowDown, Close, Upload } from '@element-plus/icons-vue'
	import utils from '@/common/utils.js'
	import { publicStudentList, assignToSelf, deleteMyCard, getStoreAccountStaffSimple, assignFollower } from '@/api/intentionList.js'
	import CommonTitle from '@/components/CommonTitle.vue'
	import Confirm from '@/components/Confirm.vue'
	import formQuery from '@/components/branch-store/formQuery.vue';
	import ExportMember from '@/components/branch-store/member/ExportMember.vue';
	const store = useStore();
	const router = useRouter();
	const confirmRef = ref();
	const pageData = reactive({
		
		followPeopleShow:false, //分配跟进人
		followPeopleList:[],
		followPeopleId:'',
		
		exportMemberShow:false,
		
		tableData:[],
		totalCount:0,
		searchForm:{
			page:1,
			limit:15,
			keyword:'',
			followStatusId:'',
			ifImportanceDegree:'',
			followerId:'',
			channelId:''
		},
		queryOpts:{
			fields:[{
				type : 'input',
				fieldName : 'searchKeys',
				placehold : '请输入会员姓名/手机号'
			},{
				type : 'select',
				fieldName : 'followStatusId',
				placehold : '跟进状态',
				options : [],
				value:''
			},{
				type : 'select',
				fieldName : 'ifImportanceDegree',
				placehold : '意向级别',
				options : utils.getDict('starState',{label : '意向级别' ,value : ''}),
				value:''
			},{
				type : 'selectMany',
				fieldName : 'lastAdviserIds',
				placehold : '前跟进人',
				options : [],
				value:''
			},{
				type : 'select',
				fieldName : 'channelId',
				placehold : '会员来源',
				options : [],
				value:''
			}
			// ,{
			// 	type : 'select',
			// 	fieldName : 'storeId',
			// 	placehold : '标签',
			// 	type:'selectMany',
			// 	options : [],
			// 	value:''
			// }
			,{
				type : 'dateRange',
				fieldName : 'enterPoolTimeStr',
				placeholder : '投入公海时间',
				width:380,
				value:''
			}]
		}
	}) 
	// 不需要响应式监听的全局变量
	let confirmType = '',//通用确认调用类型
	nowTableRow = '',//操作-当前行数据
	selectCheckData = [];//批量多选数据集合;
	
	//获取字典
	utils.getDynaDict('followState',{label : '跟进状态' ,value : ''},function(res){
	    for(var i=0;i<res.length;i++){
	        pageData.queryOpts.fields[1].options.push(res[i]);
	    }
	});
	utils.getDynaDict('followerList','',function(res){
	    for(var i=0;i<res.length;i++){
	        pageData.queryOpts.fields[3].options.push(res[i]);
	    }
	});
	utils.getDynaDict('channelList',{label : '会员来源' ,value : ''},function(res){
	    for(var i=0;i<res.length;i++){
	        pageData.queryOpts.fields[4].options.push(res[i]);
	    }
	});
	utils.getDynaDict('upTagList',{label : '会员标签' ,value : ''},function(res){
	    for(var i=0;i<res.length;i++){
	        pageData.queryOpts.fields[5].options.push(res[i]);
	    }
	});
	//筛选表单回调
	function getTableData(options){
		for(var key in options){
			pageData.searchForm[key] = options[key];
		}
		pageData.searchForm.lastAdviserIds = pageData.searchForm.lastAdviserIds.length ? pageData.searchForm.lastAdviserIds.join() : ''
		pageData.searchForm.enterPoolTimeStr = pageData.searchForm.enterPoolTimeStr.length ? pageData.searchForm.enterPoolTimeStr.join('-') : ''
		searchTable();
	}
	
	//搜索table
	function searchTable(){
		pageData.searchForm.page = 1;
		getList();
	}
	//切换分页数量
	function pageSizeChange(limit){
		pageData.searchForm.limit = limit;
		searchTable();
	}
	getList();
	function getList(exportObj){
		var formData = JSON.parse(JSON.stringify(pageData.searchForm));
		if(exportObj){ //导出
			exportObj.exportData = 1;
			formData.page = 1;
			formData.limit = 9999;
			Object.assign(formData,exportObj);
		}
		publicStudentList(formData).then((res)=>{
			if(res.code == 0){
				if(exportObj){ //导出
					ElMessage({
						type: 'success',
						message: '正在导出，请稍后前往下载中心下载'
					})
				}else{
					pageData.tableData = res.data.map((n)=>{
						n.ifImportanceDegree = utils.getStartNum(n.ifImportanceDegree); //特殊处理意向级别
						return n;
					});
					pageData.totalCount = res.count;
				}
			}
		})
	}
	//table通用勾选
	function selectionChange(data){
		selectCheckData = data;
	}
	//获取table勾选集合的某个字段集合
	function getSelectCheckKey(key){
	 	return selectCheckData.map(n=>n[key]).join(',');
	}
	// 验证批量操作是否勾选了数据
	function CheckLengthCallBack(callback){
		if(selectCheckData.length == 0){
			ElMessage({
				type: 'warning',
				message: '请选择需要操作的会员',
			})
			return false;
		}
		callback && callback();
	}
	//ajax提交保存通用提示
	function ajaxSaveCallBack(text,callback){
		ElMessage({
			type: 'success',
			message: text
		})
		searchTable();
		callback && callback();
	}
	
	// 导出
	function exportMember(){
		console.log(1111)
		pageData.exportMemberShow = true;
	}
	function exportMemberSave(res){
		pageData.exportMemberShow = false;
		getList(res);
	}
	function exportMemberClose(){
		pageData.exportMemberShow = false;
	}
	
	
	// 分配跟进人
	function followPeopleEvent(){
		CheckLengthCallBack(()=>{
			getStoreAccountStaffSimple().then((res)=>{
				if(res.code == 0){
					pageData.followPeopleList = res.data;
				}
			})
			pageData.followPeopleShow = true;
		})
	}
	
	// 分配跟进人保存
	function followPeopleSave(){
		if(pageData.followPeopleId.length == 0){
			ElMessage({
				type: 'warning',
				message: '请选择跟进人',
			})
			return false;
		}
		var arr = [];
		pageData.followPeopleId.map((n)=>{
			arr.push(pageData.followPeopleList[n]);
		})
		assignFollower({
			followerList:arr,
			studentIdList:getSelectCheckKey('cardMyId').split(','),
		}).then((res)=>{
			if(res.code == 0){
				pageData.followPeopleShow = false;
				ajaxSaveCallBack('分配成功');
			}
		})
	}
	
	
	// 捞取
	var memberId = '';
	function gainEvent(id){
		memberId = id;
		pageData.confirmTitle = '确定要将该会员分配给自己吗？';
		confirmType = 'gain';
		confirmRef.value.show();
	}
	//批量删除会员
	function batchDeleMember(){
		CheckLengthCallBack(()=>{
			pageData.confirmTitle = '是否删除勾选的会员？';
			confirmType = 'delete';
			confirmRef.value.show();
		})
		
	}
	//通用确认框确认事件回调
	function confirmSave(){
		if(confirmType == 'gain'){
			assignToSelf({
				studentIds:memberId
			}).then((res)=>{
				if(res.code == 0){
					ajaxSaveCallBack('操作成功');
				}
			})
		}else if(confirmType == 'delete'){
			deleteMyCard({
				ids:getSelectCheckKey('encryptionCardMyId')
			}).then((res)=>{
				if(res.code == 0){
					ajaxSaveCallBack('删除成功');
				}
			})
		}
		
	}
	
	
	//会员详情
	function memberDetail(id){
    router.push({path:'/branch/memberDetail',query:{encryptionId:id}});
	}
	
	
	// 页面缓存
	onBeforeRouteLeave((to, from)=>{
	    if (to.path === '/branch/memberDetail') {
	        store.commit('SetKeep',{keepLiveRoute : ['IntentionList']});
	    } else {
	        store.commit('SetKeep',{keepLiveRoute : []});
	    }
	})
	
</script>

<style scoped lang="stylus">
	.potential_member{
		.uploadTips{
			border 1px solid #00A3FF;
			border-radius 2px;
			padding-left 5px;
			background #EBF8FF;
			color #00A3FF;
		}
	}
</style>
