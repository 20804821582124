<!-- 会员列表页面 -->
<template>
	<workArea :barlist="pageData.barlist">
    <div class="bg-fff pl16 pt10 pr16">
        <formQuery :queryOpts="pageData.queryOpts" @query="getMemberListPage"></formQuery>
        <!-- 按钮区域 -->
        <div class="rx-bc mb16">
			<div class="rx-sc">
				<el-button type="primary" @click="toAddMember">新增正式会员</el-button>
				<el-button class="mr16" @click="followPeopleEvent">分配跟进人</el-button>
				<el-button class="mr16" @click="synchro">同步门店正式会员</el-button>
				<el-dropdown @command="dropdownEvent">
					<el-button type="primary" plain >
						更多操作<el-icon class="el-icon--right"><ArrowDown /></el-icon>
					</el-button>
					<template #dropdown>
						<el-dropdown-menu>
							<el-dropdown-item command="2" >删除会员</el-dropdown-item>
						</el-dropdown-menu>
					</template>
				</el-dropdown>
			</div>
			<!-- <el-button @click="exportMember" :icon="Upload">导出</el-button> -->
        </div>
        <!-- 数据表格 -->
        <div class="">
            <el-table :data="pageData.memberList" stripe style="width: 100%" @selection-change="selectionChange">
                <el-table-column type="selection" width="55" />
                <el-table-column fixed prop="realName" label="会员姓名" show-overflow-tooltip >
                    <template #default="scope">
                        <span @click="toMemberDetail(scope.row.encryptionId)" class="color-409EFF c_p tool_btn">{{scope.row.realName}}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="telephoneNum" label="手机号" width="120" ></el-table-column>
                <el-table-column prop="channelName" label="会员来源" show-overflow-tooltip></el-table-column>
                <el-table-column prop="joinTime" label="入会时间"></el-table-column>
                <el-table-column prop="actualMoney" label="实际购买(元)"></el-table-column>
                <el-table-column prop="adviserNames" label="跟进人" show-overflow-tooltip></el-table-column>
                <el-table-column label="操作" width="130" >
                    <template #default="scope">
						<a href="javascript:;" class="t_d_u v_a_m color-409EFF tool_btn" @click="toEdit(scope.row.encryptionId)">编辑</a>
                    </template>
                </el-table-column>
				<template #empty>
                    <el-empty description="暂无数据"></el-empty>
                </template>
            </el-table>
            <div class="mt20 rx-ec">
                <el-pagination
                  v-model:currentPage="pageData.searchForm.page"
                  :page-sizes="[15,20,30,50,100]"
                  :page-size="pageData.searchForm.limit"
                  layout="total, sizes, prev, pager, next, jumper"
                  :total="pageData.totalCount"
                  @size-change="pageSizeChange"
                  @current-change="()=>{getList()}"
                >
                </el-pagination>
            </div>
        </div>
<!-- 分配跟进人 -->
		 <el-dialog
		     v-model="pageData.followPeopleShow"
		     title="选择跟进人"
		 	width="400px"
		     :before-close="()=>{pageData.followPeopleShow = false}"
		     custom-class="popwechat"
		   >
			<div class="pl20 pt50 pb50 pr20">
				<div class="rx-cc">
					<span class="">跟进人：</span>
					<div>
						<el-select multiple collapse-tags v-model="pageData.followPeopleId" placeholder="请选择">
							<el-option v-for="(item,index) in pageData.followPeopleList" :key="item.id" :label="item.realName" :value="index">
							</el-option>
						</el-select>
					</div>
				</div>
		  </div>
		   <template #footer>
		    	<div class="dialog-footer">
		        	<el-button @click="pageData.followPeopleShow = false">取消</el-button>
		        	<el-button type="primary" @click="followPeopleSave">确定</el-button>
		    	</div>
		    </template>
		</el-dialog>

		<!-- 批量打标签 -->
		 <el-dialog
		     v-model="pageData.batchSetLabelShow"
		     title="批量分配标签"
		 	 width="400px"
		     :before-close="()=>{pageData.batchSetLabelShow = false}"
		     custom-class="popwechat"
		   >
			<div class="pl20 pt50 pb50 pr20">
				<div class="rx-cc">
					<span class="">选择标签：</span>
					<div class="w200">
						<el-select class="query_con" v-model="pageData.batchSetLabelId" placeholder="请选择">
							<template v-for="item in pageData.queryOpts.fields[2].options">
								<el-option v-if="item.value" :key="item.value" :label="item.label" :value="item.eId"></el-option>
							</template>
						</el-select>
					</div>
				</div>
		  </div>
		   <template #footer>
		    	<div class="dialog-footer">
		        	<el-button @click="pageData.batchSetLabelShow = false">取消</el-button>
		        	<el-button type="primary" @click="batchSetLabelSave">确定</el-button>
		    	</div>
		    </template>
		</el-dialog>
		<!-- 批量删除标签 -->
		<el-dialog
		    v-model="pageData.deletLabelShow"
		    title="删除标签"
			width="600px"
		    :before-close="()=>{pageData.deletLabelShow = false}"
		    custom-class="popwechat"
		  >
		  <div class="pl20 pt50 pb50 pr20">
			  <div class="mb12">会员标签去重后如下,删除对应标签，点击【确认】即可批量去除会员名下对应标签。</div>
			  <ul class=" rx-sc">
				  <div class="pl5 pr5 bdr4 h20 ft12 bg-main rx-cc color-fff mr10" :style="{'background-color':'#'+ tag.backColor,'color':'#' + tag.fontColor}" v-for="(tag, index) in pageData.deletLabelList" :key="index">
                    {{tag.tagName}}
                    <img src="@/assets/branch/tag-delete.png" class="w15 h15 ml5 c_p" @click="delOneLabel(index)">
                  </div>
			  </ul>
		  </div>
		   <template #footer>
		    	<div class="dialog-footer">
		        	<el-button @click="pageData.deletLabelShow = false">取消</el-button>
		        	<el-button type="primary" @click="deletLabelSave">确定</el-button>
		    	</div>
		    </template>
		</el-dialog>
		<!-- 同步门店潜在会员 -->
		<el-dialog
		    v-model="pageData.dialogPotentialShow"
		    title="同步门店正式会员"
		 	width="780px"
		    :before-close="handleClosePotential"
		   >
			<div class="p20">
		        <el-table ref="multipleTable" 
		            :data="pageData.potentialList" 
		            stripe tooltip-effect="dark" style="width: 100%"
		            @selection-change="handleSelectionChange"
		            height="500"
					:row-key="row=>row.id"
		        >
		        <el-table-column type="selection" width="55" :reserve-selection="true"/>
		        <el-table-column prop="realName" label="门店潜在会员" />
		        <el-table-column prop="telephoneNum" label="手机号" />
		        <el-table-column label="意向级别" >
		            <template #default="scope">
		                <el-rate v-model="scope.row.ifImportanceDegree" disabled />
		            </template>
		        </el-table-column>
		    </el-table>
		    <!-- <div class="mt20 rx-ec" v-show="pageData.potentialList.length > 0">
		        <el-pagination
		          v-model:currentPage="pageData.potentialPage"
		          :page-sizes="[15,20,30,50,100]"
		          :page-size="pageData.potentialLimit"
		          layout="total, sizes, prev, pager, next, jumper"
		          :total="pageData.potentialCount"
		          @size-change="pageSizeChange1"
		          @current-change="pageChange1"
		        >
		        </el-pagination>
		    </div> -->
		    </div>
		    <template #footer>
		    	<div class="dialog-footer">
		        	<el-button @click="handleClosePotential">取消</el-button>
		        	<el-button type="primary" @click="dialogPotentialSave">确定</el-button>
		    	</div>
		    </template>
		</el-dialog>
        <AddMember v-model="pageData.addMemberShow" :title="pageData.addMemberTitle" :id="pageData.memberId" @close="addMemberClose" @save="addMemberSave" flag="1"/>
		<Confirm ref="confirmRef" :title="pageData.confirmTitle" :text="pageData.confirmText" @save="confirmSave"/>
		<ExportMember v-model="pageData.exportMemberShow" type="4" :data="pageData.searchForm" @close="exportMemberClose" @save="exportMemberSave"></ExportMember>
    </div>
	</workArea>
	
</template>

<script setup>
    import { ref, reactive, onMounted } from 'vue';
    import { useRouter, onBeforeRouteLeave } from 'vue-router';
    import { useStore } from 'vuex'
    import { ElMessage } from 'element-plus'
    import { ArrowDown, Close, Upload, ArrowUp } from '@element-plus/icons-vue'
    import utils from '@/common/utils.js'
    import Confirm from '@/components/Confirm.vue'
    import { getMemberList } from '@/api/member.js';
    import { getStoreAccountStaffSimple, assignFollower, vipUpdateTagBatch, followUpTagList,deleteTagDetails,deleteMyCard} from '@/api/intentionList.js';
	import { syncSubjectTrailStudent ,getSubjectTrailStudent} from '@/api/market.js';
	import workArea from '@/components/branch-store/workArea.vue';
    import formQuery from '@/components/branch-store/formQuery.vue';
    import AddMember from '@/components/branch-store/member/AddMember.vue';
    import ExportMember from '@/components/branch-store/member/ExportMember.vue';
import { fa } from 'element-plus/lib/locale';
    const store = useStore();
    const router = useRouter();
    const pageData = reactive({
		barlist:[{ name : '首页' },{ name : '会员管理' },{ name : '正式会员'}],// 面包屑
		exportMemberShow:false,
        addMemberTitle : '新增正式会员',
        memberId : '',
        addMemberShow:false, // 显示新增会员
        memberList : [],
        totalCount : 0,
        searchForm:{
            page:1,
            limit:15,
			searchKeys:'',
			channelId:'',
			tagList:'',
			joinTimeStr:'',
			adviserIds:''
        },
		followPeopleShow:false, //分配跟进人
		followPeopleList:[],
		followPeopleId:[],
		
		batchSetLabelShow:false, //批量打标签
		batchSetLabelId:'',
		confirmTitle:'', //通用确认框
		confirmText:'',
		userInfo:'',
        queryOpts:{
            fields:[{
                type : 'input',
                fieldName : 'searchKeys',
                placehold : '会员姓名/手机号'
            },{
                type : 'select',
                fieldName : 'channelId',
                placehold : '会员来源',
                options : [],
                value:''
            },{
                type : 'dateRange',
                fieldName : 'joinTimeStr',
                placeholder : '入会时间',
                options : [],
                value:''
            },{
                type : 'selectMany',
                fieldName : 'adviserIds',
                placehold : '跟进人',
                options : [],
                value:''
            }]
        },
		potentialList:[],
		synchroList: [],
		dialogPotentialShow:false,
		potentialPage:1,
		potentialLimit:15,
		potentialCount:0
    })
	var selectCheckData = [],confirmType = '';
	const confirmRef = ref();
	//获取字典
	utils.getDynaDict('followerList','',function(res){
	    pageData.queryOpts.fields[3].options = res;
	});
	utils.getDynaDict('channelList',{label : '会员来源' ,value : ''},function(res){
	    pageData.queryOpts.fields[1].options = res;
	});
	function changeDropdown(row, bool) {
		row.bool = bool ? true : false
	}
	// 导出
	function exportMember(){
		pageData.exportMemberShow = true;
	}
	function exportMemberSave(res){
		pageData.exportMemberShow = false;
		getList(res);
	}
	function exportMemberClose(){
		pageData.exportMemberShow = false;
	}
	
	
	
    function toMemberDetail(id){
        router.push('/branch/memberDetail?encryptionId='+id);
    }
    // 编辑会员
    function toEdit(encryptionId){
        pageData.addMemberTitle = "编辑正式会员";
        pageData.memberId = encryptionId;
        pageData.addMemberShow = true;
    }
    function toAddMember(){
        pageData.addMemberTitle = "新增正式会员";
        pageData.memberId = '';
        pageData.addMemberShow = true;
    }
    // 新增会员后
    function addMemberSave(res){
        pageData.addMemberShow = false;
        searchTable();
    }
    // 关闭新增会员
    function addMemberClose(){
        pageData.addMemberShow = false;
    }
	//筛选表单回调
	function getMemberListPage(options){
		for(var key in options){
			pageData.searchForm[key] = options[key];
		}
		pageData.searchForm.joinTimeStr =  pageData.searchForm.joinTimeStr && pageData.searchForm.joinTimeStr.join('-')
		searchTable();
	}
	//切换分页数量
	function pageSizeChange(limit){
		pageData.searchForm.limit = limit;
		searchTable();
	}
	function searchTable(){
		pageData.searchForm.page = 1;
		getList();
	}
	getList();
    function getList(exportObj){
		var formData = JSON.parse(JSON.stringify(pageData.searchForm));
		formData.tagList = formData.tagList && formData.tagList.length > 0 ? formData.tagList.join(',') : '';
		formData.adviserIds = formData.adviserIds && formData.adviserIds.length > 0 ? formData.adviserIds.join(',') : '';
		if(exportObj){ //导出
			exportObj.exportData = 1;
			formData.page = 1;
			formData.limit = 9999;
			Object.assign(formData,exportObj);
		}
        getMemberList(formData).then(function(res){
            if(res.code == 0){
				if(exportObj){ //导出
					ElMessage({
						type: 'success',
						message: '正在导出，请稍后前往下载中心下载'
					})
				}else{
					pageData.memberList = res.data || [];
					pageData.totalCount = res.count
				}
            }
        });
    }
	
	// 分配跟进人
	function followPeopleEvent(){
		CheckLengthCallBack(()=>{
			getStoreAccountStaffSimple().then((res)=>{
				if(res.code == 0){
					pageData.followPeopleList = res.data;
				}
			})
			pageData.followPeopleShow = true;
		})
	}
	
	// 分配跟进人保存
	function followPeopleSave(){
		if(pageData.followPeopleId.length == 0){
			ElMessage({
				type: 'warning',
				message: '请选择跟进人',
			})
			return false;
		}
		var arr = [];
		pageData.followPeopleId.map((n)=>{
			arr.push(pageData.followPeopleList[n]);
		})
		assignFollower({
			followerList:arr,
			studentIdList:getSelectCheckKey('cardMyId').split(','),
		}).then((res)=>{
			if(res.code == 0){
				pageData.followPeopleShow = false;
				ajaxSaveCallBack('分配成功');
			}
		})
	}

	//下拉框按钮操作事件
	function dropdownEvent(data){
		CheckLengthCallBack(()=>{
			pageData.confirmTitle = '是否删除勾选的会员？';
			confirmType = 'delete';
			confirmRef.value.show();
		})
	}
	//通用确认框确认事件回调
	function confirmSave(){
		if(confirmType == 'delete'){
			deleteMyCard({
				ids:getSelectCheckKey('encryptionId')
			}).then((res)=>{
				if(res.code == 0){
					ajaxSaveCallBack('删除成功');
				}
			})
		}
		
	}


	//table通用勾选
	function selectionChange(data){
		selectCheckData = data;
	}
	//获取table勾选集合的某个字段集合
	function getSelectCheckKey(key){
	 	return selectCheckData.map(n=>n[key]).join(',');
	}
	// 验证批量操作是否勾选了数据
	function CheckLengthCallBack(callback){
		if(selectCheckData.length == 0){
			ElMessage({
				type: 'warning',
				message: '请选择需要操作的会员',
			})
			return false;
		}
		callback && callback();
	}
	//ajax提交保存通用提示
	function ajaxSaveCallBack(text,callback){
		ElMessage({
			type: 'success',
			message: text
		})
		searchTable();
		callback && callback();
	}
	//切换分页数量
	function pageSizeChange1(limit){
		pageData.potentialLimit = limit;
		pageData.potentialPage = 1;
		getMemberBySubjectTrail();
	}
	function pageChange1(page){
		pageData.potentialPage = page;
		getMemberBySubjectTrail();
	}
	
    /**
     * 获取门店正式列表
     */
    function getMemberBySubjectTrail() {
        getSubjectTrailStudent({
			trialFlag:1,
			page:pageData.potentialPage,
			limit:pageData.potentialLimit,
		}).then(res => {
            if (res.code == 0) {
                pageData.potentialList = res.data || [];
                pageData.potentialCount = res.count;
            }
        })
    }
    /**
     * 同步
     */
    function synchro() {
        getMemberBySubjectTrail()
        pageData.dialogPotentialShow = true
        
    }
    /**
     * 取消同步
     */
    function handleClosePotential() {
        pageData.dialogPotentialShow = false
    }
    /**
     * 选择同步的数据
     */
    function handleSelectionChange (val) {
        pageData.synchroList = val
    }
    /**
     * 确定同步
     */
    function dialogPotentialSave() {
        if (!pageData.synchroList.length) {
            ElMessage.warning('请选择需要同步的数据')
            return
        }
        let arr = []
        pageData.synchroList.forEach(item => {
            arr.push(item.cardMyId)
        })
        let params = {
            ids: arr.join(','),
    		trialFlag:1
        }
        syncSubjectTrailStudent(params).then(res=> {
            if (res.code == 0) {
                ElMessage.success('同步成功')
                multipleTable.value.clearSelection()
                getList()
                pageData.dialogPotentialShow = false
               if (res.remark.length>0) {
                    ElMessageBox.alert('以下'+ res.remark.length +'位会员同步失败，原因：该手机号在本系统已存在</br>'+ res.remark ,'温馨提示',{
    	            	confirmButtonText: '好的',
    	            	dangerouslyUseHTMLString: true,
    	            });
               }
                
            }
        })
    }
    // 页面缓存
    onBeforeRouteLeave((to, from)=>{
        // if (to.path === '/branch/memberDetail') {
        //     store.commit('SetKeep',{keepLiveRoute : ['BranchMemberList']});
        // } else {
        //     store.commit('SetKeep',{keepLiveRoute : []});
        // }
    })
</script>
<style scoped lang="stylus">
	.delTagList{
		li{
			border 1px solid #ccc;
			span:first-child{
				border-right 1px solid #ccc;
			}
		}
	}
	.Sign:hover{
		color: #2D6FB2;
	}
</style>