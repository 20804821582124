<template>
	<workArea :barlist="barlist">
		<div class="pl20 pr20 pb20 pt20">
			<div class="rx-wss">
				<div class="rx-ss ww50 mb20" v-if="hallType">
					<span class="w95 t_a_r"><span class="color-red">*</span>使用模板：</span>
					<img v-show="selectTempData.tid" class="w160 mr20" :src="selectTempData.hallCover" alt="">
					<el-button v-show="!tid || !hallType" type="primary" @click="selectTemplate">{{selectTempData.tid ? '重选模板':'选择模板'}}</el-button>
				</div>
				<div class="rx-ss mb20" v-if="hallType">
					<span class="w95 t_a_r"><span class="color-red">*</span>展厅封面：</span>
					<div class="">
						<CommonUpload v-if="!formData.form.hallCover" @change="uploadChange" :autoCropWidth="320"
							:autoCropHeight="180" :isCropper="true">
							<div class="uploadBox cx-cc">
								<img class="upload1 w25 mb10" src="@/assets/upload1.png" alt="">
								<img class="upload2 w25 mb10" src="@/assets/upload2.png" alt="">
								<p>上传图片</p>
							</div>
						</CommonUpload>
						<div class="imgBox" v-else>
							<img class="w160 h90 bdr2" :src="formData.form.hallCover" alt="">
							<div class="del" @click="formData.form.hallCover = ''">
								<img class="del1 w15" src="@/assets/del1.png" alt="">
								<img class="del2 w15" src="@/assets/del2.png" alt="">
							</div>
							<CommonUpload @change="uploadChange" :autoCropWidth="320" :autoCropHeight="180" :isCropper="true">
								<p>重新上传</p>
							</CommonUpload>
						</div>
						<p class="color-999 mt15">尺寸要求：1600*900px</p>
					</div>
				</div>
			</div>
			<div class="rx-wss">
				<div class="rx-sc mb20 ww50 h40">
					<span class="w95 t_a_r"><span class="color-red">*</span>展厅名称：</span>
					<div class="w240">
						<el-input type="text" maxlength="10" v-model="formData.form.name" placeholder="请输入"></el-input>
					</div>
				</div>
				<div class="rx-sc mb20" v-if="hallType && formData.hallType == 2">
					<span class="w95 t_a_r"><span class="color-red">*</span>所属会员：</span>
					<div class="w240">
						<el-select class="query_con" v-model="formData.form.cardMyId" placeholder="请选择">
							<el-option :value="item.cardMyId" :label="item.realName"
								v-for="item in tempData.memberList"></el-option>
						</el-select>
					</div>
				</div>
				<div v-if="hallType">
					<div class="rx-sc mb20">
						<span class="w95 t_a_r"><span class="color-red">*</span>有效期：</span>
						<div class="flex1">
							<el-radio-group v-model="formData.timeType" class="ml-4">
								<el-radio :label="1" size="large">自定义时间</el-radio>
								<el-radio :label="2" size="large">永久有效</el-radio>
							</el-radio-group>
						</div>
					</div>
					<div class="rx-sc mb20" v-show="formData.timeType == 1">
						<span class="w95 t_a_r"></span>
						<div class="flex1">
							<el-date-picker v-model="formData.form.startTimeStr" type="daterange" range-separator="-"
								start-placeholder="开始日期" end-placeholder="结束日期" />
						</div>
					</div>
				</div>
			</div>
			<div class="rx-sc mb20" v-if="hallType">
				<span class="w95 t_a_r">展厅权限：</span>
				<div class="flex1 rx-sc">
					<el-checkbox label="放入机构主页" v-model="formData.form.showIndex" />
					<div style="height: 32px;" class="rx-cc d_i_b">
						<a href="JavaScript:;" class="color-03A3FF t_d_u mr20" @click="preview(1)">查看位置</a>
					</div>
					<el-checkbox label="放入平台展厅推荐区" v-model="formData.form.showPlatform" />
					<div style="height: 32px;" class="rx-cc d_i_b">
						<a href="JavaScript:;" class="color-03A3FF t_d_u ml20" @click="preview(2)">查看位置</a>
					</div>
				</div>
			</div>
			<div class="rx-ss mb20" v-if="hallType">
				<span class="w95 t_a_r"><span class="color-red">*</span>展厅介绍：</span>
				<div class="flex1" style="max-width:700px;">
					<el-input type="textarea" :rows="4" show-word-limit v-model="formData.form.remark" maxlength="200"
						placeholder="请输入"></el-input>
				</div>
			</div>
			<div class="rx-sc mb20">
				<span class="w95 t_a_r"><span class="color-red">*</span>案例图片：</span>
				<div class="rx-sc">
					<CommonUpload @change="caseUploadChange" :isCropper="false">
						<el-button type="primary">添加本地图片</el-button>
					</CommonUpload>
					<el-button class="ml20 btnBlue" @click="worksEvent">从作品库导入</el-button>
				</div>
			</div>
			<div class="pl95 color-999 ft12 mb20">
				{{'提示：本展厅最多可上传'+(selectTempData.picNum||0)+'张图片；图片格式支持PNG、JPEG、JPG，图片大小不超过20M。'}}
			</div>
			<div class="bg-F5F5F5">
				<div class="pt30 pl30 pr30 pb30 rx-wsc" v-show="formData.list.length">
					<div class="ww50 mb30" :class="index % 2 == 0 ? 'pr30':'pl30'" v-for="(item,index) in formData.list"
						:key="index">
						<div class="mb12">作品{{index+1}}</div>
						<div class="rx-sc">
							<div class="imgBox mr20" style="width:160px;height:160px;">
								<div class="w160 h160 o_h posi-r">
									<img class="h160 bdr2 posi-c" :src="item.pictureURL" alt="">
								</div>
								<div class="del" @click="formDataDelEvent(index)">
									<img class="del1 w15" src="@/assets/del1.png" alt="">
									<img class="del2 w15" src="@/assets/del2.png" alt="">
								</div>
							</div>
							<div class="flex1">
								<div class="mb20">
									<el-input maxlength="10" type="text" v-model="item.stuffName"
										placeholder="作品名称(10个字符)"></el-input>
								</div>
								<el-input type="textarea" maxlength="200" show-word-limit :rows="4" v-model="item.stuffDesc"
									placeholder="作品简介(200个字符)"></el-input>
							</div>
						</div>
					</div>
				</div>
				<div class="noData cx-cc h400" v-show="formData.list.length == 0">
					<img class="w200 mb25" src="@/assets/noData2.png" alt="">
					<p class="color-808080 ft12">展厅内暂无图片，点击“添加本地图片”或“从作品库导入”按钮进行上传</p>
				</div>
			</div>
			<div class="mt20">
				<el-button @click="router.go(-1)">取消</el-button>
				<el-button type="primary" @click="addUpdateSave">确定</el-button>
			</div>
		</div>
		<!-- 导入作品库图片 -->
		<el-dialog v-model="worksData.show" title="导入作品库图片" width="1080px" :before-close="()=>{worksData.show = false}"
			custom-class="popwechat">
			<div class="pl20 pr20 pb20 pt20" style="max-height: 500px;overflow: auto;">
				<div class="rx-bc mb20">
					<div>
						<span class="fwb mr10">作品库图片：</span>
						<span class="ft12 color-999">提示：点击图片即可选择该图。</span>
					</div>
					<el-checkbox v-model="worksData.allState" @change="allSelect" label="全部选择" size="large" />
				</div>
				<div class="bg-F5F5F5">
					<el-checkbox-group v-model="worksData.ids">
						<div class="pt30 pl30 pr30 pb30 rx-wsc" v-show="worksData.list.length">
							<div class="posi-r w160 h160 o_h mb25 worksImgBox" @click="selectWorkItem(item.id)"
								:class="(index+1)%5 == 0 ? '':'mr25'" v-for="(item,index) in worksData.list" :key="index">
								<img class="posi-c h160" :src="item.worksPic" alt="">
								<div class="posi-a bottom0 right0 w40 rx-cc" style="transform: translate(8px,5px);">
									<el-checkbox :label="item.id" size="large"><span></span></el-checkbox>
								</div>
							</div>
						</div>
					</el-checkbox-group>
					<div class="noData cx-cc h400" v-show="worksData.list.length == 0">
						<img class="w200 mb25" src="@/assets/noData2.png" alt="">
						<p class="color-808080 ft12">作品库暂无图片</p>
					</div>
				</div>
			</div>
			<template #footer>
				<div class="dialog-footer">
					<el-button @click="worksData.show = false">取消</el-button>
					<el-button type="primary" @click="worksSave">确定</el-button>
				</div>
			</template>
		</el-dialog>
		<!-- 选择使用模板 -->
		<el-dialog v-model="selectTempData.show" title="选择使用模板" width="770px"
			:before-close="()=>{selectTempData.show = false}" custom-class="popwechat">
			<div class="pl20 pr20 pb20 pt20" style="max-height: 500px;overflow: auto;">
				<el-radio-group v-model="selectTempData.tid">
					<template v-for="item in tempData.list" :key="item.id">
						<div class="selectTempBox" v-if="item.hasBuy == 1">
							<div class="posi-r">
								<img class="ww100" :src="item.hallCover" alt="">
								<div class="posi-a" style="right: 20px;bottom:20px;">
									<el-radio :label="item.id"><span></span></el-radio>
								</div>
							</div>
						</div>
					</template>
				</el-radio-group>
			</div>
			<template #footer>
				<div class="dialog-footer">
					<el-button @click="selectTempData.show = false">取消</el-button>
					<el-button type="primary" @click="selectTemplateSave">确定</el-button>
				</div>
			</template>
		</el-dialog>
		<!-- 预览 -->
		<el-dialog v-model="previewData.show" title="预览" width="370px" :before-close="()=>{previewData.show = false}"
			custom-class="popwechat">
			<div class="pl40 pr40 pb20 pt20">
				<img v-show="formData.hallType == 1 && previewData.type == 1" class="ww100" src="@/assets/preview1.png" alt="">
				<img v-show="formData.hallType == 2 && previewData.type == 1" class="ww100" src="@/assets/preview2.png" alt="">
				<img v-show="previewData.type == 2" class="ww100" src="@/assets/preview3.png" alt="">
			</div>
			<template #footer>
				<div class="dialog-footer">
					<el-button type="primary" @click="previewData.show = false">关闭</el-button>
				</div>
			</template>
		</el-dialog>
	</workArea>
</template>

<script setup>
	import {
		ref,
		reactive,
		onMounted
	} from 'vue';
	import { useRouter } from 'vue-router';
	import {
		ElMessage
	} from 'element-plus'
	import CommonUpload from '@/components/CommonUpload.vue';
	import workArea from '@/components/branch-store/workArea.vue';
	import {
		getHallTemplateList,
		getHallTemplateInfo,
		getHallWorksList,
		getHallContentInfo,
		insertHallContent,
		updateHallContent,
	} from '@/api/showroom.js'
	import {
		getMemberList
	} from '@/api/member.js';
	import utils from '@/common/utils.js';
	const router = useRouter();
	var {encryptionId,tid,hallType} = router.currentRoute.value.query;
	onMounted(()=>{
		localStorage.setItem('tabIndex',hallType);
	})
	var navName = hallType == 1 ? '机构展厅':'会员专属展厅';
	const barlist = ref([{ name : '返回',type :0 },{ name : encryptionId ? '编辑'+navName:'新增'+navName }])// 面包屑
	const tempData = reactive({
		list: [],
		memberList:[]
	})
	getTemplate()
	function getTemplate() {
		getHallTemplateList({
			page: 1,
			limit: 9999
		}).then((res) => {
			if (res.data) {
				tempData.list = res.data.filter(n => n.state == 1);
			}
		})
	}
	if(hallType == 2){
		getMemberList({
			page: 1,
			limit: 9999,
		}).then((res) => {
			if (res.code == 0) {
				tempData.memberList = res.data;
			}
		})
	}
	
	//新增/编辑展厅
	const formData = reactive({
		form: {
			encryptionId: '',
			tid: '',
			hallCover: '',
			showIndex: false,
			showPlatform: false,
			name: '',
			cardMyId: '',
			startTimeStr: '',
			remark: ''
		},
		timeType: 2,
		hallType:hallType,
		list: [],
		show: false,
	})

	//选择模板
	const selectTempData = reactive({
		show: false,
		hallCover: '',
		tid: '',
		picNum: 0,
	})
	
	if(tid){
		getHallTemplateInfo({
			id:tid
		}).then(res=>{
			selectTempData.tid = res.data.id;
			formData.form.tid = res.data.id;
			selectTempData.hallCover = res.data.hallCover;
			selectTempData.picNum = res.data.picNum;
		})
	}
	
	function selectTemplate() {
		selectTempData.show = true;
	}

	function selectTemplateSave() {
		var arr = tempData.list.filter(n => n.id == selectTempData.tid);
		selectTempData.hallCover = arr[0].hallCover;
		selectTempData.picNum = arr[0].picNum;
		formData.form.tid = selectTempData.tid;
		selectTempData.show = false;
	}
	if (encryptionId) {
		getHallContentInfo({
			encryptionId
		}).then(res => {
			for (var k in formData.form) {
				formData.form[k] = res.data[k];
			}
			formData.form.showIndex = res.data.showIndex == 1 ? true : false;
			formData.form.showPlatform = res.data.showPlatform == 1 ? true : false;
			if (res.data.startTime) {
				formData.form.startTimeStr = [utils.getDateObj(res.data.startTime).time, utils.getDateObj(res.data
					.endTime).time]
			}
			formData.list = res.data.stuffInfo;
		})
	}

	function addUpdateSave() {
		var form = JSON.parse(JSON.stringify(formData.form));
		form.stuffInfo = formData.list;
		if (form.startTimeStr) {
			var str = '';
			var arr = form.startTimeStr.map(n => {
				var {
					Y,
					M,
					D
				} = utils.getDateObj(n);
				return Y + '-' + M + '-' + D;
			})
			str += arr.join(' - ');
			form.startTimeStr = str;
		} else {
			form.startTimeStr = -1;
		}
		var pass = verification(form);
		if (!pass) {
			return false;
		}
		if (form.stuffInfo.length != selectTempData.picNum) {
			ElMessage.warning('还差' + (selectTempData.picNum - form.stuffInfo.length) + '张作品，请继续上传');
			return false;
		}
		form.showIndex = form.showIndex == true ? 1 : 0;
		form.showPlatform = form.showPlatform == true ? 1 : 0;
		if (form.encryptionId) {
			updateHallContent(form).then((res) => {
				ElMessage.success('保存成功');
				setTimeout(()=>{
					router.go(-1);
				},1000)
			})
		} else {
			insertHallContent(form).then((res) => {
				ElMessage.success('保存成功');
				setTimeout(()=>{
					router.go(-1);
				},1000)
			})
		}
	}

	function verification(data) {
		var pass = true;
		if(!data.tid){
			pass = false;
			ElMessage.warning('请选择展厅模板');
			return false;
		}
		if(!data.name){
			pass = false;
			ElMessage.warning('请输入展厅名称');
			return false;
		}
		if(formData.hallType && !data.remark){
			pass = false;
			ElMessage.warning('请输入展厅介绍');
			return false;
		}
		if (formData.hallType == 2 && !data.cardMyId) {
			pass = false;
			ElMessage.warning('请选择所属会员');
			return false;
		}
		if (formData.timeType == 1 && data.startTimeStr == -1) {
			pass = false;
			ElMessage.warning('请选择有效时间日期范围');
			return false;
		}
		return pass;
	}
	//上传封面
	function uploadChange(url) {
		formData.form.hallCover = url;
	}

	//上传作品
	function caseUploadChange(url) {
		if (!formData.form.tid) {
			ElMessage.warning('请选择展厅模板');
			return false;
		}
		if (formData.list.length >= selectTempData.picNum) {
			ElMessage.warning('作品最多上传' + selectTempData.picNum + '张');
			return false;
		}
		formData.list.unshift({
			stuffName: '',
			stuffDesc: '',
			pictureURL: url,
		})
	}


	//从作品库导入
	const worksData = reactive({
		show: false,
		ids: [],
		allState: false,
		list: [],
	})

	function allSelect(state) {
		worksData.ids = state ? worksData.list.map(n => n.id) : [];
	}

	function worksEvent(row) {
		if (!formData.form.tid) {
			ElMessage.warning('请选择展厅模板');
			return false;
		}
		worksData.show = true;
		if (worksData.list.length == 0) {
			getHallWorksList({
				page: 1,
				limit: 99999
			}).then(res => {
				worksData.list = res.data;
			})
		}
	}

	function selectWorkItem(id) {
		var index = worksData.ids.indexOf(id);
		if (index > -1) {
			worksData.ids.splice(index, 1);
		} else {
			worksData.ids.push(id);
		}
	}

	function worksSave() {
		if (worksData.ids.length + formData.list.length > selectTempData.picNum) {
			ElMessage.warning('作品数超出' + selectTempData.picNum + '张');
		} else {
			worksData.list.forEach(n => {
				if (worksData.ids.indexOf(n.id) > -1) {
					formData.list.unshift({
						stuffName: '',
						stuffDesc: '',
						pictureURL: n.worksPic
					})
				}
			});
			worksData.show = false;
		}
	}

	function formDataDelEvent(index) {
		formData.list.splice(index, 1);
	}


	
	//预览
	const previewData = reactive({
		show: false,
		type: ''
	})

	function preview(type) {
		previewData.type = type;
		previewData.show = true;
	}

	
</script>
<style lang='stylus' scoped>
	.tempItem {
		display: none;
	}

	.tempBox:hover .tempItem {
		display: block !important;
	}

	.uploadBox {
		border: 1px dashed #D6D6D6;
		height: 90px;
		width: 160px;
		border-radius: 2px;
		color: #999999;
		cursor: pointer;
	}

	.uploadBox:hover {
		border: 1px dashed #1989FA;
		color: #1989FA;
	}

	.uploadBox .upload2 {
		display: none;
	}

	.uploadBox:hover .upload2 {
		display: block;
	}

	.uploadBox:hover .upload1 {
		display: none;
	}

	.imgBox {
		position: relative;
		width: 160px;
		height: 90px;
	}

	.imgBox .del,
	.imgBox:hover .del2 {
		display: none;
	}

	.imgBox:hover .del {
		display: block;
		cursor: pointer;
	}

	.imgBox .del:hover .del2 {
		display: block;
	}

	.imgBox .del:hover .del1 {
		display: none;
	}

	.imgBox .del {
		position: absolute;
		right: 0;
		top: 0;
		transform: translate(50%, -50%);
	}

	.imgBox p {
		position: absolute;
		bottom: 0;
		left: 0;
		right: 0;
		padding: 5px 0;
		text-align: center;
		color: #FFFFFF;
		background-color: rgba(0, 0, 0, 0.4);
		display: none;
		cursor: pointer;
	}

	.imgBox:hover p {
		display: block;
	}

	.box_container:nth-child(2n) {
		padding-left: 10px;
		padding-right: 0px !important;
	}

	.box_container {
		float: left;
		padding-right: 10px;
		width: 50%;
		margin-bottom: 15px;

		.container_card {
			padding-top: 15px;
			padding-bottom: 15px;
			background: #FAF9F9;
			border-radius: 4px;
			border: 1px solid #D6D6D6;

			.price {
				color: #F85016;
				font-size: 16px;
				font-weight: bold;
				line-height: 22px;
				margin-left: 6px;
			}

			.obtain {
				color: #333;
			}
		}

		.container_active {
			background: #FC3D3D;
			border: 1px solid #FD3546;

			.price,
			.obtain,
			.gold {
				color: #FFF;
			}
		}

	}

	.selectTempBox {
		width: 50%;
		float: left;
		padding-left: 20px;
		margin-bottom: 20px;
	}

	.selectTempBox:nth-child(2n-1) {
		padding-right: 20px;
	}

	.worksImgBox::after {
		content: '';
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		z-index: 1000
	}
</style>