<template>
    <div class="box hh100 bg-FAFAFC">
        <div class="container hh100">
            <div class="ft16 fwb">基础能力测评</div>
            <div class="mt20 container-box">
                <Dance v-if="pageProp.type == 1" :info="pageProp.info" :qualityList="pageProp.qualityList" :pliableList="pageProp.pliableList"></Dance>
                <Sports v-if="pageProp.type == 2" :info="pageProp.info" :qualityList="pageProp.qualityList" :pliableList="pageProp.pliableList"></Sports>
            </div>
        </div>
    </div>
</template>

<script setup>
    import { reactive } from 'vue';
    import Dance from '@/components/branch-store/template/basicAbility/dance.vue';
    import Sports from '@/components/branch-store/template/basicAbility/sports.vue';
    const pageProp = defineProps({
       info: Object,
       pliableList: Array,
       qualityList: Array,
       type: Number 
    })
    const pageData = reactive({})
</script>
<style type='css/style' scoped>
.box {
    max-width: 920px;
}
.container{
    padding: 20px 50px;
}
.container-box {
    height: 94%;
}
</style>