<template>
    <div class="ww100 hh100 bg-gray">
        <!-- 导航 -->
        <div class="ww100 h50 p20 pl20 rx-sc bg-fff">
            <div class="rx-sc" v-for="(item, index) in recept.barlist" :key="index">
                <div v-if="item.type == 0" @click="goback()" class="w50 h50 c_p  rx-sc">
                    <!-- <img src="@/assets/branch/back.png" class="w15 h15" /> -->
                    <ArrowLeftBold class="w15 h15 color-main"></ArrowLeftBold>
                    <div class="color-main" style="margin-left:2px;" >{{item.name}}</div>
                </div>
                <div v-if="item.type != 0" class="c_p" >
                    <span :class="[index < barlist.length - 1 ? 'color-on' : 'color-off']">
                        {{item.name}}
                    </span>
                    
                </div>
                <div v-if="index < barlist.length - 1" class="ml5 mr5 w10 h30 rx-cc">
                    <img src="@/assets/branch/xiegan.png" style="width:12px;height:12px;">
                </div>
            </div>
        </div>
        <!-- 内容区 -->
        <div class="ww100 bg-gray p16" style="padding-bottom:0px;">
            <div class="ww100 bg-fff" :style="{height : windowHeight + 'px'}" style="overflow-y:scroll;">
                <slot></slot>
            </div>
        </div>
    </div>
</template>

<script>
  export default {
    name: 'workArea'
  }
</script>


<script setup>
    import {ref,onMounted} from 'vue';
    import { ArrowLeftBold } from '@element-plus/icons-vue';
    const recept = defineProps({
      barlist : Array
    })
    const windowHeight = ref(500);
    onMounted(()=>{
        windowHeight.value = window.innerHeight - 120;
    });
    function goback(){
        history.back();
    }
</script>

<style type="text/css">
    .color-on{
        color : #999;
    }
    .color-off{
        color :#595959
    }
</style>
