<template>
	<div class="pt20">
		<div class="rx-sc mb30">
			<div class="flex1 bg-F9F8FD">
				<div class="rx-bc pl20 pt15 pb15 pr20 borderBottom">
					<b class="fwb ft16 color-333">合同详情</b>
					<span class="color-999 ft12" id="expireTime">截止有效期为：{{info.expireTime || '-'}}</span>
				</div>
				<div class="rx-sc pt25 pb25">
					<div class="ww33 pl60 rightBorder rx-sc">
						<img class="w90 mr20" src="@/assets/contract_top1.png" alt="">
						<div class="flex1">
							<p class="color-999 mb10 ft14">已购电子合同（份）</p>
							<p class="color-333 fwb ft24" id="buyNum">{{info.num || 0}}</p>
						</div>
					</div>
					<div class="ww33 pl60 rightBorder rx-sc">
						<img class="w90 mr20" src="@/assets/contract_top2.png" alt="">
						<div class="flex1">
							<p class="color-999 mb10 ft14">已使用（份）</p>
							<p class="color-333 fwb ft24" id="useNum">{{info.num-info.remainNum || 0}}</p>
						</div>
					</div>
					<div class="ww33 pl60 rx-sc">
						<img class="w90 mr20" src="@/assets/contract_top3.png" alt="">
						<div class="flex1">
							<p class="color-999 mb10 ft14">剩余可用（份）</p>
							<p class="color-333 fwb ft24" id="remainNum">{{info.remainNum || 0}}</p>
						</div>
					</div>
				</div>
			</div>
			<div class="w435 bg-F9F8FD ml20">
				<div class="borderBottom pt20 pl20 pr20 pb25">
					<div class="rx-bc mb25">
						<img class="w50" src="@/assets/contract_top4.png" alt="">
						<span @click="toRecord" class="btn1 t_d_u">充值记录</span>
					</div>
					<p class="color-999 ft14 opacity0">合同条数为
						<span class="color-00a3ff">10</span>条时，预警提示合同条数不足
						<!-- <span class="btn1 t_d_u">>>去设置</span> -->
					</p>
				</div>
				<div class="rx-bc pl20 pt15 pb15 pr20 cursor-pointer rechargeBtns" @click="rechargeEvent"> 
					<b class="fwb ft14 color-333">去充值</b>
					<div>
						<img class="w20 img1" src="@/assets/contract_top51.png" alt="">
						<img class="w20 img2 none" src="@/assets/contract_top52.png" alt="">
					</div>
				</div>
			</div>
		</div>
		<div class="rx-wsc">
			<div class="w220 mr12 mb12">
				<el-input type="text" v-model="searchForm.signNameLike" placeholder="请输入合同名称"></el-input>
			</div>
			<div class="w220 mr12 mb12">
				<Select :list="pageData.memberList" placeholder="全部会员" :width="220" @change="getUserList" @itemclick="itemclickEvent"></Select>
			</div>
			<div class="w220 mr12 mb12">
				<el-select v-model="searchForm.signFlowStatus" placeholder="全部状态">
					<el-option value="" label="全部状态"></el-option>
					<el-option value="1" label="签署中"></el-option>
					<el-option value="2" label="已完成"></el-option>
					<el-option value="3" label="已撤销"></el-option>
					<el-option value="5" label="已过期"></el-option>
					<el-option value="7" label="已拒签"></el-option>
					<el-option value="11" label="解约中"></el-option>
					<el-option value="13" label="已解约"></el-option>
					<el-option value="0" label="待生成"></el-option>
					<el-option value="20" label="待发起"></el-option>
				</el-select>
			</div>
			<div class="w220 mr12 mb12">
				<el-select v-model="searchForm.createBy" placeholder="全部发起人">
					<el-option value="" label="全部发起人"></el-option>
					<el-option :label="item.realName" :value="item.id" v-for="item in pageData.userList"></el-option>
				</el-select>
			</div>
			<div class="mr12 mb12">
				<el-date-picker
				        v-model="searchForm.createTime"
				        type="daterange"
				        range-separator="-"
				        start-placeholder="发起开始时间"
				        end-placeholder="发起结束时间"
						value-format="YYYY-MM-DD"
				      />
			</div>
			<div class="mr12 mb12">
				<el-date-picker
				        v-model="searchForm.expireTime"
				        type="daterange"
				        range-separator="-"
				        start-placeholder="截止开始时间"
				        end-placeholder="截止结束时间"
						value-format="YYYY-MM-DD"
				      />
			</div>
			<el-button class="mb12" type="primary" @click="getList(1)">搜索</el-button>
			<el-button class="mb12" type="primary" @click="toInitiate">发起合同</el-button>
		</div>
		<div class="color-999 mb12">签署合同发起后不管是否签署完成都会消耗<span class="color-409EFF">1</span>份，而解约合同则为双方签署成功之后，才会消耗<span class="color-409EFF">1</span>份合同份额</div>
		<el-table ref="table" :data="pageData.list" stripe style="width: 100%" height="65vh">
			<el-table-column prop="id" fixed label="合同ID" width="100"/>
			<el-table-column fixed label="合同名称" width="120" >
				<template #default="scope">
					<span v-if="scope.row.signFlowStatus  == 0 && scope.row.rescissionStatus == 0">{{scope.row.signName}}</span>
					<span v-else @click="toSignDetail(scope.row.encryptionId)" class="color-409EFF c_p tool_btn">{{scope.row.signName}}</span>
				</template>
			</el-table-column>
			<el-table-column prop="realName" label="会员名称" min-width="100"/>
			<el-table-column label="合同状态" min-width="120">
				<template #default="scope">
					<div class="rx-sc color-00a3ff" v-if="scope.row.signFlowStatus  == 1 && scope.row.rescissionStatus == 0"><span class="fwb ft30">·</span>签署中</div>
					<div class="rx-sc color-999" v-if="scope.row.signFlowStatus  == 5 && scope.row.rescissionStatus == 0"><span class="fwb ft30">·</span>已过期</div>
					<div class="rx-sc color-999" v-if="scope.row.signFlowStatus  == 3 && scope.row.rescissionStatus == 0"><span class="fwb ft30">·</span>撤销签署</div>
					<div class="rx-sc color-25D384" v-if="scope.row.signFlowStatus  == 2 && scope.row.rescissionStatus == 0"><span class="fwb ft30">·</span>签署完成</div>
					<div class="rx-sc color-red" v-if="scope.row.signFlowStatus  == 20 && scope.row.rescissionStatus == 0"><span class="fwb ft30">·</span>待发起</div>
					<div class="rx-sc color-red" v-if="scope.row.signFlowStatus  == 7 && scope.row.rescissionStatus == 0"><span class="fwb ft30">·</span>已拒签</div>
					<div class="rx-sc color-red" v-if="scope.row.signFlowStatus  == 0 && scope.row.rescissionStatus == 0"><span class="fwb ft30">·</span>待生成</div>
					<div class="rx-sc color-red" v-if="scope.row.rescissionStatus == 3"><span class="fwb ft30">·</span>解约完成</div>
					<div class="rx-sc color-red" v-if="scope.row.rescissionStatus == 1"><span class="fwb ft30">·</span>解约签署中</div>
				</template>
			</el-table-column>
			<el-table-column prop="createName" label="发起人" min-width="100"/>
			<el-table-column prop="createTime" label="发起时间" min-width="170"/>
			<el-table-column prop="expireTime" label="签署截止时间" min-width="170"/>
			<el-table-column label="签署地址" min-width="100">
				<template #default="scope">
					<span class="btn1 copy" @click="copyEvent(scope.row.shortUrl)">点击复制</span>
				</template>
			</el-table-column>
			<!-- <el-table-column label="会员端展示" min-width="100">
				<template #default="scope">
					<div class="show"></div>
				</template>
			</el-table-column> -->
			<el-table-column fixed="right" label="操作" width="200">
				<template #default="scope">
					<a v-if="scope.row.signFlowStatus  == 1 || scope.row.rescissionStatus == 1" href="javascript:;" class="t_d_u color-red tool_btn" @click="backEvent(scope.row.encryptionId)">撤回</a>
					<a v-if="scope.row.signFlowStatus  == 5 || scope.row.signFlowStatus  == 3" href="javascript:;" class="t_d_u color-409EFF tool_btn" @click="againEvent(scope.row.encryptionId)">重新发起</a>
					<a v-if="scope.row.signFlowStatus  == 2 && scope.row.rescissionStatus != 3" href="javascript:;" class="t_d_u color-409EFF tool_btn" @click="downloadEvent(scope.row.encryptionId)">下载</a>
					<a v-if="scope.row.signFlowStatus  == 2 && scope.row.rescissionStatus == 0" href="javascript:;" class="t_d_u color-red tool_btn" @click="breakEvent(scope.row.encryptionId)">解约</a>
					<a v-if="scope.row.signFlowStatus  == 20 && scope.row.rescissionStatus == 0" href="javascript:;" class="t_d_u color-409EFF tool_btn" @click="initiateEvent(scope.row.encryptionId)">发起合同</a>
					<a v-if="scope.row.signFlowStatus  == 0 && scope.row.rescissionStatus == 0" href="javascript:;" class="t_d_u color-409EFF tool_btn" @click="generateEvent(scope.row.shortUrl)">生成合同</a>
					<a v-if="scope.row.signFlowStatus  == 0 && scope.row.rescissionStatus == 0" href="javascript:;" class="t_d_u color-red tool_btn" @click="deleteEvent(scope.row.encryptionId)">删除</a>
					<a href="javascript:;" class="t_d_u color-409EFF tool_btn" @click="detailEvent(scope.row)">详情</a>
				</template>
			</el-table-column>
		</el-table>
		<div class="mt20 rx-ec">
			<el-pagination v-model:currentPage="searchForm.page" :page-sizes="[15,20,30,50,100]"
				:page-size="searchForm.limit" layout="total, sizes, prev, pager, next, jumper"
				:total="pageData.count" @size-change="pageSizeChange" @current-change="()=>{getList()}">
			</el-pagination>
		</div>
		 <el-drawer
		    v-model="detailData.show"
		    title="合同详情"
		    direction="rtl"
		    :before-close="()=>{detailData.show = false}"
		  >
		    <div>
				<div class="rx-sc mb15 pb15 borderDashedBottom">
					<span class="color-999 ft14 w90">发起方</span>
					<span class="color-333 ft14" id="storeName">{{detailData.form.storeName}}</span>
				</div>
				<div class="rx-sc mb15 pb15 borderDashedBottom">
					<span class="color-999 ft14 w90">发起人</span>
					<span class="color-333 ft14" id="createName">{{detailData.form.createName}}</span>
				</div>
				<div class="rx-sc mb15 pb15 borderDashedBottom">
					<span class="color-999 ft14 w90">学员</span>
					<span class="color-333 ft14" id="realName">{{detailData.form.realName}}</span>
				</div>
				<div class="rx-sc mb15 pb15 borderDashedBottom">
					<span class="color-999 ft14 w90">合同发起时间</span>
					<span class="color-333 ft14" id="createTime">{{detailData.form.createTime}}</span>
				</div>
				<div class="rx-sc mb15 pb15 borderDashedBottom">
					<span class="color-999 ft14 w90">合同截止时间</span>
					<span class="color-333 ft14" id="expireTime">{{detailData.form.expireTime}}</span>
				</div>
				<div class="rx-sc mb15 pb15 borderDashedBottom">
					<span class="color-999 ft14 w90">合同ID</span>
					<span class="color-333 ft14" id="financeId">{{detailData.form.id || '-'}}</span>
				</div>
			</div>
		</el-drawer>
	</div>
</template>

<script setup>
	defineProps({
	  info: Object
	})
	import { ref, reactive,onMounted ,onUnmounted} from 'vue';
	import { ElMessage,ElMessageBox  } from 'element-plus'
	import { 
		getEsignFlowList,
		getCardMyList,
		getAdviserList,
		cancelEsignFlow,
		rescissionEsignFlow,
		updateDelEsignFlow,
		reInsertEsignFlow,
		insertEsignFlow,
		downloadSignFile,
		stuShowFlagUpdate,
		getEsignFlowInfo
		} from '@/api/contract.js';
	import Input from '@/components/Input.vue';
	import Select from '@/components/Select.vue';
	import {useRouter } from 'vue-router';
	const emit = defineEmits(['recharge']);
	const router = useRouter();
	const pageData = reactive({
		list:[],
		userList:[],
		count:0,
	})
	const searchForm = reactive({
		page:1,
		limit:15,
		signNameLike:'',
		cardMyId:'',
		signFlowStatus:'',
		financeId:'',
		createBy:'',
		createTime:'',
		expireTime:'',
	})
	//远程搜索会员
	function itemclickEvent(val){
		console.log(val)
		searchForm.cardMyId = val;
	}
	getUserList();
	function getUserList(query){
		getCardMyList({
			searchKey:query
		}).then(res=>{
			pageData.memberList = res.data.map(n=>{
				return {
					label:n.realName,
					value:n.cardMyId
				}
			});
		})
	}
	//操作人列表
	getAdviserList().then(res=>{
		pageData.userList = res.data;
	})
	function pageSizeChange(limit){
		searchForm.limit = limit;
		getList(1);
	}
	getList();
	function getList(page){
		if(page){
			searchForm.page = 1;
		}
		var formData = JSON.parse(JSON.stringify(searchForm));
		formData.createTimeStr = formData.createTime && formData.createTime.length > 0 ? formData.createTime.join(' - ') : '';
		formData.expireTimeStr = formData.expireTime && formData.expireTime.length > 0 ? formData.expireTime.join(' - ') : '';
		delete formData.createTime;
		delete formData.expireTime;
		getEsignFlowList(formData).then(res=>{
			pageData.list = res.data;
			pageData.count = res.count;
		})
	}
	//查看合同详情
	function toSignDetail(encryptionId){
		getEsignFlowInfo({
			encryptionId
		}).then(res=>{
			window.open('https://s.cardmee.net/cardmee_stu_m/contract/js/pdf/web/viewer.html?file='+res.data.fileDownloadUrl+'&title='+res.data.signName )
		}) 
	}
	//复制
	function copyEvent(url){
		let ele = document.createElement('textarea');
		ele.setAttribute('readonly','readonly');
		ele.value = url;
		document.body.appendChild(ele);
		ele.select();
		let isTrue = document.execCommand('copy');
		ElMessage.success(isTrue ? '复制成功' : '复制失败');
		document.body.removeChild(ele);
	}
	//发起合同
	function toInitiate(){
		router.push('/branch/initiate');
	}
	//充值记录
	function toRecord(){
		router.push('/branch/record');
	}
	//撤回
	function backEvent(encryptionId){
		ElMessageBox.confirm(
		    '是否确认撤回？撤回后，乙方进入签署地址将显示已撤回，无法签署',
		    '提示',
		    {
		      confirmButtonText: '确定',
		      cancelButtonText: '取消',
		      type: 'warning',
		    }
		  ).then(() => {
			cancelEsignFlow({
				encryptionId
			}).then(res=>{
				ElMessage.success('撤回成功');
				getList(1);
			}) 
		     
		})
	}
	//解约
	function breakEvent(encryptionId){
		ElMessageBox.confirm(
		    '是否确认解约？解约合同发起后，将自动以短信的形式给家长发送通知，提醒家长有效期前进行解约签署，解约签署成功后会消耗1份合同份额',
		    '提示',
		    {
		      confirmButtonText: '确定',
		      cancelButtonText: '取消',
		      type: 'warning',
		    }
		  ).then(() => {
			rescissionEsignFlow({
				encryptionId
			}).then(res=>{
				ElMessage.success('解约成功');
				getList(1);
			}) 
		     
		})
	}
	//删除
	function deleteEvent(encryptionId){
		ElMessageBox.confirm(
		    '确定删除该合同吗？',
		    '提示',
		    {
		      confirmButtonText: '确定',
		      cancelButtonText: '取消',
		      type: 'warning',
		    }
		  ).then(() => {
			updateDelEsignFlow({
				encryptionId
			}).then(res=>{
				ElMessage.success('删除成功');
				getList(1);
			}) 
		     
		})
	}
	//重新发起
	function againEvent(encryptionId){
		ElMessageBox.confirm(
		    '合同发起后，将自动以短信的形式给家长发送通知，提醒家长有效期前进行签署，家长签署完成即合同完成',
		    '提示',
		    {
		      confirmButtonText: '确定',
		      cancelButtonText: '取消',
		      type: 'warning',
		    }
		  ).then(() => {
			reInsertEsignFlow({
				encryptionId
			}).then(res=>{
				ElMessage.success('发起成功');
				getList(1);
			}) 
		     
		})
	}
	//发起合同
	function initiateEvent(encryptionId){
		ElMessageBox.confirm(
		    '合同发起后，将自动以短信的形式给家长发送通知，提醒家长有效期前进行签署，家长签署完成即合同完成',
		    '提示',
		    {
		      confirmButtonText: '确定',
		      cancelButtonText: '取消',
		      type: 'warning',
		    }
		  ).then(() => {
			insertEsignFlow({
				encryptionId
			}).then(res=>{
				ElMessage.success('发起成功');
				getList(1);
			}) 
		     
		})
	}
	//下载
	function downloadEvent(encryptionId){
		downloadSignFile({
			encryptionId
		}).then(res=>{
			window.location.href = res.data.fileDownloadUrl;
		}) 
	}
	//生成合同
	function generateEvent(url){
		window.open(url);
	}
	//查看详情
	const detailData = reactive({
		show:false,
		form:{}
	})
	//查看详情
	function detailEvent(row){
		detailData.form = row;
		var userInfo = JSON.parse(window.localStorage.getItem('businessUserData'));
		detailData.form.storeName = userInfo.storeName;
		detailData.show = true;
	}
	//充值
	function rechargeEvent(){
		emit('recharge');
	}
	onMounted(()=>{
		document.addEventListener('visibilitychange',function(){
			if( document.visibilityState == 'visible'){
				getList(1);
			}
		})
	})
	onUnmounted(()=>{
		document.removeEventListener('visibilitychange',function(){});
	})
	
	
</script>

<style scoped>
	.rechargeBtns:hover{
		background-color: #00A3FF;
	}
	.rechargeBtns:hover b{
		color:white;
	}
	.rechargeBtns:hover .img1{
		display: none;
	}
	.rechargeBtns:hover .img2{
		display: block !important;
	}
</style>